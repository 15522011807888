import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";

const UtilityPieChart = (props) => {
  if (props?.dataRes?.length === 0) {
    return (
      <React.Fragment>
        <p> No record for this month </p>
      </React.Fragment>
    );
  }
  // console.log("props?.dataRes", props?.dataRes);
  const res = props?.dataRes?.map((item) => item.type);

  const newArray = props?.dataRes?.map((item) => ({
    name: item.type,
    value: item.cost,
  }));

  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: res,
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#02a499", "#f8b425", "#ec4561", "#272427", "#3BA915", "#F84D17"],
      series: [
        {
          name: "Total utility charges",
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: newArray,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={getOption()} />
    </React.Fragment>
  );
};
export default UtilityPieChart;
