import PropTypes from "prop-types"
import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody } from "reactstrap";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact"
import { withRouter, Link, useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next"

import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { Utility_Obj } from '../../APIs/Utility'

function UtilityTable() {
  const [dataDb, setData] = useState([
    {
      utility_name: 'Security',
      cost: '400',
      base_fee: '300',

      editBtn: <button className="btn report-primary text-white"
        onClick={() => history.push(`/add-property`)}>Edit</button>,

      deleteBtn: <button className="btn report-danger text-white"
        onClick={() => console.log("delete")}>Delete</button>,


    }
  ]);
  let history = useHistory();

  function handleClick(prm) {
    console.log(prm);
    history.push(`/utility-details/${prm}`);
  }

  var requestOptions = {
    method: 'POST',
    redirect: 'follow'
  };

  useEffect(() => {
    const fecthData = async () => {
      let data_arr = [];
      try {
        const dbData = await fetch("http://127.0.0.1:8000/api/utility/GetUtility", requestOptions)
        const dataJSN = await dbData.json();

        dataJSN.data.forEach(data => {
          data_arr.push({ id: data.UnitID, ...data, clickEvent: () => handleClick(data.UnitID) })
        });

        setData(data_arr);
      } catch (error) {
        console.log(error);
      }
    }
    fecthData();
  }, [])

  const column = [
    { label: "Utility Name", field: "utility_name", sort: "asc", width: 150 },
    { label: "Utility Cost", field: "cost", sort: "asc", width: 150 },
    { label: "Base Fee", field: "base_fee", sort: "asc", width: 150 },

    { label: "Action", field: "editBtn", sort: "asc", width: 150 },
    { label: "Action", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const data = {
    columns: column,
    rows: dataDb,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Utility" />
        <Breadcrumb default="Property" title="Utility" />

        <CardBody>
          <Container fluid>
            <Row className="d-flex justify-content-around align-items-center">
              <CardBody data-aos="fade-bottom">
                <div className="page-title-box mb-3">
                  {/*  <Link to="/add-utility" className="btn login-btn w-md waves-effect waves-light" type="submit"> + Add Utility </Link> */}
                </div>
                <Card className="bd-rds">
                  <CardBody>
                    <MDBDataTable entries={5} entriesOptions={[5, 10, 50]} responsive bordered striped hover data={data} fullPagination />
                  </CardBody>
                </Card>
              </CardBody>
            </Row>
          </Container>
        </CardBody>
      </div>
    </React.Fragment>
  )
}

UtilityTable.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(UtilityTable))