import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Col, Container, Row, CardBody, Card, Spinner, } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { withRouter, Link, useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumb from "../../components/Common/Breadcrumb"
import MetaTagComp from "../../components/Common/MetaTags"
import QRCode from 'qrcode.react';
import { var_num } from "./../../components/Vatnumber";
import { useStore1Selector } from "index"
import { loginUser } from "Redux/Slices/userSlice"
import { successMessage, warningMessage } from "components/Notifications"
import uuid from 'react-uuid';

function AddAssets() {
  const history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const propId = userDet?.original?.userDetails?.OwnerID;
  const portfolioPropId = userDet?.OwnerID
  const [value, setValue] = useState("")
  const [data, setData] = useState();
  const [blockId, setBlockId] = useState();
  const [dataBlock, setDataBlock] = useState();
  const [dataUnit, setDataUnit] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [loadBtn, setIloadBtn] = useState(false);
  const [QRCodeVar, setQRCodeVar] = useState();
  const [detailsData, setDetails] = useState();
  const [displayBtn, setDisplayBtn] = useState(false);
  const [code, setCode] = useState(uuid());
  const [selectedBlockId, setSelectedBlockId] = useState([]);
  const [selectPropId, setSelectPropId] = useState([]);

  console.log(" portfolioPropId : ", portfolioPropId)

  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    setValue();
    setIloadBtn(true)
    setDetails(values)

    const formdata = new FormData();
    formdata.append("AssetType", "");
    formdata.append("CompanyName", "");
    formdata.append("CompanyRegNumber", "");
    formdata.append("PropertyID", values.propertyName);
    formdata.append("UnitID", values.assign_unit);
    formdata.append("BlockID", !values.assign_block ? " " : values.assign_block);
    formdata.append("AssetName", values.assetName);
    formdata.append("NextMaintananceDate", values.maintainance);
    formdata.append("DateOfInstallationDate", values.installation);
    formdata.append("CostOfReplacement", values.cost);
    formdata.append("Model", values.model);
    formdata.append("SerialNumber", values.serialNumber);
    formdata.append("Attachment", selectedFile);
    formdata.append("QRCocde", code.split('-')[0]);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://prop.faspro24.com/api/assets/AddAssets", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          successMessage("Successful added");
          setIloadBtn(false);
          setDisplayBtn(true)
        }
      })
      .catch((error) => {
        warningMessage(`try again something went wrong${error.message}`);
        setIloadBtn(false);
      });
  };

  const downloadQRCode = () => {
    const qrCodeURL = document.getElementById('qrCodeEl').toDataURL("image/png").replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://prop.faspro24.com/api/property/GetPropertyList/${propId ? propId : portfolioPropId}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setData(result.data.sort().reverse())
      })
      .catch(error => console.log('error', error));
  }, []);

  function handleChange(event) {
    setSelectPropId(event.target.value)
  }

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    fetch(`https://prop.faspro24.com/api/property/GetBlockList/${selectPropId}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setDataBlock(result?.data.sort().reverse())
      })
      .catch(error => console.log('error', error));
  }, [selectPropId])

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://prop.faspro24.com/api/unit/GetUnitsByPropertyID/${selectPropId}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setDataUnit(result?.data.sort().reverse())
      })
      .catch(error => console.log('error', error));
  }, [selectPropId])

  const handleFileChange = event => {
    setSelectedFile(event.target.files[0]);
  }
  const handleBlock = event => {
    setSelectedBlockId(event.target.value);
  }

  const names = [
    detailsData?.assetName,
    detailsData?.model,
    detailsData?.maintainance,
    code.split('-')[0]
  ]

  const generateQRCode = () => {
    setQRCodeVar(names)
  }

  const getPropDet = data?.filter(propDet => { return propDet.PropertyID === parseInt(selectPropId) })
  const propDet = !getPropDet ? null : getPropDet[0];

  const filterProp = data?.filter(prop => {
    const arr_ = prop.PropertyID === parseInt(blockId)
    return arr_
  })

  const resData = !filterProp ? null : filterProp[0];

  const unitUnderBlockID = dataUnit?.filter(res => {
    return res?.BlockID === parseInt(selectedBlockId)
  })


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Add Asset" />
        <Breadcrumb default="Property" title="Add Asset" />

        <Container fluid>
          <div className="page-title-box">
            <CardBody data-aos="fade-bottom">
              <Row className="align-items-center mb-5">
                <div>
                  <Link to="/my-assets" className="btn login-btn w-md waves-effect waves-light" type="submit"> Back </Link>
                </div>
              </Row>
              <Card className="bd-rds">
                <div className="TenantHeader bd-rds-header"> <h5> Add an assets </h5> </div>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={12} lg={12} xl={12}>
                      <AvForm className="form-horizontal mt-4" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>
                        <Row>
                          <Row>
                            <Col md={4}>
                              <div className="mb-3">
                                <AvField
                                  name="propertyName" label="Assign Property" className="form-control" type="select" required
                                  onChange={handleChange}>
                                  <option> Select... </option>
                                  {
                                    data?.map(property => (
                                      <option value={property?.PropertyID}> {property?.Name} </option>
                                    ))
                                  }
                                </AvField>
                              </div>
                            </Col>

                            {
                              // resData?.are_blocks_available === 1 ? (
                              propDet?.are_blocks_available === 1 ? (
                                <Col md={3}>
                                  <div className="mb-3">
                                    <AvField name="assign_block"
                                      label="Select block"
                                      className="form-control"
                                      type="select"
                                      onChange={handleBlock}
                                    >
                                      <option> Select... </option>
                                      {dataBlock?.map((block) => (
                                        <option value={block?.id}> {block?.BlockName} </option>
                                      ))}
                                    </AvField>
                                  </div>
                                </Col>
                              ) : null
                            }

                            {
                              propDet?.are_blocks_available === 0 ? (
                                <Col md={4}>
                                  <div className="mb-3">
                                    <AvField
                                      name="assign_unit" label="Assign Unit Name" className="form-control" type="select" required
                                    >
                                      <option> Select... </option>
                                      {
                                        dataUnit?.map(unit => (
                                          <option value={unit?.UnitID}> {unit?.Name} </option>
                                        ))
                                      }
                                    </AvField>
                                  </div>
                                </Col>
                              ) : null
                            }
                            {
                              propDet?.are_blocks_available === 1 ? (
                                <Col md={4}>
                                  <div className="mb-3">
                                    <AvField
                                      name="assign_unit" label="Assign Unit Name" className="form-control" type="select" required
                                    >
                                      <option> Select... </option>
                                      {
                                        unitUnderBlockID?.map(unit => (
                                          <option value={unit?.UnitID}> {unit?.Name} </option>
                                        ))
                                      }
                                    </AvField>
                                  </div>
                                </Col>
                              ) : null
                            }

                          </Row>


                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="assetName" label="Asset Name" className="form-control" type="text" required
                              />
                            </div>

                            <div className="mb-3">
                              <AvField
                                name="cost" label="Cost of Replacement" className="form-control" type="text" required
                              />
                            </div>
                          </Col>


                          <Col md={6}>
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField
                                    name="maintainance" label="Next Maintainance Date" className="form-control" type="date" required
                                  />
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField
                                    name="installation" label="Date of Installation" className="form-control" type="date" required
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField
                                    name="model" label="Model" className="form-control" type="text" required
                                  />
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField
                                    name="serialNumber" label="Serial Number" className="form-control" type="text" required
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Col md={12}>
                              <div className="mb-3">
                                <AvField
                                  name="asset_image" label="Upload asset image (recommended dimension 600 x 400)" className="form-control" type="file" required
                                  onChange={handleFileChange}
                                />
                              </div>
                            </Col>

                          </Col>

                        </Row>
                        {
                          !displayBtn ? (
                            <button className="btn login-btn w-md waves-effect waves-light" type="submit">
                              {!loadBtn ? <span className="me-2"> Submit </span> : null}
                              {!loadBtn ? null : (
                                <span> <Spinner as="span" animation="border" size="sm" className="me-2" /> Loading... </span>
                              )}
                            </button>
                          ) : null
                        }
                      </AvForm>
                      {
                        displayBtn ? (
                          <button className="btn login-btn w-md waves-effect waves-light me-5" type="submit" onClick={generateQRCode}> Generate QRCode </button>
                        ) : (
                          null
                        )
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardBody>
          </div>
        </Container>

        <CardBody>
          <CardBody>
            <Row className="text-center">
              <Col md={12}>
                <Card className="bd-rds">
                  <CardBody>
                    <CardBody>

                      <QRCode
                        value={QRCodeVar}
                        size={300}
                        id="qrCodeEl"
                        renderAs="canvas"
                        includeMargin='true'
                        level='Q'
                      />
                      <br />
                      <br />
                      <input type="button" className="btn login-btn w-md waves-effect waves-light" value="Download" onClick={downloadQRCode} />

                    </CardBody>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </CardBody>

      </div>
    </React.Fragment>
  )
}

AddAssets.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(AddAssets))
