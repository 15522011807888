import React, { useState } from 'react'
import { Row, Col, Card, CardBody } from "reactstrap"
import { AvForm, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import { modules } from "./Object"

function My_modules() {
    const [check, setCheck] = useState("");

    const handleValidSubmit = (values) => {
        console.log(values)
    }

    return (
        <AvForm className="mt-4" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>
            <AvCheckboxGroup name="checkboxExample" >

                <Row className="main-container">
                    {
                        modules().map((module, idx) => (
                            <Col md={6} key={idx}>
                                <Card>
                                    <CardBody className="d-flex">
                                        <div className="img__container">
                                            <img src={module.img} alt="" />
                                        </div>
                                        <div className="checkbox">
                                            <div>
                                                <h3>${module.price}<small>/month</small></h3>
                                                <small className="text-center"> {module.text} </small>
                                            </div>
                                            <p>
                                                <AvCheckbox
                                                    label={module.label}
                                                    value={module.value}
                                                    disabled={module.disable}
                                                    checked={module.checked}
                                                    onChange={e => {
                                                        setCheck(e.target.checked);
                                                    }} />
                                            </p>
                                        </div>
                                    </CardBody>
                                    <div className="border__bottom"></div>
                                </Card>
                            </Col>

                        ))
                    }
                </Row>

            </AvCheckboxGroup>


        </AvForm>
    )
}

export default My_modules