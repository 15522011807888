import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, Modal, Button, Spinner } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
import { ImEye } from "react-icons/im";
import { useStore1Selector } from "./../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
import Loading from "components/Loading";
import BlockModel from "./components/BlockModel";
import EditPropertyForm from "./components/Edit-Prop-Details";
import { warningMessage, successMessage } from "../../components/Notifications";
import { BsFillHouseFill } from "react-icons/bs";
import useCustomQuery from "../../hooks/useCustomQuery";
import useCustomMutation from "../../hooks/useCustomMutation";
import LoadingTable from "../../components/LoadingTable";

function PropertyTable(props) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const UserRole = userDet?.data?.roles[0];
  const [propertyID, setPropertyID] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const key = ["myProperties"];
  const mutation = useCustomMutation(key);

  const teamsArray = process.env.REACT_APP_TEAM.split(",");
  const [myId, setMyId] = useState(
    teamsArray.includes(UserRole) ? "teamId" : "userId"
  );

  let list = [];

  //access property as Team or prop owner

  const {
    data: getMyProperties,
    error,
    isLoading,
  } = useCustomQuery(
    ["myProperties"],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/property_owner?fieldName=${myId}`,
    token
  );

  // console.log("properties...", getMyProperties);

  function deleteFunc(id) {
    setPropertyID(id);
    setDeleteModal(true);
  }

  getMyProperties?.forEach((res) => {
    list.push({
      ...res,
      viewBtn: (
        <Link to={`/property-details/${res?._id}`} className="btn btn-success">
          {props.t("Detailed view")}
        </Link>
      ),
      editBtn: (
        <Link to={`/edit-property/${res?._id}`} className="btn btn-primary">
          {props.t("Edit")}
        </Link>
      ),
      deleteBtn: (
        <button
          className="btn btn-danger"
          onClick={() => deleteFunc(`${res?._id}`)}
        >
          {props.t("Delete")}
        </button>
      ),
    });
  });

  const deleteProperty = () => {
    const Method = "DELETE",
      endPoint = `properties/${propertyID}`,
      isJSON = true;
    var formdata = new FormData();

    const raw = formdata;
    const data = {
      endPoint: endPoint,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Property Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const column = [
    { label: "Property Name", field: "propertyName", sort: "asc", width: 150 },
    { label: "Property Type", field: "propertyType", sort: "asc", width: 150 },
    { label: "City", field: "city", sort: "asc", width: 150 },
    { label: "Address", field: "address", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const newdata = {
    columns: column,
    rows: list,
  };
  useEffect(() => {
    if (mutation.isSuccess) {
      setDeleteModal(false);
    }
  }, [mutation.isSuccess]);

  // const someVar = JSON.parse(process.env.REACT_APP_OWNER_ROLES);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | My Property" />
        <Breadcrumb
          default={props.t("Facility Managements")}
          title={props.t("My Properties")}
        />

        <Container fluid>
          <Row
            className="d-flex justify-content-around align-items-center"
            data-aos="fade-bottom"
          >
            <Card.Body>
              <Card className="bd-rds">
                <Card.Body className="d-flex align-items-center">
                  <p className="me-3">
                    <BsFillHouseFill size={32} />
                  </p>
                  <h5>{props.t("My Properties")} </h5>
                </Card.Body>
              </Card>

              <Card className="bd-rds">
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <Link
                      to="/add-property"
                      className="btn login-btn w-md waves-effect waves-light mb-4 report-primary"
                      type="submit"
                    >
                      {" "}
                      + {props.t("Add property")}{" "}
                    </Link>

                    <button className="btn login-btn w-md waves-effect waves-light mb-4 d-flex justify-content-end report-warning">
                      <CSVLink
                        data={list?.reverse()}
                        separator={";"}
                        className="text-white"
                      >
                        {props.t("Download in Excel")}
                      </CSVLink>
                    </button>
                  </div>
                  {isLoading ? (
                    <LoadingTable />
                  ) : (
                    <MDBDataTable
                      entries={5}
                      entriesOptions={[5, 10, 50]}
                      responsive
                      bordered
                      striped
                      hover
                      data={newdata}
                    />
                  )}
                </Card.Body>
              </Card>
            </Card.Body>
          </Row>
        </Container>

        <Modal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Property</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 className="text-danger">
              {props.t("Are you sure you want to delete this property ? ")}
            </h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setDeleteModal(false)}>
              {props.t("No")}
            </Button>

            <Button variant="danger" onClick={deleteProperty}>
              {!mutation.isLoading ? (
                <>{props.t("Yes")}</>
              ) : (
                <>
                  <span>
                    <Spinner as="span" animation="border" size="sm" />{" "}
                    Loading...
                  </span>
                </>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}

PropertyTable.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(PropertyTable));
