import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";
import { AiFillPrinter } from "react-icons/ai";

import { withTranslation } from "react-i18next";
import Navbar from "./components/Navbar";
import PageYearlyTickets from "./components/PageYearlyTickets";
import PageMonthlyTickets from "./components/PageMonthlyTickets";
import PageMonthlyTicketsCompare from "./components/PageMonthlyTicketsCompare";
import PageYearlyTasks from "./components/PageYearlyTasks";
import PageMonthlyTasks from "./components/PageMonthlyTasks";
import PageTaskTiming from "./components/PageTaskTiming";
import MaintenanceCost from "./components/MaintenanceCost";
import PropertyMaintenanceCost from "./components/PropertyMaintenanceCost";

function Report({ t }) {
  const [totalCost, setTotalCost] = useState(null);
  const handlePrint = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Facility Maintenance Report" />
        <Breadcrumb default={t("Facility Maintenance")} title={t("report")} />
        <Container fluid>
          <Row>
            <div className="mb-3 d-flex justify-content-end">
              <button
                className="btn btn-warning print-button"
                onClick={handlePrint}
              >
                <AiFillPrinter
                  size={20}
                  color="white"
                  className="me-1 mb-1 mt-1"
                />
                <span className="text-white h6">Print Report</span>
              </button>
            </div>
          </Row>
          <Navbar t={t} totalCost={totalCost} />
          <Row>
            <Col md={12}>
              <PropertyMaintenanceCost />
            </Col>
            <Col md={12}>
              <PageYearlyTickets setTotalCost={setTotalCost} />
            </Col>
            <Col md={6}>
              <PageMonthlyTickets />
            </Col>
            <Col md={6}>
              <PageMonthlyTicketsCompare />
            </Col>
          </Row>
          <Row>
            {/* <Col md={12}>
              <PageYearlyTasks />
            </Col>
            <Col md={6}>
              <PageMonthlyTasks />
            </Col>
            <Col md={6}>
              <PageTaskTiming />
            </Col> */}

            {/* <Col md={12}>
              <MaintenanceCost />
            </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

Report.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(Report));
