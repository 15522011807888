import React, { useState } from "react";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import { Card, Modal, Button } from "react-bootstrap";
import { useStore1Selector } from "../../../../../index";
import { loginUser } from "../../../../../Redux/Slices/userSlice";
import usePost from "../../../../../hooks/usePost";
// import Unit_View_Notice from "./Unit_View_Notice";
import UtilitiesEditForm from "./UtilitiesEditForm";

function UnitUtilitiesTableForm({
  propID,
  unitID,
  unitUtilityTable,
  reFetchUtility,
}) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending } = usePost();
  const [editNoticeModal, setEditUtilityModal] = useState(false);
  const [viewUtilityModal, setViewUtilityModal] = useState(false);
  const [deleteUtilityModal, setDeleteUtilityModal] = useState(false);

  const [deleteUtilityID, setDeleteUtilityID] = useState();
  const [viewUtilityID, setViewUtilityID] = useState();
  const [editUtilityID, setEditUtilityID] = useState();
  const listUtility = [];
  unitUtilityTable.forEach((res) => {
    listUtility.push({
      ...res,
      unitName: res?.unitId?.unitName,
      utility_cost: `GH₵ ${res?.cost}`,
      viewBtn: (
        <button
          className="btn btn-success"
          onClick={() => viewUnitUtilityFunc(res?._id)}
        >
          view
        </button>
      ),
      editBtn: (
        <button
          className="btn btn-primary"
          onClick={() => editUnitUtilityFunc(res?._id)}
        >
          Edit
        </button>
      ),
      deleteBtn: (
        <button
          className="btn btn-danger"
          onClick={() => deleteUnitUtilityFunc(res?._id)}
        >
          Delete
        </button>
      ),
    });
  });

  const columnNotice = [
    { label: "Utility Name", field: "utilityName", sort: "asc", width: 150 },
    { label: "Utility Type", field: "type", sort: "asc", width: 150 },
    { label: "Rate", field: "rate", sort: "asc", width: 150 },
    { label: "Consumption", field: "consumption", sort: "asc", width: 150 },
    { label: "Utility Cost", field: "utility_cost", sort: "asc", width: 150 },
    { label: "Provider", field: "provider", sort: "asc", width: 150 },
    // { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    // { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Action", field: "deleteBtn", sort: "asc", width: 150 },
  ];
  const DataUtility = {
    columns: columnNotice,
    rows: listUtility,
  };

  function viewUnitUtilityFunc(id) {
    setViewUtilityID(id);
    setViewUtilityModal(true);
  }

  function editUnitUtilityFunc(id) {
    setEditUtilityID(id);
    setEditUtilityModal(true);
  }

  function deleteUnitUtilityFunc(id) {
    setDeleteUtilityID(id);
    setDeleteUtilityModal(true);
  }
  const deleteUnitUtility = () => {
    const Method = "DELETE",
      endPoint = `utilities/${deleteUtilityID}`,
      isJSON = true;
    var formdata = new FormData();
    execute(
      endPoint,
      formdata,
      Method,
      "Utility Successfully Deleted",
      token,
      isJSON
    );
    setDeleteUtilityModal(false);
  };
  if (pending) {
    setTimeout(() => {
      reFetchUtility();
    }, 2000);
  }

  return (
    <Card className="bd-rds">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <button className="btn login-btn w-md waves-effect waves-light mb-4 d-flex justify-content-end report-warning">
            <CSVLink
              data={"ExtraChargesData"}
              headers={listUtility?.reverse()}
              separator={";"}
              className="text-white"
            >
              Download in Excel
            </CSVLink>
          </button>
        </div>
        <MDBDataTable
          entries={5}
          entriesOptions={[5, 10, 50]}
          responsive
          bordered
          striped
          hover
          data={DataUtility}
        />
      </Card.Body>
      <Modal
        className="modal-close-out"
        show={viewUtilityModal}
        onHide={() => setViewUtilityModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>View utility </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* <Unit_View_Notice invoiceID={viewUtilityID} /> */}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={() => setViewUtilityModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="modal-close-out"
        show={editNoticeModal}
        onHide={() => setEditUtilityModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> Edit utility </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <UtilitiesEditForm
            unitID={unitID}
            utilityID={editUtilityID}
            closeModal={setEditUtilityModal}
            reFetchUtility={reFetchUtility}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={() => setEditUtilityModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={deleteUtilityModal}
        onHide={() => setDeleteUtilityModal(false)}
        size="sm"
      >
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to delete this utility ? </h6>{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setDeleteUtilityModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteUnitUtility}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
}

export default UnitUtilitiesTableForm;
