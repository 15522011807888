import React, { useState } from "react";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import { Container, Row, Card, Modal, Button } from "react-bootstrap";
import { useStore1Selector } from "../../../../../index";
import { loginUser } from "../../../../../Redux/Slices/userSlice";
import usePost from "../../../../../hooks/usePost";
import Unit_View_Notice from "./Unit_View_Notice";
import Unit_Edit_Notice from "./Unit_Edit_Notice";

function UnitNoticesTableForm({
  propID,
  unitID,
  unitNoticeTable,
  reFetchNotice,
}) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending } = usePost();
  const [editNoticeModal, setEditNoticeModal] = useState(false);
  const [viewNoticeModal, setViewNoticeModal] = useState(false);
  const [deleteNoticeModal, setDeleteNoticeModal] = useState(false);

  const [deleteNoticeID, setDeleteNoticeID] = useState();
  const [viewNoticeID, setViewNoticeID] = useState();
  const [editNoticeID, setEditNoticeID] = useState();
  const listNotice = [];
  unitNoticeTable.forEach((res) => {
    listNotice.push({
      ...res,
      unitName: res?.unitId?.unitName,
      viewBtn: (
        <button
          className="btn btn-success"
          onClick={() => viewUnitNoticeFunc(res?._id)}
        >
          view
        </button>
      ),
      editBtn: (
        <button
          className="btn btn-primary"
          onClick={() => editUnitNoticeFunc(res?._id)}
        >
          Edit
        </button>
      ),
      deleteBtn: (
        <button
          className="btn btn-danger"
          onClick={() => deleteUnitNoticeFunc(res?._id)}
        >
          Delete
        </button>
      ),
    });
  });

  const columnNotice = [
    { label: "Unit Name", field: "unitName", sort: "asc", width: 150 },
    { label: "Subject", field: "subject", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];
  const DataNotice = {
    columns: columnNotice,
    rows: listNotice,
  };

  function viewUnitNoticeFunc(id) {
    setViewNoticeID(id);
    setViewNoticeModal(true);
  }

  function editUnitNoticeFunc(id) {
    setEditNoticeID(id);
    setEditNoticeModal(true);
  }

  function deleteUnitNoticeFunc(id) {
    setDeleteNoticeID(id);
    setDeleteNoticeModal(true);
  }
  const deleteUnitNotice = () => {
    const Method = "DELETE",
      endPoint = `notices/${deleteNoticeID}`,
      isJSON = true;
    var formdata = new FormData();
    execute(
      endPoint,
      formdata,
      Method,
      "Notice Successfully Deleted",
      token,
      isJSON
    );
    setDeleteNoticeModal(false);
  };
  if (pending) {
    setTimeout(() => {
      reFetchNotice();
    }, 2000);
  }
  return (
    <Card className="bd-rds">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <button className="btn login-btn w-md waves-effect waves-light mb-4 d-flex justify-content-end report-warning">
            <CSVLink
              data={"ExtraChargesData"}
              headers={listNotice?.reverse()}
              separator={";"}
              className="text-white"
            >
              Download in Excel
            </CSVLink>
          </button>
        </div>
        <MDBDataTable
          entries={5}
          entriesOptions={[5, 10, 50]}
          responsive
          bordered
          striped
          hover
          data={DataNotice}
        />
      </Card.Body>
      <Modal
        className="modal-close-out"
        show={viewNoticeModal}
        onHide={() => setViewNoticeModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>View notice </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Unit_View_Notice noticeID={viewNoticeID} />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={() => setViewNoticeModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="modal-close-out"
        show={editNoticeModal}
        onHide={() => setEditNoticeModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> Edit notice </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Unit_Edit_Notice
            noticeID={editNoticeID}
            closeModal={setEditNoticeModal}
            reFetchNotice={reFetchNotice}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={() => setEditNoticeModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={deleteNoticeModal}
        onHide={() => setDeleteNoticeModal(false)}
        size="sm"
      >
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to delete this Notice ? </h6>{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setDeleteNoticeModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteUnitNotice}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
}

export default UnitNoticesTableForm;
