import PropTypes from "prop-types";
import React, { useState } from "react";
import { Container, Row, Card, CardBody, Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
// import ViewModal from "./components/ViewModal";
// import Loading from "components/Loading";
import { useStore1Selector } from "../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
import usePost from "../../hooks/usePost";
import useFetch from "../../hooks/useFecth";
import TenantDetails from "./components/TenantDetailsForm";
import EditTenant from "./components/EditTenantDetailsForm";

function TenantTable({ t }) {
  const { id } = useParams();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const userId = userDet?.data?._id;
  const { execute, pending, data } = usePost();
  const { data: getTenants, reFetch: reFetchTenants } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/users/propertyOwner/${userId}`,
    token
  );
  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewID, setViewID] = useState();
  const [delteID, setDeleteID] = useState();
  const myTenant = [];
  getTenants?.forEach((res) => {
    myTenant.push({
      ...res,
      Phone_Number: `+${res?.phoneNumber}`,
      Role:
        res?.roles[0] === "tenant" ? (
          <p className="text-success">Tenant</p>
        ) : res?.roles[0] === "co_tenant" ? (
          <p className="text-dark">Co-Tenant</p>
        ) : res?.roles[0] === "guest_tenant" ? (
          <p className="text-warning">Guest</p>
        ) : null,
      viewBtn: (
        <button
          className="btn btn-success"
          onClick={() => viewFunc(`${res?._id}`)}
        >
          {t("View")}
        </button>
      ),
      suspendBtn:
        res?.status === true ? (
          <button
            className="btn btn-dark"
            onClick={() => suspendFunc(`${res?._id}`)}
          >
            {t("Suspend")}
          </button>
        ) : (
          <button
            className="btn btn-info"
            onClick={() => unsuspendFunc(res?._id)}
          >
            Unsuspend
          </button>
        ),
      deleteBtn: (
        <button
          className="btn btn-danger"
          onClick={() => deleteFunc(`${res?._id}`)}
        >
          {t("Delete")}
        </button>
      ),
    });
  });

  function viewFunc(id) {
    setViewModal(true);
    setViewID(id);
  }

  function suspendFunc(id) {
    const Method = "PATCH",
      endPoint = `users/${id}/suspended`;
    const raw = "";
    execute(endPoint, raw, Method, "Tenant successfully suspended", token);

    setTimeout(() => {
      reFetchTenants();
    }, 3000);
  }

  function unsuspendFunc(id) {
    const Method = "PATCH",
      endPoint = `users/${id}/un-suspended`;
    const raw = "";
    execute(endPoint, raw, Method, "Tenant successfully unsuspended", token);
    setTimeout(() => {
      reFetchTenants();
    }, 3000);
  }

  function deleteFunc(id) {
    setDeleteID(id);
    setDeleteModal(true);
  }

  const deleteTenant = () => {
    const Method = "DELETE",
      endPoint = `users/${delteID}`,
      isJSON = true;
    var formdata = new FormData();
    execute(
      endPoint,
      formdata,
      Method,
      "Tenant successfully deleted",
      token,
      isJSON
    );
    setTimeout(() => {
      reFetchTenants();
    }, 3000);
    setDeleteModal(false);
  };

  const column = [
    { label: "First Name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last Name", field: "lastName", sort: "asc", width: 150 },
    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "Phone Number", field: "Phone_Number", sort: "asc", width: 150 },
    { label: "Gender", field: "gender", sort: "asc", width: 150 },
    { label: "Role", field: "Role", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Action", field: "suspendBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const newData = {
    columns: column,
    rows: myTenant.reverse(),
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Tenant" />
        <Breadcrumb default="Property" title="Tenant" />
        <CardBody>
          <Container fluid>
            <Row className="d-flex justify-content-around align-items-center">
              <CardBody data-aos="fade-bottom">
                <Card className="bd-rds">
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <button className="btn login-btn w-md waves-effect waves-light mb-4 d-flex justify-content-end report-warning">
                        <CSVLink
                          data={"myTenant"}
                          separator={";"}
                          className="text-white"
                        >
                          {"Download in Excel"}
                        </CSVLink>
                      </button>
                    </div>
                    <MDBDataTable
                      entries={5}
                      entriesOptions={[5, 10, 50]}
                      responsive
                      bordered
                      striped
                      hover
                      data={newData}
                    />
                  </CardBody>
                </Card>
              </CardBody>
            </Row>
          </Container>
        </CardBody>
      </div>

      <Modal show={viewModal} onHide={() => setViewModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>View Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TenantDetails viewID={viewID} />
        </Modal.Body>
      </Modal>

      <Modal show={editModal} onHide={() => setEditModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditTenant viewID={viewID} />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-danger text-white"
            onClick={() => setEditModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="sm">
        <Modal.Body className="text-danger">
          <h6>Are you sure you want to delete this user? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button className="bg-success text-white" onClick={deleteTenant}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

TenantTable.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(TenantTable));
