import React from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  Spinner,
  Badge,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";

function OverdueTable({ data }) {
  return (
    <div>
      <Card className="bd-rds">
        <CardBody>
          <div className="d-flex justify-content-end">
            <button className="btn login-btn w-md waves-effect waves-light mb-4">
              <CSVLink data={"dataDb"} separator={";"} className="text-white">
                {" "}
                Download in Excel{" "}
              </CSVLink>{" "}
            </button>
          </div>
          <MDBDataTable
            entries={5}
            entriesOptions={[5, 10, 50]}
            responsive
            bordered
            striped
            hover
            data={data}
            fullpagination="true"
          />
        </CardBody>
      </Card>
    </div>
  );
}

export default OverdueTable;
