import PropTypes from "prop-types"
import React, { useState } from "react"
import { Col, Container, Row, CardBody, Card } from "reactstrap";
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import PropertyChart from "./Components/Prop-chart";
import Content from "./Components/Content";


const FinanceDetails = () => {

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTagComp meta_tags="FASPRO24 | Unit details" />
                <Breadcrumb default="Details" title="Unit Details" />
                <Container fluid>
                    <PropertyChart />
                    <Content />
                </Container>
            </div>
        </React.Fragment>
    )
}

FinanceDetails.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(FinanceDetails))