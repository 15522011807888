import PropTypes from "prop-types";
import React, { useState } from "react";
import { Container, Row, Card, CardBody, Modal } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { AvForm, AvField } from "availity-reactstrap-validation";
import MaintenanceReport from "./Maint-report";
import { CSVLink } from "react-csv";
import { MdOutlineRemoveRedEye } from "react-icons/md";

function MaintenanceRequest() {
  const [modal_scroll, setmodal_scroll] = useState(false);

  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
  }

  const [dataDb, setData] = useState([
    {
      property_name: "Preston",
      unit_name: "TNG",
      person: "DSTV",
      date: "2022/07/25",
      status: <b className="text-success"> Open </b>,
      desc: "Items descriptions",
      emergency: <b className="text-warning">Yes</b>,
      assign_to_main: (
        <button className="btn report-warning text-white" onClick={tog_scroll}>
          Assign
        </button>
      ),

      view: (
        <button
          className="btn report-success text-white"
          onClick={() => history.push(`/add-property`)}
        >
          {" "}
          <MdOutlineRemoveRedEye size={18} />{" "}
        </button>
      ),
    },
    {
      property_name: "Barnato",
      unit_name: "Simboti",
      person: "ESKOM",
      date: "2022/07/29",
      status: <b className="text-warning"> Pending </b>,
      desc: "Items descriptions",
      emergency: <b className="text-black">No</b>,
      assign_to_main: (
        <button className="btn report-warning text-white" onClick={tog_scroll}>
          Assign
        </button>
      ),

      view: (
        <button
          className="btn report-success text-white"
          onClick={() => history.push(`/add-property`)}
        >
          <MdOutlineRemoveRedEye size={18} />
        </button>
      ),
    },
    {
      property_name: "Gallagher",
      unit_name: "TNG",
      person: "NSP",
      date: "2022/07/19",
      status: <b className="text-success"> Completed </b>,
      desc: "Items descriptions",
      emergency: <b className="text-black">No</b>,
      assign_to_main: (
        <button className="btn report-warning text-white" onClick={tog_scroll}>
          Assign
        </button>
      ),

      view: (
        <button
          className="btn report-success text-white"
          onClick={() => history.push(`/add-property`)}
        >
          <MdOutlineRemoveRedEye size={18} />
        </button>
      ),
    },
    {
      property_name: "Charlotte cot",
      unit_name: "BARNATO",
      person: "ISP",
      date: "2022/07/02",
      status: <b className="text-warning"> Work in Progress </b>,
      desc: "Items descriptions",
      emergency: <b className="text-black">No</b>,
      assign_to_main: (
        <button className="btn report-warning text-white" onClick={tog_scroll}>
          Assign
        </button>
      ),

      view: (
        <button
          className="btn report-success text-white"
          onClick={() => history.push(`/add-property`)}
        >
          <MdOutlineRemoveRedEye size={18} />
        </button>
      ),
    },
    {
      property_name: "Barnato",
      unit_name: "Simboti",
      person: "MSP",
      date: "2022/07/24",
      status: <b className="text-danger"> Closed </b>,
      desc: "Items descriptions",
      emergency: <b className="text-black">No</b>,
      assign_to_main: (
        <button className="btn report-warning text-white" onClick={tog_scroll}>
          Assign
        </button>
      ),

      view: (
        <button
          className="btn report-success text-white"
          onClick={() => history.push(`/add-property`)}
        >
          <MdOutlineRemoveRedEye size={18} />
        </button>
      ),
    },
    {
      property_name: "Preston",
      unit_name: "Simboti",
      person: "SSP",
      date: "2022/07/23",
      status: <b className="text-black"> Cancelled </b>,
      desc: "Items descriptions",
      emergency: <b className="text-black">No</b>,
      assign_to_main: (
        <button className="btn report-warning text-white" onClick={tog_scroll}>
          Assign
        </button>
      ),

      view: (
        <button
          className="btn report-success text-white"
          onClick={() => history.push(`/add-property`)}
        >
          <MdOutlineRemoveRedEye size={18} />
        </button>
      ),
    },
  ]);
  let history = useHistory();

  const column = [
    { label: "Property Name", field: "property_name", sort: "asc", width: 150 },
    { label: "Unit Name", field: "unit_name", sort: "asc", width: 150 },
    { label: "Service provider", field: "person", sort: "asc", width: 150 },
    { label: "Date", field: "date", sort: "asc", width: 150 },
    { label: "Emergency", field: "emergency", sort: "asc", width: 150 },
    { label: "Status", field: "status", sort: "asc", width: 150 },
    { label: "Description", field: "desc", sort: "asc", width: 150 },
    { label: "View", field: "view", sort: "asc", width: 150 },
    { label: "Assign", field: "assign_to_main", sort: "asc", width: 150 },
  ];

  const data = {
    columns: column,
    rows: dataDb,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Maintenance request" />
        <Breadcrumb default="Property" title="Maintenance request" />

        <CardBody>
          <Container fluid>
            <AvForm className="form-horizontal">
              <div className="mb-3">
                <AvField
                  name="filter"
                  label="Filter per year"
                  className="form-control w-25"
                  type="select"
                  required
                >
                  <option> 2022 </option>
                  <option> 2023 </option>
                  <option> 2024 </option>
                </AvField>
              </div>
            </AvForm>

            <MaintenanceReport />

            <Row className="d-flex justify-content-around align-items-center">
              <CardBody data-aos="fade-bottom">
                <Card className="bd-rds">
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <Link
                        to="/add-maintenance"
                        className="btn login-btn w-md waves-effect waves-light mb-4 report-primary"
                        type="submit"
                      >
                        {" "}
                        + Add new maintenance request
                      </Link>
                      <button className="btn login-btn w-md waves-effect waves-light mb-4 d-flex justify-content-end report-warning">
                        <CSVLink
                          data={dataDb}
                          separator={";"}
                          className="text-white"
                        >
                          {" "}
                          Download in Excel{" "}
                        </CSVLink>{" "}
                      </button>
                    </div>
                    <MDBDataTable
                      entries={5}
                      entriesOptions={[5, 10, 50]}
                      responsive
                      bordered
                      striped
                      hover
                      data={data}
                      fullpagination="true"
                    />
                  </CardBody>
                </Card>
              </CardBody>
            </Row>
          </Container>
        </CardBody>

        <Modal
          isOpen={modal_scroll}
          toggle={() => {
            tog_scroll();
          }}
          scrollable={true}
        >
          <div className="modal-header">
            <h4 className="modal-title"> Assign</h4>

            <button
              type="button"
              onClick={() => setmodal_scroll(false)}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body text-center">
            <h3 className="text-success"> Successful assign </h3>
            <div className="d-flex justify-content-end">
              <button
                className="btn w-md waves-effect waves-light"
                type="submit"
                onClick={() => setmodal_scroll(false)}
              >
                {" "}
                Close{" "}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
}

MaintenanceRequest.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(MaintenanceRequest));
