import React from "react"
import { Col, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { var_num } from "../../../components/Vatnumber"

function UnitForm() {

    const handleValidSubmit = (e, values) => {
        e.preventDefault();
        console.log(values)
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <AvForm className="form-horizontal mt-4" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>

                    <Row>
                        <Col md={6}>
                            <Row>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <AvField name="Unit_name" label="Unit Name" className="form-control" type="text" required />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <AvField name="Unit_number" label="Unit Number" className="form-control" type="text" required />
                                    </div>
                                </Col>
                            </Row>
                            <Row>

                                <Row>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <AvField name="Unit_owner" label="Unit Owner" className="form-control" type="text" required />
                                        </div>
                                    </Col>
                                </Row>

                                <Col md={6}>
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <AvField name="Bathrooms" label="Bathrooms" className="form-control" type="select" required>
                                                    {var_num.map((num) => <option> {num} </option>)}
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <AvField name="Bedrooms" label="Bedrooms" className="form-control" type="select" required>
                                                    {var_num.map((num) => <option> {num} </option>)}
                                                </AvField>
                                            </div>
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>

                        </Col>

                        <Col md={6}>
                            <Row>
                                <Col md={4}>
                                    <div className="mb-3">
                                        <AvField name="UnitType" label="Unit Type" className="form-control" type="select">
                                            <option> Select... </option>
                                            <option> Single </option>
                                            <option> Multiple </option>
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mb-3">
                                        <AvField name="UnitBlock" label="Unit Block Name /Number" className="form-control" type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mb-3">
                                        <AvField name="parking" label="Parking and Garage" className="form-control" type="select" required>
                                            {var_num.map(num =>
                                                <option>{num}</option>
                                            )}
                                        </AvField>
                                    </div>
                                </Col>
                            </Row>

                            <div className="mb-3">
                                <AvField name="Area" label="Surface Area (Square Meter)" className="form-control" type="text" required />
                            </div>

                        </Col>
                    </Row>

                    <div className="mb-3 row">
                        <div className="col-12">
                            <button className="btn login-btn w-md waves-effect waves-light" type="submit">  Submit  </button>
                        </div>
                    </div>

                </AvForm>

            </div>
        </React.Fragment>
    )
}

export default UnitForm