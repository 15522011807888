import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

const TicketBarChart = ({ getYearTicket }) => {
  const openTickets = getYearTicket?.map((item) => {
    return item?.totalTicketsCreated;
  });

  const pendingTickets = getYearTicket?.map((item) => {
    return item?.totalTicketsPending;
  });

  const closeTickets = getYearTicket?.map((item) => {
    return item?.totalTicketsClosed;
  });

  const overdueTickets = getYearTicket?.map((item) => {
    return item?.totalOverdueTickets;
  });

  const month = getYearTicket?.map((item) => {
    return item?.month.substring(0, 3);
  });

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: ["#38a4f8", "#f8b425", "#26AA58", "#E40C1B"],
    plotOptions: {
      bar: {
        columnWidth: "45%",
        dataLabels: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      borderColor: "#f8f8fa",
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    stroke: {
      show: true,
      width: 1.5,
      colors: ["#fff"],
    },
    xaxis: {
      categories: month.reverse(),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
  };
  const series = [
    {
      name: "Open Tickets",
      data: openTickets,
    },
    {
      name: "Pending Tickets",
      data: pendingTickets,
    },
    {
      name: "Completed Tickets",
      data: closeTickets,
    },
    {
      name: "Overdue Tickets",
      data: overdueTickets,
    },
  ];
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="290"
      />
    </React.Fragment>
  );
};
export default TicketBarChart;
