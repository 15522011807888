import React, { useState, useEffect } from "react";
import { Row, Card, CardBody, Col, Spinner } from "reactstrap";
import { Years } from "../../../components/Years";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import useFecth from "../../../hooks/useFecth";
import usePost from "../../../hooks/usePost";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

function PropertyReport() {
  const userDet = useStore1Selector(loginUser);
  const { execute, pending, data: dataPost } = usePost();
  const token = userDet?.token;
  const UserRole = userDet?.data?.roles[0];

  const [selectProperty, setselectProperty] = useState(null);

  const Roles = [
    "property_mgt_marketing",
    "property_mgt_sales",
    "property_mgt_finance",
    "property_mgt_maintenance",
    "property_mgt_leasing_officer",
    "portfolio_manager",
    "property_agency",
    "facility_manager",
  ];

  const [myId, setMyId] = useState(
    Roles.includes(UserRole) ? "teamId" : "userId"
  );

  const { data: getProperties, reFetch: reFetchGetProperties } = useFecth(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/property_owner?fieldName=${myId}`,
    token
  );

  const handleSelectProperty = (event) => {
    setselectProperty(event.target.value);
  };

  const handleRunReport = (e, v) => {
    const Method = "POST",
      endPoint = `maintenanceTickets/${selectProperty}/property`;
    const raw = JSON.stringify({
      status: "closed",
      startDate: v.From,
      endDate: v.To,
    });
    execute(endPoint, raw, Method, null, token);
  };

  useEffect(() => {}, [dataPost?.status]);
  // console.log("dataPost?.....", dataPost?.data);
  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="h5 text-center mb-3">Report Per Property</div>
            <div>
              <AvForm
                className="form-horizontal mt-4"
                onValidSubmit={(e, v) => {
                  handleRunReport(e, v);
                }}
              >
                <div className="d-flex justify-content-between">
                  <div className="mb-3  d-flex">
                    <div className="d-flex align-items-center">
                      <b>Property Name : </b>
                    </div>
                    <select
                      id="selectOption"
                      className="btn btn-primary mx-2"
                      value={selectProperty}
                      onChange={handleSelectProperty}
                    >
                      <option>Select...</option>
                      {getProperties?.map((item, index) => {
                        return (
                          <option value={item?._id} key={index}>
                            {item?.propertyName}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="mb-3 d-flex">
                    <div className="d-flex align-items-center">
                      <b>From : </b>
                    </div>
                    <div className="mx-2">
                      <AvField
                        name="From"
                        type="date"
                        required
                        className="form-control"
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <b>To : </b>
                    </div>
                    <div className="mx-2">
                      <AvField
                        name="To"
                        type="date"
                        required
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="mb-3 mx-2">
                    <button className="btn btn-success" type="submit">
                      {!pending ? (
                        "Run Report"
                      ) : (
                        <>
                          <Spinner as="span" animation="border" size="sm" />
                          <span>Loading</span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </AvForm>
            </div>

            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap mb-0">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col" className="text-center">
                      Property Name
                    </th>
                    <th scope="col" className="text-center">
                      Unit Name
                    </th>

                    <th scope="col" className="text-center">
                      Number of Overstay Visit
                    </th>
                    <th scope="col" className="text-center">
                      Total Number of Visit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataPost?.data?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">{item.updatedAt.substring(0, 10)}</th>
                        <th scope="row" className="text-center">
                          {item.ticketNumber}
                        </th>
                        <th scope="row" className="text-center">
                          {item?.unitId?.unitName}
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default PropertyReport;
