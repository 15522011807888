import PropTypes from "prop-types"
import React, { useState } from 'react'
import { Col, Container, Row, Card, CardBody, Modal } from "reactstrap";
import { MDBDataTable } from "mdbreact"
import { withRouter, Link, useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { BsEye } from 'react-icons/bs';
import { CSVLink } from "react-csv";
import { BsFillHouseFill } from 'react-icons/bs';
import { FaUsers } from 'react-icons/fa';
import { AiTwotoneNotification } from 'react-icons/ai';
import { IoIosCreate } from 'react-icons/io';

function PropertyModule({ id, t }) {
    return (
        <React.Fragment>
            <Row>
                <Col xl={3} md={6}>
                    <Link to={`/service-provider/${id}`}>
                        <Card className="mini-stat report-dark text-black bd-rds">
                            <CardBody>
                                <div className="mb-1">
                                    <div className="float-start mini-stat-img me-2 font-size-22">
                                        <BsFillHouseFill size={28} />
                                    </div>
                                    <h6 className="font-size-14 text-uppercase mt-0 text-black-50">
                                        {t("My service providers")}
                                    </h6>
                                    <BsEye size={25} /> {t("View")}
                                </div>
                            </CardBody>
                        </Card>
                    </Link>
                </Col>
                <Col xl={3} md={6}>
                    <Link to={`/create-request/${id}`}>
                        <Card className="mini-stat report-dark text-black bd-rds">
                            <CardBody>
                                <div className="mb-1">
                                    <div className="float-start mini-stat-img me-2 font-size-22">
                                        <IoIosCreate size={32} />
                                    </div>
                                    <h6 className="font-size-14 text-uppercase mt-0 text-black-50">
                                        {t("Tickets management")}
                                    </h6>
                                    <BsEye size={25} /> {t("View")}
                                </div>
                            </CardBody>
                        </Card>
                    </Link>
                </Col>
            </Row>
        </React.Fragment>
    )
}
PropertyModule.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(PropertyModule))