import React, { useState } from "react";
import "../../../components/Multistep.scss";
import Input1 from "./components/Input1";
import Input2 from "./components/Input2";
import Input3 from "./components/Input3";
import Input4 from "./components/Input4";
import { useHistory } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import { Spinner } from "reactstrap";
// import { useStore1Selector } from "./../../../index";
// import { loginUser } from "../../../Redux/Slices/userSlice";
import usePost from "../../../hooks/usePost";
import useFecth from "../../../hooks/useFecth";
import { RegisterMsg } from "components/NotifyMessage";

const Index = ({ leadId, invoiceId }) => {
  let history = useHistory();
  // const token = userDet?.token;
  const { execute, pending, data } = usePost();
  const [currentStep, setCurrentStep] = useState(1);
  const [profileServer, setProfileServer] = useState();
  const [phoneState, setPhoneState] = useState();
  const [loadBtn, setIloadBtn] = useState(false);
  const { data: getLeadDetails, reFetch: reFetchLeadDetails } = useFecth(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/leads/${leadId}/register`
  );

  const steps = [
    {
      id: 1,
      label: "Company Details",
      fields: <Input1 setProfileServer={setProfileServer} />,
    },
    {
      id: 2,
      label: "Contact Person",
      fields: (
        <Input2
          setPhoneState={setPhoneState}
          firstName={getLeadDetails?.firstName}
          lastName={getLeadDetails?.lastName}
          email={getLeadDetails?.email}
        />
      ),
    },
    {
      id: 3,
      label: "Office address",
      fields: <Input3 />,
    },
    {
      id: 4,
      label: "Bank Details",
      fields: <Input4 />,
    },
  ];

  const handleNextClick = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevClick = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    setIloadBtn(true);
    const Method = "POST",
      endPoint = "auth/property_owner/register",
      isJSON = true,
      token = null;

    var formdata = new FormData();
    formdata.append("firstName", values.firstName);
    formdata.append("lastName", values.lastName);
    formdata.append("phoneNumber", phoneState);
    formdata.append("gender", values.gender);
    formdata.append("terms", values.privacy);
    formdata.append("email", values.email);
    formdata.append("profilePic", profileServer);
    formdata.append("password", values.newPassword);
    formdata.append("passwordConfirm", values.confirmPassword);
    formdata.append("BuildingName", values.buildingName);
    formdata.append("passportNumber", values.ID);
    formdata.append("street", values.address);
    formdata.append("suburb", values.suburb);
    formdata.append("city", values.city);
    formdata.append("state", values.state);
    formdata.append("country", values.country);
    formdata.append("zip", values.zip);
    formdata.append("bankName", values.accountName);
    formdata.append("branchName", values.branchName);
    formdata.append("swiftCode", values.branchCode);
    formdata.append("accountNumber", values.accountNumber);
    formdata.append("accountType", values.accountType);
    formdata.append("roles", "property_owner_company");
    formdata.append("companyName", values.companyName);
    formdata.append("tradingAs", values.trading);
    formdata.append("vatRate", values.vatRate);
    formdata.append("vatNumber", values.vatNumber);
    formdata.append("registrationNumber", values.regNumber);
    formdata.append("invoiceId", invoiceId);
    execute(endPoint, formdata, Method, RegisterMsg, token, isJSON);
  };

  if (data?.status === "fail") {
    // console.log("result...new...:", data?.message);
    setIloadBtn(false);
    warningMessage(data?.message);
  }
  if (data?.status === "success") {
    window.setTimeout(() => {
      history.push("/login");
    }, 2000);
  }

  return (
    <div className="form-wrapper">
      <div className="progress-container">
        {steps.map((step) => (
          <div
            key={step.id}
            className={`progress-step ${
              step.id <= currentStep ? "completed" : ""
            }`}
          >
            {step.id}
          </div>
        ))}
      </div>

      <AvForm
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        {steps.map((step) => (
          <div
            key={step.id}
            className={`step ${step.id === currentStep ? "active" : ""}`}
          >
            {step.fields}
          </div>
        ))}

        <div className="button-container">
          {currentStep !== 1 && (
            <button
              type="button"
              className="prev-button"
              onClick={handlePrevClick}
            >
              Previous
            </button>
          )}
          {currentStep !== steps.length && (
            <button
              type="button"
              className="next-button"
              onClick={handleNextClick}
            >
              Next
            </button>
          )}
          {currentStep === steps.length && (
            <button type="submit" className="submit-button">
              {!pending ? (
                <span className="me-2">Submit</span>
              ) : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading...
                </span>
              )}
            </button>
          )}
        </div>
      </AvForm>
    </div>
  );
};

export default Index;
