import React from "react";
import Packages from "./components/Packages";

function Index() {
  return (
    <div className="h-100 w-100 d-flex justify-content-center">
      <Packages />
    </div>
  );
}

export default Index;
