import React from "react";
import PropTypes from "prop-types";
import { Col, Row, CardBody, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useStore1Selector } from "../../../../../index";
import { loginUser } from "../../../../../Redux/Slices/userSlice";
import useFetch from "../../../../../hooks/useFecth";

function ViewExtraChargesForm({ chargeID, unitID }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { data } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/extraCharges/${chargeID}/${unitID}`,
    token
  );

  // const startDate = data?.startDate.substring(0, 10);
  return (
    <React.Fragment>
      <CardBody>
        <Row className="align-items-center">
          <Col md={12} lg={12} xl={12}>
            <p>
              <b className="text-success"> Extra charges name : </b>{" "}
              {data?.type}
            </p>
          </Col>
          <Col md={12}>
            <p>
              <b className="text-success"> Frequency: </b> {data?.frequency}
            </p>
          </Col>
          <Col md={12}>
            <p>
              <b className="text-success"> Amount: </b>
              GH₵ {data?.amount}
            </p>
          </Col>

          <Col md={12}>
            <p>
              <b className="text-success"> Extra charges start date: </b>
              {data?.startDate?.substring(0, 10)}
            </p>
          </Col>
          <Col md={12}>
            <p>
              <b className="text-success"> Extra charges end date: </b>
              {data?.endDate?.substring(0, 10)}
            </p>
          </Col>
        </Row>
      </CardBody>
    </React.Fragment>
  );
}

ViewExtraChargesForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(ViewExtraChargesForm));
