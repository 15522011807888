import React, { useState } from "react";
import { Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import useCustomMutation from "../../../hooks/useCustomMutation";

function BlockForm({ propId, closeModal }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const key = ["myBlocks"];
  const mutation = useCustomMutation(key);

  const handleValidSubmit = (e, v) => {
    e.preventDefault();
    const Method = "POST",
      endPoint = `blocks/${userDet?.data?._id}/${propId}`;
    const raw = JSON.stringify({
      blockName: v.blockName,
      blockNumber: v.blockNumber,
      blockAddress: v.blockAddress,
      numberOfUnits: v.numberOfUnits,
      blockSize: v.blockSize,
      Amenities: "",
      maintenanceInformation: "",
      TenantInformation: "",
    });

    const data = {
      endPoint: endPoint,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Block Successfully Created",
      token: token,
      isJSON: null,
    };

    mutation.mutate(data);
  };

  if (mutation.isSuccess) {
    closeModal(false);
  }
  return (
    <React.Fragment>
      <div className="mt-3">
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >
          <div className="mb-3">
            <AvField
              name="blockName"
              label="Block name"
              className="form-control"
              type="text"
              required
            />
          </div>
          <div className="mb-3">
            <AvField
              name="blockNumber"
              label="Block number"
              className="form-control"
              type="text"
            />
          </div>
          <div className="mb-3">
            <AvField
              name="blockAddress"
              label="Block Address"
              className="form-control"
              type="text"
              required
            />
          </div>
          <div className="mb-3">
            <AvField
              name="numberOfUnits"
              label="Number of Units"
              className="form-control"
              type="text"
              required
            />
          </div>
          <div className="mb-3">
            <AvField
              name="blockSize"
              label="Block Size"
              className="form-control"
              type="text"
            />
          </div>
          <button
            className="btn login-btn w-md waves-effect waves-light"
            type="submit"
          >
            {!mutation.isLoading ? (
              <span className="me-2"> Submit </span>
            ) : null}
            {!mutation.isLoading ? null : (
              <span>
                {" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="me-2"
                />
                Loading...
              </span>
            )}
          </button>
        </AvForm>
      </div>
    </React.Fragment>
  );
}
export default BlockForm;
