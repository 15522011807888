import React, { useState, useRef } from 'react'
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Col, Row, Button, Spinner } from "reactstrap"
import { countryList } from "../../../components/Countries";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Select from "react-select"
import { warningMessage, successMessage } from "../../../components/Notifications";
import { useHistory } from "react-router-dom"
import Loading from 'components/Loading';

function EditUserForm(props) {

    const history = useHistory();
    const [phone, setPhoneState] = useState();
    const [imageState, setImageState] = useState()
    const [displayImg, setDisplayImg] = useState()
    const [loadBtn, setIloadBtn] = useState(false)
    const [toggle, setToggle] = useState("")

    const refFileUpload = useRef(null);
    const onThumbChangeClick = () => {
        if (refFileUpload) {
            refFileUpload.current.dispatchEvent(new MouseEvent('click'));
        }
    };

    const changeHandler = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImageState(event.target.files[0]);

            const reader = new FileReader();
            reader.onload = (loadEvent) => {
                setDisplayImg(loadEvent.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleValidSubmit = (e, values) => {
        e.preventDefault();
        setIloadBtn(true);

        const formdata = new FormData();
        formdata.append("UserID", props.id);
        formdata.append("RoleID", values.role);
        formdata.append("PropertyID", props?.details[0]?.PropertyID);
        formdata.append("FirstName", props?.details[0]?.FirstName);
        formdata.append("LastName", props?.details[0]?.LastName);
        formdata.append("Email", props?.details[0]?.Email);
        formdata.append("PhoneNumber", props?.details[0]?.PhoneNumber);
        formdata.append("PhysicalAddress", props?.details[0]?.PhysicalAddress);
        formdata.append("Suburb", props?.details[0]?.Suburb);
        formdata.append("City", props?.details[0]?.City);
        formdata.append("StateProvince", props?.details[0]?.StateProvince);
        formdata.append("Country", props?.details[0]?.Country);
        formdata.append("IDPassport", props?.details[0]?.IDPassport);
        // formdata.append("ProfilePicture", imageState);

        const requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://prop.faspro24.com/api/team/UpdateTeamMember", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setIloadBtn(false)
                    successMessage("Successful updated")
                    window.setTimeout(() => {
                        location.reload();
                    }, 3000)
                }
            })
            .catch(error => {
                if (error.message === "error") {
                    setIloadBtn(false)
                    warningMessage(error.message)
                }
            });
    }

    const Individual_toggleFunc = (e, v) => {
        v === "1" ? setToggle(true) : setToggle(false)
    }

    if (!props.details[0]) return <Loading />

    const [selectedMulti, setselectedMulti] = useState(props?.details[0]?.PropertyID)
    const optionGroup = [
        {
            options: [
                { label: props?.details[0]?.PropertyID, value: props?.details[0]?.PropertyID },
                { label: "Preston", value: "Preston" },
                { label: "Jabula", value: "Jabula" },
                { label: "Gallagher", value: "Gallagher" }
            ]
        },
    ]

    function handleMulti(selectedMulti) {
        setselectedMulti(selectedMulti)
    }

    console.log(props?.details[0]?.Name)

    return (
        <React.Fragment key={props?.details?.id}>
            <AvForm className="form-horizontal mt-4" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>
                <Row>
                    {/* <Row>
                        <div className="d-flex justify-content-center align-items-center mb-3">
                            <img src={!displayImg ? `https://prop.faspro24.com/TeamImages/${props?.details[0]?.ProfilePicture}` : displayImg} alt="user" className="rounded-circle" width={100} height={100} />
                            <Button size="sm" variant="separator-light" className="btn-icon btn-icon-only mt-5 position-absolute rounded s-0 b-0"
                                onClick={onThumbChangeClick}>
                                <i className="ion ion-md-image"></i>
                            </Button>
                            <input type="file" ref={refFileUpload} className="file-upload d-none" accept="image/*" onChange={changeHandler} />
                        </div>
                    </Row> */}

                    <Col md={12}>
                        <div className="mb-3">
                            <AvField name="role" label="Role" className="form-control" type="select"
                                onChange={Individual_toggleFunc}
                            >
                                <option> {props?.details[0]?.Name} </option>
                                <option value="1"> Portfolio manager </option>
                                <option value="2"> Property agent </option>
                                <option value="3"> Security </option>
                                <option value="4"> Finance </option>
                                <option value="5"> Marketing </option>
                                <option value="6"> Maintenance </option>
                                <option value="7"> Listing officers </option>
                            </AvField>
                        </div>
                    </Col>

                    {/* {
                        !toggle ? <Col md={6}>
                            <div className="mb-3">
                                <label className="control-label ">  Assign Property </label>
                                <Select
                                    value={selectedMulti}
                                    isMulti={true}
                                    onChange={() => { handleMulti() }}
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                />
                            </div>
                        </Col> : null
                    } */}


                    {/* <Col md={6}>
                        <div className="mb-3">
                            <AvField name="first_name" label="First Name" type="text" required value={props?.details[0]?.FirstName} />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="last_name" label="Last Name" className="form-control" type="text" required value={props?.details[0]?.LastName} />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="email" label="Email" type="text" required value={props?.details[0]?.Email} />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="ID" label="ID / Passport number" type="text" required value={props?.details[0]?.IDPassport} />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <p>Phone number</p>
                            <PhoneInput
                                buttonStyle={{
                                    borderBottomLeftRadius: 5,
                                    borderTopLeftRadius: 5,
                                    height: 40,
                                }}
                                inputStyle={{
                                    margin: 0,
                                    width: "100%",
                                    color: 'purple',
                                    height: 1,
                                    border: 'none'
                                }}
                                containerStyle={{
                                    width: "100%",
                                }}
                                className="blue"
                                country={"za"}
                                enableSearch={true}
                                value={props?.details[0]?.PhoneNumber}
                                onChange={(phone) => setPhoneState(phone)}
                            />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="address" label="User Address" type="text" required value={props?.details[0]?.PhysicalAddress} />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="suburb" label="Suburb" type="text" required value={props?.details[0]?.Suburb} />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="city" label="City" type="text" required value={props?.details[0]?.City} />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="state" label="State / Province " type="text" required value={props?.details[0]?.StateProvince} />
                        </div>
                    </Col> */}

                    {/* <Col md={6}>
                        <div className="mb-3">
                            <AvField name="country" label="Country" type="select" required value={props?.details[0]?.Country}>
                                {
                                    countryList.map(country => (
                                        <option> {country} </option>
                                    ))
                                }
                            </AvField>
                        </div>
                    </Col> */}

                </Row>

                <button className="btn login-btn w-md waves-effect waves-light" type="submit">
                    {!loadBtn ? <span className="me-2">Submit</span> : null}
                    {!loadBtn ? null : <span>  <Spinner as="span" animation="border" size="sm" /> Loading...</span>}
                </button>

            </AvForm>
        </React.Fragment>
    )
}

export default EditUserForm