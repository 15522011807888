import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { useStore1Selector } from "index";
import { loginUser } from "Redux/Slices/userSlice";
import useFecth from "../../hooks/useFecth";
import io from "socket.io-client";

const socket = io.connect(process.env.REACT_APP_DOMAIN);

const SidebarContent = (props) => {
  const userDet = useStore1Selector(loginUser);
  const ref = useRef();
  const token = userDet?.token;
  const userId = userDet?.data?._id;
  const roles = userDet?.data?.roles[0];

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");
        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active");
          parent3.childNodes[0].classList.add("mm-active");
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show");
              parent5.childNodes[0].classList.add("mm-active");
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  const ServirceProviderRequestList = [];
  const ServiceProviderHistoryList = [];
  const MyTicketList = [];

  // socket.emit("register_service_provider", userId);
  const {
    data: getServiceProviderRequest,
    reFetch: reFetchGetServiceProviderRequest,
  } = useFecth(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceTickets/${userId}/serviceProvider`,
    token
  );
  getServiceProviderRequest?.forEach((res) => {
    if (res?.status === "open") {
      ServirceProviderRequestList.push({});
    }
  });

  getServiceProviderRequest?.forEach((res) => {
    if (res?.status === "accepted") {
      MyTicketList.push({});
    }
  });
  const {
    data: getMyTicketHistoryServiceProvider,
    reFetch: reFetchGetMyTicketHistoryServiceProvider,
  } = useFecth(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/ticketHistories/${userId}/serviceProvider`,
    token
  );
  getMyTicketHistoryServiceProvider?.forEach((res) => {
    ServiceProviderHistoryList.push({});
  });

  useEffect(() => {
    reFetchGetServiceProviderRequest();
    reFetchGetMyTicketHistoryServiceProvider();
  }, [socket]);

  // useEffect(() => {
  //   reFetchGetServiceProviderRequest();
  //   reFetchGetMyTicketHistoryServiceProvider();
  // }, [getServiceProviderRequest]);

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {roles === "service_provider" ||
            roles === "service_provider_manager" ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-ticket-alt"></i>
                    {props.t("Facility Maintenance")}
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/request-service">
                        <i className="fa fa-send"></i>

                        {ServirceProviderRequestList?.length === 0 ? null : (
                          <span className="badge rounded-pill bg-danger float-end">
                            {ServirceProviderRequestList?.length}
                          </span>
                        )}

                        {props.t("Request Service")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/my-tickets">
                        <i className="fas fa-receipt"></i>

                        {MyTicketList?.length === 0 ? null : (
                          <span className="badge rounded-pill bg-danger float-end">
                            {MyTicketList?.length}
                          </span>
                        )}

                        {props.t("My Tickets")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/my-history">
                        <i className="fas fa-clock"></i>

                        {ServiceProviderHistoryList?.length === 0 ? null : (
                          <span className="badge rounded-pill bg-danger float-end">
                            {ServiceProviderHistoryList?.length}
                          </span>
                        )}

                        {props.t("My History")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/facitity-maintenance-report">
                        <i className="fas fa-file"></i>
                        {props.t("Report")}
                      </Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-settings"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-profile">
                        <i className="ti-user"></i>
                        {props.t("Edit profile")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}
            {roles === "security_manger" ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-shield-alt"></i>
                    <span>{props.t("Security Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/visitor-access">
                        <i className="fas fa-clipboard-list"></i>
                        {props.t("Access List")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/security-team">
                        <i className="fas fa-users"></i>
                        {props.t("My Team")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/security-report">
                        <i className="fas fa-file"></i>
                        {props.t("Report")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-settings"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-profile">
                        <i className="ti-user"></i>
                        {props.t("Edit profile")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            {roles === "property_owner_company" ||
            roles === "property_owner_individual" ||
            roles === "property_mgt_marketing" ||
            roles === "property_mgt_sales" ||
            roles === "property_mgt_finance" ||
            roles === "property_mgt_maintenance" ||
            roles === "property_mgt_leasing_officer" ||
            roles === "property_agency" ||
            roles === "property_agency" ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-home"></i>
                    <span>{props.t("Facility Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-properties">
                        <i className="fas fa-home"></i>
                        <span> {props.t("My Properties")} </span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/my-tenants">
                        <i className="ti-id-badge"></i>
                        {props.t("My Tenants")}
                      </Link>
                    </li>
                    <li>
                      {/* /service-provider/:id  */}
                      <Link to="/service-provider">
                        <i className="fas fa-house-user"></i>
                        {props.t("Service Provider")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-ticket-alt"></i>
                    {props.t("Facility Maintenance")}
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/tickets">
                        <i className="fas fa-receipt"></i>
                        {/* <span className="badge rounded-pill bg-danger float-end">
                          10
                        </span> */}
                        {props.t("Tickets")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/maintance-invoices">
                        <i className="fas fa-file-invoice-dollar"></i>
                        {/* <span className="badge rounded-pill bg-danger float-end">
                          10
                        </span> */}
                        {props.t("Quotations")}
                      </Link>
                    </li>

                    {/* <li>
                      <Link to="/my-tasks">
                        <i className="fas fa-tasks"></i>
                        <span className="badge rounded-pill bg-danger float-end">
                          10
                        </span>
                        {props.t("My Tasks")}
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/tickets-history">
                        <i className="fas fa-clock"></i>
                        {/* <span className="badge rounded-pill bg-danger float-end">
                          20
                        </span> */}
                        {props.t("Tickets History")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/facitity-maintenance-report">
                        <i className="fas fa-file"></i>
                        {props.t("Report")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-shield-alt"></i>
                    <span>{props.t("Security Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/request-visitor">
                        <i className="far fa-address-card"></i>
                        {props.t("Request Visitor Access")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/security-manager">
                        <i className="fas fa-user-shield"></i>
                        {props.t("Security Manager")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/visit-list">
                        <i className="fas fa-clipboard-list"></i>
                        {props.t("Access List")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/security-report">
                        <i className="fas fa-file"></i>
                        {props.t("Report")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-folder"></i>
                    <span>{props.t("File Management")}</span>
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-documents">
                        {/* fas fa-file */}
                        <i className="fas fa-folder-open"></i>
                        {props.t("Shared Document")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/Create-documents">
                        <i className="fas fa-plus-circle"></i>
                        {props.t("Create Document")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/document-report">
                        <i className="fas fa-file"></i>
                        {props.t("Report")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow  waves-effect">
                    <i className="fas fa-calendar-check"></i>
                    {props.t("Bookings Management")}
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-bookings">
                        <i className="fas fa-calendar-check"></i>
                        {props.t("Bookable units")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/add-bookings">
                        <i className="fas fa-calendar-plus"></i>
                        {props.t("Add Bookable units")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/request-booking">
                        <i className="fas fa-envelope-open-text"></i>
                        {props.t("Booking Request")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/bookings-report">
                        <i className="fas fa-file"></i>
                        {props.t("Report")}
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* 
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-package"></i>
                    <span>{props.t("Asset Management")}</span>
                  </Link>

                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <Link to="/my-assets">
                        <i className="ti-gift"></i>
                        {props.t("Assets")}
                      </Link>
                    </li>
                  </ul>
                </li>

                

               

                

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-user"></i>
                    <span>{props.t("User Management")}</span>
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-teams">
                        <i className="ti-user"></i>
                        {props.t("Teams /user role")}
                      </Link>
                    </li>
                  </ul>
                </li> */}
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-group"></i>
                    <span>{props.t("My Team")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/add-team">
                        <i className="fas fa-user"></i>
                        {props.t("Add a team member")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/view-team">
                        <i className="fas fa-group"></i>
                        {props.t("View my team")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-settings"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-profile">
                        <i className="ti-user"></i>
                        {props.t("Edit profile")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/my-subscription">
                        <i className="ti-package"></i>
                        {props.t("Subscription")}
                      </Link>
                    </li> */}
                  </ul>
                </li>
              </>
            ) : null}

            {userDet?.RoleID === 6 ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-user"></i>
                    {props.t("Maintenance management")}
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-tickets">
                        <i className="ti-ticket"></i>
                        {props.t("My tickets")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            {userDet?.RoleID === 3 ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-server"></i>
                    <span>{props.t("Security Management")}</span>
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-access-list">
                        <i className="ti-comments"></i>
                        {props.t("Access List")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}
            {userDet?.RoleID === 4 ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-map-alt"></i>
                    <span>{props.t("Facility Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-properties">
                        <i className="ti-ticket"></i>
                        {props.t("My Properties")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-settings"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-subscription">
                        <i className="ti-package"></i>
                        {props.t("Subscription")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            {userDet?.RoleID === 1 ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-map-alt"></i>
                    <span>{props.t("Facility Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-properties">
                        <i className="ti-ticket"></i>
                        {props.t("My Properties")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/my-tenants">
                        <i className="ti-id-badge"></i>
                        {props.t("My Tenants")}
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-user"></i>
                    {props.t("Maintenance management")}
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-tickets">
                        <i className="ti-ticket"></i>
                        {props.t("My tickets")}
                      </Link>
                    </li>
                  </ul>
                </li> */}

                {/* <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-package"></i>
                    <span>{props.t("Asset Management")}</span>
                  </Link>

                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <Link to="/my-assets">
                        <i className="ti-gift"></i>
                        {props.t("Assets")}
                      </Link>
                    </li>
                  </ul>
                </li> */}

                {/* <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-server"></i>
                    <span>{props.t("Security Management")}</span>
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-access-list">
                        <i className="ti-comments"></i>
                        {props.t("Access List")}
                      </Link>
                    </li>
                  </ul>
                </li> */}

                {/* <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-import"></i>
                    <span>{props.t("File Management")}</span>
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-documents">
                        <i className="ti-car"></i>
                        {props.t("Share Document")}
                      </Link>
                    </li>
                  </ul>
                </li> */}

                {/* <li>
                  <Link to="/#" className="has-arrow  waves-effect">
                    <i className="ti-car"></i>
                    {props.t("Bookings Management")}
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-bookings">
                        <i className="ti-car"></i>
                        {props.t("Bookable units")}
                      </Link>
                    </li>
                  </ul>
                </li> */}

                {/* <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-user"></i>
                    <span>{props.t("User Management")}</span>
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-teams">
                        <i className="ti-user"></i>
                        {props.t("Teams /user role")}
                      </Link>
                    </li>
                  </ul>
                </li> */}

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-settings"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-profile">
                        <i className="ti-user"></i>
                        {props.t("Edit profile")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/my-subscription">
                        <i className="ti-package"></i>
                        {props.t("Subscription")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            {userDet?.RoleID === 2 ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-map-alt"></i>
                    <span>{props.t("Facility Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-properties">
                        <i className="ti-ticket"></i>
                        {props.t("My Properties")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/my-tenants">
                        <i className="ti-id-badge"></i>
                        {props.t("My Tenants")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-user"></i>
                    {props.t("Maintenance management")}
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-tickets">
                        <i className="ti-ticket"></i>
                        {props.t("My tickets")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-package"></i>
                    <span>{props.t("Asset Management")}</span>
                  </Link>

                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <Link to="/my-assets">
                        <i className="ti-gift"></i>
                        {props.t("Assets")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-server"></i>
                    <span>{props.t("Security Management")}</span>
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-access-list">
                        <i className="ti-comments"></i>
                        {props.t("Access List")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-import"></i>
                    <span>{props.t("File Management")}</span>
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-documents">
                        <i className="ti-car"></i>
                        {props.t("Share Document")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow  waves-effect">
                    <i className="ti-car"></i>
                    {props.t("Bookings Management")}
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-bookings">
                        <i className="ti-car"></i>
                        {props.t("Bookable units")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-user"></i>
                    <span>{props.t("User Management")}</span>
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-teams">
                        <i className="ti-user"></i>
                        {props.t("Teams /user role")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-settings"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my-profile">
                        <i className="ti-user"></i>
                        {props.t("Edit profile")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/my-subscription">
                        <i className="ti-package"></i>
                        {props.t("Subscription")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
