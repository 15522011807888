import PropTypes from "prop-types";
import React from 'react';
import { Col, Container, Row, CardBody, Card, Progress } from "reactstrap"
import { admin_json_Obj } from '../../ARRAYS-AND-OBJECTS/Admin-object';
import { withTranslation } from "react-i18next"
import { withRouter, Link } from "react-router-dom"

import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { GiFamilyHouse, GiHumanPyramid } from 'react-icons/gi';
import { GrDocumentText } from 'react-icons/gr';
import { RiToolsFill } from 'react-icons/ri';
import { BsVectorPen } from 'react-icons/bs';

function CardsChart(props) {

  let AdminJSON = admin_json_Obj();

  return (
    <React.Fragment>
      <Row>
        <Col xl={3} md={6}>
          <Card className="mini-stat bg-card text-white bd-rds">
            <CardBody>
              <div className="mb-4">
                <div className="float-start mini-stat-img me-4">
                  <GiFamilyHouse size={35} />
                </div>
                <h6 className="font-size-14 text-uppercase mt-0 text-white-50">
                  Properties
                </h6>
                <h4 className="fw-medium font-size-24">
                  100
                  <i className="mdi mdi-arrow-up text-warning ms-2"></i>
                </h4>
                <div className="mini-stat-label report-warning">
                  <p className="mb-0">+ 100</p>
                </div>
              </div>
              <div className="pt-2">
                <div className="float-end">
                  <Link to="#" className="text-white-50">
                    <i className="mdi mdi-arrow-right h5"></i>
                  </Link>
                </div>

              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} md={6}>
          <Card className="mini-stat bg-card text-white bd-rds">
            <CardBody>
              <div className="mb-4">
                <div className="float-start mini-stat-img me-4">
                  <GiHumanPyramid size={35} />
                </div>
                <h6 className="font-size-14 text-uppercase mt-0 text-white-50">
                  Tenants
                </h6>
                <h4 className="fw-medium font-size-24">
                  54
                  <i className="mdi mdi-arrow-up text-warning ms-2"></i>
                </h4>
                <div className="mini-stat-label report-warning">
                  <p className="mb-0">+54</p>
                </div>
              </div>
              <div className="pt-2">
                <div className="float-end">
                  <Link to="#" className="text-white-50">
                    <i className="mdi mdi-arrow-right h5"></i>
                  </Link>
                </div>

                {/* <p className="text-white-50 mb-0 mt-1">Since last month</p> */}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} md={6}>
          <Card className="mini-stat bg-card text-white bd-rds">
            <CardBody>
              <div className="mb-4">
                <div className="float-start mini-stat-img me-2">
                  <BsVectorPen size={35} />
                </div>
                <h6 className="font-size-14 text-uppercase mt-0 text-white-50">
                  Leases
                </h6>
                <h4 className="fw-medium font-size-24">
                  152
                  <i className="mdi mdi-arrow-up text-warning ms-2"></i>
                </h4>
                <div className="mini-stat-label report-warning">
                  <p className="mb-0">- 00</p>
                </div>
              </div>
              <div className="pt-2">
                <div className="float-end">
                  <Link to="#" className="text-white-50">
                    <i className="mdi mdi-arrow-right h5"></i>
                  </Link>
                </div>

                {/*<p className="text-white-50 mb-0 mt-1">Since last month</p> */}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} md={6}>
          <Card className="mini-stat bg-card text-white bd-rds">
            <CardBody>
              <div className="mb-4">
                <div className="float-start mini-stat-img me-2">
                  <RiToolsFill size={35} />
                </div>
                <h6 className="font-size-14 text-uppercase mt-0 text-white-50">
                  Utilities
                </h6>
                <h4 className="fw-medium font-size-24">


                  <i className="mdi mdi-arrow-up text-warning ms-2"></i>
                </h4>
                <div className="mini-stat-label report-warning">
                  <p className="mb-0">+ 84%</p>
                </div>
              </div>
              <div className="pt-2">
                <div className="float-end">
                  <Link to="#" className="text-white-50">
                    <i className="mdi mdi-arrow-right h5"></i>
                  </Link>
                </div>
                {/* <p className="text-white-50 mb-0 mt-1">Since last month</p> */}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardsChart.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(CardsChart))