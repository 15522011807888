import React from "react";
import PropTypes from "prop-types";
import { Col, Row, CardBody, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useStore1Selector } from "../../../../../index";
import { loginUser } from "../../../../../Redux/Slices/userSlice";
import usePost from "../../../../../hooks/usePost";
import useFetch from "../../../../../hooks/useFecth";
import { Payment } from "../../../../../components/Payment";
import { ExtraCharges } from "../../../../../components/ExtraCharges";

function EditExtraChargesForm({
  propID,
  unitID,
  chargeID,
  closeModal,
  reFetchExtraCharge,
}) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { data } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/extraCharges/${chargeID}/${unitID}`,
    token
  );
  const { execute, pending } = usePost();

  const handleValidSubmit = (e, v) => {
    e.preventDefault();
    // console.log(v);
    // console.log("token...", token);
    const Method = "PATCH",
      endPoint = `extraCharges/${chargeID}`,
      isJSON = null;
    const raw = JSON.stringify({
      amount: v.amount,
      type: v.type,
      frequency: v.frequency,
      startDate: v.startDate,
      endDate: v.endDate,
    });
    execute(
      endPoint,
      raw,
      Method,
      "Extra charges successfully updated",
      token,
      isJSON
    );
    closeModal(false);
    setTimeout(() => {
      reFetchExtraCharge();
    }, 2000);
  };

  return (
    <React.Fragment>
      <CardBody>
        <Row className="align-items-center">
          <Col md={12} lg={12} xl={12}>
            <AvForm
              className="form-horizontal mt-4"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <AvField
                      name="type"
                      label="Extra charges name"
                      type="select"
                      className="form-control"
                    >
                      <option value={data?.type}> {data?.type}</option>
                      {ExtraCharges.map((item, index) => {
                        if (data?.type !== item) {
                          return (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          );
                        }
                      })}
                    </AvField>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="frequency"
                      label="Frequency"
                      type="select"
                      className="form-control"
                    >
                      <option value={data?.frequency}>{data?.frequency}</option>
                      {Payment.map((item, index) => {
                        if (data?.frequency !== item) {
                          return <option key={index}>{item}</option>;
                        }
                      })}
                    </AvField>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="amount"
                      value={data?.amount}
                      label="Amount"
                      type="number"
                      className="form-control"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="startDate"
                      value={data?.startDate?.substring(0, 10)}
                      label="Extra charges start date"
                      type="date"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="endDate"
                      value={data?.endDate?.substring(0, 10)}
                      label="Extra charges end date"
                      type="date"
                      className="form-control"
                    />
                  </div>
                </Col>
              </Row>

              <div className="text-start mt-5">
                <button
                  className="btn login-btn  waves-effect waves-light"
                  type="submit"
                >
                  {!pending ? <span className="me-2">Submit</span> : null}
                  {!pending ? null : (
                    <span>
                      {" "}
                      <Spinner as="span" animation="border" size="sm" />{" "}
                      Loading...
                    </span>
                  )}
                </button>
              </div>
            </AvForm>
          </Col>
        </Row>
      </CardBody>
    </React.Fragment>
  );
}

EditExtraChargesForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(EditExtraChargesForm));
