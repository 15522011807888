import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, CardBody, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import usePost from "../../../../../hooks/usePost";
import { useStore1Selector } from "../../../../../index";
import { loginUser } from "../../../../../Redux/Slices/userSlice";
import { var_num_100 } from "../../../../../components/numbers_100";
import { DayOfMonth } from "../../../../../components/DayOfMonth";

function EditLeaseForm({ closeModal, reFetchLease, unitLease }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending } = usePost();
  const [leaseDocument, setLeaseDocument] = useState();
  const handleValidSubmit = (e, v) => {
    e.preventDefault();
    const Method = "PATCH",
      endPoint = `leases/${unitLease[0]?._id}`,
      isJSON = true;
    const formdata = new FormData();
    formdata.append("rentDeposit", v.rentDeposit);
    formdata.append("rentAmount", v.rentAmount);
    formdata.append("latePaymentPercentage", v.latePaymentPercentage);
    formdata.append("gracePeriod", v.gracePeriod);
    formdata.append("startDate", v.startDate);
    formdata.append("endDate", v.endDate);
    if (leaseDocument) {
      formdata.append("leaseAgreement", leaseDocument);
    }
    // formdata.append("paymentDueDate", v.paymentDueDate);
    execute(
      endPoint,
      formdata,
      Method,
      "Lease successfully edited",
      token,
      isJSON
    );
    reFetchLease();
    closeModal(false);
  };

  const AttachedFileFunc = (e) => {
    setLeaseDocument(e.target.files[0]);
  };
  if (pending) {
    setTimeout(() => {
      reFetchLease();
    }, 2000);
  }

  return (
    <React.Fragment>
      <CardBody>
        <Row className="align-items-center">
          <Col md={12} lg={12} xl={12}>
            <AvForm
              className="form-horizontal mt-3"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <Row>
                <Col md={6}>
                  <div className="mb-2">
                    <AvField
                      name="rentDeposit"
                      label="Rent Deposit"
                      className="form-control"
                      value={unitLease[0]?.rentDeposit}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="rentAmount"
                      label="Rent Amount"
                      type="number"
                      className="form-control"
                      value={unitLease[0]?.rentAmount}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <AvField
                      name="paymentDueDate"
                      label="Payment due date"
                      type="select"
                      className="form-control"
                      value={unitLease[0]?.paymentDueDate}
                    >
                      {DayOfMonth.map((item, index) => {
                        if (unitLease[0]?.paymentDueDate !== item) {
                        }
                        return <option key={index}> {item}</option>;
                      })}
                    </AvField>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="latePaymentPercentage"
                      label="Late fee percentage"
                      type="select"
                      className="form-control"
                      value={unitLease[0]?.latePaymentPercentage}
                    >
                      {var_num_100.map((percentage, i) => (
                        <option key={i}> {percentage}</option>
                      ))}
                    </AvField>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="gracePeriod"
                      label="Grace period (days) "
                      type="text"
                      className="form-control"
                      value={unitLease[0]?.gracePeriod}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="startDate"
                      label="Lease Start Date"
                      type="date"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="endDate"
                      label="Lease End Date"
                      type="date"
                      className="form-control"
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <div className="mb-3">
                  <span> Title </span>
                  <AvField
                    name="title"
                    className="form-control"
                    value="Lease Agreement"
                    type="text"
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <span> Attach a document </span>
                  <AvField
                    name="name"
                    type="file"
                    className="form-control"
                    onChange={AttachedFileFunc}
                  />
                </div>
              </Row>

              <div className="text-start mt-5">
                <button
                  className="btn login-btn  waves-effect waves-light"
                  type="submit"
                >
                  {!pending ? <span className="me-2">Submit</span> : null}
                  {!pending ? null : (
                    <span>
                      {" "}
                      <Spinner as="span" animation="border" size="sm" />{" "}
                      Loading...
                    </span>
                  )}
                </button>
              </div>
            </AvForm>
          </Col>
        </Row>
      </CardBody>
    </React.Fragment>
  );
}

EditLeaseForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(EditLeaseForm));
