import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row, CardBody, Card, Progress } from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter, Link, useParams } from "react-router-dom";
import {
  MdOutlineAssignmentTurnedIn,
  MdPendingActions,
  MdOutlinePreview,
} from "react-icons/md";
import Click from "../../../../assets/Gif/Click here.gif";

function Nav({
  openTask,
  setOpenTask,
  onHoldTask,
  setOnHoldTask,
  pendingTask,
  setPendingTask,
  clodedTask,
  setClodedTask,
  overdueTask,
  setOverdueTask,
}) {
  const { propId } = useParams();

  const handleOpen = () => {
    setOpenTask(true);
    setPendingTask(false);
    setOnHoldTask(false);
    setClodedTask(false);
    setOverdueTask(false);
  };

  const handleHolding = () => {
    setOpenTask(false);
    setOnHoldTask(true);
    setPendingTask(false);
    setClodedTask(false);
    setOverdueTask(false);
  };

  const handlePending = () => {
    setOpenTask(false);
    setOnHoldTask(false);
    setPendingTask(true);
    setClodedTask(false);
    setOverdueTask(false);
  };

  const handleClosed = () => {
    setOpenTask(false);
    setOnHoldTask(false);
    setPendingTask(false);
    setClodedTask(true);
    setOverdueTask(false);
  };
  const handleOverdue = () => {
    setOpenTask(false);
    setOnHoldTask(false);
    setPendingTask(false);
    setClodedTask(false);
    setOverdueTask(true);
  };

  return (
    <React.Fragment>
      <Row>
        <Container fluid>
          <button
            onClick={handleOpen}
            className={
              openTask
                ? "btn bg-white boder-3 border-info text-info me-3 p-3 rounded-3"
                : "btn btn-info me-3 p-3 rounded-3"
            }
          >
            <div>
              <i className="fas fa-clipboard me-1"></i> Open Task (10)
            </div>
          </button>
          <button
            onClick={handleHolding}
            className={
              onHoldTask
                ? "btn bg-white boder-3 border-dark text-dark me-3 p-3 rounded-3"
                : "btn btn-dark me-3 p-3 rounded-3"
            }
          >
            <i className="far fa-pause-circle me-1"></i> On Hold Task (0)
          </button>
          <button
            onClick={handlePending}
            className={
              pendingTask
                ? "btn bg-white text-warning border border-warning me-3 p-3 rounded-3"
                : "btn btn-warning me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-spinner fa-spin"></i> Pending Task (30)
          </button>
          <button
            onClick={handleClosed}
            className={
              clodedTask
                ? "btn btn-success bg-white border border-success text-success me-3 p-3 rounded-3"
                : "btn btn-success me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-check-circle"></i> closed Task (04)
          </button>
          <button
            onClick={handleOverdue}
            className={
              overdueTask
                ? "btn bg-white border border-danger text-danger me-3 p-3 rounded-3"
                : "btn btn-danger me-3 p-3 rounded-3"
            }
          >
            <i className="	fas fa-clock"></i> Overdue Task (01)
          </button>
        </Container>
      </Row>
    </React.Fragment>
  );
}

Nav.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Nav));
