import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Row, Progress, Col } from "reactstrap";
import { GiTakeMyMoney } from "react-icons/gi";

import { useStore1Selector } from "./../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import useFecth from "../../../../hooks/useFecth";

const Navbar = ({ t }) => {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { data: getYearTicket, reFetch: reFetchGetYearTicket } = useFecth(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceReport`,
    token
  );

  const totalCost = getYearTicket[0]?.totalTicketsAmountClosed;

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <Card className="bd-rds">
            <CardBody className="d-flex align-items-center">
              <div className="w-100 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <span>
                    <p className="me-3">
                      <GiTakeMyMoney size={50} />
                    </p>
                  </span>
                  <span>
                    <h5>{t("Maintenance expenses report")}</h5>
                  </span>
                </div>
                <div>
                  {totalCost ? (
                    <h4 className="text-info">GH₵ {totalCost}</h4>
                  ) : null}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* <Col md={3}>
          <Card>
            <CardBody>
              <Row className="align-items-center">
                <div className="col-8">
                  <p className="mb-2 text-dark">
                    {t("Total Maintenance Cost")}
                  </p>
                  <h4 className="mb-0">GH₵ 1000</h4>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col> */}
        {/* <Col md={3}>
          <Card>
            <CardBody>
              <Row className="align-items-center">
                <div className="col-8">
                  <p className="mb-2 text-info">
                    {" "}
                    {t("Total Created Tickets")}{" "}
                  </p>
                  <h4 className="mb-0">1200</h4>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col> */}
        {/* <Col md={3}>
          <Card>
            <CardBody>
              <Row className="align-items-center">
                <div className="col-8">
                  <p className="mb-2 text-warning">
                    {t("Total Pending Tickets")}
                  </p>
                  <h4 className="mb-0">400</h4>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col> */}
        {/* <Col md={3}>
          <Card>
            <CardBody>
              <Row className="align-items-center">
                <div className="col-8">
                  <p className="mb-2 text-success">
                    {t("Total Closed Tickets")}{" "}
                  </p>
                  <h4 className="mb-0">800</h4>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col> */}
      </Row>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(Navbar));
