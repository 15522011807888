import React, { useState, useEffect } from "react";
import { Card, CardBody, Badge, Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";

import ViewInvoiceForm from "./ViewInvoiceForm";
import TakeActionForm from "./TakeActionForm";
import useFecth from "../../../../hooks/useFecth";

function ReceivedIvoiceTable() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;

  const [viewID, setViewID] = useState();
  const [actionID, setActionID] = useState();
  const [ticketRef, setTicketRef] = useState();
  const [viewModal, setViewModal] = useState(false);
  const [actionModal, setActionModal] = useState(false);

  const list = [];

  const { data: getMaintenanceTickets, reFetch: reFetchMaintenanceTickets } =
    useFecth(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceTickets`,
      token
    );

  getMaintenanceTickets?.forEach((res) => {
    if (res?.status === "sent") {
      list.push({
        ...res,
        propertyName: res?.propertyId?.propertyName,
        assidnedTo: res?.ticketAssign
          ? `${res?.assigned_to?.firstName} ${res?.assigned_to?.lastName}`
          : null,
        status:
          res?.status === "sent" ? (
            <>
              <Badge className="rounded-pill bg-warning p-2 ">Pending</Badge>
            </>
          ) : null,
        viewBtn: (
          <button
            className="btn btn-dark"
            onClick={() => viewInvoiceFunc(`${res?._id}`)}
          >
            View
          </button>
        ),
        actionBtn: (
          <button
            className="btn btn-success"
            onClick={() => actionInvoiceFunc(`${res?._id}`, res?.ticketNumber)}
          >
            Review
          </button>
        ),
      });
    }
  });

  const viewInvoiceFunc = (id) => {
    setViewID(id);
    setViewModal(true);
  };
  const actionInvoiceFunc = (id, ticketNum) => {
    setActionID(id);
    setTicketRef(ticketNum);
    setActionModal(true);
  };

  const column = [
    { label: "Property name", field: "propertyName", sort: "asc", width: 150 },
    { label: "Title Name", field: "subject", sort: "asc", width: 150 },
    { label: "Status", field: "status", sort: "asc", width: 150 },
    {
      label: "Ticket Reference",
      field: "ticketNumber",
      sort: "asc",
      width: 150,
    },
    {
      label: "Assign To",
      field: "assidnedTo",
      sort: "asc",
      width: 150,
    },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Action", field: "actionBtn", sort: "asc", width: 150 },
  ];

  const dataDB = { columns: column, rows: list };

  // console.log("getMaintenanceTickets...", getMaintenanceTickets);

  return (
    <div>
      <Card className="bd-rds">
        <CardBody>
          <div className="d-flex justify-content-between">
            <button className="btn login-btn w-md waves-effect waves-light mb-4">
              <CSVLink data={"dataDb"} separator={";"} className="text-white">
                Download in Excel
              </CSVLink>
            </button>
          </div>

          <MDBDataTable
            entries={5}
            entriesOptions={[5, 10, 50]}
            responsive
            bordered
            striped
            hover
            data={dataDB}
          />
        </CardBody>
      </Card>

      <Modal show={viewModal} onHide={() => setViewModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> View Quotation </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ViewInvoiceForm
            viewID={viewID}
            setViewModal={setViewModal}
            reFetchMaintenanceTickets={reFetchMaintenanceTickets}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-danger text-white"
            onClick={() => setViewModal(false)}
          >
            close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={actionModal} onHide={() => setActionModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Review Quotation </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <TakeActionForm
            actionID={actionID}
            ticketRef={ticketRef}
            setActionModal={setActionModal}
            reFetchMaintenanceTickets={reFetchMaintenanceTickets}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ReceivedIvoiceTable;
