import React from "react";
import { loginUser } from "../../../Redux/Slices/userSlice";
import { useStore1Selector } from "./../../../index";
import useFetch from "../../../hooks/useFecth";
function NoticeDetails({ noticeId }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;

  const { data, reFetch } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/notices/${noticeId}`,
    token
  );

  return (
    <div>
      <p>
        <b> Subject : </b> {data?.subject}
      </p>
      <h5 className="mb-3">
        <b> Description : </b>
      </h5>
      <p> {data?.description} </p>
      {data?.noticeDocument ? (
        <iframe
          src={`${data?.noticeDocument}`}
          width="100%"
          height="500px"
        ></iframe>
      ) : null}
    </div>
  );
}

export default NoticeDetails;
