export const UtilitiesTypes = {
  Residential: [
    "Generator",
    "Cleaning",
    "Electricity",
    "Garbage",
    "Water",
    "Other",
  ],
  Commercial: [
    "Generator",
    "Cleaning",
    "Electricity",
    "Garbage",
    "Water",
    "Other",
  ],
};
