import React from "react";
import useCustomMutation from "../../../hooks/useCustomMutation";
import { Row, CardBody, Col, Button, Spinner } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

function SuspendForm({ unitID, setSuspendModal, ownerId, token }) {
  const key = ["getAllBookableUnits", ownerId];
  const mutation = useCustomMutation(key);

  const handleSuspendUnit = (e, v) => {
    let startDateTimeStr = v.startDate + "T" + v.startTime + ":00.000Z";
    let endDateTimeStr = v.endDate + "T" + v.endTime + ":00.000Z";

    let startDateTime = new Date(startDateTimeStr);
    let endDateTime = new Date(endDateTimeStr);

    const Method = "PATCH",
      url = `bookableUnits/${unitID}/updateStatus`,
      isJSON = true;
    var formdata = new FormData();
    // formdata.append("suspensionReason", v.reason);
    // formdata.append("unitSuspensionStartDate", startDateTime);
    // formdata.append("unitSuspensionStartDate", endDateTime);
    formdata.append("bookableField", false);

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Bookable Unit Successfully Suspended",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  if (mutation.isSuccess) {
    setSuspendModal(false);
  }

  return (
    <>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleSuspendUnit(e, v);
        }}
      >
        <Row>
          <Col md={6}>
            <AvField
              name="startTime"
              label="Start time"
              className="form-control mb-2"
              type="time"
            />
          </Col>
          <Col md={6}>
            <AvField
              name="endTime"
              label="End time"
              className="form-control mb-2"
              type="time"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <AvField
              name="startDate"
              label="Start date"
              className="form-control mb-2"
              type="date"
            />
          </Col>
          <Col md={6}>
            <AvField
              name="endDate"
              label="End date"
              className="form-control mb-2"
              type="date"
            />
          </Col>
          <Col md={12}>
            <AvField
              name="reason"
              label="Reason For Suspension"
              className="form-control mb-2"
              rows={5}
              type="textarea"
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-center">
          <button
            className="btn login-btn w-md waves-effect waves-light mt-2"
            type="submit"
          >
            {!mutation.isLoading ? (
              <span className="me-2"> Submit </span>
            ) : null}
            {!mutation.isLoading ? null : (
              <span>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="me-2"
                />
                Loading...
              </span>
            )}
          </button>
        </div>
      </AvForm>
    </>
  );
}

export default SuspendForm;
