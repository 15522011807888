import PropTypes from "prop-types"
import React from "react"
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  Card,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

import Breadcrumb from "../../components/Common/Breadcrumb"
import MetaTagComp from "../../components/Common/MetaTags"

function AddVocateNote() {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Vacate Notice" />
        <Breadcrumb default="Property" title="Add Notice to vacate" />

        <Container fluid>


          <div className="page-title-box" >
            <CardBody data-aos="fade-bottom">

              <Row className="align-items-center mb-5">
                <div>
                  <Link to="/my-vacate-notices" className="btn login-btn w-md waves-effect waves-light" type="submit"> Back </Link>
                </div>
              </Row>

              <Card className="bd-rds" >
                <div className="TenantHeader bd-rds-header">
                  <h5>Add Notice to vacate</h5>
                </div>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={12} lg={12} xl={12}>
                      <AvForm className="form-horizontal mt-4" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>

                        <Row>
                          <Col md={12} lg={12} xl={12}>
                            <Row>
                              <Col md={4}>
                                <div className="mb-3">
                                  <AvField name="Tenant" label="Tenant Name" className="form-control" type="select" required>
                                    <option> Select... </option>
                                    <option> John Doe </option>
                                    <option> Jewel </option>
                                  </AvField>
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <AvField name="unit" label="Unit Name" className="form-control" type="select" required>
                                    <option> Select... </option>
                                    <option> TNG </option>
                                  </AvField>
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <AvField name="vacating_date" label="Vacating Date" type="date" required
                                  />
                                </div>
                              </Col>
                            </Row>

                          </Col>
                        </Row>

                        <div className="newTenantSubmitBtn">
                          <button className="btn login-btn w-md waves-effect waves-light" type="submit">Submit</button>
                        </div>

                      </AvForm>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardBody>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddVocateNote.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(AddVocateNote))
