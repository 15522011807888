import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useStore1Selector } from "./../../../../../index";
import { loginUser } from "../../../../../Redux/Slices/userSlice";
import usePost from "../../../../../hooks/usePost";
import useFetch from "../../../../../hooks/useFecth";
import NoticeType from "../../../../../components/NoticeType";

function UnitEditNotice({ noticeID, closeModal, reFetchNotice }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending } = usePost();
  const [noticeDocument, setNoticeDocument] = useState();

  const { data } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/notices/${noticeID}`,
    token
  );

  const handleValidSubmit = (e, v) => {
    e.preventDefault();
    const Method = "PATCH",
      endPoint = `notices/${noticeID}`,
      isJSON = true;

    const formdata = new FormData();
    formdata.append("subject", v.title);
    formdata.append("description", v.notice);
    if (noticeDocument) {
      formdata.append("noticeDocument", noticeDocument);
    }
    execute(
      endPoint,
      formdata,
      Method,
      "Notice successfully updated",
      token,
      isJSON
    );
    closeModal(false);
    setTimeout(() => {
      reFetchNotice();
    }, 2000);
  };

  const onFileChange = (e) => {
    setNoticeDocument(e.target.files[0]);
  };

  return (
    <div>
      <AvForm
        className="form-horizontal mt-4"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <div className="mb-3">
          <AvField
            name="title"
            label="Subject"
            className="form-control"
            type="select"
            required
          >
            <option value={data?.subject}> {data?.subject} </option>
            {NoticeType.map((item, index) => {
              if (data?.subject !== item) {
                return (
                  <option value={item} key={index}>
                    {item}
                  </option>
                );
              }
            })}
          </AvField>
        </div>

        <div className="mb-3">
          <AvField
            name="name"
            label="Attachment (Optional)"
            type="file"
            className="form-control"
            onChange={onFileChange}
          />
        </div>

        <div className="mb-3">
          <AvField
            className="form-control"
            type="textarea"
            label="Message"
            rows="3"
            name="notice"
            value={data?.description}
            errorMessage="This value is required."
            validate={{ required: { value: true } }}
          />
        </div>

        <button
          className="btn login-btn w-md waves-effect waves-light"
          type="submit"
        >
          {!pending ? <span className="me-2"> Submit </span> : null}
          {!pending ? null : (
            <span>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="me-2"
              />
              Loading...
            </span>
          )}
        </button>
      </AvForm>
    </div>
  );
}

export default UnitEditNotice;
