import PropTypes from "prop-types"
import React from "react"
import { Col, Container, Row, CardBody, Card } from "reactstrap";
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import ApexChart from './components/ApexChart'
import LineChart from './components/LineChart'
import CardsChart from './components/CardsChart'

const Dashboard = () => {

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | Dashboard" />
        <Breadcrumb default="Dashboard" title="" />

        <Container fluid>
          <div className="page-title-box">
            {/* <Welcome /> */}
            <CardsChart />
            <Row>
              <Col lg={6} data-aos="fade-up-right">
                <Card className="bd-rds">
                  <CardBody>
                    <h4 className="card-title mb-4"> Title here </h4>
                    <ApexChart />
                  </CardBody>
                </Card>
              </Col>

              <Col lg={6} data-aos="fade-up-left">
                <Card className="bd-rds">
                  <CardBody>
                    <h4 className="card-title mb-4"> Title here </h4>
                    <LineChart />
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>
        </Container>

      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Dashboard))