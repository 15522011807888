import React, { useState } from "react"
import { Form, Button } from "reactstrap"
import { var_num_100 } from "./../../../components/numbers_100"

function ExtraChargesForm() {

    const [rows1, setrows1] = useState([])
    const [rows2, setrows2] = useState([])

    function handleAddRowNested() {
        const item1 = { name1: "" }
        setrows1([...rows1, item1])
    }

    function handleAddRowNested1() {
        const item2 = { name1: "" }
        setrows2([...rows2, item2])
    }

    return (
        <>
            <Form className="repeater">
                <div data-repeater-list="group-a">
                    <div data-repeater-item className="row">
                        <div className="mb-3 col-lg-3">
                            <label className="form-label" htmlFor="name">Extra charge name</label>
                            <select type="select" id="name" name="quantity" className="form-control">
                                <option> Select... </option>
                                <option> Water </option>
                                <option> Light </option>
                                <option> Gardening </option>
                                <option> Key Deposits </option>
                                <option> Other </option>
                            </select>
                        </div>

                        <div className="mb-3 col-lg-3">
                            <label className="form-label" htmlFor="email">Extra Charge Value</label>
                            <input type="number" className="form-control" name="extra_charge_val" />
                        </div>

                        <div className="mb-3 col-lg-3">
                            <label className="form-label" htmlFor="name">Late Fee percentage</label>
                            <select type="select" id="name" name="late_fee_per" className="form-control">
                                {
                                    var_num_100.map(percentage => (<option> {percentage}</option>))
                                }
                            </select>
                        </div>

                        <div className="mb-3 col-lg-3">
                            <label className="form-label" htmlFor="name"> Grace Period (Days) </label>
                            <select type="select" id="name" name="grace_period" className="form-control">
                                {
                                    var_num_100.map(percentage => (<option> {percentage}</option>))
                                }
                            </select>
                        </div>
                    </div>
                </div>

                {rows2.map((item2, idx) => (
                    <React.Fragment key={idx}>
                        <div data-repeater-list="group-a" id={"addr" + idx} >
                            <div data-repeater-item className="row">
                                <div className="mb-3 col-lg-3">
                                    <label className="form-label" htmlFor="name">Extra charge name</label>
                                    <select type="select" id="name" name="quantity" className="form-control">
                                        <option> Select... </option>
                                        <option> Water </option>
                                        <option> Light </option>
                                        <option> Gardening </option>
                                        <option> Key Deposits </option>
                                        <option> Other </option>
                                    </select>
                                </div>

                                <div className="mb-3 col-lg-3">
                                    <label className="form-label" htmlFor="email">Extra Charge Value</label>
                                    <input type="number" className="form-control" name="extra_charge_val" />
                                </div>

                                <div className="mb-3 col-lg-3">
                                    <label className="form-label" htmlFor="name">Late Fee percentage</label>
                                    <select type="select" id="name" name="late_fee_per" className="form-control">
                                        {
                                            var_num_100.map(percentage => (<option> {percentage}</option>))
                                        }
                                    </select>
                                </div>

                                <div className="mb-3 col-lg-3">
                                    <label className="form-label" htmlFor="name"> Grace Period (Days) </label>
                                    <select type="select" id="name" name="grace_period" className="form-control">
                                        {
                                            var_num_100.map(percentage => (<option> {percentage}</option>))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
                <Button onClick={() => { handleAddRowNested1() }} color="success" className="btn login-btn w-md waves-effect waves-light me-5">Add more</Button>

                <button className="btn report-primary text-white w-md me-2" type="submit">Submit</button>
            </Form>

        </>
    )
}

export default ExtraChargesForm