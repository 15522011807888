import PropTypes from "prop-types";
import React from "react";
import "./Style.scss";
import { Row, Col } from "reactstrap";
import MetaTagComp from "../../../components/Common/MetaTags";
import { LoginMeta } from "../../../components/SCO_Name";
import LoginForm from "./Components/LoginForm";
import Slide from "../../../components/Slide";
const Index = () => {
  return (
    <React.Fragment>
      <MetaTagComp meta_tags={LoginMeta} />

      <div className="account-pages">
        <Row className="login">
          <Col md={6} className="login-left">
            <LoginForm />
          </Col>

          <Col md={6} className="login-container">
            <div className="login-img-container">
              <Slide />
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Index;
