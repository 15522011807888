import React, { useState } from "react";
import { Row, Col, Card, CardText, CardBody } from "reactstrap";
import SwitchSelector from "react-switch-selector";
import MonthlyForm from "./MonthlyForm";
import YearlyForm from "./YearlyForm";
import Recommended from "./Recommended";

function Packages() {
  const [toggle, setToggle] = useState("monthly");

  const options = [
    {
      label: <span className="mt-2 text-light">Monthly</span>,
      value: "monthly",
      selectedBackgroundColor: "#0097e6",
    },
    {
      label: <span className="mt-2 text-light">Yearly</span>,
      value: "yearly",
      selectedBackgroundColor: "#28a745",
    },
  ];
  const onChange = (newValue) => {
    setToggle(newValue);
  };

  const initialSelectedIndex = options.findIndex(
    ({ value }) => value === "monthly"
  );

  return (
    <Row className="package_subscription_Container">
      <Col md={6}>
        <div className="package_subscription">
          <div className="package_subscription_title"></div>
          <Card>
            <div className="p-4 package_subscription_card">
              <div>
                <div>
                  <h4 className="d-flex justify-content-center">Starter</h4>
                  <p className="d-flex justify-content-center text-justify">
                    Great for individual property owners with a few properties
                    to manage
                  </p>
                  <div className="d-flex justify-content-center">
                    <div
                      className="your-required-wrapper"
                      style={{ width: 200, height: 40 }}
                    >
                      <SwitchSelector
                        onChange={onChange}
                        options={options}
                        initialSelectedIndex={initialSelectedIndex}
                        backgroundColor={"#353b48"}
                        fontColor={"#f5f6fa"}
                      />
                    </div>
                  </div>
                  {toggle == "monthly" ? (
                    <div className="mt-3 h4 text-info d-flex justify-content-center">
                      $ 200 /Month
                    </div>
                  ) : (
                    <div className="mt-3 h4 text-success d-flex justify-content-center">
                      $ 2200 /Year
                    </div>
                  )}
                  <hr></hr>
                  {toggle == "monthly" ? <MonthlyForm /> : <YearlyForm />}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Col>
      <Col md={6}>
        <div className="package_subscription faspro_bg">
          <div className="package_subscription_title">
            <p className="package_subscription_text">Recommended</p>
          </div>
          <Card>
            <div className="p-4 package_subscription_card">
              <div>
                <div>
                  <h4 className="d-flex justify-content-center">
                    Professional
                  </h4>
                  <p className="d-flex justify-content-center text-justify">
                    Great for Property management and Estate management
                    companies
                  </p>
                  <hr></hr>
                  <Recommended />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Col>
    </Row>
  );
}

export default Packages;
