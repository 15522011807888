import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card } from "reactstrap";
import {
  registerUser,
  apiError,
  registerUserFailed,
} from "../../store/actions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { connect } from "react-redux";
import MetaTagComp from "../../components/Common/MetaTags";
import { PropertyManagerMeta } from "../../components/SCO_Name";
import Individual from "./Individual/Index";
import Company from "./Company/Index";
import SwitchSelector from "react-switch-selector";

const PropertyManagement = () => {
  const { userType, leadId, invoiceId } = useParams();
  const [toggle, setToggle] = useState("individual");
  const options = [
    {
      label: <span className="mt-2 text-light">Individual</span>,
      value: "individual",
      selectedBackgroundColor: "#0097e6",
    },
    {
      label: <span className="mt-2 text-light">Company</span>,
      value: "company",
      selectedBackgroundColor: "#28a745",
    },
  ];
  const onChange = (newValue) => {
    setToggle(newValue);
  };

  const initialSelectedIndex = options.findIndex(
    ({ value }) => value === "individual"
  );

  return (
    <React.Fragment>
      <MetaTagComp meta_tags={PropertyManagerMeta} />
      <div className="account-pages">
        <Row className="justify-content-center">
          <Col md={12} lg={12} xl={12} className="justify-content-center">
            <div className="property-content-container mt-3">
              <Card className="">
                <div className=" d-flex justify-content-center">
                  <div>
                    {/* <div className="mt-3 d-flex text-right border border-danger">
                      Already have an account ? Login
                    </div> */}
                    <h2 className="mt-3 d-flex justify-content-center">
                      Register as
                    </h2>
                    <div className="d-flex justify-content-center">
                      {/* <div
                        className="your-required-wrapper"
                        style={{ width: 200, height: 40 }}
                      >
                        <SwitchSelector
                          onChange={onChange}
                          options={options}
                          initialSelectedIndex={initialSelectedIndex}
                          backgroundColor={"#353b48"}
                          fontColor={"#f5f6fa"}
                        />
                      </div> */}
                    </div>
                    {/* {toggle == "individual" ? <Individual /> : <Company />} */}
                    {userType === "Individual" ? (
                      <Individual
                        userType={userType}
                        leadId={leadId}
                        invoiceId={invoiceId}
                      />
                    ) : (
                      <Company
                        userType={userType}
                        leadId={leadId}
                        invoiceId={invoiceId}
                      />
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

PropertyManagement.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(PropertyManagement);
