import React, { useState, useRef } from "react";
import "../Style.scss";
// import Img from "../../../../assets/images/faspro24_logo_original.png";
import { AvField } from "availity-reactstrap-validation";
import Img_600 from "./../../../../assets/images/img-size/600x400.gif";
import { Row, Col, Button } from "reactstrap";
import { var_num } from "../../../../components/Vatnumber";

function Input1(props) {
  const [profile, setProfile] = useState();
  const refFileUpload = useRef(null);
  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };

  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      props.setProfileServer(event.target.files[0]);

      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <div className="mt-4 d-flex justify-content-center container-form">
      <div className="wrapper-form">
        <div className="d-flex justify-content-center">
          <h2>Company Details</h2>
        </div>
        <div className="d-flex justify-content-center align-items-center mb-2">
          <img
            src={profile === undefined ? Img_600 : profile}
            alt="user"
            width={200}
            height={150}
            className="rounded"
          />
          <Button
            size="sm"
            variant="separator-light"
            className="btn-icon btn-icon-only position-absolute rounded s-0 b-0 mt-5"
            onClick={onThumbChangeClick}
          >
            <i className="ion ion-md-image"></i>
          </Button>
          <input
            type="file"
            ref={refFileUpload}
            className="file-upload d-none"
            accept="image/*"
            onChange={changeThumb}
          />
        </div>
        <Row>
          <Col md={6} lg={6}>
            <div className="mb-3">
              <AvField
                name="companyName"
                label="Company / Trustee name"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={6} lg={6}>
            <div className="mb-3">
              <AvField name="trading" label="Trading As" type="text" required />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <div className="mb-3">
              <AvField
                name="vatRate"
                label="VAT Rate(percentage)"
                type="select"
                required
              >
                {var_num.map((num) => (
                  <option> {num} </option>
                ))}
              </AvField>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={6}>
            <div className="mb-3">
              <AvField
                name="vatNumber"
                label="VAT Number(if applicable)"
                type="text"
              />
            </div>
          </Col>
          <Col md={6} lg={6}>
            <div className="mb-3">
              <AvField
                name="regNumber"
                label="Registration Number(if applicable)"
                type="text"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Input1;
