import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";

// import { successMessage, warningMessage } from "components/Notifications";

import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import useFetch from "../../../hooks/useFecth";
import UseDetailsForm from "./UserDetails";
import OtherUserDetailsForm from "./OtherUserDetails";
import BankDetails from "./BankDetails";
import UserPassword from "./UserPassword";

function AccountForm() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const userId = userDet?.data?._id;
  const [picture, setPicture] = useState(userDet?.data?.profilePic);

  const { data: getUserById, reFetch: reFetchGetUserById } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/users/${userId}`,
    token
  );

  const myRole = getUserById?.roles ?? [];
  const Roles = [
    "property_owner_individual",
    "property_owner_agency",
    "property_owner_company",
    "property_mgt_marketing",
    "property_mgt_sales",
    "property_mgt_finance",
    "property_mgt_maintenance",
    "property_mgt_leasing_officer",
    "portfolio_manager",
    "property_agency",
    "facility_manager",
    "service_provider",
    "service_provider_manager",
  ];

  return (
    <div>
      {myRole[0] === Roles[0] ||
      myRole[0] === Roles[1] ||
      myRole[0] === Roles[2] ? (
        <Card>
          <CardBody>
            <UseDetailsForm
              picture={picture}
              getUserById={getUserById}
              token={token}
              reFetchGetUserById={reFetchGetUserById}
            />
          </CardBody>
        </Card>
      ) : myRole[0] === Roles[3] ||
        myRole[0] === Roles[4] ||
        myRole[0] === Roles[5] ||
        myRole[0] === Roles[6] ||
        myRole[0] === Roles[7] ||
        myRole[0] === Roles[8] ||
        myRole[0] === Roles[9] ||
        myRole[0] === Roles[10] ||
        myRole[0] === Roles[11] ||
        myRole[0] === Roles[12] ? (
        <Card>
          <CardBody>
            <OtherUserDetailsForm
              picture={picture}
              getUserById={getUserById}
              token={token}
            />
          </CardBody>
        </Card>
      ) : null}
      <Card>
        <CardBody>
          <UserPassword
            getUserById={getUserById}
            token={token}
            reFetchGetUserById={reFetchGetUserById}
          />
        </CardBody>
      </Card>
      {/* {myRole[0] === Roles[0] ||
      myRole[0] === Roles[1] ||
      myRole[0] === Roles[2] ? (
        <Card>
          <CardBody>
            <BankDetails getUserById={getUserById} token={token} />
          </CardBody>
        </Card>
      ) : null} */}
    </div>
  );
}

export default AccountForm;
