/* eslint-disable no-unused-expressions */

import { useState } from "react";
import {
  dangerMessage,
  successMessage,
  warningMessage,
} from "../components/Toast";

const usePostRegistration = () => {
  const [postData, updatePostData] = useState({
    pending: false,
    data: undefined,
    error: undefined,
    lengths: undefined,
    status: null,
  });

  const execute = async (
    endPoint,
    raw,
    Method,
    displayMessage,
    token,
    isJSON
  ) => {
    updatePostData({
      pending: true,
      data: undefined,
      error: undefined,
      lengths: undefined,
      status: null,
    });

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    isJSON ? null : myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: Method,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/${endPoint}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res?.status === "success") {
          const Roles = [
            "property_owner_individual",
            "property_owner_agency",
            "property_owner_company",
            "property_mgt_marketing",
            "property_mgt_sales",
            "property_mgt_finance",
            "property_mgt_maintenance",
            "property_mgt_leasing_officer",
            "portfolio_manager",
            "property_agency",
            "facility_manager",
            "service_provider_manager",
            "service_provider",
            "security_manger",
          ];
          const isRoleIncluded = res?.data?.roles?.some((value) =>
            Roles.includes(value)
          );

          if (isRoleIncluded) {
            if (res?.data?.license) {
              updatePostData({
                pending: false,
                data: res,
                error: undefined,
                lengths: res?.result,
                status: res?.status,
              });
              displayMessage === null
                ? null
                : successMessage(`${displayMessage}`);
            } else {
              updatePostData({
                pending: false,
              });
              warningMessage("Your license has expired contact admin");
            }
          } else {
            updatePostData({
              pending: false,
            });
            warningMessage("Unauthorized access log into admin portal");
          }
        }
        if (res?.status === "fail") {
          updatePostData({
            pending: false,
            data: undefined,
            error: res?.message,
            lengths: undefined,
            status: res?.status,
          });
          warningMessage(`${res?.message}`);
        }
        if (res?.status === "error") {
          updatePostData({
            pending: false,
            data: undefined,
            error: res?.message,
            lengths: undefined,
          });
          if (res?.message === "jwt malformed") {
            updatePostData({
              pending: false,
            });
            warningMessage("You need to login");
          } else {
            updatePostData({
              pending: false,
            });
            warningMessage("Network issue please try again");
          }
        }
      })
      .catch((error) => {
        updatePostData({
          pending: false,
          data: undefined,
          error: error.message,
          lengths: undefined,
        });

        {
          error?.message === "Failed to fetch"
            ? dangerMessage("Oops! your internet is not working")
            : dangerMessage(`${error?.message}`);
        }
      });
  };

  return {
    execute,
    pending: postData.pending,
    data: postData.data,
    status: postData.status,
    error: postData.error,
    lengths: postData.lengths,
  };
};

export default usePostRegistration;
