import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row, CardBody, Spinner, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useStore1Selector } from "../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import usePost from "../../../../hooks/usePost";

function ReasonForm({ reopenID, reFetchMaintenanceTickets, setReopenModal }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending, data: postData } = usePost();

  const handleValidSubmit = (e, v) => {
    e.preventDefault();
    const Method = "PATCH",
      endPoint = `maintenanceTickets/${reopenID}/reopenTicket`,
      isJSON = null;

    const raw = JSON.stringify({
      status: "reopen",
      reopenReason: v.reason,
    });

    execute(
      endPoint,
      raw,
      Method,
      "Ticket successfully reopened",
      token,
      isJSON
    );
  };

  useEffect(() => {
    if (postData?.status === "success") {
      reFetchMaintenanceTickets();
      setReopenModal(false);
    }
  }, [postData?.status]);

  return (
    <>
      <AvForm
        className="form-horizontal mt-4"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <div className="mb-3">
          <AvField
            name="reason"
            className="form-control"
            type="textarea"
            rows={6}
            required
          />
        </div>

        <div className="d-flex justify-content-end">
          <button
            className="mx-3 btn login-btn w-md waves-effect waves-light"
            type="submit"
          >
            {!pending ? (
              "Reopen"
            ) : (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="me-2"
                />
                Loading...
              </>
            )}
          </button>

          <Button
            className="bg-danger text-white"
            onClick={() => setReopenModal(false)}
          >
            Close
          </Button>
        </div>
      </AvForm>
    </>
  );
}

export default ReasonForm;
