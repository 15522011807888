import React, { useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Spinner, Button } from "reactstrap";

import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";

import usePost from "../../../hooks/usePost";

function WorkingTicketForm({
  ticketId,
  setTicketModal,
  reFetchMaintenanceTickets,
}) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending, data: postData } = usePost();
  const [propertyId, setPropertyId] = useState(null);
  const [unitDetails, setUnitDetails] = useState([]);
  const [unitId, setUnitId] = useState("");
  const [category, setCategory] = useState("");
  const [attachedDocument, setAttachedDocument] = useState("");
  const ownerId = userDet?.data?._id;

  const handleValidSubmit = (e, v) => {
    e.preventDefault();
    const Method = "PATCH",
      endPoint = `maintenanceTickets/${ticketId}/moveToPending`,
      isJSON = null;

    const raw = JSON.stringify({
      status: "pending",
      estimated_completion_date: v.dateSetTime,
    });

    execute(
      endPoint,
      raw,
      Method,
      "Ticket has been successfully moved to the working queue",
      token,
      isJSON
    );
  };

  if (postData?.status === "success") {
    setTicketModal(false);
    reFetchMaintenanceTickets();
  }

  return (
    <div>
      <AvForm
        className="form-horizontal mt-4"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="dateSetTime"
                label="Set Completion time"
                className="form-control"
                type="date"
                required
              />
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-center">
          <button
            className="btn login-btn w-md waves-effect waves-light"
            type="submit"
          >
            {!pending ? (
              "Confirm"
            ) : (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="me-2"
                />
                Loading...
              </>
            )}
          </button>
        </div>
      </AvForm>
    </div>
  );
}

export default WorkingTicketForm;
