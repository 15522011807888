import PropTypes from "prop-types";
import React from "react";
import { Col, Row, CardBody, Card, Container } from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter, Link, useParams } from "react-router-dom";
import {
  MdOutlineAssignmentTurnedIn,
  MdPendingActions,
  MdOutlinePreview,
} from "react-icons/md";
import Click from "../../../../assets/Gif/Click here.gif";

function Nav({
  createTicket,
  setCreateTicket,
  tenantTicket,
  pendingTicket,
  setTenantTicket,
  setPendingTicket,
  clodedTicket,
  setClodedTicket,
  overdueTicket,
  setOverdueTicket,
}) {
  const { propId } = useParams();

  const handleTenant = () => {
    setTenantTicket(true);
    setCreateTicket(false);
    setPendingTicket(false);
    setClodedTicket(false);
    setOverdueTicket(false);
  };

  const handleCreated = () => {
    setCreateTicket(true);
    setTenantTicket(false);
    setPendingTicket(false);
    setClodedTicket(false);
    setOverdueTicket(false);
  };

  const handlePending = () => {
    setCreateTicket(false);
    setTenantTicket(false);
    setPendingTicket(true);
    setClodedTicket(false);
    setOverdueTicket(false);
  };

  const handleClosed = () => {
    setCreateTicket(false);
    setTenantTicket(false);
    setPendingTicket(false);
    setClodedTicket(true);
    setOverdueTicket(false);
  };
  const handleOverdue = () => {
    setCreateTicket(false);
    setTenantTicket(false);
    setPendingTicket(false);
    setClodedTicket(false);
    setOverdueTicket(true);
  };

  return (
    <React.Fragment>
      <Row>
        <Container fluid>
          <button
            onClick={handleCreated}
            className={
              createTicket
                ? "btn bg-white boder-3 border-dark text-dark me-3 p-3 rounded-3"
                : "btn btn-dark me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-ticket-alt me-1"></i> Created Ticket(0)
          </button>
          <button
            onClick={handleTenant}
            className={
              tenantTicket
                ? "btn bg-white boder-3 border-info text-info me-3 p-3 rounded-3"
                : "btn btn-info me-3 p-3 rounded-3"
            }
          >
            <div>
              <i className="fas fa-clipboard me-1"></i>Tenants Ticket(10)
            </div>
          </button>

          <button
            onClick={handlePending}
            className={
              pendingTicket
                ? "btn bg-white text-warning border border-warning me-3 p-3 rounded-3"
                : "btn btn-warning me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-spinner fa-spin"></i> Ticket in progess (30)
          </button>
          <button
            onClick={handleClosed}
            className={
              clodedTicket
                ? "btn btn-success bg-white border border-success text-success me-3 p-3 rounded-3"
                : "btn btn-success me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-check-circle"></i> closed Ticket (04)
          </button>
          <button
            onClick={handleOverdue}
            className={
              overdueTicket
                ? "btn bg-white border border-danger text-danger me-3 p-3 rounded-3"
                : "btn btn-danger me-3 p-3 rounded-3"
            }
          >
            <i className="	fas fa-clock"></i> Overdue Ticket (01)
          </button>
        </Container>
      </Row>
    </React.Fragment>
  );
}

Nav.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Nav));
