import React, { useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import useFetch from "../../../hooks/useFecth";
import InvoiceForm from "./UnitsComponents/Invoices/InvoiceForm";
import CreateLeaseForm from "./UnitsComponents/Lease/CreateLeaseForm";
import EditLeaseForm from "./UnitsComponents/Lease/EditLeaseForm";
import { LineWave } from "react-loader-spinner";

function ViewUnitDetails({
  unitID,
  propID,
  unitLease,
  unitUtilityTable,
  unitExtraChargeTable,
  reFetchInvoice,
  reFetchLease,
  //   setTenantDet,
  //   setGetTenantDet,
  //   setGetLeaseDet,
}) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const [CreateLeaseModal, setCreateLeaseModal] = useState(false);
  const [editLeaseModal, setEditLeaseModal] = useState(false);

  const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
  const { data } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/units/${unitID}`,
    token
  );
  const { data: propDetails } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/${propID}`,
    token
  );
  function SendInvoice() {
    setCreateInvoiceModal(true);
  }
  function CreateLeaseFunc() {
    setCreateLeaseModal(true);
  }
  function EditLeaseFunc() {
    setEditLeaseModal(true);
  }
  // console.log("unitLease.....", unitLease[0]?._id);
  return (
    <div>
      <Button onClick={() => SendInvoice()}>Generate an invoice</Button>
      {unitLease.length === 0 ? (
        <Button
          onClick={() => CreateLeaseFunc()}
          className="btn btn-success mx-2"
        >
          Create Lease
        </Button>
      ) : (
        <Button onClick={() => EditLeaseFunc()} className="btn btn-dark mx-2">
          Edit Lease
        </Button>
      )}

      <Row>
        <Col md={4}>
          <div>
            <h4 className="my-4"> Unit details : </h4>
            <p>
              {" "}
              <b>Unit Name </b> : {data?.unitName}
            </p>
            <p>
              {" "}
              <b>Unit Owner </b> : {data?.unitOwnerName}
            </p>
            <p>
              {" "}
              <b>Unit Type </b> : {data?.unitType}
            </p>
            <p>
              <b>Unit Number </b> : {data?.unitNumber}
            </p>
            {data?.numberOfBathRooms === "" ? null : (
              <p>
                {" "}
                <b>Number of bathrooms </b> : {data?.numberOfBathRooms}
              </p>
            )}

            {data?.numberOfBedrooms === "" ? null : (
              <p>
                <b>
                  {propDetails?.propertyType === "Residential"
                    ? "Number of Bedrooms"
                    : "Number of Rooms"}
                </b>
                : {data?.numberOfBedrooms}
              </p>
            )}
            <p>
              <b>Surface Area </b> : {data?.surfaceArea}
            </p>
          </div>
        </Col>
      </Row>
      <Modal
        className="modal-right scroll-out-negative"
        show={createInvoiceModal}
        onHide={() => setCreateInvoiceModal(false)}
        scrollable
        dialogClassName="full"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Generate Invoice</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <InvoiceForm
            propID={propID}
            unitID={unitID}
            unitLease={unitLease}
            unitUtilityTable={unitUtilityTable}
            unitExtraChargeTable={unitExtraChargeTable}
            closeModal={setCreateInvoiceModal}
            reFetchInvoice={reFetchInvoice}
          />
        </Modal.Body>
      </Modal>
      <Modal
        className="modal-right scroll-out-negative"
        show={CreateLeaseModal}
        onHide={() => setCreateLeaseModal(false)}
        scrollable
        dialogClassName="full"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a Lease </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CreateLeaseForm
            propID={propID}
            unitID={unitID}
            closeModal={setCreateLeaseModal}
            reFetchLease={reFetchLease}
          />
        </Modal.Body>
      </Modal>
      <Modal
        className="modal-right scroll-out-negative"
        show={editLeaseModal}
        onHide={() => setEditLeaseModal(false)}
        scrollable
        dialogClassName="full"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit lease </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditLeaseForm
            closeModal={setEditLeaseModal}
            reFetchLease={reFetchLease}
            unitLease={unitLease}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ViewUnitDetails;
