import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import "./Talk.scss";
import Logo from "../../../assets/images/logo-light.png";
import {
  AvForm,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { modules_arr } from "./Obejct-available";

function Slider() {
  return (
    <div className="ex-page-content mb-0">
      <div className="">
        <CardBody className="card__bg__color shadow-lg">
          <div className="talk_____logo">
            <img src={Logo} alt="" className="w-100" />
          </div>

          <div className="mt-5">
            <div>
              <h3 className="text-white mt-4">Start your journey with us</h3>
            </div>
            <p className="Talk-text mt-4">
              A management agent’s Property partner! It allows for integrations
              and scalable solutions for big or small properties
            </p>
          </div>
          <div className="">
            <Row>
              <Col
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{ delay: 2500, disableOnInteraction: false }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  {modules_arr().map((module, i) => {
                    return (
                      <SwiperSlide
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                        key={i}
                      >
                        <div className="d-flex justify-content-center module_____container">
                          <CardBody className="d-flex module_____wrapper">
                            <div className="module_____img">
                              <img src={module.img} alt="" />
                            </div>
                            <div className="module_____content">
                              <div>
                                <h3>{module.label}</h3>
                                <small className="text-center">
                                  {module.text}
                                </small>
                              </div>
                            </div>
                          </CardBody>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Col>
            </Row>
          </div>
        </CardBody>
      </div>
    </div>
  );
}

export default Slider;
