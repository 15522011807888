import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  Card,
  FormGroup,
  Spinner,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter, Link, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";

import useFetch from "../../../hooks/useFecth";
import Avatar from "../../../assets/images/Avatar.png";

function TenantDetailsForm({ viewID }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { data: unitDetails, reFetch: reFetchUnitDetails } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/units/tenants/${viewID}`,
    token
  );
  const { data: userDetails, reFetch: reFetchUserDetails } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/users/${viewID}`,
    token
  );

  return (
    <React.Fragment>
      <CardBody>
        <Row className="align-items-center">
          <Col md={12} lg={12} xl={12}>
            <AvForm className="form-horizontal mt-3">
              <Row>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={Avatar}
                    alt="user"
                    width={150}
                    height={150}
                    className="rounded"
                  />
                </div>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="role"
                      label="Role"
                      value={userDetails?.roles}
                      type="Text"
                      disabled
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="propertyName"
                      label="Property name"
                      value={unitDetails?.propertyId?.propertyName}
                      type="Text"
                      disabled
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="unitName"
                      label="Unit Name"
                      value={unitDetails?.unitName}
                      type="Text"
                      disabled
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="address"
                      label="Address"
                      value={`${unitDetails?.propertyId?.address} ${unitDetails?.propertyId?.city}`}
                      type="Text"
                      disabled
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="firstName"
                      label="First Name"
                      value={userDetails?.firstName}
                      className="form-control"
                      type="text"
                      disabled
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="lastName"
                      label="Last Name"
                      value={userDetails?.lastName}
                      className="form-control"
                      type="text"
                      disabled
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="Email"
                      value={userDetails?.email}
                      className="form-control"
                      type="email"
                      disabled
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="phoneNumber"
                      label="PhoneNumber"
                      value={`+${userDetails?.phoneNumber}`}
                      type="text"
                      disabled
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="gender"
                      label="Gender"
                      value={userDetails?.gender}
                      type="text"
                      disabled
                    />
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Col>
        </Row>
      </CardBody>
    </React.Fragment>
  );
}

TenantDetailsForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(TenantDetailsForm));
