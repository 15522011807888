import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Spinner } from "reactstrap";
import { Modal } from "react-bootstrap";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import UnitForm from "./UnitForm";
import EditBlockForm from "./EditBlockForm";

import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import useCustomMutation from "../../../hooks/useCustomMutation";

function PropBlock({ blockData, t, propId, propType, propertyCategory }) {
  const userDet = useStore1Selector(loginUser);
  const [unit, setUnit] = useState(false);
  const [blockId, setBlockId] = useState();
  const [blockIdUpdate, setBlockIdUpdate] = useState();
  const [blockIdDelete, setBlockIdDelete] = useState();
  const [block, setBlock] = useState(false);
  const [deleteBlock, setDeleteBlock] = useState(false);
  const token = userDet?.token;
  const key = ["myBlocks"];
  const mutation = useCustomMutation(key);

  let list = [];

  function addUnit(Id_Block) {
    setUnit(true);
    setBlockId(Id_Block);
  }
  function editFunc(Id_Block) {
    setBlock(true);
    setBlockIdUpdate(Id_Block);
  }
  function deleteFunc(Id_Block) {
    setDeleteBlock(true);
    setBlockIdDelete(Id_Block);
  }

  blockData?.forEach((res) => {
    list.push({
      ...res,
      viewBtn: (
        <button
          onClick={() => addUnit(`${res?._id}`)}
          className="btn btn-success"
        >
          {t("Add unit")}
        </button>
      ),
      editBtn: (
        <button
          className="btn btn-primary"
          onClick={() => editFunc(`${res?._id}`)}
        >
          {t("Edit")}
        </button>
      ),
      deleteBtn: (
        <button
          className="btn btn-danger"
          onClick={() => deleteFunc(`${res?._id}`)}
        >
          {t("Delete")}
        </button>
      ),
    });
  });

  const deleteBlockFunc = () => {
    const Method = "DELETE",
      endPoint = `blocks/${blockIdDelete}`,
      isJSON = true;
    var formdata = new FormData();

    const data = {
      endPoint: endPoint,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Block Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };

    mutation.mutate(data);
  };
  useEffect(() => {
    if (mutation.isSuccess) {
      setDeleteBlock(false);
    }
  }, [mutation.isSuccess]);

  const column = [
    { label: "Block Name", field: "blockName", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const newDdata = {
    columns: column,
    rows: list.reverse(),
  };
  // console.log(" propertyCategory", propertyCategory);
  return (
    <div>
      <MDBDataTable
        entries={5}
        entriesOptions={[5, 10, 50]}
        responsive
        bordered
        striped
        hover
        data={newDdata}
      />

      <Modal
        className="modal-right scroll-out-negative"
        show={unit}
        onHide={() => setUnit(false)}
        scrollable
        dialogClassName="full"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Add a unit")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <UnitForm
            blockId={blockId}
            closeModal={setUnit}
            propId={propId}
            propType={propType}
            propertyCategory={propertyCategory}
          />
        </Modal.Body>
      </Modal>

      <Modal
        className="modal-right scroll-out-negative"
        show={block}
        onHide={() => setBlock(false)}
        scrollable
        dialogClassName="full"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Update block details")} </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditBlockForm blockIdUpdate={blockIdUpdate} setBlock={setBlock} />
        </Modal.Body>
      </Modal>

      <Modal show={deleteBlock} onHide={() => setDeleteBlock(false)} size="sm">
        <Modal.Body className="text-danger">
          <h4> {t("Are you sure you want to delete this block ?")} </h4>{" "}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            variant="primary"
            onClick={() => setDeleteBlock(false)}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn btn-danger"
            variant="danger"
            onClick={deleteBlockFunc}
          >
            {!mutation.isLoading ? <>{t("Yes")}</> : null}
            {!mutation.isLoading ? null : (
              <span>
                {" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="me-2"
                />
                Loading...
              </span>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

PropBlock.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(PropBlock));
