import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, CardBody, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useStore1Selector } from "./../../../../../index";
import { loginUser } from "../../../../../Redux/Slices/userSlice";
import usePost from "../../../../../hooks/usePost";
import { Payment } from "./../../../../../components/Payment";
import { ExtraCharges } from "./../../../../../components/ExtraCharges";
import { CgLogIn } from "react-icons/cg";

function ExtraChargesForm({
  propID,
  propType,
  unitID,
  closeModal,
  reFetchExtraCharge,
}) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending } = usePost();
  const [other, setOther] = useState();

  const handleValidSubmit = (e, v) => {
    e.preventDefault();

    const Method = "POST",
      endPoint = `extraCharges/${propID}/${unitID}`,
      isJSON = null;
    const raw = JSON.stringify({
      amount: v.amount,
      type: v.type !== "Other" ? v.type : v.Specify,
      frequency: v.frequency,
      startDate: v.startDate,
      endDate: v.endDate,
    });
    execute(
      endPoint,
      raw,
      Method,
      "Extra charges successfully added",
      token,
      isJSON
    );
    setOther();
    closeModal(false);
    setTimeout(() => {
      reFetchExtraCharge();
    }, 2000);
  };

  function handleChange(event) {
    if (event.target.value === "Other") {
      setOther(event.target.value);
    }
  }

  return (
    <React.Fragment>
      <CardBody>
        <Row className="align-items-center">
          <Col md={12} lg={12} xl={12}>
            <AvForm
              className="form-horizontal mt-4"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    {propType === "Commercial" ? (
                      <AvField
                        name="type"
                        onChange={handleChange}
                        label="Select extra charges"
                        type="select"
                        required
                        className="form-control"
                      >
                        <option> Select...</option>

                        {ExtraCharges?.commercial?.map((item, index) => {
                          return <option key={index}>{item}</option>;
                        })}
                      </AvField>
                    ) : propType === "Residential" ? (
                      <AvField
                        name="type"
                        onChange={handleChange}
                        label="Select extra charges"
                        type="select"
                        required
                        className="form-control"
                      >
                        <option> Select...</option>

                        {ExtraCharges?.residential?.map((item, index) => {
                          return <option key={index}>{item}</option>;
                        })}
                      </AvField>
                    ) : null}
                  </div>
                </Col>
              </Row>
              {other === "Other" ? (
                <Row>
                  <Col md={12}>
                    <AvField
                      name="Specify"
                      label="Specify extra charge name"
                      type="text"
                      required
                      className="form-control mb-3"
                    />
                  </Col>
                </Row>
              ) : null}

              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="frequency"
                      label="Frequency"
                      type="select"
                      required
                      className="form-control"
                    >
                      <option> Select...</option>
                      {Payment.map((item, index) => {
                        return <option key={index}>{item}</option>;
                      })}
                    </AvField>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="amount"
                      label="Amount"
                      type="number"
                      required
                      className="form-control"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="startDate"
                      label="Extra charges start date"
                      type="date"
                      required
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="endDate"
                      label="Extra charges end date"
                      type="date"
                      required
                      className="form-control"
                    />
                  </div>
                </Col>
              </Row>

              <div className="text-start mt-5">
                <button
                  className="btn login-btn  waves-effect waves-light"
                  type="submit"
                >
                  {!pending ? <span className="me-2">Submit</span> : null}
                  {!pending ? null : (
                    <span>
                      {" "}
                      <Spinner as="span" animation="border" size="sm" />{" "}
                      Loading...
                    </span>
                  )}
                </button>
              </div>
            </AvForm>
          </Col>
        </Row>
      </CardBody>
    </React.Fragment>
  );
}

ExtraChargesForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(ExtraChargesForm));
