const propertyCategory = [
  {
    name: "Commercial",
    data: [
      "Offices",
      "Warehouse",
      "Hotels & Restaurant",
      "Shopping Malls",
      "Industrial Buildings",
      "Retail Store",
    ],
  },
  {
    name: "Residential",
    data: [
      "Apartments",
      "Duplex",
      "Single family homes",
      "Vacation homes",
      "condominiums",
      "Townhouses",
    ],
  },
];
export default propertyCategory;
