export const ExtraCharges = {
  residential: [
    "Water",
    "Electricity",
    "Gas",
    "Pest control",
    "Air-conditioning installations",
    "Sprinkler installations",
    "Fire fighter equipment",
    "Maximum demand metering",
    "Load shedding equipment",
    "Parking access equipment",
    "Pylon signs and other signage",
    "Advertising hoardings",
    "Insurance",
    "Other",
  ],
  commercial: [
    "Water",
    "Electricity",
    "Gas",
    "Pest control",
    "Air-conditioning installations",
    "Sprinkler installations",
    "Fire fighter equipment",
    "Maximum demand metering",
    "Load shedding equipment",
    "Parking access equipment",
    "Pylon signs and other signage",
    "Advertising hoardings",
    "Fire evacuation systems",
    "Cleaning equipment",
    "Sanitary cleansing equipment",
    "Sanitary consumables",
    "Fire/burglar alarm systems",
    "Lifts",
    "Other",
  ],
};
