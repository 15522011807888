import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Spinner,
  Badge,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { Link, useParams, useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "./../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import CreateTableForm from "./CreateTicketForm";
import ViewTicketForm from "./ViewTicketForm";
import useFecth from "../../../../hooks/useFecth";
import usePost from "../../../../hooks/usePost";
import CancelForm from "./CancelForm";

function OpenTable() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;

  const { execute, pending, data: postData } = usePost();
  // const [viewID, setViewID] = useState();
  const history = useHistory();
  const [cancelID, setCancelID] = useState();
  const [deleteID, setDeleteID] = useState();
  const [ticketModal, setTicketModal] = useState(false);
  // const [viewModal, setViewModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [btnAction, setBtnAction] = useState(false);
  const [more_Menu, setmore_Menu] = useState(false);

  const list = [];

  const { data: getMaintenanceTickets, reFetch: reFetchMaintenanceTickets } =
    useFecth(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceTickets`,
      token
    );

  // console.log("getMaintenanceTickets...", getMaintenanceTickets);

  getMaintenanceTickets?.forEach((res) => {
    if (res?.status === "open" || res?.status === "reopen") {
      list.push({
        ...res,
        propertyName: res?.propertyId?.propertyName,
        assidnedTo: res?.ticketAssign ? (
          `${res?.assigned_to?.firstName} ${res?.assigned_to?.lastName}`
        ) : (
          <p className="text-danger fw-bold"> Not Assigned</p>
        ),
        status:
          res?.status === "open" ? (
            <>
              <Badge className="rounded-pill bg-primary p-2">Created</Badge>
            </>
          ) : res?.status === "reopen" ? (
            <>
              <Badge className="rounded-pill bg-danger p-2">Reopened</Badge>
            </>
          ) : null,
        actionBtn: (
          <div className="dropdown-wrapper">
            <Dropdown
              isOpen={more_Menu}
              direction="up"
              toggle={() => {
                setmore_Menu(!more_Menu);
              }}
              className="btn-group me-2"
            >
              <DropdownToggle
                className="btn btn-success waves-light waves-effect dropdown-toggle"
                tag="div"
              >
                More <i className="mdi mdi-chevron-up" />
                {/* <i className="mdi mdi-chevron-down ms-1"></i> */}
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-right">
                <DropdownItem
                  className="custom-dropdown-item"
                  onClick={() => viewFunc(`${res?._id}`)}
                >
                  View
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="custom-dropdown-item">
                  Edit
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  className="custom-dropdown-item"
                  onClick={() => cancelFunc(`${res?._id}`)}
                >
                  Acknoledge Ticket
                </DropdownItem>
                <DropdownItem divider />
                {/* <DropdownItem
                  className="custom-dropdown-item"
                  onClick={() => cancelFunc(`${res?._id}`)}
                >
                  Cancel
                </DropdownItem> */}
                <DropdownItem divider />
                {/* <DropdownItem
                  className="custom-dropdown-item"
                  onClick={() => deleteFunc(`${res?._id}`)}
                >
                  Delete
                </DropdownItem> */}
                {/* <DropdownItem divider />
                <DropdownItem className="custom-dropdown-item">
                  Option 7
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="custom-dropdown-item">
                  Option 3
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="custom-dropdown-item">
                  Option 4
                </DropdownItem> */}
              </DropdownMenu>
            </Dropdown>
          </div>
        ),
      });
    }
  });

  const hanfdleAddService = () => {
    setTicketModal(true);
  };

  const column = [
    { label: "Property name", field: "propertyName", sort: "asc", width: 150 },

    { label: "Title Name", field: "subject", sort: "asc", width: 150 },
    { label: "Ticket Reference", field: "TicketRef", sort: "asc", width: 150 },
    { label: "Status", field: "status", sort: "asc", width: 150 },
    {
      label: "Assign To",
      field: "assidnedTo",
      sort: "asc",
      width: 150,
    },

    { label: "More Action", field: "actionBtn", sort: "asc", width: 150 },
  ];

  const dataDB = { columns: column, rows: list };

  const viewFunc = (id) => {
    history.push(`/view-ticket/${id}`);
  };

  const cancelFunc = (id) => {
    setCancelID(id);
    setCancelModal(true);
  };

  const deleteFunc = (id) => {
    setDeleteID(id);
    setDeleteModal(true);
  };

  const handleDeleteTicket = () => {
    const Method = "DELETE",
      endPoint = `maintenanceTickets/${deleteID}`,
      isJSON = null;

    const raw = null;

    execute(endPoint, raw, Method, "Ticket successfully delete", token, isJSON);
  };

  useEffect(() => {
    if (postData?.status === "success") {
      reFetchMaintenanceTickets();
      setDeleteModal(false);
      setCancelModal(false);
    }
  }, [postData?.status]);

  return (
    <div>
      <Card className="bd-rds">
        <CardBody>
          <div className="d-flex justify-content-between">
            {/* <Link
              onClick={hanfdleAddService}
              className="btn login-btn w-md waves-effect waves-light mb-4 report-primary"
              type="submit"
            >
              Create ticket
            </Link> */}
            <button className="btn login-btn w-md waves-effect waves-light mb-4">
              <CSVLink data={"dataDb"} separator={";"} className="text-white">
                Download in Excel
              </CSVLink>
            </button>
          </div>
          <div className="position-relative">
            <style>
              {`
          .dropdown-wrapper {
            position: absolute;
            z-index: 9999;
          }
           .dropdown-menu-right {
            max-height: none !important;
            overflow: visible !important;
          }
          
        `}
            </style>
            <MDBDataTable
              entries={5}
              entriesOptions={[5, 10, 50]}
              responsive
              bordered
              striped
              hover
              data={dataDB}
              fullpagination="true"
            />
          </div>
        </CardBody>
      </Card>

      {/* <Modal show={ticketModal} onHide={() => setTicketModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Create a ticket </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CreateTableForm
            setTicketModal={setTicketModal}
            reFetchMaintenanceTickets={reFetchMaintenanceTickets}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-danger text-white"
            onClick={() => setTicketModal(false)}
          >
            close
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* <Modal show={viewModal} onHide={() => setViewModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> View a ticket </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ViewTicketForm
            viewID={viewID}
            setViewModal={setViewModal}
            reFetchMaintenanceTickets={reFetchMaintenanceTickets}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-danger text-white"
            onClick={() => setViewModal(false)}
          >
            close
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal show={cancelModal} onHide={() => setCancelModal(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Cancel Ticket </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-dark h5">Give a reaseon</div>
          <CancelForm
            cancelID={cancelID}
            reFetchMaintenanceTickets={reFetchMaintenanceTickets}
            setCancelModal={setCancelModal}
          />
        </Modal.Body>
      </Modal>
      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Delete Ticket </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-danger h4">
            Are you sure you want to delete this ticket ?
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-success text-white"
            onClick={handleDeleteTicket}
          >
            {!pending ? (
              "Sure"
            ) : (
              <span>
                <Spinner as="span" animation="border" size="sm" /> Loading...
              </span>
            )}
          </Button>
          <Button
            className="bg-danger text-white"
            onClick={() => setDeleteModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default OpenTable;
