import React from "react";
import { Button } from "reactstrap";
import { BsCheckLg } from "react-icons/bs";

function Recommended() {
  return (
    <div className="package_subscription_features">
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>

        <h5 className="ms-2 p-1 mb-2">Everything in the starter package </h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>

        <h5 className="ms-2 p-1 mb-2">
          Add Teams using the user management module
        </h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>

        <h5 className="ms-2 p-1 mb-2">Manage property assets</h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>

        <h5 className="ms-2 p-1 mb-2">Add bookable units</h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>

        <h5 className="ms-2 p-1 mb-2">Manage and share property documents</h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>
        <h5 className="ms-2 p-1 mb-2">
          Secure property using the security management module
        </h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>
        <h5 className="ms-2 p-1 mb-2">
          Full access to analytics / property performance reports
        </h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>
        <h5 className="ms-2 p-1 mb-2">
          Add and manage service providers assigned to properties
        </h5>
      </div>
      <div className="mt-2 d-flex justify-content-center">
        <a href="https://faspro24.com/contact-us/">
          <Button className="bg-success text-light">Let’s Talk</Button>
        </a>
      </div>
    </div>
  );
}

export default Recommended;
