import React, { useState } from "react";
import { AvField } from "availity-reactstrap-validation";
import { Row, Col, CardBody, Button } from "reactstrap";
import PhoneInput from "react-phone-input-2";

function Input2(props) {
  return (
    <div className="mt-4 d-flex justify-content-center container-form">
      <div className="wrapper-form">
        <div className="d-flex justify-content-center">
          <h2>Contact Person</h2>
        </div>
        <Col md={12} lg={12}>
          <div className="mb-3">
            <AvField
              name="email"
              label="Email"
              className="form-control"
              type="email"
              required
            />
          </div>
        </Col>
        <Row>
          <Col md={6} lg={6}>
            <div className="mb-3">
              <AvField
                name="newPassword"
                label="Password"
                type="password"
                required
              />
            </div>
          </Col>
          <Col md={6} lg={6}>
            <div className="mb-3">
              <AvField
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                required
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="firstName"
                label="First Name"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField name="lastName" label="Last Name" type="text" required />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <AvField name="gender" label="Gender" type="select" required>
                <option> Select.. </option>
                <option> Male </option>
                <option> Female </option>
              </AvField>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="ID"
                label="ID / Passport number"
                type="text"
                required
              />
            </div>
          </Col>
        </Row>



        <label>Phone number</label>
        <div className="mb-3">
          <PhoneInput
            buttonStyle={{
              borderBottomLeftRadius: 5,
              borderTopLeftRadius: 5,
              height: 40,
            }}
            inputStyle={{
              margin: 0,
              width: "100%",
              color: "purple",
              height: 1,
              border: "none",
            }}
            containerStyle={{
              width: "100%",
            }}
            className="blue"
            country={"za"}
            enableSearch={true}
            onChange={(phone) => props.setPhoneState(phone)}
          />
        </div>
      </div>
    </div>
  );
}

export default Input2;
