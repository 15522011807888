import PropTypes from "prop-types";
import React from "react";
import { Row, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";

import MyHistoryTable from "./components/MyHistoryTable";

function MyHistory() {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | Tickets History" />
        <Breadcrumb default="Maintenance Manangement" title="My History" />

        <Row className="d-flex justify-content-around align-items-center">
          <CardBody data-aos="fade-bottom">
            <Card className="bd-rds">
              <CardBody>
                <MyHistoryTable />
              </CardBody>
            </Card>
          </CardBody>
        </Row>
      </div>
    </React.Fragment>
  );
}

MyHistory.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(MyHistory));
