import React, { useState, useRef } from "react";

import { useHistory } from "react-router-dom";
import { Col, Row, Button, Spinner, Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useStore1Selector } from "./../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import useCustomMutation from "../../../../hooks/useCustomMutation";

function appartments({ closeModal, propId }) {
  const [unitType, setUnitType] = useState(null);
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const userId = userDet?.data?._id;

  const key = ["myPropertyUnits"];
  const mutation = useCustomMutation(key);

  const handleValidSubmit = (e, v) => {
    e.preventDefault();
    const Method = "POST",
      endPoint = `units/${userId}/${propId}`;
    const raw = JSON.stringify({
      unitType: v.unitType,
      surfaceArea: v.surfaceArea,
      unitName: v.unitName,
      unitNumber: v.unitNumber,
      RentAmount: v.RentAmount,
      unitOwnerName: v.unitOwnerName,
      numberOfBedrooms: v.numberOfBedrooms,
      numberOfBathRoom: v.numberOfBathRoom,
      kitchen: v.kitchen,
      storage: v.storage,
      parkingGarage: v.parkingGarage,
      numberOfFloor: v.numberOfFloor ? v.numberOfFloor : null,
      numberOfBalcony: v.numberOfBalcony,
      numberOfLivingRoom: v.numberOfLivingRoom,
      leaseTerm: "yes",
    });

    const data = {
      endPoint: endPoint,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Unit successfully added",
      token: token,
      isJSON: null,
    };

    mutation.mutate(data);
  };

  const selectHandle = (e) => {
    setUnitType(e.target.value);
  };

  if (mutation.isSuccess) {
    closeModal(false);
  }
  return (
    <div>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="unitType"
                onChange={selectHandle}
                label="Unit type"
                className="form-control"
                type="select"
                required
              >
                <option> Select... </option>
                <option> Single </option>
                <option> Multiple </option>
              </AvField>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="surfaceArea"
                label="Unit size (Square Meter)"
                className="form-control"
                type="text"
              />
            </div>
          </Col>
        </Row>
        <Row>
          {unitType === "Multiple" ? (
            <Col md={12}>
              <div className="mb-3">
                <AvField
                  name="numberOfFloor"
                  label="Number of floor(s)"
                  className="form-control"
                  type="number"
                />
              </div>
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="unitName"
                label="Unit name"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="unitNumber"
                label="Unit number"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="RentAmount"
                label="Rent amount"
                className="form-control"
                type="text"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="unitOwnerName"
                label="Unit owner(optional)"
                className="form-control"
                type="text"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="numberOfLivingRoom"
                label="Number of living room(s)"
                className="form-control"
                type="number"
                required
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="numberOfBalcony"
                label="Number of balcony(s)"
                className="form-control"
                type="number"
                required
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="numberOfBedrooms"
                label="Number of bedroom(s)"
                className="form-control"
                type="number"
                required
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="numberOfBathRoom"
                label="Number of bathRoom(s)"
                className="form-control"
                type="number"
                required
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="kitchen"
                label="Number of kitchen(s)"
                className="form-control"
                type="number"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="storage"
                label="Number of storeroom(s)"
                className="form-control"
                type="number"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="parkingGarage"
                label="Parking and garage"
                className="form-control"
                type="text"
              />
            </div>
          </Col>
        </Row>
        <button
          className="btn login-btn w-md waves-effect waves-light"
          type="submit"
        >
          {!mutation.isLoading ? <span className="me-2"> Submit </span> : null}
          {!mutation.isLoading ? null : (
            <span>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="me-2"
              />
              Loading...
            </span>
          )}
        </button>
      </AvForm>
    </div>
  );
}

export default appartments;
