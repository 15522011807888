import React, { useEffect, useState } from "react";
import { Row, Col, CardBody } from "reactstrap";
import MetaTagComp from "../../components/Common/MetaTags";
import { Link, useParams } from "react-router-dom";
import { warningMessage } from "../../components/Notifications";

const VerifyEmail = () => {
  const { id, token } = useParams();
  const [response, setResponse] = useState(false);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/auth/verify/${id}/${token}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result.....:", result);
        if (result?.status === "success") {
          setResponse(" Thank you, you have successful verified your email");
        }
        if (result?.status === "fail") {
          setResponse(result?.message);
        }
        if (result?.status === "error") {
          setResponse(result?.message);
        }
      })
      .catch((error) => {
        warningMessage(error.message);
      });
  }, [id]);

  return (
    <React.Fragment>
      <MetaTagComp meta_tags="FASPRO 24 | Verify email" />
      <div className="account-pages">
        <Row className="justify-content-center mt-5">
          <Col md={12} lg={12} xl={12}>
            <CardBody className="text-center">
              {response === "invalid link" ? (
                <h3 className="text-danger">{response}</h3>
              ) : (
                <h3 className="text-success">{response}</h3>
              )}

              {response === "invalid link" ? null : (
                <Link
                  to="/login"
                  className="btn login-btn w-md waves-effect waves-light mt-5"
                  type="submit"
                >
                  Go to Log In
                </Link>
              )}
            </CardBody>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default VerifyEmail;
