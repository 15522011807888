import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, Modal, Spinner } from "react-bootstrap";
import { withRouter, useParams, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import Property_port from "./components/properties-Report";
import BlockForm from "./components/Block-Form";
// import EditBlockForm from "./components/EditBlockForm";
import UnitFormWithNoBlock from "./components/UnitFormWithNoBlock";
import { loginUser } from "../../Redux/Slices/userSlice";
import { useStore1Selector } from "./../../index";

import useCustomQuery from "../../hooks/useCustomQuery";
import useCustomMutation from "../../hooks/useCustomMutation";

import { ThreeCircles } from "react-loader-spinner";
import Loading from "components/Loading";
import PropBlock from "./components/PropBlock";
import { BsEye } from "react-icons/bs";
import { GiHouse } from "react-icons/gi";
import PropertyNotice from "./components/PropertyNotice";
import PropertyReport from "./components/properties-Report";
import PropertyModule from "./components/PropertyModule";
// import ServiceProviderForm from "./components/ServiceProviderForm";

function PropertyDetails(props) {
  const { propId } = useParams();
  const userDet = useStore1Selector(loginUser);

  const key = ["myPropertyReport"];
  const mutation = useCustomMutation(key);

  const token = userDet?.token;

  const {
    data: getMyPropertyDetails,
    error: errorMyPropDetails,
    isLoading: loadingMyPropDetails,
  } = useCustomQuery(
    ["myPropertyDetails", propId],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/${propId}`,
    token
  );

  const {
    data: getMyBlocks,
    error: errorMyBlocks,
    isLoading: loadingMyBlocks,
  } = useCustomQuery(
    ["myBlocks"],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/blocks/property/${propId}`,
    token
  );

  const [unit, setUnit] = useState(false);
  const [notice, setNotice] = useState(false);
  const [block, setBlock] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  function addUnit() {
    setUnit(true);
  }
  function sendNotice() {
    setNotice(true);
  }

  function GenerateReport() {
    const Method = "POST",
      endPoint = `units/property-statistics/${propId}`;
    const formdata = new FormData(),
      isJSON = true;

    const data = {
      endPoint: endPoint,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Report successfully generated",
      token: token,
      isJSON: isJSON,
    };

    mutation.mutate(data);
  }

  function addBlock() {
    setBlock(true);
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Property detail" />
        <Breadcrumb
          default={props.t("Property")}
          title={props.t("Property detail")}
        />

        <Card.Body>
          <Card.Body>
            <Link className="btn login-btn mb-3" to="/my-properties">
              {props.t("Back")}
            </Link>

            <PropertyReport
              propId={getMyPropertyDetails?._id}
              propType={getMyPropertyDetails?.propertyType}
            />

            <Card className="bd-rds">
              <Card.Body>
                <Container fluid>
                  <div>
                    {getMyPropertyDetails?.propertyBlock ? (
                      <button
                        onClick={addBlock}
                        className="btn login-btn w-md waves-effect waves-light mb-4 report-primary"
                        type={props.t("submit")}
                      >
                        {props.t("+ Add a block")}
                      </button>
                    ) : null}

                    {!getMyPropertyDetails?.propertyBlock ? (
                      <button
                        onClick={addUnit}
                        className="btn login-btn w-md waves-effect waves-light mb-4 report-primary mx-2"
                        type={props.t("submit")}
                      >
                        {props.t("+ Add a unit")}
                      </button>
                    ) : null}

                    <button
                      onClick={sendNotice}
                      className="btn login-btn w-md waves-effect waves-light mb-4 report-primary mx-2"
                      type={props.t("submit")}
                    >
                      {props.t("Send Notice")}
                    </button>
                    <button
                      onClick={GenerateReport}
                      className="btn login-btn w-md waves-effect waves-light mb-4 report-danger mx-2"
                      type={props.t("submit")}
                    >
                      {!mutation.isLoading ? (
                        "Generate report"
                      ) : (
                        <span>
                          <Spinner as="span" animation="border" size="sm" />{" "}
                          loading...
                        </span>
                      )}
                    </button>
                  </div>

                  <Row className="d-flex justify-content-around align-items-center">
                    <Col sm={12} md={6} lg={6}>
                      <h3 className="mb-4 text-success">
                        {getMyPropertyDetails?.propertyName}
                      </h3>
                      <p>
                        <b>Property Type: </b>{" "}
                        {getMyPropertyDetails?.propertyType}
                      </p>
                      <p>
                        <b>Property Category: </b>{" "}
                        {getMyPropertyDetails?.propertyCategory}
                      </p>
                      <p>
                        <b>{props.t("Address")} </b>:{" "}
                        {getMyPropertyDetails?.address}
                      </p>
                      <p>
                        <b> {props.t("Country")} </b>:{" "}
                        {getMyPropertyDetails?.country}
                      </p>
                      <p>
                        <b> {props.t("City")} </b>: {getMyPropertyDetails?.city}
                      </p>
                      <p>
                        <b> {props.t("Postal Code")}: </b>
                        {getMyPropertyDetails?.postalCode}
                      </p>
                    </Col>

                    <Col sm={12} md={6} lg={6}>
                      {loadingMyPropDetails ? (
                        <ThreeCircles
                          height="100"
                          width="100"
                          color="#23B25A"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="three-circles-rotating"
                          outerCircleColor=""
                          innerCircleColor=""
                          middleCircleColor=""
                        />
                      ) : (
                        <div className="property-image">
                          <img
                            src={getMyPropertyDetails?.propertyImage}
                            alt=""
                            className="img"
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>

            {getMyPropertyDetails?.propertyBlock ? (
              <Card className="bd-rds">
                <Card.Body>
                  <PropBlock
                    blockData={getMyBlocks}
                    propId={propId}
                    propType={getMyPropertyDetails?.propertyType}
                    propertyCategory={getMyPropertyDetails?.propertyCategory}
                  />
                </Card.Body>
              </Card>
            ) : null}
          </Card.Body>
        </Card.Body>

        <Modal
          className="modal-right scroll-out-negative"
          show={unit}
          onHide={() => setUnit(false)}
          scrollable
          dialogClassName="full"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{props.t("Add a unit")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UnitFormWithNoBlock
              propId={propId}
              propType={getMyPropertyDetails?.propertyType}
              propertyCategory={getMyPropertyDetails?.propertyCategory}
              closeModal={setUnit}
            />
          </Modal.Body>
        </Modal>

        <Modal
          className="modal-right scroll-out-negative"
          show={block}
          onHide={() => setBlock(false)}
          scrollable
          dialogClassName="full"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {props.t("Enter Your Property Block Details")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <BlockForm propId={propId} closeModal={setBlock} />
          </Modal.Body>
        </Modal>

        <Modal
          className="modal-right scroll-out-negative"
          show={notice}
          onHide={() => setNotice(false)}
          scrollable
          dialogClassName="full"
        >
          <Modal.Header closeButton>
            <Modal.Title> {props.t("Send General notice")} </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <PropertyNotice
              propId={getMyPropertyDetails?._id}
              closeModal={setNotice}
            />
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
}

PropertyDetails.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(PropertyDetails));
