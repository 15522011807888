import React, { useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import usePost from "hooks/usePost";
// import { LeadNoticeMsg } from "components/NotifyMessage";

function Form({ setValidation }) {
  const [selectedModules, setselectedModules] = useState([]);
  const [phoneState, setPhoneState] = useState();
  const [loader, setLoader] = useState(false);
  const [orgTypeValue, setOrgTypeValue] = useState();
  const onSelect = (event) => {
    setselectedModules(event);
  };
  const onRemove = (e) => {
    setselectedModules(e);
  };

  const modules = [
    "Facility Management",
    "Maintenance Management",
    "Asset Management",
    "Bookings Management",
    "Security Management",
    "File Management",
  ];

  const { execute, pending, data } = usePost();
  const handleSend = (e, v) => {
    e.preventDefault();
    setLoader(true);
    const Method = "POST",
      endPoint = "leads";

    const raw = JSON.stringify({
      email: v.email,
      phoneNumber: phoneState,
      firstName: v.firstName,
      companyName: v.companyName,
      lastName: v.lastName,
      companySize: v.companySize,
      organizationType: v.organizationType,
      interestedModules: selectedModules,
      description: "",
    });

    execute(endPoint, raw, Method, "Successfully Sent");
  };

  if (pending) {
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }
  if (data?.status === "success") {
    setValidation(true);
  }
  const multiselectStyles = {
    multiselectContainer: {
      backgroundColor: "#F7F7F7",
      border: "1px solid #ffff",
      borderRadius: "4px",
    },
    optionContainer: {
      backgroundColor: "#fff",
      border: "1px solid #C8C8C8",
      color: "#333",
    },
    option: {
      backgroundColor: "#F7F7F7",
      border: "1px solid #C8C8C8",
      color: "#26a958",
    },
    chips: {
      backgroundColor: "#26a958",
      borderRadius: "4px",
      color: "#fff",
    },
  };
  const selectHandle = (e) => {
    setOrgTypeValue(e.target.value);
  };

  return (
    <div>
      <h2 className="mb-3">Complete your details</h2>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleSend(e, v);
        }}
      >
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="firstName"
                label="First Name"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="lastName"
                label="Last Name"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="email"
                label="Email"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={12} lg={12}>
            <label>Phone number</label>
            <div className="mb-3">
              <PhoneInput
                buttonStyle={{
                  borderBottomLeftRadius: 5,
                  borderTopLeftRadius: 5,
                  height: 40,
                }}
                inputStyle={{
                  margin: 0,
                  width: "100%",
                  color: "purple",
                  height: 1,
                  border: "none",
                }}
                containerStyle={{
                  width: "100%",
                }}
                className="blue"
                country={"za"}
                enableSearch={true}
                onChange={(phone) => setPhoneState(phone)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="organizationType"
                onChange={selectHandle}
                label="Organization Type"
                className="form-control"
                type="select"
                required
              >
                <option>Select...</option>
                <option>Individual</option>
                <option>Company</option>
                <option>Other</option>
              </AvField>
            </div>
          </Col>
          {orgTypeValue === "Other" ? (
            <Col md={12}>
              <div className="mb-3">
                <AvField
                  name="organizationType"
                  className="form-control"
                  type="text"
                />
              </div>
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="companyName"
                label="Company Name"
                className="form-control"
                type="text"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="companySize"
                label="Number of Employees"
                className="form-control"
                type="number"
                required
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <label className="control-label">
                Select Modules of Interest
              </label>
              <Multiselect
                isObject={false}
                options={modules} // Options to display in the dropdown
                // selectedValues={"this.state.selectedValue"} // Preselected value to persist in dropdown
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                // displayValue="name" // Property name to display in the dropdown options
                style={multiselectStyles}
              />
            </div>
          </Col>
        </Row>
        <div className="mb-3 row ">
          <div className="col-12 d-flex justify-content-center">
            <button
              className="btn login-btn w-md waves-effect waves-light"
              type="submit"
            >
              {!loader ? <span className="me-2"> Submit </span> : null}

              {!loader ? null : (
                <span>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    className="me-2"
                  />
                  Loading...
                </span>
              )}
            </button>
          </div>
        </div>
      </AvForm>
    </div>
  );
}

export default Form;
