import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";

const MaintenanceLinebarchart = ({ getAllstats }) => {
  // console.log(" getAllstats ");
  const resRentPaid = getAllstats?.map((item) => item?.total_Paid_RentAmount);
  const resPaidUtility = getAllstats?.map(
    (item) => item?.total_Paid_UtilityRentAmount
  );
  const resExtraCharge = getAllstats?.map(
    (item) => item?.total_Paid_ExtraChargesRentAmount
  );

  const resMonth = getAllstats?.map((item) => item?.month?.substring(0, 3));
  // console.log("resRentPaid...,", resRentPaid);
  const getOption = () => {
    return {
      series: [
        {
          name: "Maintenance",
          type: "bar",
          data: resRentPaid,
        },
        {
          name: "Management",
          type: "bar",
          data: resPaidUtility,
        },
        {
          name: "Repair",
          type: "bar",
          data: resExtraCharge,
        },
      ],
      grid: {
        zlevel: 0,
        x: 70,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
        backgroundColor: "rgba(0,0,0,0)",
        borderColor: "rgba(0,0,0,0)",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      toolbox: {
        feature: {
          dataView: { show: false, readOnly: false },
          magicType: { show: false, type: ["line", "bar"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      color: ["#1BC5C5", "#FFCB11", "#FB0404"],
      legend: {
        data: ["Maintenance", "Management", "Repair"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      xAxis: [
        {
          type: "category",
          data: resMonth,
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: `GH₵`,
          min: 0,
          max: 100000,
          interval: 10000,
          axisLabel: {
            formatter: "{value}",
          },
          axisLine: {
            lineStyle: {
              color: "#74788d",
            },
          },
        },
      ],
    };
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={getOption()} />
    </React.Fragment>
  );
};
export default MaintenanceLinebarchart;
