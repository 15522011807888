import React, { useState } from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "reactstrap"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

function ViewDetails(props) {

    return (
        <div className="page-title-box">
            <div className="bd-rds" data-aos="fade-bottom">
                <Row>
                    <Col md={6}>
                        <h5 className="mb-3">Personal Details : </h5>
                        <p>First Name : {props.details[0].FirstName}</p>
                        <p>Last Name : {props.details[0].LastName}</p>
                        <p>Email : {props.details[0].Email}</p>
                        <p>Role : {props.details[0].Name}</p>
                    </Col>
                </Row>
            </div>

        </div>
    )
}

ViewDetails.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(ViewDetails))
