import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { AiOutlineArrowRight } from "react-icons/ai";

const signUp_arr = [
  {
    id: 1,
    icon: BsFillArrowRightCircleFill,
    name: "Property Owner",
    link: "/property-owner",
    btn_icon: AiOutlineArrowRight,
  },
  {
    id: 2,
    icon: BsFillArrowRightCircleFill,
    name: "Property Agency",
    link: "/property-agencies",
    btn_icon: AiOutlineArrowRight,
  },
];

export const signUp = () => {
  return signUp_arr;
};
