import PropTypes from "prop-types";
import React from 'react';
import { Col, Container, Row, CardBody, Card, Progress } from "reactstrap"
import { withTranslation } from "react-i18next"
import { withRouter, Link, useParams } from "react-router-dom"
import { BsEye } from 'react-icons/bs';
import { HiOutlineLockClosed } from 'react-icons/hi';
import { MdOutlineAssignmentTurnedIn, MdPendingActions, MdOutlinePreview } from 'react-icons/md';
import Click from '../../assets/Gif/Click here.gif'

function MaintenanceReport(props) {

  const { propId } = useParams()

  return (
    <React.Fragment>
      <Row>

        <Col xl={3} md={6}>
          <Link to={`uknowladge-ticket/${propId}`}>
            <Card className="mini-stat report-primary text-white bd-rds">
              <CardBody>
                <div className="mb-4">
                  <div className="float-start mini-stat-img me-2 font-size-22">
                    05
                  </div>
                  <h6 className="font-size-14 text-uppercase mt-0 text-white-50">
                    Open Tickets
                  </h6>
                  <BsEye size={25} /> View
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>

        <Col xl={3} md={6}>
          <Link to={`/pending-ticket/${propId}`}>
            <Card className="mini-stat report-warning text-white bd-rds">
              <CardBody>
                <div className="mb-4">
                  <div className="float-start mini-stat-img me-2 font-size-22">
                    02
                  </div>
                  <h6 className="font-size-14 text-uppercase mt-0 text-white-50">
                    Pending Tickets
                  </h6>
                  <BsEye size={25} /> View
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>

        <Col xl={3} md={6}>
          <Link to={`/assign-ticket/${propId}`}>
            <Card className="mini-stat report-success text-white bd-rds">
              <CardBody>
                <div className="mb-4">
                  <div className="float-start mini-stat-img me-2 font-size-22">
                    12
                  </div>
                  <h6 className="font-size-14 text-uppercase mt-0 text-white-50">
                    Assigned Tickets
                  </h6>
                  <BsEye size={25} /> View
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>

        <Col xl={3} md={6}>
          <Link to={`/close-ticket/${propId}`}>
            <Card className="mini-stat report-danger text-white bd-rds">
              <CardBody>
                <div className="mb-4">
                  <div className="float-start mini-stat-img me-2 font-size-22">
                    52
                  </div>
                  <h6 className="font-size-14 text-uppercase mt-0 text-white-50">
                    Closed Tickets
                  </h6>
                  <BsEye size={25} /> View
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>

      </Row>
    </React.Fragment>
  )
}

MaintenanceReport.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(MaintenanceReport))