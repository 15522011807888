import PropTypes from "prop-types"
import React, { useState } from 'react'
import { Container, Row, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import Message from "./Components/messages";
function Index() {

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTagComp meta_tags="FASPRO24 | Notifications" />
                <Breadcrumb default="Property" title="notifications" />
                <CardBody>
                    <Container fluid>
                        <Message />
                    </Container>
                </CardBody>
            </div>
        </React.Fragment>
    )
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(Index))