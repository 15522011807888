import React, { useState } from "react";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import { Container, Row, Card, Modal, Button } from "react-bootstrap";
import { useStore1Selector } from "./../../../../../index";
import { loginUser } from "../../../../../Redux/Slices/userSlice";
import usePost from "../../../../../hooks/usePost";
import ViewExtraChargesForm from "./ViewExtrachargesForm";
import EditExtraChargesForm from "./EditExtrachargesForm";

function ExtraChargesTableForm({
  propID,
  unitID,
  unitExtraChargeTable,
  reFetchExtraCharge,
}) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending } = usePost();
  const [viewExtraChargesModal, setViewExtraChargesModal] = useState(false);
  const [editExtraChargesModal, setEditExtraChargesModal] = useState(false);
  const [deleteExtraChargesModal, setDeleteExtraChargesModal] = useState(false);

  const [viewExtraChargesID, setViewExtraChargesID] = useState();
  const [editExtraChargesID, setEditExtraChargesID] = useState();
  const [deleteExtraChargesID, setDeleteExtraChargesID] = useState();
  const listExtraCharge = [];
  unitExtraChargeTable.forEach((res) => {
    listExtraCharge.push({
      ...res,
      type: res?.type,
      amount_extracharge: `GH₵ ${res?.amount}`,
      viewBtn: (
        <button
          className="btn btn-success"
          onClick={() => viewExtraChargeFunc(res?._id)}
        >
          view
        </button>
      ),
      editBtn: (
        <button
          className="btn btn-primary"
          onClick={() => editExtraChargeFunc(res?._id)}
        >
          Edit
        </button>
      ),
      deleteBtn: (
        <button
          className="btn btn-danger"
          onClick={() => deleteExtraChargeFunc(res?._id)}
        >
          Delete
        </button>
      ),
    });
  });

  const columnExtraCharges = [
    { label: "Name", field: "type", sort: "asc", width: 150 },
    { label: "Amount", field: "amount_extracharge", sort: "asc", width: 150 },
    { label: "Frequency", field: "frequency", sort: "asc", width: 150 },
    // { label: "Satrt date", field: "frequency", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    // { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const DataExtracharge = {
    columns: columnExtraCharges,
    rows: listExtraCharge,
  };

  function viewExtraChargeFunc(id) {
    setViewExtraChargesID(id);
    setViewExtraChargesModal(true);
  }
  function editExtraChargeFunc(id) {
    setEditExtraChargesID(id);
    setEditExtraChargesModal(true);
  }
  function deleteExtraChargeFunc(id) {
    setDeleteExtraChargesID(id);
    setDeleteExtraChargesModal(true);
  }
  const deleteExtraCharge = () => {
    const Method = "DELETE",
      endPoint = `extraCharges/${deleteExtraChargesID}`,
      isJSON = true;
    var formdata = new FormData();
    execute(
      endPoint,
      formdata,
      Method,
      "Extra charges Successfully Deleted",
      token,
      isJSON
    );
    setDeleteExtraChargesModal(false);
  };
  if (pending) {
    setTimeout(() => {
      reFetchExtraCharge();
    }, 2000);
  }
  return (
    <Card className="bd-rds">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <button className="btn login-btn w-md waves-effect waves-light mb-4 d-flex justify-content-end report-warning">
            <CSVLink
              data={"listExtraCharge"}
              headers={listExtraCharge?.reverse()}
              separator={";"}
              className="text-white"
            >
              Download in Excel
            </CSVLink>
          </button>
        </div>
        <MDBDataTable
          entries={5}
          entriesOptions={[5, 10, 50]}
          responsive
          bordered
          striped
          hover
          data={DataExtracharge}
        />
      </Card.Body>
      <Modal
        className="modal-right scroll-out-negative"
        show={viewExtraChargesModal}
        onHide={() => setViewExtraChargesModal(false)}
        scrollable
        dialogClassName="full"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>View extra charges </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ViewExtraChargesForm
            chargeID={viewExtraChargesID}
            unitID={unitID}
            closeModal={setViewExtraChargesModal}
            reFetchExtraCharge={reFetchExtraCharge}
          />
        </Modal.Body>
      </Modal>
      <Modal
        className="modal-right scroll-out-negative"
        show={editExtraChargesModal}
        onHide={() => setEditExtraChargesModal(false)}
        scrollable
        dialogClassName="full"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit extra charges </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditExtraChargesForm
            chargeID={editExtraChargesID}
            propID={propID}
            unitID={unitID}
            closeModal={setEditExtraChargesModal}
            reFetchExtraCharge={reFetchExtraCharge}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={deleteExtraChargesModal}
        onHide={() => setDeleteExtraChargesModal(false)}
        size="sm"
      >
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to delete this Extra charge ? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setDeleteExtraChargesModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteExtraCharge}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
}

export default ExtraChargesTableForm;
