import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Card, CardBody, Button } from "reactstrap"
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact"
import { withRouter, Link, useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumb from "../../components/Common/Breadcrumb"
import MetaTagComp from "../../components/Common/MetaTags"
import { CSVLink } from "react-csv";
import { ImEye } from 'react-icons/im';
import { useStore1Selector } from "index"
import { loginUser } from "Redux/Slices/userSlice"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { successMessage } from "components/Notifications"
import Report from "./components/Report"


function CurrentMonthAssets() {

  const userDet = useStore1Selector(loginUser)
  const propId = userDet?.original?.userDetails?.OwnerID
  const portfolioPropId = userDet?.OwnerID

  const [dataDb, setData] = useState()
  const [dataProperty, setDataProperty] = useState()
  const [selectedProperty, setSelectedProperty] = useState()
  const [smExample, setSmExample] = useState(false);
  const [delteID, setDeleteID] = useState()

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://prop.faspro24.com/api/property/GetPropertyList/${propId ? propId : portfolioPropId}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setDataProperty(result.data.sort().reverse())
      })
      .catch(error => console.log('error', error));
  }, []);

  useEffect(() => {
    getAssets();
  }, [selectedProperty]);

  const getAssets = () => {
    let list = [];
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://prop.faspro24.com/api/assets/getAssets/${selectedProperty}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        result.forEach((res) => {
          list.push({
            ...res,
            // viewBtn: <Link to={`/property-details/${res.AssetID}`} className="btn btn-success"> view </Link>,
            // editBtn: <button className="btn btn-primary" onClick={() => editFunc(`${res.AssetID}`)}> Edit </button>,
            deleteBtn: <button className="btn btn-danger" onClick={() => deleteFunc(res.AssetID)}> Delete </button>
          })
        })
        setData(list.sort().reverse())
      })
      .catch(error => console.log('error', error));
  }

  function handleChange(event) {
    setSelectedProperty(event.target.value)
  }

  function deleteFunc(id) {
    setDeleteID(id)
    setSmExample(true)
  }

  const deleteProperty = () => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://prop.faspro24.com/api/assets/DeleteAsset/${delteID}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === "success") {
          successMessage("Successful deleted")
          setSmExample(false)
          getAssets();
        }
      })
      .catch(error => {
        console.log(`try again something went wrong${error.message}`)
        location.reload();
        setSmExample(false)
      });
  }

  const column = [
    { label: "Asset Name", field: "AssetName", sort: "asc", width: 150 },
    { label: "Property Name", field: "PropertyName", sort: "asc", width: 150 },
    { label: "Unit Name", field: "UnitName", sort: "asc", width: 150 },
    { label: "Model", field: "Model", sort: "asc", width: 150 },
    { label: "Cost Replacement", field: "CostOfReplacement", sort: "asc", width: 150 },

    { label: "Serial Number", field: "SerialNumber", sort: "asc", width: 150 },
    { label: "Date Of Installation", field: "DateOfInstallationDate", sort: "asc", width: 150 },
    { label: "Next Maintanance Date", field: "NextMaintananceDate", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },

  ]

  const data = {
    columns: column,
    rows: dataDb,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | My Assets" />
        <Breadcrumb default="Property" title="Current month Assets" />

        <CardBody>
          <Container fluid>

            <Link to="/my-assets" className="btn login-btn w-md waves-effect waves-light mx-2" type="submit"> Back </Link>
            <Row className="d-flex justify-content-around align-items-center">

              <CardBody data-aos="fade-bottom">
                <Card className="bd-rds">
                  <CardBody>
                    <AvForm>
                      <h2>Select property</h2>
                      <AvField name="propertyName" className="form-control" type="select" required onChange={handleChange}>
                        <option> Select... </option>
                        {
                          dataProperty?.map(property => (
                            <option value={property?.PropertyID}> {property?.Name} </option>
                          ))
                        }
                      </AvField>
                    </AvForm>
                  </CardBody>

                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <Link to="/add-asset" className="btn report-primary text-white w-md waves-effect waves-light mb-4" type="submit"> + Add New Assets </Link>
                      <button className="btn report-warning w-md waves-effect waves-light mb-4 d-flex justify-content-end">
                        <CSVLink data={"dataDb"} separator={";"} className="text-white"> Download in Excel </CSVLink>
                      </button>
                    </div>
                    <MDBDataTable entries={5} entriesOptions={[5, 10, 50]} responsive bordered striped hover data={data} fullPagination />
                  </CardBody>
                </Card>
              </CardBody>
            </Row>
          </Container>
        </CardBody>

        <Modal show={smExample} onHide={() => setSmExample(false)} size="sm">
          <Modal.Body className='text-danger'> <h4> Are you sure you want to delete this asset ? </h4> </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={() => setSmExample(false)}> Cancel </button>
            <button className="btn btn-danger" onClick={deleteProperty}>Yes</button>
          </Modal.Footer>
        </Modal>

      </div>
    </React.Fragment>
  )
}

CurrentMonthAssets.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(CurrentMonthAssets))
