import PropTypes from "prop-types"
import React, { useState } from "react"
import { Col, Container, Row, CardBody, Card } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import ExpensesChart from "./Components/Expenses-chart"
import ExpensesType from "./Components/Expenses-type"
import { CSVLink } from "react-csv";
import { months } from "./../../components/Month";

const Finance = () => {

    const [dataDb, setData] = useState([
        {
            unit_name: "TNG",
            unit_num: "5",
            unit_type: "Single",
            unit_block: "6",
            view: <Link to="/finance-details" className="btn report-primary text-white">View</Link>,
            deleteBtn: <button className="btn report-danger text-white">Delete</button>,
        }
    ]);

    const column = [
        { label: "Unit Name", field: "unit_name", sort: "asc", width: 150 },
        { label: "Unit Number", field: "unit_num", sort: "asc", width: 150 },
        { label: "Unit type", field: "unit_type", sort: "asc", width: 150 },
        { label: "Unit block", field: "unit_block", sort: "asc", width: 150 },
        { label: "View", field: "view", sort: "asc", width: 150 },
        { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
    ];

    const data = {
        columns: column,
        rows: dataDb,
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTagComp meta_tags="FASPRO24 | finance" />
                <Breadcrumb default="Finance" title="finance" />

                <Container fluid>
                    <div className="page-title-box">
                        <Row>
                            <AvForm className="form-horizontal d-flex">
                                <div className="mb-3 pe-3">
                                    <AvField name="filter" label="Filter property" className="form-control" type="select" required>
                                        <option> Select... </option>
                                        <option> Preston </option>
                                        <option> Gallagher </option>
                                    </AvField>
                                </div>
                                <div className="mb-3 pe-3">
                                    <AvField name="filter" label="Filter per month" className="form-control" type="select" required>
                                        {months.map(num => (<option>{num}</option>))}
                                    </AvField>
                                </div>
                                <div className="mb-3">
                                    <AvField name="filter" label="Filter per year" className="form-control" type="select" required>
                                        <option> Select... </option>
                                        <option> 2022 </option>
                                        <option> 2023 </option>
                                    </AvField>
                                </div>
                            </AvForm>
                            <Col md={12}>
                                <ExpensesType />
                            </Col>
                            <Col md={12}>
                                <Card className="bd-rds"> <CardBody>  <ExpensesChart /> </CardBody> </Card>
                            </Col>
                        </Row>
                    </div>


                    <Row className="d-flex justify-content-around align-items-center">
                        <CardBody data-aos="fade-bottom">
                            <Card className="bd-rds">
                                <CardBody>
                                    <div className="d-flex justify-content-between">
                                        <button className="btn login-btn w-md waves-effect waves-light mb-4 d-flex justify-content-end report-warning">
                                            <CSVLink data={dataDb} separator={";"} className="text-white"> Download in Excel </CSVLink> </button>
                                    </div>
                                    <MDBDataTable entries={5} entriesOptions={[5, 10, 50]} responsive bordered striped hover data={data} fullPagination />

                                </CardBody>
                            </Card>
                        </CardBody>
                    </Row>


                </Container>

            </div>
        </React.Fragment>
    )
}

Finance.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(Finance))