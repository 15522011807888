import PropTypes from "prop-types";
import React from "react";
import { Container, Row } from "reactstrap";

import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import EditForm from "./components/EditForm";
import { Link } from "react-router-dom";

function EditProperty() {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Edit Ppoperties" />
        <Breadcrumb default="Property" title="Edit Property" />

        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center mb-3">
              <div>
                <Link
                  to="/my-properties"
                  className="btn login-btn w-md waves-effect waves-light"
                  type="submit"
                >
                  Back
                </Link>
              </div>
            </Row>
            <Row className="align-items-center" data-aos="fade-bottom">
              <EditForm />
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

EditProperty.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(EditProperty));
