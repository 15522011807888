import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Card,
  CardBody,
  Col,
  Container,
  Spinner,
} from "reactstrap";
import { Link, useParams, withRouter } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { AiFillPrinter } from "react-icons/ai";
import { useStore1Selector } from "./../../index";
import useCustomQuery from "hooks/useCustomQuery";
import { loginUser } from "../../Redux/Slices/userSlice";
import ReportCard from "./components/ReportCard";
import { withTranslation } from "react-i18next";
import useFetch from "../../hooks/useFecth";
import UnitOccupancyPieChart from "./components/PropertyReport/Chart/UnityOccupancyGraph";
import PropertyLinebarchart from "./components/PropertyReport/Chart/PropertyLinebarchart";
import OverdueLinechart from "./components/PropertyReport/Chart/OverdueLinechart";
import MaintenaceLineChart from "./components/PropertyReport/Chart/MaintenanceLinebarchart";
import ReceivablePie from "./components/PropertyReport/Chart/ReceivablePie";
import TotalCostPie from "./components/PropertyReport/Chart/TotalCostPie";
import { Years } from "../../components/Years";
import { Aging } from "../../components/Aging";
import LoadingTable from "components/LoadingTable";

function ReportPage({ t }) {
  const { propID } = useParams();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const [receivableYear, setReceivableYear] = useState(currentYear);
  const [overdueRentalYear, setOverdueRentalYear] = useState(currentYear);
  const [managementCostYear, setManagementCostYear] = useState(currentYear);
  const [propOccuppancyYear, setPropOccuppancyYear] = useState(currentYear);
  const [propIncomeYear, setPropIncomeYear] = useState(currentYear);

  // const { data: PropertyDetails, reFetch: reFetchPropertyDetails } = useFetch(
  //   `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/${propID}`,
  //   token
  // );

  const {
    data: getMyPropertyDetails,
    error: errorMyPropDetails,
    isLoading: loadingMyPropDetails,
  } = useCustomQuery(
    ["myPropertyDetails", propID],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/${propID}`,
    token
  );

  const { data: unitsStats, reFetch: reFetchUnitsStats } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/units/property-statistics/${propID}`,
    token
  );
  const { data: getAllstats, reFetch: reFetchGetAllstats } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/units/${propID}/statistics?createdOn=${currentYear}`,
    token
  );
  const { data: getreceivableStats, reFetch: reFetchGetreceivableStats } =
    useFetch(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/units/${propID}/statistics?createdOn=${receivableYear}`,
      token
    );
  const { data: getOverdueRentalStats, reFetch: reFetchGetOverdueRentalStats } =
    useFetch(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/units/${propID}/statistics?createdOn=${overdueRentalYear}`,
      token
    );
  const { data: getOccuppancStats, reFetch: reFetchGetOccuppancStats } =
    useFetch(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/units/${propID}/statistics?createdOn=${propOccuppancyYear}`,
      token
    );

  const {
    data: getPropIncomeYearStats,
    reFetch: reFetchGetPropIncomeYearStats,
  } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/units/${propID}/statistics?createdOn=${propIncomeYear}`,
    token
  );

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    const style = document.createElement("style");
    style.type = "text/css";
    style.appendChild(
      document.createTextNode(`
      @media print {
        @page {
          size: landscape;
        }
      }
    `)
    );
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);
  const handleSelectReceivableYear = (event) => {
    setReceivableYear(event.target.value);
  };
  const handleSelectOverdueRentalYear = (event) => {
    setOverdueRentalYear(event.target.value);
  };
  const handleSelectManagementCostYear = (event) => {
    setManagementCostYear(event.target.value);
  };
  const handleSelectPropOccuppancyYear = (event) => {
    setPropOccuppancyYear(event.target.value);
  };
  const handleSelectPropIncomeYear = (event) => {
    setPropIncomeYear(event.target.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | property report" />
        <Breadcrumb default={t("Property")} title={t("property report")} />

        <Container fluid>
          <div>
            <Link
              to={`/property-details/${propID}`}
              className="btn login-btn mb-2 print-button"
            >
              {" "}
              {t("Back")}{" "}
            </Link>
          </div>
          <Row>
            <div className="mb-3 d-flex justify-content-end">
              <button
                className="btn btn-warning print-button"
                onClick={handlePrint}
              >
                <AiFillPrinter
                  size={20}
                  color="white"
                  className="me-1 mb-1 mt-1"
                />

                <span className="text-white h6">Print Report</span>
              </button>
            </div>
          </Row>

          <ReportCard t={t} />
          <Row>
            <Col md={6}>
              <Card>
                <div className="h5 mx-4 mt-2">Property details</div>
                <CardBody className="property_card">
                  {loadingMyPropDetails ? (
                    <div className="w-100">
                      <LoadingTable />
                    </div>
                  ) : (
                    <Row>
                      <Col md={6}>
                        <div className="property_report_img">
                          <img
                            src={`${getMyPropertyDetails?.propertyImage}`}
                            alt=""
                            className="w-100"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="">
                          <p>
                            <b>Property name: </b>
                            {getMyPropertyDetails?.propertyName}
                          </p>
                          <p>
                            <b>Property type: </b>
                            {getMyPropertyDetails?.propertyType}
                          </p>
                          <p>
                            <b>Address: </b>
                            {getMyPropertyDetails?.address}
                          </p>
                          <p>
                            <b>City: </b>
                            {getMyPropertyDetails?.city}
                          </p>
                          <p>
                            <b>Country: </b>
                            {getMyPropertyDetails?.country}
                          </p>
                          {getMyPropertyDetails?.propertyBlock ? (
                            <p>
                              <b>Number of blocks: </b>
                              {unitsStats?.numberOfBlocks}
                            </p>
                          ) : null}
                          <p>
                            <b>Number of units: </b>
                            {unitsStats?.numberOfUnits}
                          </p>
                          <p>
                            <b>Number of leases: </b>
                            {unitsStats?.numberOfLeases}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody className="property_card">
                  <div className="h5">Current occupancy rate for the month</div>
                  <Row className="justify-content-center">
                    <Col sm={4}>
                      <div className="text-center">
                        <h5 className="mb-0 font-size-20">
                          {unitsStats?.numberOfUnits}
                        </h5>
                        <p className="text-muted">Total</p>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="text-center text-success">
                        <h5 className="mb-0 font-size-20">
                          {unitsStats?.occupiedUnit}
                        </h5>
                        <p className="text-success">Occupied</p>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="text-center text-danger">
                        <h5 className="mb-0 font-size-20">
                          {unitsStats?.unoccupiedUnit}
                        </h5>
                        <p className="text-danger">Vacant</p>
                      </div>
                    </Col>
                  </Row>
                  <UnitOccupancyPieChart
                    occupiedUnit={unitsStats?.occupiedUnit}
                    unoccupiedUnit={unitsStats?.unoccupiedUnit}
                  />
                </CardBody>
                <div className="mt-4"></div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between mb-3">
                    <div className="h5">Receivables</div>
                    <div className="">
                      <select
                        id="selectOption"
                        className="btn btn-dark"
                        value={receivableYear}
                        onChange={handleSelectReceivableYear}
                      >
                        {Years.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <PropertyLinebarchart getAllstats={getreceivableStats} />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="h5 mb-4">Montly Rental</div>
                  <Row className="justify-content-center"></Row>
                  <ReceivablePie unitsStats={unitsStats} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <div className="h5">Overdue Rental Payments</div>
                    <div className="mb-3">
                      <select
                        id="selectOption"
                        className="btn btn-dark"
                        value={overdueRentalYear}
                        onChange={handleSelectOverdueRentalYear}
                      >
                        {Years.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <OverdueLinechart getAllstats={getOverdueRentalStats} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <div className="h5">
                      Cost spent on managing the property
                    </div>
                    <div className="mb-3">
                      <select
                        id="selectOption"
                        className="btn btn-dark"
                        value={managementCostYear}
                        onChange={handleSelectManagementCostYear}
                      >
                        {Years.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <MaintenaceLineChart />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody className=" mb-3">
                  <div className="h5">
                    Monthly cost spent on managing the property
                  </div>
                  <Row className="justify-content-center"></Row>
                  <TotalCostPie />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <div className="h5">Property occupancy report</div>
                    <div className="mb-3">
                      <select
                        id="selectOption"
                        className="btn btn-dark"
                        value={propOccuppancyYear}
                        onChange={handleSelectPropOccuppancyYear}
                      >
                        {Years.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col" className="text-center">
                            No. of Units
                          </th>
                          <th scope="col" className="text-center">
                            No. of ocuppied Units
                          </th>
                          <th scope="col" className="text-center">
                            No. of vacant Units
                          </th>
                          <th scope="col" className="text-center">
                            No. of Lease(s)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getOccuppancStats?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <th scope="row">
                                {propOccuppancyYear} - {item?.month}
                              </th>
                              <th scope="row" className="text-center">
                                {item?.numberOfUnits}
                              </th>
                              <th scope="row" className="text-center">
                                {item?.occupiedUnit}
                              </th>
                              <th scope="row" className="text-center">
                                {item?.unoccupiedUnit}
                              </th>
                              <th scope="row" className="text-center">
                                {item?.numberOfLeases}
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h2 className="card-title mb-4">Property income report</h2>
                    <div className="mb-3">
                      <select
                        id="selectOption"
                        className="btn btn-dark"
                        value={propIncomeYear}
                        onChange={handleSelectPropIncomeYear}
                      >
                        {Years.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col" className="text-center">
                            Paid Amount
                          </th>
                          <th scope="col" className="text-center">
                            Unpaid Amount
                          </th>
                          <th scope="col" className="text-center">
                            Rental Amount
                          </th>
                          <th scope="col" className="text-center">
                            Extra charges
                          </th>
                          <th scope="col" className="text-center">
                            Total Utilities charges
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getPropIncomeYearStats?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <th scope="row">
                                {propIncomeYear} - {item?.month}
                              </th>
                              {item?.total_Paid_OfAll !== 0 ? (
                                <th scope="row" className="text-center">
                                  {item?.total_Paid_OfAll}
                                </th>
                              ) : (
                                <th scope="row" className="text-center">
                                  -
                                </th>
                              )}

                              {item?.total_Overdue_OfAll !== 0 ? (
                                <th scope="row" className="text-center">
                                  {item?.total_Overdue_OfAll}
                                </th>
                              ) : (
                                <th scope="row" className="text-center">
                                  -
                                </th>
                              )}

                              {item?.total_Paid_RentAmount !== 0 ? (
                                <th scope="row" className="text-center">
                                  {item?.total_Paid_RentAmount}
                                </th>
                              ) : item?.total_Pending_RentAmount !== 0 ? (
                                <th scope="row" className="text-center">
                                  {item?.total_Pending_RentAmount}
                                </th>
                              ) : item?.total_Overdue_RentAmount !== 0 ? (
                                <th scope="row" className="text-center">
                                  {item?.total_Overdue_RentAmount}
                                </th>
                              ) : null}

                              {item?.total_Paid_UtilityRentAmount !== 0 ? (
                                <th scope="row" className="text-center">
                                  {item?.total_Paid_UtilityRentAmount}
                                </th>
                              ) : item?.total_Pending_UtilityRentAmount !==
                                0 ? (
                                <th scope="row" className="text-center">
                                  {item?.total_Pending_UtilityRentAmount}
                                </th>
                              ) : item?.total_Overdue_UtilityRentAmount !==
                                0 ? (
                                <th scope="row" className="text-center">
                                  {item?.total_Overdue_UtilityRentAmount}
                                </th>
                              ) : (
                                <th scope="row" className="text-center">
                                  -
                                </th>
                              )}

                              {item?.total_Paid_ExtraChargesRentAmount !== 0 ? (
                                <th scope="row" className="text-center">
                                  {item?.total_Paid_ExtraChargesRentAmount}
                                </th>
                              ) : item?.total_Pending_ExtraChargesRentAmount !==
                                0 ? (
                                <th scope="row" className="text-center">
                                  {item?.total_Pending_ExtraChargesRentAmount}
                                </th>
                              ) : item?.total_Overdue_ExtraChargesRentAmount !==
                                0 ? (
                                <th scope="row" className="text-center">
                                  {item?.total_Overdue_ExtraChargesRentAmount}
                                </th>
                              ) : (
                                <th scope="row" className="text-center">
                                  -
                                </th>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div>
                    <h2 className="card-title mb-4">Property aging analysis</h2>
                  </div>
                  <div className="d-flex justify-content-between"></div>
                </div>

                <div className="table-responsive">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Month</th>
                        <th scope="col" className="text-center">
                          Total Paid Amount
                        </th>
                        <th scope="col" className="text-center">
                          Total Unpaid Amount
                        </th>
                        <th scope="col" className="text-center">
                          Total Rental Amount
                        </th>
                        <th scope="col" className="text-center">
                          Total Extra charges
                        </th>
                        <th scope="col" className="text-center">
                          Total Utilities charges
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getAllstats?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">{item?.month}</th>
                            {item?.total_Paid_OfAll !== 0 ? (
                              <th scope="row" className="text-center">
                                {item?.total_Paid_OfAll}
                              </th>
                            ) : (
                              <th scope="row" className="text-center">
                                -
                              </th>
                            )}

                            {item?.total_Overdue_OfAll !== 0 ? (
                              <th scope="row" className="text-center">
                                {item?.total_Overdue_OfAll}
                              </th>
                            ) : (
                              <th scope="row" className="text-center">
                                -
                              </th>
                            )}

                            {item?.total_Paid_RentAmount !== 0 ? (
                              <th scope="row" className="text-center">
                                {item?.total_Paid_RentAmount}
                              </th>
                            ) : item?.total_Pending_RentAmount !== 0 ? (
                              <th scope="row" className="text-center">
                                {item?.total_Pending_RentAmount}
                              </th>
                            ) : item?.total_Overdue_RentAmount !== 0 ? (
                              <th scope="row" className="text-center">
                                {item?.total_Overdue_RentAmount}
                              </th>
                            ) : null}

                            {item?.total_Paid_UtilityRentAmount !== 0 ? (
                              <th scope="row" className="text-center">
                                {item?.total_Paid_UtilityRentAmount}
                              </th>
                            ) : item?.total_Pending_UtilityRentAmount !== 0 ? (
                              <th scope="row" className="text-center">
                                {item?.total_Pending_UtilityRentAmount}
                              </th>
                            ) : item?.total_Overdue_UtilityRentAmount !== 0 ? (
                              <th scope="row" className="text-center">
                                {item?.total_Overdue_UtilityRentAmount}
                              </th>
                            ) : (
                              <th scope="row" className="text-center">
                                -
                              </th>
                            )}

                            {item?.total_Paid_ExtraChargesRentAmount !== 0 ? (
                              <th scope="row" className="text-center">
                                {item?.total_Paid_ExtraChargesRentAmount}
                              </th>
                            ) : item?.total_Pending_ExtraChargesRentAmount !==
                              0 ? (
                              <th scope="row" className="text-center">
                                {item?.total_Pending_ExtraChargesRentAmount}
                              </th>
                            ) : item?.total_Overdue_ExtraChargesRentAmount !==
                              0 ? (
                              <th scope="row" className="text-center">
                                {item?.total_Overdue_ExtraChargesRentAmount}
                              </th>
                            ) : (
                              <th scope="row" className="text-center">
                                -
                              </th>
                            )}

                            <td>
                              <span
                              // className={`${
                              //   item?.status === "paid"
                              //     ? "badge bg-success"
                              //     : item?.status === "pending"
                              //     ? "badge bg-warning"
                              //     : "badge bg-danger"
                              // }`}
                              >
                                {/* {item?.status} */}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

ReportPage.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(ReportPage));
