import React from 'react'
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Col, Row } from "reactstrap"
import { var_num } from "./../../../components/Vatnumber";

function LeaseInfo() {
    const handleValidSubmit = (e, values) => {
        e.preventDefault()
        console.log(" Result ", values);
    }
    return (
        <AvForm className="form-horizontal mt-4" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>
            <Row>
                <Col md={6}>
                    <div className="mb-3">
                        <AvField name="property" label="Assign unit" className="form-control" type="select" required>
                            <option> Select... </option>
                            <option> TNG </option>
                        </AvField>
                    </div>
                    <Row>
                        <Col md={4}>
                            <div className="mb-3">
                                <AvField name="unit" label="Unit Number" className="form-control" type="select" placeholder="...">
                                    {var_num.map(num => (<option> {num} </option>))}
                                </AvField>
                            </div>
                        </Col>
                        <Col m={4}>
                            <div className="mb-3">
                                <AvField name="unit" label="Floor number" className="form-control" type="select" placeholder="...">
                                    {var_num.map(num => (<option> {num} </option>))}
                                </AvField>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="mb-3">
                                <AvField name="duration" label="Duration (Month)" className="form-control" type="select" required placeholder="...">
                                    {var_num.map(num => (<option>{num} </option>))}
                                </AvField>
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col md={6}>
                    <div className="mb-3">
                        <AvField name="rent_dep_amount" label="Rent Deposit Amount" className="form-control" type="text" required />
                    </div>
                    <Row>
                        <Col md={4}>
                            <div className="mb-3">
                                <AvField name="rent_amount" label="Rent Amount" className="form-control" type="number" required />
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="mb-3">
                                <AvField name="startDate" label="Lease Start Date" className="form-control" type="date" required />
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="mb-3">
                                <AvField name="endDate" label="Lease end date" className="form-control" type="date" required />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <button className="btn login-btn w-md mt-3"> Submit </button>

        </AvForm>
    )
}

export default LeaseInfo