import React, { useState } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody } from "reactstrap";
import { AiFillPrinter } from "react-icons/ai";
import { useStore1Selector } from "./../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
import { useParams, Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import useFetch from "../../hooks/useFecth";
import PieExtraCharges from "./components/UnitsComponents/ExtraCharges/Pie";
import PieUtilities from "./components/UnitsComponents/Utilities/UtilityPieChart";
import LineBar from "./components/UnitsComponents/linebarchart";

function UnitReport() {
  const { propID, propType, unitID } = useParams();
  // const [ReportData, setReportData] = useState([]);
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");

  const current_newDate = `${currentYear}-${currentMonth}`;
  const [selectedOption, setSelectedOption] = useState(current_newDate);
  const [selectedOptionYear, setSelectedOptionYear] = useState(currentYear);

  const { data: invoiceByUnit, reFetch: reFetchInvoice } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/invoices/unit/${unitID}?createdOn=${selectedOptionYear}`,
    token
  );
  const { data: reportExtraCharges, reFetch: reFetchExtraCharges } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/extraCharges/unit/month/${unitID}?monthDate=${selectedOption}`,
    token
  );
  const { data: reportUtilitiesCharges, reFetch: reFetchUtilitiesCharges } =
    useFetch(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/utilities/unit/month/${unitID}?monthDate=${selectedOption}`,
      token
    );

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleSelectChangeYear = (event) => {
    setSelectedOptionYear(event.target.value);
  };
  const handlePrint = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Unit report" />
        <Breadcrumb default="Property" title="Unit report" />
        <Link
          to={`/unit-details/${propID}/${unitID}/${propType}`}
          className="btn login-btn mb-4 print-button"
        >
          {" "}
          Back{" "}
        </Link>

        <Row>
          <div className="mb-3 d-flex justify-content-end">
            <button
              className="btn btn-warning print-button"
              onClick={handlePrint}
            >
              <AiFillPrinter
                size={20}
                color="white"
                className="me-1 mb-1 mt-1"
              />

              <span className="text-white h6">Print Report</span>
            </button>
          </div>
          <div className="d-flex justify-content-end">
            <div className="mb-2">
              <label htmlFor="selectOption" className="mx-2">
                Select:
              </label>
              <select
                id="selectOption"
                className="btn btn-outline-success"
                value={selectedOptionYear}
                onChange={handleSelectChangeYear}
              >
                <option value="">Select...</option>
                <option value="2016">2016</option>
                <option value="2017">2017</option>
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
              </select>
            </div>
            <div className="mb-2 mx-2">
              {/* <label htmlFor="selectOption" className="mx-2">
                Choose a month:
              </label> */}
              <select
                id="selectOption"
                className="btn btn-outline-success"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="">Select...</option>
                <option value={`${selectedOptionYear}-01`}>January</option>
                <option value={`${selectedOptionYear}-02`}>February</option>
                <option value={`${selectedOptionYear}-03`}>March</option>
                <option value={`${selectedOptionYear}-04`}>April</option>
                <option value={`${selectedOptionYear}-05`}>May</option>
                <option value={`${selectedOptionYear}-06`}>June</option>
                <option value={`${selectedOptionYear}-07`}>July</option>
                <option value={`${selectedOptionYear}-08`}>August</option>
                <option value={`${selectedOptionYear}-09`}>September</option>
                <option value={`${selectedOptionYear}-10`}>October</option>
                <option value={`${selectedOptionYear}-11`}>November</option>
                <option value={`${selectedOptionYear}-12`}>December</option>
              </select>
            </div>
          </div>
          <Col md={6}>
            <Card>
              <CardBody>
                <h4 className="mt-0 header-title mb-4">Extra Charges</h4>
                <div id="pie-chart" className="e-chart">
                  <PieExtraCharges dataRes={reportExtraCharges} />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardBody>
                <h4 className="mt-0 header-title mb-4">Utility Charges</h4>
                <div id="pie-chart" className="e-chart">
                  <PieUtilities dataRes={reportUtilitiesCharges} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Card>
              <CardBody>
                <h4 className="mt-0 header-title mb-4">
                  Unit income Analysis report
                </h4>
                <div id="mix-line-bar" className="e-chart">
                  <LineBar invoiceByUnit={invoiceByUnit} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between">
              <h2 className="card-title mb-4">Unit income statement</h2>
            </div>

            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap mb-0">
                <thead>
                  <tr>
                    <th scope="col">Month</th>
                    <th scope="col">Rental Amount</th>
                    <th scope="col">Extra Charges</th>
                    <th scope="col">Utilities Charges</th>
                    <th scope="col">Total Amount</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceByUnit.map((item, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">{item?.month}</th>
                        <td>
                          <div>
                            GH₵
                            {item?.rentalAmount}
                          </div>
                        </td>
                        <td>GH₵ {item?.extraCharges}</td>
                        <td>GH₵ {item?.utilityCharges}</td>
                        <td>GH₵ {item?.totalAmount}</td>
                        <td>
                          <span
                            className={`${
                              item?.status === "paid"
                                ? "badge bg-success"
                                : item?.status === "pending"
                                ? "badge bg-warning"
                                : "badge bg-danger"
                            }`}
                          >
                            {item?.status}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
        {/* <Card className="bd-rds">
          <CardBody>
            <button className="btn login-btn w-md waves-effect waves-light mb-4 d-flex justify-content-end report-warning">
              <CSVLink data={""} separator={";"} className="text-white">
                Download in Excel
              </CSVLink>
            </button>

            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  {invoiceByUnit.map((item, i) => {
                    return <th className="align-middle"> {item.month} </th>;
                  })}
                </tr>
              </thead>
              <tbody>
                <tr key={"_tr_" + "key"}>
                  {invoiceByUnit.map((item, i) => {
                    return (
                      <td key={i}>
                        <div>
                          <h6 className="py-4"> Total rent </h6>
                          <p> {item.status} </p>
                          <h6> R {item.totalAmount} </h6>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card> */}
      </div>
    </React.Fragment>
  );
}

export default UnitReport;
