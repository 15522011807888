import PropTypes from 'prop-types'
import React from "react"
import MetaTagComp from "../../../components/Common/MetaTags";
import Modules from "./Components/Modules";

const Module = () => {

  return (
    <React.Fragment>
      <MetaTagComp meta_tags="FASPRO24 | Modules" />
      <div className="bg-module">
        <Modules />
      </div>
    </React.Fragment>
  )
}

export default Module