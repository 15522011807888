import React, { useState, useEffect } from "react";
import { Card, CardBody, Spinner, Badge, Button } from "reactstrap";
import { Modal } from "react-bootstrap";

import { MDBDataTable } from "mdbreact";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "./../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";

import ReasonForm from "./ReasonForm";
import useFecth from "../../../../hooks/useFecth";
import usePost from "../../../../hooks/usePost";

function OverdueTable() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending, data: postData } = usePost();
  const history = useHistory();
  const list = [];

  const { data: getMaintenanceTickets, reFetch: reFetchMaintenanceTickets } =
    useFecth(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceTickets`,
      token
    );

  getMaintenanceTickets?.forEach((res) => {
    if (res?.status === "overdue") {
      list.push({
        ...res,
        propertyName: res?.propertyId?.propertyName,
        assidnedTo: `${res?.assigned_to?.firstName} ${res?.assigned_to?.lastName}`,
        status:
          res?.status === "overdue" ? (
            <>
              <Badge className="rounded-pill bg-danger p-2">Overdue</Badge>
            </>
          ) : null,
        viewBtn: (
          <button
            className="btn btn-success"
            onClick={() => viewFunc(`${res?._id}`)}
          >
            View
          </button>
        ),
      });
    }
  });

  const column = [
    { label: "Property name", field: "propertyName", sort: "asc", width: 150 },

    { label: "Title Name", field: "subject", sort: "asc", width: 150 },
    {
      label: "Ticket Reference",
      field: "ticketNumber",
      sort: "asc",
      width: 150,
    },
    { label: "Status", field: "status", sort: "asc", width: 150 },
    {
      label: "Assign To",
      field: "assidnedTo",
      sort: "asc",
      width: 150,
    },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
  ];

  const dataDB = { columns: column, rows: list };

  const viewFunc = (id) => {
    history.push(`/view-ticket/${id}`);
  };

  useEffect(() => {
    if (postData?.status === "success") {
      reFetchMaintenanceTickets();
    }
  }, [postData?.status]);

  return (
    <div>
      <Card className="bd-rds">
        <CardBody>
          <div className="d-flex justify-content-end">
            <button className="btn login-btn w-md waves-effect waves-light mb-4">
              <CSVLink data={"dataDb"} separator={";"} className="text-white">
                Download in Excel
              </CSVLink>
            </button>
          </div>
          <MDBDataTable
            entries={5}
            entriesOptions={[5, 10, 50]}
            responsive
            bordered
            striped
            hover
            data={dataDB}
            fullpagination
          />
        </CardBody>
      </Card>
    </div>
  );
}

export default OverdueTable;
