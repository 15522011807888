import { BsFillHouseFill } from 'react-icons/bs';

export const Report = [
    {
        id: 1,
        name: "Maintenance",
        expense: 12000,
        color: "report-success",
        icon: BsFillHouseFill,
    },
    {
        id: 2,
        name: "Assets",
        expense: 7000,
        color: "report-warning",
        icon: BsFillHouseFill,
    },
]