import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  Card,
  FormGroup,
  Spinner,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter, Link, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import gender from "../../../components/Gender";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import usePost from "../../../hooks/usePost";
import Avatar from "../../../assets/images/Avatar.png";

function TenantForm({ propID, unitID, closeModal }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending, data } = usePost();

  const ownerId = userDet?.data?._id;
  const [phone, setPhone] = useState("");

  const handleValidSubmit = (e, v) => {
    e.preventDefault();

    const Method = "POST",
      endPoint = `auth/property_owner/register/${ownerId}`,
      isJSON = true;

    const formdata = new FormData();
    formdata.append("firstName", v.firstName);
    formdata.append("lastName", v.lastName);
    formdata.append("phoneNumber", phone);
    formdata.append("gender", v.gender);
    formdata.append("email", v.email);
    formdata.append("roles", v.roles);
    formdata.append("unitId", unitID);
    formdata.append("property_id", propID);

    execute(
      endPoint,
      formdata,
      Method,
      "User successfully added",
      token,
      isJSON
    );
    closeModal(false);
  };

  return (
    <React.Fragment>
      <CardBody>
        <Row className="align-items-center">
          <Col md={12} lg={12} xl={12}>
            <AvForm
              className="form-horizontal mt-3"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <Row>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={Avatar}
                    alt="user"
                    width={150}
                    height={150}
                    className="rounded"
                  />
                </div>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <AvField name="roles" label="Type" type="select" required>
                      <option> Select... </option>
                      <option value="tenant"> Tenant </option>
                      <option value="co_tenant"> Co Tenant </option>
                      <option value="guest_tenant"> Guest </option>
                    </AvField>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="firstName"
                      label="First Name"
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="lastName"
                      label="Last Name"
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="Email"
                      className="form-control"
                      type="email"
                      required
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="gender"
                      label="Gender"
                      type="select"
                      required
                    >
                      <option> Select... </option>
                      {gender.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </AvField>
                  </div>
                </Col>
              </Row>

              <PhoneInput
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                buttonStyle={{
                  borderBottomLeftRadius: 5,
                  borderTopLeftRadius: 5,
                  height: 40,
                }}
                inputStyle={{
                  margin: 0,
                  width: "100%",
                  color: "purple",
                  height: 1,
                  border: "none",
                }}
                containerStyle={{
                  width: "100%",
                }}
                className="blue"
                country={"gh"}
                enableSearch={true}
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />

              <div className="text-center mt-5">
                <button
                  className="btn login-btn  waves-effect waves-light"
                  type="submit"
                >
                  {!pending ? <span className="me-2">Submit</span> : null}

                  {!pending ? null : (
                    <span>
                      {" "}
                      <Spinner as="span" animation="border" size="sm" />{" "}
                      Loading...
                    </span>
                  )}
                </button>
              </div>
            </AvForm>
          </Col>
        </Row>
      </CardBody>
    </React.Fragment>
  );
}

TenantForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(TenantForm));
