import React, { useState } from "react";
import "./../Style.scss";
import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { ForgotPasswordMsg } from "../../../../components/NotifyMessage";
import usePost from "hooks/usePost";

function Form() {
  const { execute, pending, data } = usePost();

  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    const Method = "POST",
      endPoint = "auth/forgotPassword";
    const raw = JSON.stringify({
      email: values.email,
    });
    execute(endPoint, raw, Method, ForgotPasswordMsg);
  };
  return (
    <div className="login-content-container">
      <div className="forget-pass">
        {data?.status === "success" ? (
          <div class="success-message">
            <p>
              {" "}
              Thank you for requesting a password reset. We have sent an email
              to the address associated with your account with further
              instructions on how to reset your password. If you do not receive
              an email from us within a few minutes, please check your spam
              folder.{" "}
            </p>
          </div>
        ) : null}

        <Card className="overflow-hidden">
          <div className="login-header">
            <div className="text-primary text-center p-4">
              <h5 className="text-white font-size-20 p-2">Forget Password</h5>
            </div>
          </div>
          <CardBody>
            <AvForm
              className="form-horizontal mt-4"
              onValidSubmit={(e, v) => handleValidSubmit(e, v)}
            >
              <div className="mb-3">
                <AvField
                  name="email"
                  label="Email"
                  className="form-control"
                  type="email"
                  required
                />
              </div>

              <Row className="mb-3">
                <Col className="text-center">
                  <button
                    className="btn login-btn  waves-effect waves-light"
                    type="submit"
                  >
                    {!pending ? (
                      <span className="me-2">Forget Password</span>
                    ) : null}
                    {!pending ? null : (
                      <span>
                        {" "}
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        Loading...
                      </span>
                    )}
                  </button>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <div className="mt-5 text-center">
          <p>
            Remember It ?{" "}
            <Link to="login" className="fw-medium remember">
              {" "}
              Sign In here{" "}
            </Link>{" "}
          </p>
          <p> © {new Date().getFullYear()} FASPRO24 </p>
        </div>
      </div>
    </div>
  );
}

export default Form;
