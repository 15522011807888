import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, Modal, Button, Badge } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
import { ImEye } from "react-icons/im";
import { useStore1Selector } from "../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
import AddSecurityForm from "./Components/AddSecurityTeamForm";
import AddSecurityManagerForm from "./Components/AddSecurityManagerForm";
import EditServiceProviderForm from "./Components/EditSecurityForm";
import ViewServiceProviderForm from "./Components/ViewSecurityForm";
import useCustomQuery from "../../hooks/useCustomQuery";
import useCustomMutation from "../../hooks/useCustomMutation";
import LoadingTable from "../../components/LoadingTable";

function SecurityTeamTable() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const securityManagerID = userDet?.data?._id;

  const {
    data: getMySecurityTeam,
    error,
    isLoading,
  } = useCustomQuery(
    ["mySecurityTeam", securityManagerID],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/users/team?fieldName=securityManagerId`,
    token
  );

  console.log("getMySecurityTeam...", getMySecurityTeam);

  const key = ["mySecurityTeam", securityManagerID];
  const mutation = useCustomMutation(key);
  const [editDet, setEdit] = useState(false);
  const [viewID, setViewID] = useState(false);
  const [deleteID, setDeleteID] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [AddServiceProviderModal, setAddSecurityModal] = useState(false);

  const list = [];

  getMySecurityTeam?.forEach((res) => {
    list.push({
      ...res,
      FullName: `${res?.firstName} ${res?.lastName}`,
      status: res?.status ? (
        <>
          <Badge className="rounded-pill bg-success p-2">Active</Badge>
        </>
      ) : (
        <>
          <Badge className="rounded-pill bg-danger p-2">Desactivated</Badge>
        </>
      ),
      viewBtn: (
        <button
          className="btn btn-success"
          onClick={() => viewFunc(`${res?._id}`)}
        >
          View
        </button>
      ),
      editBtn: (
        <button
          className="btn btn-dark"
          onClick={() => editFunc(`${res?._id}`)}
        >
          Edit
        </button>
      ),
      deleteBtn: (
        <button
          className="btn btn-danger"
          onClick={() => deleteFunc(`${res?._id}`)}
        >
          Delete
        </button>
      ),
    });
  });

  const viewFunc = (id) => {
    setViewID(id);
    setViewModal(true);
  };

  const editFunc = (id) => {};

  const deleteFunc = (id) => {
    setDeleteID(id);
    setDeleteModal(true);
  };
  const deleteUnitFunc = () => {
    const Method = "DELETE",
      // endPoint = `users/${deleteID}/serviceProvider`,
      isJSON = null;

    const raw = null;
    const data = {
      endPoint: endPoint,
      bodyContent: raw,
      Method: Method,
      displayMessage: "User successfully delete",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const column = [
    { label: "First Name", field: "FullName", sort: "asc", width: 150 },
    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    // { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const data = {
    columns: column,
    rows: list.reverse(),
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      setDeleteModal(false);
    }
  }, [mutation.isSuccess]);

  // console.log("Security Manager..", getMySecurityManager);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Add Security" />
        <Breadcrumb default="Security Management" title="Security Team" />

        <Card.Body>
          <Container fluid>
            <Row
              className="d-flex justify-content-around align-items-center"
              data-aos="fade-bottom"
            >
              <Card className="bd-rds">
                <Card.Body>
                  <button
                    onClick={() => setAddSecurityModal(true)}
                    className="btn login-btn w-md waves-effect waves-light mb-4 report-primary mx-2"
                    type="submit"
                  >
                    + Add Security Team
                  </button>
                  {isLoading ? (
                    <LoadingTable />
                  ) : (
                    <MDBDataTable
                      entries={5}
                      entriesOptions={[5, 10, 50]}
                      responsive
                      bordered
                      striped
                      hover
                      data={data}
                    />
                  )}
                </Card.Body>
              </Card>
            </Row>
          </Container>
        </Card.Body>

        <Modal
          className="modal-center scroll-out-negative"
          show={AddServiceProviderModal}
          onHide={() => setAddSecurityModal(false)}
          scrollable
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Security Member</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <AddSecurityForm setAddSecurity={setAddSecurityModal} />
          </Modal.Body>
        </Modal>
        <Modal
          className="modal-center scroll-out-negative"
          show={viewModal}
          onHide={() => setViewModal(false)}
          scrollable
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Security details</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <ViewServiceProviderForm
              viewID={viewID}
              setViewModal={setViewModal}
            />
          </Modal.Body>
        </Modal>

        <Modal
          className="modal-right scroll-out-negative"
          show={editDet}
          onHide={() => setEdit(false)}
          scrollable
          dialogClassName="full"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Service Provider details</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EditServiceProviderForm setEdit={setEdit} />
          </Modal.Body>
        </Modal>

        <Modal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          size="md"
        >
          <Modal.Body className="text-danger">
            <h4>Are you sure you want to delete this service provider ?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={deleteUnitFunc}>
              Sure
            </Button>
            <Button variant="danger" onClick={() => setDeleteModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}

SecurityTeamTable.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SecurityTeamTable));
