import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "index";
import { loginUser } from "Redux/Slices/userSlice";
// import ViewDetails from "./components/ViewDetails";
// import EditDetails from "./components/EditDetails";
import { successMessage } from "components/Notifications";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { warningMessage } from "../../components/Notifications";

//import Navigation

import Nav from "./components/Nav";
import AllUnits from "./components/AllUnits";
import AvaibleUnits from "./components/AvaibleUnits";
import Suspended_Units from "./components/SuspendedUnits";

function Index() {
  const userDet = useStore1Selector(loginUser);
  const ownerId = userDet?.original?.userDetails?.OwnerID;
  const [ViewModal, setViewModal] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [DeleteModal, setDeleteModal] = useState(false);
  const [SuspendModal, setSuspendModal] = useState(false);
  const [dataDb, setData] = useState([]);
  const [bookingId, setBookingId] = useState([]);
  const [dataProperty, setDataProperty] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState();
  const [loadBtn, setIloadBtn] = useState(false);

  // to keep
  // const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const [allBookableUnits, setAllBookableUnits] = useState(true);
  const [availableUnits, setAvailableUnits] = useState(false);
  const [suspendedBUnits, setSuspendedUnits] = useState(false);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://prop.faspro24.com/api/property/GetPropertyList/${ownerId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setDataProperty(result.data.sort().reverse());
      })
      .catch((error) => console.log("error", error));
  }, []);

  function ViewFunction(id) {
    setViewModal(true);
    setBookingId(id);
  }
  function EditFunction(id) {
    setEditModal(true);
    setBookingId(id);
  }
  function deleteFunc(id) {
    setDeleteModal(true);
    setBookingId(id);
  }
  function suspendFunc(id) {
    setSuspendModal(true);
    setBookingId(id);
  }
  function handleChange(event) {
    setSelectedProperty(event.target.value);
  }

  const GetBookableUnit = () => {
    let list = [];
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://prop.faspro24.com/api/booking/GetBookingListByPropertyID/${selectedProperty}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        result.forEach((res) => {
          list.push({
            ...res,
            // PropertyName: res.property.Name,
            // UnitName: res.unit.Name,
            viewBtn: (
              <button
                className="btn btn-success"
                onClick={() => ViewFunction(`${res.BookingID}`)}
              >
                {" "}
                View{" "}
              </button>
            ),
            editBtn: (
              <button
                className="btn btn-primary"
                onClick={() => EditFunction(`${res.BookingID}`)}
              >
                {" "}
                Edit{" "}
              </button>
            ),
            suspendBtn: (
              <button
                className="btn btn-info"
                onClick={() => suspendFunc(`${res.BookingID}`)}
              >
                {" "}
                Suspend{" "}
              </button>
            ),
            deleteBtn: (
              <button
                className="btn btn-danger"
                onClick={() => deleteFunc(`${res.BookingID}`)}
              >
                {" "}
                Delete{" "}
              </button>
            ),
          });
        });
        setData(list.sort().reverse());
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    GetBookableUnit();
  }, [selectedProperty]);

  const deleteProperty = () => {
    const requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };

    fetch(
      `https://prop.faspro24.com/api/booking/DeleteBooking/${bookingId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          successMessage("Successfully deleted");
          setDeleteModal(false);
          GetBookableUnit();
        }
      })
      .catch((error) => {
        console.log(`${error.message}`);
      });
  };

  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    setIloadBtn(true);

    const formdata = new FormData();
    formdata.append("BookingID", bookingId);
    formdata.append("StartTime", values.startTime);
    formdata.append("EndTime", values.endTime);
    formdata.append("StartDate", values.startDate);
    formdata.append("EndDate", values.endDate);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `https://prop.faspro24.com/api/booking/SupspendBookableUnit`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          successMessage("Room suspended");
          setSuspendModal(false);
          setIloadBtn(false);
          GetBookableUnit();
        }
        if (result.status === "error") {
          warningMessage(result.message);
          setIloadBtn(false);
        }
      })
      .catch((error) => {
        warningMessage(error.message);
      });
  };

  const column = [
    { label: "Property Name", field: "PropertyName", sort: "asc", width: 150 },
    { label: "Unit Name", field: "UnitName", sort: "asc", width: 150 },
    { label: "Amount", field: "Amount", sort: "asc", width: 150 },
    { label: "Status", field: "Booking_status", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Suspend", field: "suspendBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const data = {
    columns: column,
    rows: dataDb,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Bookings Management" />
        <Breadcrumb default="Bookings Management" title="Bookable Units" />
        <Nav
          allBookableUnits={allBookableUnits}
          setAllBookableUnits={setAllBookableUnits}
          availableUnits={availableUnits}
          setAvailableUnits={setAvailableUnits}
          suspendedBUnits={suspendedBUnits}
          setSuspendedUnits={setSuspendedUnits}
        />
        <CardBody>
          <Container fluid>
            <Row className="d-flex justify-content-around align-items-center">
              <CardBody data-aos="fade-bottom">
                <Card className="bd-rds">
                  {allBookableUnits ? <AllUnits /> : null}
                  {availableUnits ? <AvaibleUnits /> : null}
                  {suspendedBUnits ? <Suspended_Units /> : null}
                </Card>
              </CardBody>
            </Row>
          </Container>
        </CardBody>
      </div>
    </React.Fragment>
  );
}

Index.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Index));
