import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';

class LineBar extends Component {
    getOption = () => {
        return {
            series: [
                {
                    name: 'Expenses',
                    type: 'bar',
                    data: [20, 10, 50, 25, 120, 40, 10, 30, 100, 40, 20, 150]
                },
            ],
            grid: {
                zlevel: 0,
                x: 50,
                x2: 50,
                y: 30,
                y2: 30,
                borderWidth: 0,
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: 'rgba(0,0,0,0)',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                feature: {
                    dataView: { show: true, readOnly: false },
                    magicType: { show: true, type: ['line', 'bar'] },
                    restore: { show: true },
                    saveAsImage: { show: true }
                }
            },
            color: ['#3c4ccf', '#02a499', '#38a4f8'],
            legend: {
                data: ['Expenses'],
                textStyle: {
                    color: ['#38a4f8']
                }
            },
            xAxis: [
                {
                    type: 'category',
                    data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'sep', 'Oct', 'Nov', 'Dec'],
                    axisPointer: {
                        type: 'shadow'
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: 'Amount',
                    min: 0,
                    max: 1000,
                    interval: 100,
                    axisLabel: {
                        formatter: 'R {value}'
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#74788d"
                        }
                    }
                },

            ],
        };
    };
    render() {
        return (
            <React.Fragment>
                <div className='bd-rds'>
                    <ReactEcharts style={{ height: "350px" }} option={this.getOption()} />
                </div>
            </React.Fragment>
        );
    }
}
export default LineBar;

