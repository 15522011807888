import React, { useState } from "react"
import { Col, Row, Form, Input, Button } from "reactstrap"

function UtilityForm() {

    const [rows1, setrows1] = useState([])
    const [rows2, setrows2] = useState([])

    function handleAddRowNested() {
        const item1 = { name1: "" }
        setrows1([...rows1, item1])
    }

    function handleAddRowNested1() {
        const item2 = { name1: "" }
        setrows2([...rows2, item2])
    }


    return (
        <>
            <Form className="repeater">
                <div data-repeater-list="group-a">
                    <div data-repeater-item className="row">
                        <div className="mb-3 col-lg-3">
                            <label className="form-label" htmlFor="name">Utility Name</label>
                            <select type="select" id="name" name="quantity" className="form-control">
                                <option> Select... </option>
                                <option> Generator </option>
                                <option> Cleaning </option>
                                <option> Security </option>
                                <option> Electricity </option>
                                <option> Garbage </option>
                                <option> Water </option>
                                <option> Other </option>
                            </select>
                        </div>

                        <div className="mb-3 col-lg-3">
                            <label className="form-label" htmlFor="email">Utility cost</label>
                            <input type="number" className="form-control" />
                        </div>

                        <div className="mb-3 col-lg-3">
                            <label className="form-label" htmlFor="subject">Base fee</label>
                            <input type="number" className="form-control" />
                        </div>


                    </div>
                </div>

                {rows2.map((item2, idx) => (
                    <React.Fragment key={idx}>
                        <div data-repeater-list="group-a" id={"addr" + idx} >
                            <div data-repeater-item className="row">
                                <div className="mb-3 col-lg-4">
                                    <label className="form-label" htmlFor="name">Utility Name</label>
                                    <select type="select" id="name" name="quantity" className="form-control">
                                        <option> Select... </option>
                                        <option> Generator </option>
                                        <option> Cleaning </option>
                                        <option> Security </option>
                                        <option> Electricity </option>
                                        <option> Garbage </option>
                                        <option> Water </option>
                                        <option> Other </option>
                                    </select>
                                </div>

                                <div className="mb-3 col-lg-4">
                                    <label className="form-label" htmlFor="email">Utility cost</label>
                                    <input type="number" className="form-control" />
                                </div>

                                <div className="mb-3 col-lg-4">
                                    <label className="form-label" htmlFor="subject">Base fee</label>
                                    <input type="number" className="form-control" />
                                </div>

                            </div>
                        </div>
                    </React.Fragment>
                ))}
                <Button onClick={() => { handleAddRowNested1() }} color="success" className="btn login-btn w-md waves-effect waves-light me-5">Add more</Button>

                <button className="btn report-primary text-white w-md me-2" type="submit">Submit</button>
            </Form>

        </>
    )
}

export default UtilityForm