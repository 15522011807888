import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col, Card, CardBody } from "reactstrap"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Loading from "components/Loading"

function BlockDetails(props) {

    const image = props.propDetails[0].Picture.split("/")[1]

    const [dataDb, setData] = useState([]);
    function getProperty() {
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`https://prop.faspro24.com/api/property/GetBlockList/${props.propId}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result.data)
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        getProperty();
    }, []);

    return (
        <div className="page-title-box">
            <div className="bd-rds" data-aos="fade-bottom">
                <Row>
                    <Col md={6}>
                        <p> <b> Property Name : </b>  {props.propDetails[0].Name}</p>
                        <p> <b> Property Code : </b>  {props.propDetails[0].PropertyCode}</p>
                        <p> <b> Postal Code : </b>  {props.propDetails[0].PostalCode}</p>
                        <p> <b> Country : </b>  {props.propDetails[0].Country}</p>
                        <p> <b> State or province : </b>  {props.propDetails[0].StateProvince}</p>
                        <p> <b> Suburb : </b>  {props.propDetails[0].Suburb}</p>
                        <p> <b> City : </b>  {props.propDetails[0].City}</p>
                        <p> <b> Address : </b>  {props.propDetails[0].Address}</p>
                    </Col>
                    <Col md={6}>
                        <img src={`https://prop.faspro24.com/storage/${image}`} alt="" width="200" height="200" />
                    </Col>
                </Row>
                <h5 className="mb-3">Property Blocks :  </h5>
                <div className="d-flex">
                    {
                        dataDb?.map((block, idx) => {
                            return (

                                <Card className=" m-1 text-black border">
                                    <CardBody>
                                        <b key={idx}> {block.BlockName} </b>
                                    </CardBody>
                                </Card>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
BlockDetails.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}
export default withRouter(withTranslation()(BlockDetails))
