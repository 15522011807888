import React, { useState, useEffect } from 'react';
import { Card, Button, Modal } from 'react-bootstrap';
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import Loading from 'components/Loading';
import ViewModal from "../Components/ViewModal";
import EditUserForm from './Edit-Form';
import { warningMessage, successMessage } from "../../../components/Notifications";

function UserTable() {
    const userDet = useStore1Selector(loginUser)
    const propId = userDet?.original?.userDetails?.OwnerID
    const portfolioPropId = userDet?.OwnerID
    const [dataDb, setData] = useState();
    const [viewID, setViewID] = useState()
    const [editID, setEditID] = useState()
    const [delteID, setDeleteID] = useState()

    const [modal_center, setmodal_center] = useState(false)
    const [largeRightModalExample, setLargeRightModalExample] = useState(false);
    const [smExample, setSmExample] = useState(false);

    function viewFunc(id) {
        setViewID(id)
        setmodal_center(!modal_center)
    }

    function editFunc(id) {
        setEditID(id)
        setLargeRightModalExample(true)
    }

    function deleteFunc(id) {
        setDeleteID(id)
        setSmExample(true)
    }

    function deleteUer() {
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`https://prop.faspro24.com/api/team/DeleteTeamMember/${delteID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "succes") {
                    setSmExample(false)
                    successMessage("You have successfully deleted this team member")
                    getTenantList();
                }
            })
            .catch(error => {
                setSmExample(false)
                warningMessage(error.Message)
            });
    }

    const getTenantList = () => {
        let list = [];
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`https://prop.faspro24.com/api/team/ListTeamMembers/${propId ? propId : portfolioPropId}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const resArr = result.data;
                if (result.status === 'succes') {
                    resArr.forEach((res) => {
                        list.push({
                            ...res,
                            viewBtn: <button className="btn btn-success" onClick={() => viewFunc(`${res.id}`)}> view </button>,
                            editBtn: <button className="btn btn-primary" onClick={() => editFunc(`${res.id}`)}> Edit </button>,
                            deleteBtn: <button className="btn btn-danger" onClick={() => deleteFunc(`${res.id}`)}> Delete </button>
                        })
                    })
                    setData(list);
                }
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        getTenantList();
    }, []);

    const column = [
        { label: "First Name", field: "FirstName", sort: "asc", width: 150 },
        { label: "Last Name", field: "LastName", sort: "asc", width: 150 },
        { label: "Role", field: "Name", sort: "asc", width: 150 },
        { label: "Email", field: "Email", sort: "asc", width: 150 },
        // { label: "ID/Passport", field: "IDPassport", sort: "asc", width: 150 },
        { label: "Phone Number", field: "PhoneNumber", sort: "asc", width: 150 },
        { label: "View", field: "viewBtn", sort: "asc", width: 150 },
        { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
        { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
    ];

    if (!dataDb) {
        return <Loading />
    }

    const modalUserDet = dataDb.filter(users => {
        return users.id === parseInt(viewID)
    })
    const modalUserDet2 = dataDb.filter(users => {
        return users.id === parseInt(editID)
    })

    const data = {
        columns: column,
        rows: dataDb,
    }

    return (
        <div>
            <div className="d-flex justify-content-between">
                <Link to="/add-user" className="btn login-btn w-md waves-effect waves-light mb-4 report-primary" type="submit"> + Add new user </Link>
                {/* <button className="btn login-btn w-md waves-effect waves-light mb-4 d-flex justify-content-end report-warning">
                    <CSVLink data={dataDb} separator={";"} className="text-white"> Download in Excel </CSVLink>
                </button> */}
            </div>
            <MDBDataTable entries={5} entriesOptions={[5, 10, 50]} responsive bordered striped hover data={data} fullPagination />

            <Modal className="modal-close-out" show={modal_center} onHide={() => setmodal_center(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>User details</Modal.Title>
                </Modal.Header>
                <div className="modal-body">
                    <ViewModal details={modalUserDet} />
                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setmodal_center(false)}> Close </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                className="modal-right scroll-out-negative"
                show={largeRightModalExample}
                onHide={() => setLargeRightModalExample(false)}
                scrollable
                dialogClassName="full" size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Edit user role</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <EditUserForm id={editID} details={modalUserDet2} />
                </Modal.Body>
            </Modal>


            <Modal show={smExample} onHide={() => setSmExample(false)} size="sm">
                <Modal.Body className='text-danger'> <h4> Are you sure you want to delete this user ? </h4> </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setSmExample(false)}> Cancel </Button>
                    <Button variant="danger" onClick={deleteUer}>Yes</Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default UserTable