import React, { useState, useRef, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { Col, Row, CardBody, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import useCustomMutation from "../../../hooks/useCustomMutation";
import useFetch from "../../../hooks/useFecth";
import fetcher from "../../../hooks/fetcher";
import Avatar from "../../../assets/images/Avatar.png";
import { TypeOfVisitor } from "../../../components/TypeOfVisitor";

import { NumberMinutes } from "../../../components/Number_minutes";
import { NumberHour } from "../../../components/Number_hour";
import { NumberDay } from "../../../components/Number_day";
import { NumberWeek } from "../../../components/Number_week";
import { NumberMonth } from "../../../components/Number_month";

function AddVisitorForm({ setAddVisitor }) {
  const queryClient = useQueryClient();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const UserRole = userDet?.data?.roles[0];
  const key = ["myVisitors"];
  const mutation = useCustomMutation(key);

  const ownerId = userDet?.data?._id;
  const teamsArray = process.env.REACT_APP_TEAM.split(",");

  const [myId, setMyId] = useState(
    teamsArray.includes(UserRole) ? "teamId" : "userId"
  );
  const [propertyID, setPropertyID] = useState(null);
  const [unitID, setUnitID] = useState(null);
  const [phone, setPhone] = useState("");
  const [visitorType, setVisitorType] = useState(null);
  const [selectTime, setSelectTime] = useState("");
  const [timeValue, setTimeValue] = useState(null);

  const timeOptions = {
    minutes: NumberMinutes,
    hours: NumberHour,
    days: NumberDay,
    weeks: NumberWeek,
    months: NumberMonth,
  };

  useEffect(() => {
    queryClient.prefetchQuery(["MyProper"], () =>
      fetcher(
        `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/property_owner?fieldName=${myId}`,
        token
      )
    );
  }, []);

  const { data: getUnits } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/units/property_owner/${propertyID}`,
    token
  );

  const selectHandleVisitorType = (e) => {
    setVisitorType(e.target.value);
  };

  const handleValidSubmit = (e, v) => {
    e.preventDefault();

    const currentDate = new Date();
    let multiplier = 1;
    if (selectTime === "minutes") {
      multiplier = 1000 * 60;
    } else if (selectTime === "hours") {
      multiplier = 1000 * 60 * 60;
    } else if (selectTime === "days") {
      multiplier = 1000 * 60 * 60 * 24;
    } else if (selectTime === "weeks") {
      multiplier = 1000 * 60 * 60 * 24 * 7;
    } else if (selectTime === "months") {
      multiplier = 1000 * 60 * 60 * 24 * 30;
    }

    const calculatedDuratimTime =
      currentDate.getTime() + timeValue * multiplier;
    const visitorDuration = new Date(calculatedDuratimTime);
    const duration = visitorDuration.toISOString();

    const Method = "POST",
      url = `visitors`,
      isJSON = null;

    const raw = body(
      visitorType,
      duration,
      v.fullNameDelivery,
      v.fullNameFamily,
      v.fullNameOther,
      propertyID,
      unitID,
      v.companyName,
      v.contactPerson,
      v.plateNumber,
      v.ServiceProvided,
      v.address,
      v.emailDelivery,
      v.emailFamily,
      v.emailOther,
      phone,
      v.relation,
      v.notes,
      v.additionalInfo,
      v.visitReason,
      v.reasonForVisit,
      v.visitRelation
    );

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Visitor Successfully Created",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const body = (
    visitorType,
    duration,
    fullNameDelivery,
    fullNameFamily,
    fullNameOther,
    propertyID,
    unitID,
    companyName,
    contactPerson,
    plateNumber,
    ServiceProvided,
    address,
    emailDelivery,
    emailFamily,
    emailOther,
    phone,
    relation,
    notes,
    additionalInfo,
    visitReason,
    reasonForVisit,
    visitRelation
  ) => {
    if (visitorType === "Family & Friends") {
      const raw = JSON.stringify({
        type: "Family",
        expirationTime: duration,
        unit: unitID,
        property: propertyID,
        propertyOwnerId: ownerId,
        visitorDetails: {
          fullName: fullNameFamily,
          email: emailFamily,
          phoneNumber: phone,
          family: {
            relation: relation,
            address: address,
          },
        },
      });
      return raw;
    }

    if (visitorType === "Delivery") {
      const raw = JSON.stringify({
        type: "Delivery",
        expirationTime: duration,
        unit: unitID,
        property: propertyID,
        propertyOwnerId: ownerId,
        visitorDetails: {
          fullName: fullNameDelivery,
          email: emailDelivery,
          phoneNumber: phone,
          delivery: {
            companyName: companyName,
            contactPerson: contactPerson,
            serviceProvided: ServiceProvided,
            licenseInfo: plateNumber,
            companyAddress: address,
            notes: notes,
          },
        },
      });
      return raw;
    }

    if (visitorType === "Other") {
      const raw = JSON.stringify({
        type: "Other",
        expirationTime: duration,
        unit: unitID,
        property: propertyID,
        propertyOwnerId: ownerId,
        visitorDetails: {
          fullName: fullNameOther,
          email: emailOther,
          phoneNumber: phone,
          other: {
            reasonForVisit: reasonForVisit,
            additionalInfo: additionalInfo,
          },
        },
      });
      return raw;
    }
  };

  const handlePropertyChange = (e) => {
    setPropertyID(e.target.value);
  };

  const handleUnitsChange = (e) => {
    setUnitID(e.target.value);
  };

  if (mutation.isSuccess) {
    setAddVisitor(false);
  }

  return (
    <React.Fragment>
      <CardBody>
        <Row className="align-items-center">
          <Col md={12} lg={12} xl={12}>
            <AvForm
              className="form-horizontal mt-3"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <Row>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={Avatar}
                    alt="user"
                    width={150}
                    height={150}
                    className="rounded"
                  />
                </div>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3 mt-3">
                    <AvField
                      name="visitorType"
                      label="Type of visitor"
                      type="select"
                      onChange={selectHandleVisitorType}
                      required
                    >
                      <option> Select... </option>
                      {TypeOfVisitor?.map((item, i) => {
                        return (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        );
                      })}
                    </AvField>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="dur"
                      label="Duration"
                      type="select"
                      onChange={(e) => setSelectTime(e.target.value)}
                      required
                    >
                      <option> Select... </option>
                      <option value="minutes">Minutes</option>
                      <option value="hours">Hours</option>
                      <option value="days">Days</option>
                      <option value="weeks">Weeks</option>
                      <option value="months">Months</option>
                    </AvField>
                  </div>
                </Col>
                {timeOptions[selectTime] && (
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="time"
                        label={`How many ${selectTime} :`}
                        type="select"
                        onChange={(e) => setTimeValue(e.target.value)}
                        required
                      >
                        <option> Select... </option>
                        {timeOptions[selectTime]?.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                )}

                {visitorType === "Delivery" ? (
                  <>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="fullNameDelivery"
                          label="Visitor Full Name"
                          className="form-control"
                          type="text"
                          required
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="propertyNameDelivery"
                          label="Property Name"
                          type="select"
                          onChange={handlePropertyChange}
                          required
                        >
                          <option> Select... </option>
                          {queryClient
                            .getQueryData(["MyProper"])
                            ?.map((item, i) => {
                              return (
                                <option key={i} value={item?._id}>
                                  {item?.propertyName}
                                </option>
                              );
                            })}
                        </AvField>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="unitNameDelivery"
                          label="Unit Name"
                          type="select"
                          onChange={handleUnitsChange}
                        >
                          <option> Select... </option>
                          {getUnits?.map((item, i) => {
                            return (
                              <option value={item?._id} key={i}>
                                {item?.unitName}
                              </option>
                            );
                          })}
                        </AvField>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="companyName"
                          label="Company Name"
                          className="form-control"
                          type="text"
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="contactPerson"
                          label="Contact Person"
                          className="form-control"
                          type="text"
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="plateNumber"
                          label="Plate Number"
                          className="form-control"
                          type="text"
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="ServiceProvided"
                          label="Service Provided"
                          className="form-control"
                          type="text"
                          required
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="address"
                          label="Company Address"
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="emailDelivery"
                          label="Email"
                          className="form-control"
                          type="email"
                        />
                      </div>
                    </Col>

                    <div className="mb-3">
                      <PhoneInput
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{
                          borderBottomLeftRadius: 5,
                          borderTopLeftRadius: 5,
                          height: 40,
                        }}
                        inputStyle={{
                          margin: 0,
                          width: "100%",
                          color: "purple",
                          height: 1,
                          border: "none",
                        }}
                        containerStyle={{
                          width: "100%",
                        }}
                        className="blue"
                        country={"gh"}
                        enableSearch={true}
                        value={phone}
                        onChange={(phone) => setPhone(phone)}
                      />
                    </div>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="notes"
                          label="Additional Info"
                          type="textarea"
                          rows={5}
                        />
                      </div>
                    </Col>
                  </>
                ) : null}
                {visitorType === "Family & Friends" ? (
                  <>
                    <>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="fullNameFamily"
                            label="Visitor Full Name"
                            className="form-control"
                            type="text"
                            required
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <AvField
                            name="propertyNameFamily"
                            label="Property Name"
                            type="select"
                            onChange={handlePropertyChange}
                            required
                          >
                            <option> Select... </option>
                            {queryClient
                              .getQueryData(["MyProper"])
                              ?.map((item, i) => {
                                return (
                                  <option key={i} value={item?._id}>
                                    {item?.propertyName}
                                  </option>
                                );
                              })}
                          </AvField>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <AvField
                            name="unitNameFamily"
                            label="Unit Name"
                            type="select"
                            onChange={handleUnitsChange}
                          >
                            <option> Select... </option>
                            {getUnits?.map((item, i) => {
                              return (
                                <option value={item?._id} key={i}>
                                  {item?.unitName}
                                </option>
                              );
                            })}
                          </AvField>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="emailFamily"
                            label="Email"
                            className="form-control"
                            type="email"
                            required
                          />
                        </div>
                      </Col>
                      <div className="mb-3">
                        <PhoneInput
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                          buttonStyle={{
                            borderBottomLeftRadius: 5,
                            borderTopLeftRadius: 5,
                            height: 40,
                          }}
                          inputStyle={{
                            margin: 0,
                            width: "100%",
                            color: "purple",
                            height: 1,
                            border: "none",
                          }}
                          containerStyle={{
                            width: "100%",
                          }}
                          className="blue"
                          country={"gh"}
                          enableSearch={true}
                          value={phone}
                          onChange={(phone) => setPhone(phone)}
                        />
                      </div>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="address"
                            label="Visitor Address"
                            className="form-control"
                            type="text"
                            required
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="relation"
                            label="Reason For Visiting"
                            className="form-control"
                            type="textarea"
                            rows={5}
                            required
                          />
                        </div>
                      </Col>
                    </>
                  </>
                ) : null}
                {visitorType === "Other" ? (
                  <>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="fullNameOther"
                          label="Visitor Full Name"
                          className="form-control"
                          type="text"
                          required
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="propertyNameOther"
                          label="Property Name"
                          type="select"
                          onChange={handlePropertyChange}
                          required
                        >
                          <option> Select... </option>
                          {queryClient
                            .getQueryData(["MyProper"])
                            ?.map((item, i) => {
                              return (
                                <option key={i} value={item?._id}>
                                  {item?.propertyName}
                                </option>
                              );
                            })}
                        </AvField>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="unitNameOther"
                          label="Unit Name"
                          type="select"
                          onChange={handleUnitsChange}
                        >
                          <option> Select... </option>
                          {getUnits?.map((item, i) => {
                            return (
                              <option value={item?._id} key={i}>
                                {item?.unitName}
                              </option>
                            );
                          })}
                        </AvField>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="emailOther"
                          label="Email"
                          className="form-control"
                          type="email"
                          required
                        />
                      </div>
                    </Col>
                    <div className="mb-3">
                      <PhoneInput
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{
                          borderBottomLeftRadius: 5,
                          borderTopLeftRadius: 5,
                          height: 40,
                        }}
                        inputStyle={{
                          margin: 0,
                          width: "100%",
                          color: "purple",
                          height: 1,
                          border: "none",
                        }}
                        containerStyle={{
                          width: "100%",
                        }}
                        className="blue"
                        country={"gh"}
                        enableSearch={true}
                        value={phone}
                        onChange={(phone) => setPhone(phone)}
                      />
                    </div>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="reasonForVisit"
                          label="Reason For Visit"
                          className="form-control"
                          type="text"
                          required
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="additionalInfo"
                          label="Additional Info"
                          type="textarea"
                          rows={5}
                        />
                      </div>
                    </Col>
                  </>
                ) : null}
              </Row>

              <div className="text-center mt-3">
                <button
                  className="btn login-btn  waves-effect waves-light"
                  type="submit"
                >
                  {!mutation.isLoading ? (
                    <span className="me-2">Submit</span>
                  ) : null}

                  {!mutation.isLoading ? null : (
                    <span>
                      <Spinner as="span" animation="border" size="sm" />{" "}
                      Loading...
                    </span>
                  )}
                </button>
              </div>
            </AvForm>
          </Col>
        </Row>
      </CardBody>
    </React.Fragment>
  );
}

AddVisitorForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(AddVisitorForm));
