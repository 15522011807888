import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";

import AllHistoryTable from "./components/AllHistoryTable";

function History() {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | Tickets History" />
        <Breadcrumb default="Maintenance Manangement" title="Tickets History" />

        <Row className="d-flex justify-content-around align-items-center">
          <CardBody data-aos="fade-bottom">
            <Card className="bd-rds">
              <CardBody>
                <AllHistoryTable />
              </CardBody>
            </Card>
          </CardBody>
        </Row>
      </div>
    </React.Fragment>
  );
}

History.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(History));
