import React, { useState, useRef, useEffect } from 'react'
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Col, Row, Button, Spinner } from "reactstrap"
import { countryList } from "../../../components/Countries";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import avatar from "../../../assets/images/users/placeholder.png";
import Select from "react-select"
import { warningMessage, successMessage } from "../../../components/Notifications";
import { useHistory } from "react-router-dom"
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";

function UserForm() {
    const history = useHistory();
    const userDet = useStore1Selector(loginUser)
    const propId = userDet?.original?.userDetails?.OwnerID
    const [phone, setPhoneState] = useState();
    const [imageState, setImageState] = useState()
    const [displayImg, setDisplayImg] = useState()
    const [loadBtn, setIloadBtn] = useState(false)
    const [toggle, setToggle] = useState("")
    const [myProperty, setMyProperty] = useState();
    const [value, setValue] = useState();
    const [selectedMulti, setselectedMulti] = useState(null);
    const multiplePropId = selectedMulti?.map(id => id?.value)

    const refFileUpload = useRef(null);
    const onThumbChangeClick = () => {
        if (refFileUpload) {
            refFileUpload.current.dispatchEvent(new MouseEvent('click'));
        }
    };

    const changeHandler = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImageState(event.target.files[0]);

            const reader = new FileReader();
            reader.onload = (loadEvent) => {
                setDisplayImg(loadEvent.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleValidSubmit = (e, values) => {
        e.preventDefault();
        setIloadBtn(true);

        const formdata = new FormData();
        formdata.append("RoleID", values.role);
        formdata.append("PropertyID", multiplePropId?.toString() === undefined ? null : multiplePropId?.toString());
        formdata.append("PropertyOwnerID", propId);
        formdata.append("FirstName", values.first_name);
        formdata.append("LastName", values.last_name);
        formdata.append("Email", values.email);
        formdata.append("PhoneNumber", phone);
        formdata.append("Password", values.password);

        // formdata.append("PhysicalAddress", values.address);
        // formdata.append("Suburb", values.suburb);
        // formdata.append("City", values.city);
        // formdata.append("StateProvince", values.state);
        // formdata.append("Country", values.country);
        // formdata.append("IDPassport", values.ID);
        // formdata.append("ProfilePicture", imageState);

        const requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://prop.faspro24.com/api/team/NewTeamMember", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log("Result : ", result)
                if (result.status === "success") {
                    setIloadBtn(false)
                    successMessage("Successful added")
                    window.setTimeout(() => {
                        history.push('/my-teams')
                    }, 3000);
                }
                if (result.Email[0] === "The email has already been taken.") {
                    setIloadBtn(false)
                    warningMessage("This email has already been taken")
                }
            })
            .catch(error => {
                if (error.message === "error") {
                    setIloadBtn(false)
                    warningMessage("couldn't add a team member")
                }
            });
    }

    const Individual_toggleFunc = (e, v) => {
        v === "1" ? setToggle(true) : setToggle(false)
    }
    const optionGroup = myProperty?.map(newArray => {
        return { 'label': newArray.Name, 'value': newArray.PropertyID }
    })
    function handleMulti(selectedMulti) {
        setselectedMulti(selectedMulti)
    }
    function getProperty() {
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`https://prop.faspro24.com/api/property/GetPropertyList/${propId}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setMyProperty(result?.data)
            })
            .catch(error => console.log('error', error));
    }
    useEffect(() => {
        getProperty();
    }, []);
    const selectHandle = (e) => {
        setValue(e.target.value)
    }

    return (
        <React.Fragment>
            <AvForm className="form-horizontal mt-4" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>
                <Row>
                    {/* <Row>
                        <div className="d-flex justify-content-center align-items-center mb-3">
                            <img src={!displayImg ? avatar : displayImg} alt="user" className="rounded-circle" width={100} height={100} />
                            <Button size="sm" variant="separator-light" className="btn-icon btn-icon-only mt-5 position-absolute rounded s-0 b-0"
                                onClick={onThumbChangeClick}>
                                <i className="ion ion-md-image"></i>
                            </Button>
                            <input type="file" ref={refFileUpload} className="file-upload d-none" accept="image/*" onChange={changeHandler} />
                        </div>
                    </Row> */}

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="role" label="Role" className="form-control" type="select"
                                onChange={Individual_toggleFunc}
                            >
                                <option> Select role </option>
                                <option value="1"> Portfolio manager </option>
                                <option value="2"> Property agent </option>
                                <option value="3"> Security </option>
                                <option value="4"> Finance </option>
                                <option value="5"> Marketing </option>
                                <option value="6"> Maintenance </option>
                                <option value="7"> Listing officers </option>
                            </AvField>
                        </div>
                    </Col>

                    {/* <Col md={6}>
                        <div className="mb-3">
                            <label className="control-label"> Assign  property </label>
                            <Select value={selectedMulti}
                                isMulti={true}
                                onChange={handleMulti}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                            />
                        </div>
                    </Col> */}
                    {
                        !toggle ? <Col md={6}>
                            <div className="mb-3">
                                <label className="control-label"> Assign  property </label>
                                <Select value={selectedMulti}
                                    isMulti={true}
                                    onChange={handleMulti}
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                />
                            </div>
                        </Col> : null
                    }
                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="first_name" label="First Name" type="text" required />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="last_name" label="Last Name" className="form-control" type="text" required />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="email" label="Email" type="text" required />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="password" label="Password" className="form-control" type="password" required />
                        </div>
                    </Col>

                    {/* <Col md={6}>
                        <div className="mb-3">
                            <AvField name="ID" label="ID / Passport number" type="text" required />
                        </div>
                    </Col> */}

                    <Col md={12}>
                        <div className="mb-3">
                            <p>Phone number</p>
                            <PhoneInput
                                buttonStyle={{
                                    borderBottomLeftRadius: 5,
                                    borderTopLeftRadius: 5,
                                    height: 40,
                                }}
                                inputStyle={{
                                    margin: 0,
                                    width: "100%",
                                    color: 'purple',
                                    height: 1,
                                    border: 'none'

                                }}
                                containerStyle={{
                                    width: "100%",
                                }}
                                className="blue"
                                country={"za"}
                                enableSearch={true}
                                onChange={(phone) => setPhoneState(phone)}
                            />
                        </div>
                    </Col>

                    {/* <Col md={6}>
                        <div className="mb-3">
                            <AvField name="address" label="User Address" type="text" required />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="suburb" label="Suburb" type="text" required />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="city" label="City" type="text" required />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="state" label="State / Province " type="text" required />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="country" label="Country" type="select" required>
                                {
                                    countryList.map(country => (
                                        <option> {country} </option>
                                    ))
                                }
                            </AvField>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="date" label="Date" className="form-control" type="date" required />
                        </div>
                    </Col> */}



                </Row>

                <button className="btn login-btn w-md waves-effect waves-light" type="submit">
                    {
                        !loadBtn ? <span className="me-2">Submit</span> : null
                    }
                    {
                        !loadBtn ? null : <span>  <Spinner as="span" animation="border" size="sm" /> Loading...</span>
                    }
                </button>

            </AvForm>
        </React.Fragment>
    )
}

export default UserForm