import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
  Badge,
  Button,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { Link, useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import WorkingTicketForm from "./WorkingTicketForm";
import useFecth from "../../../hooks/useFecth";

function AssignTable() {
  const history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const userId = userDet?.data?._id;
  const [ticketId, setTicketId] = useState(null);
  const [ticketModal, setTicketModal] = useState(false);

  const list = [];

  const { data: getMaintenanceTickets, reFetch: reFetchMaintenanceTickets } =
    useFecth(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceTickets/${userId}/serviceProvider`,
      token
    );

  getMaintenanceTickets?.forEach((res) => {
    if (res?.status === "accepted") {
      list.push({
        ...res,
        propertyName: res?.propertyId?.propertyName,
        assidnedTo: `${res?.assigned_to?.firstName} ${res?.assigned_to?.lastName}`,
        status:
          res?.status === "accepted" ? (
            <>
              <Badge className="rounded-pill bg-info p-2">Assigned</Badge>
            </>
          ) : null,
        viewBtn: (
          <button
            className="btn btn-dark"
            onClick={() => viewFunc(`${res?._id}`)}
          >
            View
          </button>
        ),
        sendInvoice: (
          <button
            className="btn btn-success"
            onClick={() => moveToFunc(`${res?._id}`)}
          >
            Move to working Ticket
          </button>
        ),
      });
    }
  });

  const viewFunc = (id) => {
    history.push(`/view-ticket/${id}`);
  };

  const moveToFunc = (id) => {
    setTicketId(id);
    setTicketModal(true);
  };

  const column = [
    { label: "Property name", field: "propertyName", sort: "asc", width: 150 },
    { label: "Title Name", field: "subject", sort: "asc", width: 150 },
    // {
    //   label: "Ticket Reference",
    //   field: "TicketNumber",
    //   sort: "asc",
    //   width: 150,
    // },
    { label: "Status", field: "status", sort: "asc", width: 150 },

    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Action", field: "sendInvoice", sort: "asc", width: 150 },
  ];

  const dataDB = { columns: column, rows: list.reverse() };

  return (
    <div>
      <Card className="bd-rds">
        <CardBody>
          <div className="d-flex justify-content-end">
            <button className="btn login-btn w-md waves-effect waves-light mb-4">
              <CSVLink data={"dataDb"} separator={";"} className="text-white">
                Download in Excel
              </CSVLink>
            </button>
          </div>
          <MDBDataTable
            entries={5}
            entriesOptions={[5, 10, 50]}
            responsive
            bordered
            striped
            hover
            data={dataDB}
          />
        </CardBody>
      </Card>
      <Modal show={ticketModal} onHide={() => setTicketModal(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Move to working Ticket queue </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <WorkingTicketForm
            ticketId={ticketId}
            setTicketModal={setTicketModal}
            reFetchMaintenanceTickets={reFetchMaintenanceTickets}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AssignTable;
