import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import TicketBarChart from "./TicketBarChart";
import { Years } from "../../../../components/Years";
import { BsFillSquareFill } from "react-icons/bs";

import { useStore1Selector } from "./../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import useFecth from "../../../../hooks/useFecth";

function PageYearlyTickets({ setTotalCost }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;

  const [ticketYear, setTicketYear] = useState(2023);

  const { data: getYearTicket, reFetch: reFetchGetYearTicket } = useFecth(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceReport?year=${ticketYear}`,
    token
  );

  const handleSelectTicketYear = (e) => {
    setTicketYear(e.target.value);
  };

  // setTotalCost;

  return (
    <>
      <Card>
        <CardBody className="property_card">
          <div className="d-flex justify-content-between h5 text-center">
            <div>Yearly Ticket Status Analysis</div>
            <div className="">
              <select
                id="selectOption"
                className="btn btn-dark"
                value={ticketYear}
                onChange={handleSelectTicketYear}
              >
                {Years.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div>
            <span className="text-info">
              <BsFillSquareFill />
            </span>
            <span className="m-2 text-info align-items-center">
              Open Tickets
            </span>
          </div>
          <div>
            <span className="text-warning">
              <BsFillSquareFill />
            </span>
            <span className="m-2 text-warning align-items-center">
              Pending Tickets
            </span>
          </div>
          <div>
            <span className="text-success">
              <BsFillSquareFill />
            </span>
            <span className="m-2 text-success align-items-center">
              Closed Tickets
            </span>
          </div>
          <div>
            <span className="text-danger">
              <BsFillSquareFill />
            </span>
            <span className="m-2 text-danger align-items-center">
              Overdue Tickets
            </span>
          </div>
          <TicketBarChart getYearTicket={getYearTicket} />
        </CardBody>
      </Card>
    </>
  );
}

export default PageYearlyTickets;
