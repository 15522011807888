import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
// import { var_num } from "../../../components/Vatnumber";
import { withTranslation } from "react-i18next";

import AppartmentForm from "./unitsForms/appartments";
import Offices from "./unitsForms/offices";
import Warehouse from "./unitsForms/Warehouse";
import Restaurant from "./unitsForms/HotelsAndRestaurant";
function UnitwithNoBlock({
  blockId,
  t,
  closeModal,
  propId,
  propType,
  propertyCategory,
}) {
  return (
    <div className="mt-3">
      {propType === "Residential" ? (
        <AppartmentForm closeModal={closeModal} propId={propId} />
      ) : null}
      {propertyCategory === "Offices" ? (
        <Offices closeModal={closeModal} propId={propId} />
      ) : propertyCategory === "Warehouse" ? (
        <Warehouse closeModal={closeModal} propId={propId} />
      ) : propertyCategory === "Hotels & Restaurant" ? (
        <Restaurant closeModal={closeModal} propId={propId} />
      ) : propertyCategory === "Retail Store" ? (
        <Warehouse closeModal={closeModal} propId={propId} />
      ) : null}
    </div>
  );
}

UnitwithNoBlock.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UnitwithNoBlock));
