import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Spinner, Button } from "reactstrap";
import useCustomQuery from "../../../hooks/useCustomQuery";
import useCustomMutation from "../../../hooks/useCustomMutation";
import LoadingTable from "../../../components/LoadingTable";
import { Modal } from "react-bootstrap";
import ViewVisitorDetail from "./ViewVisitorDetail";

function PropertyVisitorCheckInTable({ propertyID, token }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [viewID, setViewID] = useState();
  const [deleteID, setDeleteID] = useState();
  const [viewModal, setViewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const key = ["myPropertyCheckInVisitor", propertyID];
  const mutation = useCustomMutation(key);

  const {
    data: getMyVisitors,
    error: errorGetMyPropVisit,
    isLoading: isLoadingGetMyPropVisit,
  } = useCustomQuery(
    ["myPropertyCheckInVisitor", propertyID],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/visitors/${propertyID}/get-visitors-per-property`,
    token
  );

  const list = [];

  getMyVisitors?.forEach((res) => {
    if (res?.status === "checkIn") {
      let duration = "";
      const createdAt = new Date(res?.createdAt);
      const expirationTime = new Date(res?.expirationTime);
      const timeDifference = expirationTime - createdAt;

      const minutesDuration = Math.floor(timeDifference / (1000 * 60));
      const hoursDuration = Math.floor(timeDifference / (1000 * 60 * 60));
      const daysDuration = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const weeksDuration = Math.floor(
        timeDifference / (1000 * 60 * 60 * 24 * 7)
      );
      const monthsDuration = Math.floor(
        timeDifference / (1000 * 60 * 60 * 24 * 30)
      );

      if (minutesDuration < 60) {
        duration = `${minutesDuration + 1} min`;
      } else if (hoursDuration < 24) {
        duration = `${hoursDuration + 1} hr(s)`;
      } else if (daysDuration < 7) {
        duration = `${daysDuration + 1} day(s)`;
      } else if (weeksDuration < 4) {
        duration = `${weeksDuration + 1} week(s)`;
      } else {
        duration = `${monthsDuration + 1} month(s)`;
      }

      let expiration_Date = "";
      const now = new Date();
      const expirationDate = new Date(res?.expirationTime);
      const currentDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );
      const targetDate = new Date(
        expirationDate.getFullYear(),
        expirationDate.getMonth(),
        expirationDate.getDate()
      );

      const diffInTime = currentDate - targetDate;

      const diffInDays = diffInTime / (1000 * 60 * 60 * 24);
      if (diffInDays === 0) {
        expiration_Date = "Today";
      } else if (diffInDays === 1) {
        expiration_Date = "Yesterday";
      } else if (diffInDays > 1 && diffInDays < 7) {
        const daysOfWeek = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        expiration_Date = `${daysOfWeek[expirationDate.getDay()]}`;
      } else if (diffInDays >= 7) {
        expiration_Date = expirationDate.toISOString().slice(0, 10);
      } else {
        expiration_Date = expirationDate.toISOString().slice(0, 10);
      }

      list.push({
        ...res,
        FullName: `${res?.visitorDetails?.fullName}`,
        PropertyName: res?.property?.propertyName,
        UnitName: res?.unit ? `${res?.unit?.unitName}` : `---`,
        requestDate: `${res?.updatedAt?.substring(0, 10)}`,
        durationDate: duration,
        expirationDate: expiration_Date,
        phoneNumber: `+${res?.visitorDetails?.phoneNumber}`,
        status:
          res?.status === "checkIn" ? (
            <span className="text-success">Checked In</span>
          ) : res?.status === "checkOut" ? (
            <span className="text-danger">Checked Out</span>
          ) : (
            "---"
          ),

        viewBtn: (
          <button
            className="btn btn-success"
            onClick={() => viewFunc(`${res?._id}`)}
          >
            View
          </button>
        ),
        editBtn: (
          <button
            className="btn btn-dark"
            onClick={() => editFunc(`${res?._id}`)}
          >
            Edit
          </button>
        ),
        deleteBtn: (
          <button
            className="btn btn-danger"
            onClick={() => deleteFunc(`${res?._id}`)}
          >
            Delete
          </button>
        ),
      });
    }
  });

  const column = [
    { label: "Full Name", field: "FullName", sort: "asc", width: 150 },
    { label: "Phone Number", field: "phoneNumber", sort: "asc", width: 150 },
    { label: "Visitor Type", field: "type", sort: "asc", width: 150 },
    { label: "Pin", field: "entryPIN", sort: "asc", width: 150 },
    { label: "Duration", field: "durationDate", sort: "asc", width: 150 },
    {
      label: "Expiration Date",
      field: "expirationDate",
      sort: "asc",
      width: 150,
    },
    { label: "Property Name", field: "PropertyName", sort: "asc", width: 150 },
    { label: "Unit Name", field: "UnitName", sort: "asc", width: 150 },
    { label: "Visitor Status", field: "status", sort: "asc", width: 150 },
  ];

  const dataDB = { columns: column, rows: list };

  const viewFunc = (id) => {
    setViewID(id);
    setViewModal(true);
  };

  const handleDeleteTicket = () => {
    const Method = "DELETE",
      url = `visitors/${deleteID}`,
      isJSON = null;
    const raw = null;

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Visitor Successfully Delete",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const deleteFunc = (id) => {
    setDeleteID(id);
    setDeleteModal(true);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      setDeleteModal(false);
    }
  }, [mutation.isSuccess]);

  if (isLoadingGetMyPropVisit) {
    return <LoadingTable />;
  }

  const handlePageChange = (event, direction) => {
    if (direction === "next") {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (direction === "previous" && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <>
      <MDBDataTable
        entries={5}
        entriesOptions={[5, 10, 50]}
        responsive
        bordered
        striped
        hover
        data={dataDB}
        onPageChange={handlePageChange}
      />
      <Modal show={viewModal} onHide={() => setViewModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> View Visitor Details </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ViewVisitorDetail viewID={viewID} setViewModal={setViewModal} />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-danger text-white"
            onClick={() => setViewModal(false)}
          >
            close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Delete Ticket </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-danger h4">
            Are you sure you want to delete this ticket ?
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-success text-white"
            onClick={handleDeleteTicket}
          >
            {!mutation.isLoading ? (
              "Sure"
            ) : (
              <span>
                <Spinner as="span" animation="border" size="sm" /> Loading...
              </span>
            )}
          </Button>
          <Button
            className="bg-danger text-white"
            onClick={() => setDeleteModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default PropertyVisitorCheckInTable;
