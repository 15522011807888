import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import "./Components/Talk.scss";
import { CgArrowLongLeft } from "react-icons/cg";
//Import Images
import errorImg from "../../assets/images/error.png";
import Slider from "./Components/Slider";
import Form from "./Components/Form";

const Index = () => {
  document.title = "Faspro 24 | Talk to us";
  const [validation, setValidation] = useState(false);
  // console.log(validation);
  return (
    <React.Fragment>
      <div className="authentication-bg d-flex align-items-center pb-0 vh-100">
        {!validation ? (
          <div className="content-center w-100">
            <Container>
              <Row className="justify-content-center">
                <Col md={10}>
                  <Card className="Talk-To-us-container">
                    <CardBody>
                      <Row className="align-items-center">
                        <Col md={5} className="ms-auto">
                          <Slider />
                        </Col>
                        <Col md={7} className="mx-auto">
                          <Form setValidation={setValidation} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <div className="content-center w-100">
            <Container className="content-center w-50">
              <Row className="justify-content-center">
                <Col md={10}>
                  <Card className="Talk-To-us-container">
                    <CardBody>
                      <Row className="align-items-center">
                        <Col md={12} className="ms-auto">
                          <h2 className="mb-3">Thank you for contacting us </h2>
                          <p>Our Team will get back to you shortly.</p>
                          <div className="text-primary fw-bold">
                            <a href="https://faspro24.com/">
                              <span>
                                <CgArrowLongLeft size={30} />
                              </span>
                              <span className="mx-1 mt-2 h5">Go back</span>
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default Index;
