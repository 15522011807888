import React from "react";
import { Button, Row, Col, Card } from "reactstrap";
import { BsCheckLg } from "react-icons/bs";
import { CgLoadbar } from "react-icons/cg";

function YearlyForm() {
  return (
    <div className="package_subscription_features">
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>

        <h5 className="ms-2 p-1 mb-2">Manage up to 20 units</h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>

        <h5 className="ms-2 p-1 mb-2">Create lease</h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>

        <h5 className="ms-2 p-1 mb-2">Assign units to tenants </h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>

        <h5 className="ms-2 p-1 mb-2">Invoice tenants </h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>

        <h5 className="ms-2 p-1 mb-2">Pay rent online</h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <CgLoadbar size={20} color="red" />
        </div>
        <h5 className="ms-2 p-1 mb-2">Limited analytics</h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <CgLoadbar size={20} color="red" />
        </div>
        <h5 className="ms-2 p-1 mb-2">Limited property performance reports</h5>
      </div>
      <div className="mt-5 d-flex justify-content-center">
        <Button className="bg-success text-light">Get Started</Button>
      </div>
    </div>
  );
}

export default YearlyForm;
