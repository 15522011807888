import PropTypes from "prop-types"
import React from 'react'
import { Container, Row, CardBody, Col, Card } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next"
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import UserTable from "./Components/User-table";


function UserManagement() {

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTagComp meta_tags="FASPRO 24 | user" />
                <Breadcrumb default="Property" title="User Management" />

                <CardBody>
                    <Container fluid>
                        <Row className="d-flex justify-content-around align-items-center">
                            <Card>
                                <CardBody>
                                    <UserTable />
                                </CardBody>
                            </Card>
                        </Row>
                    </Container>
                </CardBody>
            </div>
        </React.Fragment>
    )
}

UserManagement.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(UserManagement))