import React, { useState } from 'react'
import './../Style.scss';
import { Card, CardBody, CardTitle, CardImg, Row, Col } from "reactstrap"
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";

function Content() {
    const [dataDb, setData] = useState([
        {
            No: "01",
            unit_name: "TNG",
            num1: "R 00.0",
            num2: "R 00.0",
            num3: "R 00",
            num4: "R 4500",
            num5: "R 3000",
            num6: "R 10000",
        }
    ]);

    const column = [
        { label: "No.", field: "No", sort: "asc", width: 150 },
        { label: "Unit Name", field: "unit_name", sort: "asc", width: 150 },
        { label: "120 + Days", field: "num1", sort: "asc", width: 150 },
        { label: "90 + Days", field: "num2", sort: "asc", width: 150 },
        { label: "60 + Days", field: "num3", sort: "asc", width: 150 },
        { label: "30 + Days", field: "num4", sort: "asc", width: 150 },
        { label: "Current", field: "num5", sort: "asc", width: 150 },
        { label: "Balance", field: "num6", sort: "asc", width: 150 },
    ];

    const data = {
        columns: column,
        rows: dataDb,
    }

    return (
        <Row className="d-flex justify-content-around align-items-center">
            <CardBody data-aos="fade-bottom">
                <Card className="bd-rds">
                    <CardBody>
                        <div className="d-flex justify-content-between">
                            <button className="btn login-btn w-md waves-effect waves-light mb-4 d-flex justify-content-end report-warning">
                                <CSVLink data={dataDb} separator={";"} className="text-white"> Download in Excel </CSVLink> </button>
                        </div>
                        <MDBDataTable entries={5} entriesOptions={[5, 10, 50]} responsive bordered striped hover data={data} fullPagination />

                    </CardBody>
                </Card>
            </CardBody>
        </Row>
    )
}

export default Content