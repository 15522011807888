import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { loginUser } from "../../../Redux/Slices/userSlice";
import { useStore1Selector } from "./../../../index";
import AppartmentForm from "./EditUnitForms/appartments";
import Offices from "./EditUnitForms/offices";
import Warehouse from "./EditUnitForms/Warehouse";
import Restaurant from "./EditUnitForms/HotelsAndRestaurant";
import useFetch from "../../../hooks/useFecth";

import { withTranslation } from "react-i18next";

function EditUnitDetails({ propId, unitId, editForm, t, closeModal, refetch }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { data: propDetails } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/${propId}`,
    token
  );
  const { data } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/units/${unitId}`,
    token
  );

  return (
    <React.Fragment>
      <div className="mt-3">
        {propDetails?.propertyType === "Residential" ? (
          <AppartmentForm
            propId={propId}
            unitId={unitId}
            closeModal={closeModal}
            unitDetails={data}
            refetch={refetch}
          />
        ) : null}
        {propDetails?.propertyCategory === "Offices" ? (
          <Offices
            propId={propId}
            unitId={unitId}
            closeModal={closeModal}
            unitDetails={data}
            refetch={refetch}
          />
        ) : propDetails?.propertyCategory === "Warehouse" ? (
          <Warehouse
            propId={propId}
            unitId={unitId}
            closeModal={closeModal}
            unitDetails={data}
            refetch={refetch}
          />
        ) : propDetails?.propertyCategory === "Hotels & Restaurant" ? (
          <Restaurant
            propId={propId}
            unitId={unitId}
            closeModal={closeModal}
            unitDetails={data}
            refetch={refetch}
          />
        ) : propDetails?.propertyCategory === "Retail Store" ? (
          <Warehouse
            propId={propId}
            unitId={unitId}
            closeModal={closeModal}
            unitDetails={data}
            refetch={refetch}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
}

EditUnitDetails.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(EditUnitDetails));
