import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Card, CardBody, Modal } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { useStore1Selector } from "index";
import { loginUser } from "Redux/Slices/userSlice";
import NavigationBar from "./Components/NavigationBar";
import VisitorPerProperty from "./Components/VisitorPerProperty";
import VisitorPerPropertyCheckIn from "./Components/VisitorPerPropertyCheckIn";
import VisitorPerPropertyCheckOut from "./Components/VisitorPerPropertyCheckOut";
import VisitorPerPropertyOverstay from "./Components/VisitorPerPropertyOverstay";

function VisitorAccess() {
  const userDet = useStore1Selector(loginUser);
  const [visitor, setVisitor] = useState(true);
  const [visitorIn, setVisitorIn] = useState(false);
  const [visitorOut, setVisitorOut] = useState(false);
  const [visitorOverstay, setVisitorOverstay] = useState(false);

  const token = userDet?.token;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | Visitor Access" />
        <Breadcrumb default="Security Manangement" title="Visitor Access" />
        <NavigationBar
          visitor={visitor}
          setVisitor={setVisitor}
          visitorIn={visitorIn}
          setVisitorIn={setVisitorIn}
          visitorOut={visitorOut}
          setVisitorOut={setVisitorOut}
          visitorOverstay={visitorOverstay}
          setVisitorOverstay={setVisitorOverstay}
        />
        <Row className="d-flex justify-content-around align-items-center">
          <CardBody data-aos="fade-bottom">
            <Card className="bd-rds">
              {visitor ? (
                <CardBody>
                  <VisitorPerProperty />
                </CardBody>
              ) : null}
              {visitorIn ? (
                <CardBody>
                  <VisitorPerPropertyCheckIn />
                </CardBody>
              ) : null}
              {visitorOut ? (
                <CardBody>
                  <VisitorPerPropertyCheckOut />
                </CardBody>
              ) : null}
              {visitorOverstay ? (
                <CardBody>
                  <VisitorPerPropertyOverstay />
                </CardBody>
              ) : null}
            </Card>
          </CardBody>
        </Row>
      </div>
    </React.Fragment>
  );
}

VisitorAccess.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(VisitorAccess));
