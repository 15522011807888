import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Card, CardBody, Button, Badge, Spinner } from "reactstrap";
import { Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";
import Nav from "./components/Nav";
import OpenTable from "./components/OpenTable";
import OnHoldTable from "./components/OnHoldTable";
import PendingTable from "./components/PendingTable";
import ClosedTable from "./components/ClosedTable";
import OverdueTable from "./components/OverdueTable";
import { AvForm, AvField } from "availity-reactstrap-validation";
// import Report from "./components/MaintenanceReport";
import { CSVLink } from "react-csv";

import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import Loading from "components/Loading";
import { successMessage, warningMessage } from "components/Notifications";

function Tasks() {
  const [openTask, setOpenTask] = useState(true);
  const [onHoldTask, setOnHoldTask] = useState(false);
  const [pendingTask, setPendingTask] = useState(false);
  const [clodedTask, setClodedTask] = useState(false);
  const [overdueTask, setOverdueTask] = useState(false);

  const userDet = useStore1Selector(loginUser);
  const userId = userDet?.original?.userDetails?.OwnerID;
  const { propId } = useParams();
  const [modalTask, setmodal_center] = useState(false);

  function assignFunc(id) {
    setAssignModal(true);
    setViewID(id);
  }

  function addServicePro(id) {
    setAddModal(true);
    setViewID(id);
  }

  function viewFunc(id) {
    setViewmodal(true);
    setViewID(id);
  }

  function cancelFunc(id) {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://prop.faspro24.com/api/ticket/CancelTicket/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          successMessage("You have Successfully cancel the tickets");
          window.setTimeout(() => {
            location.reload();
          }, 3000);
        }
      })
      .catch((error) => console.log("error", error));
  }

  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    setIloadBtn(true);

    const formdata = new FormData();
    formdata.append("TicketID", viewID);
    formdata.append("ServiceProviderID", values.serviceProvider);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://prop.faspro24.com/api/ticket/AssignTicket", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          successMessage("You have Successfully assign the tickets");
          setIloadBtn(false);
          window.setTimeout(() => {
            location.reload();
          }, 3000);
        }
        if (result.status === "fail") {
          warningMessage("Something went wrong try again");
          setIloadBtn(false);
        }
      })
      .catch((error) => {
        warningMessage("Something went wrong try again");
        setIloadBtn(false);
      });
  };

  useEffect(() => {
    let list = [];
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://prop.faspro24.com/api/ticket/GetTicketsByPropertyID/property_filter/${propId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("tickets result : ", result)
        result.forEach((res) => {
          list.push({
            ...res.ticket_details,
            unitTickets: res.unit_details,
            openBy: res.created_by,
            priority:
              res.ticket_details.Priority === 1 ? (
                <Badge className="btn btn-danger">Yes</Badge>
              ) : null,
            assignBtn: (
              <button
                className="btn btn-primary"
                onClick={() => assignFunc(`${res?.ticket_details?.TicketID}`)}
              >
                Assign
              </button>
            ),
            openTicket: (
              <div className="dropdown-wrapper">
                <Dropdown
                  isOpen={more_Menu}
                  direction="up"
                  toggle={() => {
                    setmore_Menu(!more_Menu);
                  }}
                  className="btn-group me-2"
                >
                  <DropdownToggle
                    className="btn btn-success waves-light waves-effect dropdown-toggle"
                    tag="div"
                  >
                    More <i className="mdi mdi-chevron-up" />
                    {/* <i className="mdi mdi-chevron-down ms-1"></i> */}
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-right">
                    <DropdownItem
                      className="custom-dropdown-item"
                      onClick={() => viewFunc(`${res?._id}`)}
                    >
                      View
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      className="custom-dropdown-item"
                      onClick={() => viewFunc(`${res?._id}`)}
                    >
                      Ackwnoledge Ticket
                    </DropdownItem>
                    <DropdownItem divider />
                    {/* <DropdownItem className="custom-dropdown-item">
                      Assign To
                    </DropdownItem> */}
                    {/* <DropdownItem divider /> */}
                  </DropdownMenu>
                </Dropdown>
              </div>
            ),
            viewBtn: (
              <button
                className="btn btn-success"
                onClick={() => viewFunc(`${res?.ticket_details?.TicketID}`)}
              >
                {" "}
                View{" "}
              </button>
            ),
            cancelBtn: (
              <button
                className="btn btn-danger"
                onClick={() => cancelFunc(`${res?.ticket_details?.TicketID}`)}
              >
                {" "}
                Cancel{" "}
              </button>
            ),
          });
        });
        setData(list.sort().reverse());
      })
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    let list = [];
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      `https://prop.faspro24.com/api/service_provider/GetServiceProviderByPropertyID/${propId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setServiceProvider(result);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const column = [
    { label: "Ticket Ref", field: "TicketRef", sort: "asc", width: 150 },
    { label: "Ticket Type", field: "TicketType", sort: "asc", width: 150 },
    { label: "Subject", field: "Subject", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Assign", field: "assignBtn", sort: "asc", width: 150 },
    { label: "Emergency", field: "priority", sort: "asc", width: 150 },
    { label: "Cancel", field: "cancelBtn", sort: "asc", width: 150 },
  ];

  // const assignTickets = dataDb?.filter((ticket) => {
  //   return ticket.status === 0;
  // });
  const assignTickets = [];
  const data = {
    columns: column,
    rows: assignTickets,
  };

  // if (!data) {
  //   return <Loading />;
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | My Tasks" />
        <Breadcrumb default="Maintenance Management" title="My Tasks" />
        <Nav
          openTask={openTask}
          setOpenTask={setOpenTask}
          onHoldTask={onHoldTask}
          setOnHoldTask={setOnHoldTask}
          pendingTask={pendingTask}
          setPendingTask={setPendingTask}
          clodedTask={clodedTask}
          setClodedTask={setClodedTask}
          overdueTask={overdueTask}
          setOverdueTask={setOverdueTask}
        />
        <CardBody>
          {/* <Report propId={propId} /> */}
          <Row className="d-flex justify-content-around align-items-center">
            <CardBody data-aos="fade-bottom">
              <Card className="bd-rds">
                <CardBody>
                  {openTask ? <OpenTable data={data} /> : null}
                  {onHoldTask ? <OnHoldTable data={data} /> : null}
                  {pendingTask ? <PendingTable data={data} /> : null}
                  {clodedTask ? <ClosedTable data={data} /> : null}
                  {overdueTask ? <OverdueTable data={data} /> : null}
                </CardBody>
              </Card>
            </CardBody>
          </Row>
        </CardBody>
      </div>

      <Modal show={modalTask} onHide={() => setModalTask(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Create a ticket </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* <OpenTickets id={viewID} propId={propId} userId={userId} /> */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-danger text-white"
            onClick={() => setModalTask(false)}
          >
            close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Tasks;
