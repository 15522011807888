import React, { useEffect, useState } from "react";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { Row, Col, Spinner } from "reactstrap";
import { useStore1Selector } from "../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import useFetch from "../../../../hooks/useFecth";
import LoadComponents from "../../../../components/LoadComponents";
import { successMessage, warningMessage } from "../../../../components/Toast";

function ViewInvoiceForm({
  actionID,
  ticketRef,
  setActionModal,
  reFetchMaintenanceTickets,
}) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const [btnApproveLoading, setbtnApproveLoading] = useState(false);
  const [btnDeclineLoading, setBtnDeclineLoading] = useState(false);
  const [btnShow, setBtnShow] = useState(false);
  const [declineReason, setDeclineReason] = useState("");

  const handleReasonChange = (e) => {
    setDeclineReason(e.target.value);
  };
  // hangeDeclineBtnStatus;
  const handleDecline = () => {
    setBtnShow(true);
  };
  const handleCancelDecline = () => {
    setBtnShow(false);
  };

  const { data: getInvoiceDetails, reFetch: reFetchGetInvoiceDetails } =
    useFetch(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/serviceProviderInvoices/${actionID}/tickets`,
      token
    );

  if (!getInvoiceDetails[0]?.serviceProvider) {
    return <LoadComponents />;
  }

  const hangeApproveReview = () => {
    setbtnApproveLoading(true);
    const isJSON = null;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    isJSON ? null : myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      status: "approved",
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/serviceProviderInvoices/${getInvoiceDetails[0]?.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setbtnApproveLoading(false);
        if (res?.status === "success") {
          hangeApproveTicketStatus();
        }
        if (res?.status === "fail") {
          warningMessage(`${res?.message}`);
        }
      })
      .catch((error) => {
        setbtnApproveLoading(false);
      });
  };

  const hangeDeclineReview = (e) => {
    e.preventDefault();
    setBtnDeclineLoading(true);
    const isJSON = null;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    isJSON ? null : myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      status: "rejected",
      declineReason: declineReason,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/serviceProviderInvoices/${getInvoiceDetails[0]?.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setBtnDeclineLoading(false);
        if (res?.status === "success") {
          hangeDeclineTicketStatus();
        }
        if (res?.status === "fail") {
          warningMessage(`${res?.message}`);
        }
      })
      .catch((error) => {
        setBtnDeclineLoading(false);
      });
  };

  const hangeApproveTicketStatus = () => {
    const isJSON = null;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    isJSON ? null : myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      status: "accepted",
      reopenReason: "",
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // `
    //gh.prop.faspro24.com/api/v1/maintenanceTickets/${actionID}/reopenTicket`,
    https: fetch(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceTickets/${actionID}/acceptTicket`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setbtnApproveLoading(false);
        if (res?.status === "success") {
          successMessage("Quotation successfully approved");
          reFetchMaintenanceTickets();
          setActionModal(false);
        }
        if (res?.status === "fail") {
          warningMessage(`${res?.message}`);
        }
      })
      .catch((error) => {
        setbtnApproveLoading(false);
      });
  };

  const hangeDeclineTicketStatus = () => {
    const isJSON = null;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    isJSON ? null : myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      status: "declined",
      declineReason: declineReason,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceTickets/${actionID}/declinedTicket`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setBtnDeclineLoading(false);
        if (res?.status === "success") {
          successMessage("Quotattion successfully declined");
          reFetchMaintenanceTickets();
          setActionModal(false);
        }
        if (res?.status === "fail") {
          warningMessage(`${res?.message}`);
        }
      })
      .catch((error) => {
        setBtnDeclineLoading(false);
      });
  };

  return (
    <div>
      {!btnShow ? (
        <>
          <div className="d-flex justify-content-center">
            <div className="mx-3">
              <button onClick={hangeApproveReview} className="btn btn-success">
                {!btnApproveLoading ? (
                  <>
                    <i className="fas fa-check-circle"></i>
                    <b className="mx-2">Approve</b>
                  </>
                ) : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" />{" "}
                    Loading...
                  </span>
                )}
              </button>
            </div>
            <div className="mx-3">
              <button onClick={handleDecline} className="btn btn-danger">
                <>
                  <i className="fa fa-times-circle"></i>
                  <b className="mx-2">Decline</b>
                </>
              </button>
            </div>
          </div>
        </>
      ) : (
        <form onSubmit={hangeDeclineReview}>
          <label>Give a Reason: </label>
          <div>
            <textarea
              className="form-control mb-3"
              name="description"
              value={declineReason}
              onChange={handleReasonChange}
              rows={10}
            />
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-success mx-1">
              {!btnDeclineLoading ? (
                <>
                  <b className="mx-2">Submit</b>
                </>
              ) : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading...
                </span>
              )}
            </button>
            <button
              type="button"
              onClick={handleCancelDecline}
              className="btn btn-danger mx-1"
            >
              Cancel
            </button>
          </div>
        </form>
      )}
      {btnShow ? null : (
        <AvForm className="form-horizontal mt-4">
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="serviceProvider"
                  value={`${getInvoiceDetails[0]?.serviceProvider?.firstName} ${getInvoiceDetails[0]?.serviceProvider?.lastName}`}
                  label="Sent By"
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="price"
                  label="Total Quotation Amount"
                  value={`GH₵ ${getInvoiceDetails[0]?.amount}`}
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <AvField
                  name="TicketRef"
                  // value={getInvoiceDetails[0]?.ticketId?.ticketNumber}
                  value={ticketRef}
                  label="Ticket Reference Number"
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </Col>
            {getInvoiceDetails[0]?.description ? (
              <Col md={12}>
                <div className="mb-3">
                  <AvField
                    name="notes"
                    value={getInvoiceDetails[0]?.description}
                    label="Additional Information"
                    className="form-control"
                    type="textarea"
                    rows={6}
                    disabled
                  />
                </div>
              </Col>
            ) : null}
            <Col md={12}>
              <div className="mb-3">
                <label>Attached Quotation</label>

                <iframe
                  src={getInvoiceDetails[0]?.attachedInvAndQuote}
                  width="100%"
                  height="500px"
                ></iframe>
              </div>
            </Col>
          </Row>
        </AvForm>
      )}
    </div>
  );
}

export default ViewInvoiceForm;
