import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  Card,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useStore1Selector } from "./../../index";
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumb from "../../components/Common/Breadcrumb"
import MetaTagComp from "../../components/Common/MetaTags"
import { loginUser } from "../../Redux/Slices/userSlice";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddNotice({ t }) {
  const userDet = useStore1Selector(loginUser)
  const propertyOwnerId = userDet?.original?.userDetails?.OwnerID
  const [state, setState] = useState()
  const [myProperty, setMyProperty] = useState();
  const [value, setValue] = useState();

  const notify = () => toast('Successful send the notice to a tenant', { position: toast.POSITION.TOP_RIGHT });

  const handleValidSubmit = (e, v) => {

    const formdata = new FormData();
    formdata.append("UnitID", "1");
    formdata.append("TenantID", "1");
    formdata.append("PropertyID", value);
    formdata.append("Description", v.notice);
    formdata.append("Date", v.date);
    formdata.append("Issuer", v.issuer);
    formdata.append("Document", state);
    formdata.append("Title", v.title);
    formdata.append("OwnerID", "1");

    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("http://prop.faspro24.com/api/notice/NewNotice", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        if (result.status === "success") {
          notify();
        }
      })
      .catch(error => console.log('error', error.message));
  }

  const onFileChange = (e) => {
    setState(e.target.files[0]);
  }

  function getProperty() {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://prop.faspro24.com/api/property/GetPropertyList/${propertyOwnerId}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setMyProperty(result?.data)
      })
      .catch(error => console.log('error', error));
  }
  useEffect(() => {
    getProperty();
  }, []);

  const selectHandle = (e) => {
    setValue(e.target.value)
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Notice" />
        <Breadcrumb default="Property" title="Notice 
        note" />
        <Container fluid>
          <div className="page-title-box" >
            <CardBody data-aos="fade-bottom">

              <Row className="align-items-center mb-5">
                <div>
                  <Link to="/my-notices" className="btn login-btn w-md waves-effect waves-light" type="submit"> Back </Link>
                </div>
              </Row>

              <Card className="bd-rds" >
                <div className="TenantHeader bd-rds-header ">
                  <h5>Add Notice</h5>
                </div>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={12} lg={12} xl={12}>
                      <AvForm className="form-horizontal mt-4" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>

                        <Row>
                          <Col md={12} lg={12} xl={12}>
                            <Row>

                              <Col md={6}>
                                <Row>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <AvField name="categories" value={value} onChange={selectHandle} label="Assign Property" className="form-control" type="select" required>
                                        <option> Select... </option>
                                        {
                                          myProperty?.map((prop, id) => {
                                            return (
                                              <option key={id} value={prop?.PropertyID}> {prop?.Name} </option>
                                            )
                                          })
                                        }
                                      </AvField>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <AvField name="assign_unit" label="Assign Unit" className="form-control" type="select">
                                        <option> Select... </option>
                                        <option> TNG </option>
                                      </AvField>
                                    </div>
                                  </Col>

                                </Row>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField name="title" label="Title" className="form-control" type="text" required />
                                </div>
                              </Col>


                              {/* <Col md={6}>
                                <div className="mb-3">
                                  <AvField name="date" label="Choose Date" type="date" required />
                                </div>
                              </Col> */}
                            </Row>
                            {/* <Row>
                             
                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField name="issuer" label="Issuer" type="text" required
                                  />
                                </div>
                              </Col>
                            </Row> */}

                            <div className="mb-3">
                              <AvField name="name" label="Attach a document" type="file" className="form-control" onChange={onFileChange} />
                            </div>

                            <div className="mb-3">
                              <AvField className="form-control" type="textarea" label="Description" rows="3" name="notice" errorMessage="This value is required." validate={{ required: { value: true } }} />
                            </div>

                          </Col>
                        </Row>

                        <div className="newTenantSubmitBtn">
                          <button className="btn login-btn w-md waves-effect waves-light" type="submit">Submit</button>
                        </div>

                      </AvForm>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardBody>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
AddNotice.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(AddNotice))
