import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, Col, Row, CardBody, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { useStore1Selector } from "../../../../../index";
import { loginUser } from "../../../../../Redux/Slices/userSlice";
import usePost from "../../../../../hooks/usePost";
import useFetch from "../../../../../hooks/useFecth";
import Status from "../../../../../components/Status";

function GetInvoiceForm({ invoiceId, unitID, reFetchInvoice, closeModal }) {
  // const InvoiceStatus = ["paid", "pending", "overdue"];
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending: pendingData } = usePost();
  const [selectStatus, setSelectStatus] = useState();

  const { data: getInvoiceData } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/invoices/${invoiceId}/${unitID}`,
    token
  );

  useEffect(() => {
    if (getInvoiceData && getInvoiceData?.status) {
      setSelectStatus(getInvoiceData?.status);
    }
  }, [getInvoiceData]);

  console.log(" getInvoiceData : ", getInvoiceData._id);
  console.log(" selectStatus : ", selectStatus);

  const handleBtnUpdate = (e, v) => {
    e.preventDefault();
    const Method = "PATCH",
      endPoint = `invoices/${getInvoiceData._id}`,
      isJSON = true;
    const raw = JSON.stringify({
      status: selectStatus,
      // status: "overdue",
    });
    execute(
      endPoint,
      raw,
      Method,
      "Invoice successfully updated",
      token,
      isJSON
    );
    reFetchInvoice();
    closeModal(false);
  };
  if (pendingData) {
    setTimeout(() => {
      reFetchInvoice();
    }, 2000);
  }

  const handleSelectStatus = (event) => {
    setSelectStatus(event.target.value);
  };

  return (
    <React.Fragment>
      <CardBody>
        <AvForm className="form-horizontal">
          <Row>
            <div className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-12">
                      <div className="invoice-title">
                        <h4 className="float-end font-size-16">
                          <strong>{getInvoiceData?.invoiceNumber}</strong>
                        </h4>
                        {/* <h3 className="mt-0">
                          <img src={logoSm} alt="logo" height="24" />
                        </h3> */}
                      </div>
                      <hr />

                      <Row>
                        <div className="d-flex justify-content-between">
                          <div className="mt-4">
                            <address>
                              <div className="mb-2">
                                <label htmlFor="selectOption" className="mx-2">
                                  Status:
                                </label>
                                <select
                                  id="selectOption"
                                  className={
                                    selectStatus === "paid"
                                      ? "btn btn-outline-success"
                                      : selectStatus === "overdue"
                                      ? "btn btn-outline-danger"
                                      : selectStatus === "pending"
                                      ? "btn btn-outline-warning"
                                      : "btn btn-outline-dark"
                                  }
                                  onChange={handleSelectStatus}
                                >
                                  <option>{getInvoiceData?.status}</option>
                                  {Status.map((item, i) => {
                                    if (getInvoiceData?.status !== item) {
                                      return (
                                        <option value={item} key={i}>
                                          {item}
                                        </option>
                                      );
                                    }
                                  })}
                                </select>
                              </div>
                            </address>
                          </div>

                          <div>
                            <address>
                              <strong>Unit Name: </strong>
                              <span>{getInvoiceData?.unitId?.unitName}</span>
                              <br />
                              <strong>Unit Number: </strong>
                              <span>{getInvoiceData?.unitId?.unitNumber}</span>
                              <br />
                              <strong>Month: </strong>
                              <span>{getInvoiceData?.month}</span>
                              <br />
                            </address>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-12">
                      <div>
                        <div className="p-2">
                          <h3 className="font-size-16">
                            <strong>Invoice summary</strong>
                          </h3>
                        </div>
                        <div className="">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <td>
                                    <strong>Description</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Amount</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong></strong>
                                  </td>
                                  <td className="text-end">
                                    <strong>Total</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {getInvoiceData?.extraCharges ? (
                                  <tr>
                                    <td>Extra charges</td>
                                    <td className="text-center">
                                      GH₵ {getInvoiceData?.extraCharges}
                                    </td>
                                    <td className="text-center"></td>
                                    <td className="text-end">
                                      GH₵
                                      {getInvoiceData?.extraCharges}
                                    </td>
                                  </tr>
                                ) : null}
                                {getInvoiceData?.utilityCharges ? (
                                  <tr>
                                    <td>Utility charges</td>
                                    <td className="text-center">
                                      GH₵
                                      {getInvoiceData?.utilityCharges}
                                    </td>
                                    <td className="text-center"></td>
                                    <td className="text-end">
                                      GH₵
                                      {getInvoiceData?.utilityCharges}
                                    </td>
                                  </tr>
                                ) : null}

                                <tr>
                                  <td>Rental Amount</td>
                                  <td className="text-center">
                                    GH₵
                                    {getInvoiceData?.rentalAmount}
                                  </td>
                                  <td className="text-center"></td>
                                  <td className="text-end">
                                    GH₵
                                    {getInvoiceData?.rentalAmount}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="thick-line"></td>
                                  <td className="thick-line"></td>
                                  <td className="thick-line text-center">
                                    <strong>Subtotal</strong>
                                  </td>
                                  <td className="thick-line text-end">
                                    GH₵
                                    {getInvoiceData?.rentalAmount}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="no-line"></td>
                                  <td className="no-line"></td>
                                </tr>
                                <tr>
                                  <td className="no-line"></td>
                                  <td className="no-line"></td>
                                  <td className="no-line text-center">
                                    <strong>Total</strong>
                                  </td>
                                  <td className="no-line text-end">
                                    <h4 className="m-0">
                                      GH₵
                                      {getInvoiceData?.totalAmount}
                                    </h4>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className="d-print-none">
                            <div className="d-flex justify-content-center">
                              <button
                                onClick={handleBtnUpdate}
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Send Invoice
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </AvForm>
      </CardBody>
    </React.Fragment>
  );
}

GetInvoiceForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(GetInvoiceForm));
