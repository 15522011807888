import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import { withTranslation } from "react-i18next";
import useFecth from "../../../hooks/useFecth";
import usePost from "../../../hooks/usePost";
import io from "socket.io-client";
const socket = io.connect("https://squid-app-hsedq.ondigitalocean.app");

const NotificationDropdown = (props) => {
  const userDet = useStore1Selector(loginUser);
  const { execute, pending, data: postData } = usePost();
  const token = userDet?.token;
  const history = useHistory();
  const roles = userDet?.data?.roles[0];
  const userId = userDet?.data?._id;

  const portfolioPropId = userDet?.OwnerID;
  const [menu, setMenu] = useState(0);
  const [tickets, setTickets] = useState();

  useEffect(() => {
    socket.emit("register_service_provider", userId);
  }, [userId]);

  const {
    data: getServiceProviderNotifications,
    reFetch: reFetchGetServiceProviderNotifications,
  } = useFecth(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/notifications/${userId}`,
    token
  );
  const [liveServiceProviderNotifications, setLiveNotifications] = useState([]);

  useEffect(() => {
    const handleNewTicket = (new_ticket) => {
      showNotification({
        title: "Faspro 24",
        message: "New ticket has been created !!!",
        duration: 5000,
        icon: logo,
        native: true,
        onClick: () => {
          alert("/request-service");
        },
      });

      setLiveNotifications((prevNotifications) => [
        ...prevNotifications,
        new_ticket,
      ]);
    };

    socket.on("receive_new_ticket", handleNewTicket);
    reFetchGetServiceProviderNotifications();
    return () => {
      socket.off("receive_new_ticket", handleNewTicket);
    };
  }, [socket]);

  const allServiceProviderNotifications = [
    ...getServiceProviderNotifications,
    ...liveServiceProviderNotifications,
  ];

  const handleMarkAsRead = (notificationId) => {
    // history.push(`/view-ticket/${ticketId}`);
    const Method = "PUT",
      endPoint = `notifications/${notificationId}`;
    const raw = null;
    execute(endPoint, raw, Method, null, token);
  };

  useEffect(() => {
    reFetchGetServiceProviderNotifications();
  }, [postData?.status]);

  // useEffect(() => {
  //   reFetchGetServiceProviderNotifications();
  // }, [getServiceProviderNotifications]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        {roles === "service_provider" ||
        roles === "service_provider_manager" ? (
          <>
            <DropdownToggle
              className="btn header-item noti-icon waves-effect"
              tag="button"
              id="page-header-notifications-dropdown"
            >
              <i className="mdi mdi-bell-outline"></i>
              <span className="badge bg-danger rounded-pill p-1">
                {allServiceProviderNotifications?.length}
              </span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 notification-menu-width">
              <div className="p-3">
                <Row className="align-items-center">
                  <Col>
                    <h6 className="m-0 font-size-16">
                      {props.t("Notifications")} (
                      {allServiceProviderNotifications?.length})
                    </h6>
                  </Col>
                </Row>
              </div>
              <SimpleBar style={{ height: "230px" }}>
                {allServiceProviderNotifications.map((notification, index) => {
                  return (
                    <Link
                      to="#"
                      className="text-reset notification-item"
                      key={index}
                    >
                      <div className="d-flex">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title bg-warning rounded-circle font-size-16">
                            <i className="mdi mdi-bell-outline"></i>
                          </span>
                        </div>
                        <div className="flex-1">
                          <h6 className="mt-0 mb-1">Requested Service</h6>
                          <div className="font-size-12 text-muted">
                            <p className="mb-1">{notification?.message}</p>
                          </div>
                        </div>
                        <button
                          style={{
                            height: "30px",
                            width: "115px",
                            marginTop: "5px",
                          }}
                          className="btn btn-danger"
                          onClick={() => handleMarkAsRead(notification?.id)}
                        >
                          <p
                            style={{
                              color: "white",
                              fontSize: "12px",
                              alignSelf: "center",
                            }}
                          >
                            MARK AS READ
                          </p>
                        </button>
                      </div>
                    </Link>
                  );
                })}
              </SimpleBar>
            </DropdownMenu>
          </>
        ) : null}
        {/* <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="mdi mdi-bell-outline"></i>
          <span className="badge bg-danger rounded-pill p-1">2</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16">
                  {props.t("Notifications")} (2)
                </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            <Link to="#" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-danger rounded-circle font-size-16">
                    <i className="mdi mdi-bell-outline"></i>
                  </span>
                </div>
                <div className="flex-1">
                  <h6 className="mt-0 mb-1">New Notifications</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">You have 4 unread messages</p>
                  </div>
                </div>
              </div>
            </Link>
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu> */}
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
