import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, Col, Row, CardBody, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { useStore1Selector } from "./../../../../../index";
import { loginUser } from "../../../../../Redux/Slices/userSlice";
// import Loading from "./../../../components/Loading";
import { AiFillCaretRight } from "react-icons/ai";
import usePost from "../../../../../hooks/usePost";

function InvoiceForm({
  unitID,
  propID,
  closeModal,
  unitUtilityTable,
  unitExtraChargeTable,
  unitLease,
  reFetchInvoice,
}) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending } = usePost();
  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    const Method = "POST",
      endPoint = `invoices/${propID}/${unitID}`,
      isJSON = true;
    const raw = "";
    execute(
      endPoint,
      raw,
      Method,
      "Invoice successfully generated",
      token,
      isJSON
    );
    closeModal(false);
  };
  if (pending) {
    setTimeout(() => {
      reFetchInvoice();
    }, 2000);
  }

  return (
    <React.Fragment>
      <CardBody>
        <h4 className="mb-3 bg-success text-white p-1"> UNIT INVOICE </h4>

        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >
          <div className="d-flex justify-content-between align-items-center mb-3 mt-3">
            <Col md={6}>
              <h5 className="text-success">
                {" "}
                <AiFillCaretRight size={28} /> Deposit amount : GH₵
                {unitLease[0]?.rentDeposit}
              </h5>
            </Col>
            <h5 className="text-success">
              {" "}
              <AiFillCaretRight size={28} /> Rent amount : GH₵{" "}
              {unitLease[0]?.rentDeposit}
            </h5>
          </div>

          <h4 className="text-success"> Extra charges : </h4>

          <Row>
            {unitExtraChargeTable?.map((extraCharge, i) => (
              <Col md={6} key={i}>
                <Card>
                  <CardBody>
                    <h2 className="badge bg-danger rounded-pill">{i + 1}</h2>
                    <p>
                      <b> Extra charge Name : </b> {extraCharge?.type}
                    </p>
                    <p>
                      <b> Frequency : </b> {extraCharge?.frequency}
                    </p>
                    <p>
                      <b> Amount : </b>
                      GH₵ {extraCharge?.amount}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          <h4 className="text-success"> Utility charges : </h4>
          <Row>
            {unitUtilityTable?.map((utilityCost, i) => (
              <Col md={4} key={i}>
                <Card>
                  <CardBody>
                    <h2 className="badge bg-primary rounded-pill">{i + 1}</h2>
                    <p>
                      {" "}
                      <b> Name : </b> {utilityCost?.utilityName}
                    </p>
                    <p>
                      {" "}
                      <b>Cost :</b>
                      GH₵{utilityCost?.cost}
                    </p>
                    <p>
                      {" "}
                      <b> Consumption :</b> {utilityCost?.consumption}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <button
            className="btn login-btn w-md waves-effect waves-light"
            type="submit"
          >
            {!pending ? <span className="me-2"> Generate Invoice </span> : null}
            {!pending ? null : (
              <span>
                {" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="me-2"
                />
                Loading...
              </span>
            )}
          </button>
        </AvForm>
      </CardBody>
    </React.Fragment>
  );
}

InvoiceForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(InvoiceForm));
