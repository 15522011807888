import React, { useState, useEffect } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { useStore1Selector } from "./../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import TicketCompareGraph from "./TicketCompareGraph";
import { Years } from "../../../../components/Years";
import { months } from "../../../../components/Month";
import useFecth from "../../../../hooks/useFecth";

function PageMonthlyTicketsCompare() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const [taskYear, setTaskYear] = useState(2023);
  const [taskMonth, setTaskMonth] = useState(null);
  const [totalOpen, setTotalOpen] = useState(null);
  const [totalOverdue, setTotalOverdue] = useState(null);

  const { data: getMonthlyTicket, reFetch: reFetchGetMonthlyTicket } = useFecth(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceReport?month=${taskMonth}&&year=${taskYear}`,
    token
  );

  const handleSelectTicketYear = (e) => {
    setTaskYear(e.target.value);
  };

  const handleSelectTicketMonth = (e) => {
    setTaskMonth(e.target.value);
  };

  useEffect(() => {
    setTotalOpen(getMonthlyTicket[0]?.totalTicketsCreated);
    setTotalOverdue(getMonthlyTicket[0]?.totalOverdueTickets);
  }, [getMonthlyTicket]);

  return (
    <>
      <Card>
        <CardBody className="property_card">
          <div className="h5 d-flex justify-content-between mb-3">
            <div>Created vs Overdue Tickets</div>
            <div className="d-flex">
              <div>
                <select
                  id="selectOption"
                  className="btn btn-dark"
                  value={taskYear}
                  onChange={handleSelectTicketYear}
                >
                  {Years.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="ms-3">
                <select
                  id="selectOption"
                  className="btn btn-primary"
                  value={taskMonth}
                  onChange={handleSelectTicketMonth}
                >
                  {months.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center ">
            <Button className="shadow-none btn btn-outline-info  bg-white">
              <div className="text-info">Created Ticktes</div>
              <span className="m-2 text-info align-items-center fw-bold h4">
                {totalOpen}
              </span>
            </Button>

            <Button className="ms-3 shadow-none btn btn-outline-danger  bg-white">
              <div className="text-danger">Overdue Ticktes</div>
              <span className="m-2 text-danger align-items-center fw-bold h4">
                {totalOverdue}
              </span>
            </Button>
          </div>
          <TicketCompareGraph
            totalOpen={totalOpen}
            totalOverdue={totalOverdue}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default PageMonthlyTicketsCompare;
