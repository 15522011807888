const propFeatures = [
  "Gym",
  "Generator",
  "Solar panel",
  "CCTV",
  "Storage",
  "Pool",
  "Parking facilities",
  "Elevator",
  "Emergency exit",
  "Center cooling",
  "Fire alarm",
  "Balcony",
  "Laundry",
  "Washing line",
  "Fire place",
  "Sauna",
  "Sprinkler systems",
];
export default propFeatures;
