import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, Modal, Button } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
import { ImEye } from "react-icons/im";
import { useStore1Selector } from "./../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
import Loading from "components/Loading";
import ViewUnitDetails from "./components/ViewUnitDetails";
ViewUnitDetails;
import EditUnitDetails from "./components/EditUnitDetails";
import TenantForm from "./components/TenantForm";
import { warningMessage, successMessage } from "../../components/Notifications";
import UnitNotice from "./components/UnitNotice";
import useFetch from "../../hooks/useFecth";
import usePost from "../../hooks/usePost";

function MyUnits({ t }) {
  const { propId, propType } = useParams();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending, status } = usePost();
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [unitID, setUnitID] = useState();
  const [unitEditID, setUnitEditID] = useState();
  let list = [];
  const { data, reFetch } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/units/property_owner/${propId}`,
    token
  );

  function editFunc(Id_unit) {
    setEditModal(true);
    setUnitEditID(Id_unit);
  }

  function deleteFunc(Id_unit) {
    setDeleteModal(true);
    setUnitID(Id_unit);
  }
  data?.forEach((res) => {
    list.push({
      ...res,
      unit_RentAmount: `GH₵ ${res?.RentAmount}`,
      viewBtn: (
        <button className="btn btn-success">
          <Link
            to={`/unit-details/${propId}/${res?._id}/${propType}`}
            className="text-white"
          >
            {t("View")}
          </Link>
        </button>
      ),
      editBtn: (
        <button
          className="btn btn-primary"
          onClick={() => editFunc(`${res?._id}`)}
        >
          {t("Edit")}
        </button>
      ),
      deleteBtn: (
        <button
          className="btn btn-danger"
          onClick={() => deleteFunc(`${res?._id}`)}
        >
          {t("Delete")}
        </button>
      ),
    });
  });

  const deleteUnitFunc = () => {
    const Method = "DELETE",
      endPoint = `units/${unitID}`,
      isJSON = true;
    var formdata = new FormData();
    execute(
      endPoint,
      formdata,
      Method,
      "Unit Successfully Deleted",
      token,
      isJSON
    );
  };
  if (pending) {
    setTimeout(() => {
      reFetch();
    }, 2000);
  }
  const column = [
    { label: "Unit Name", field: "unitName", sort: "asc", width: 150 },
    { label: "Unit Type", field: "unitType", sort: "asc", width: 150 },
    { label: "Unit Number", field: "unitNumber", sort: "asc", width: 150 },
    { label: "Unit Owner", field: "unitOwnerName", sort: "asc", width: 150 },
    { label: "Rent Amount", field: "unit_RentAmount", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const newData = {
    columns: column,
    rows: list.reverse(),
  };

  if (status === "success") {
    setTimeout(() => {
      setDeleteModal(false);
      setEditModal(false);
    }, 2000);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Unit" />
        <Breadcrumb default={t("Property")} title={t("My Units")} />

        <Card.Body>
          <Link
            to={`/property-details/${propId}`}
            className="btn login-btn mb-3"
          >
            {" "}
            {t("Back")}{" "}
          </Link>
          <Container fluid>
            <Row
              className="d-flex justify-content-around align-items-center"
              data-aos="fade-bottom"
            >
              <Card className="bd-rds">
                <Card.Body>
                  <MDBDataTable
                    entries={5}
                    entriesOptions={[5, 10, 50]}
                    responsive
                    bordered
                    striped
                    hover
                    data={newData}
                    fullPagination
                  />
                </Card.Body>
              </Card>
            </Row>
          </Container>
        </Card.Body>

        <Modal
          className="modal-right scroll-out-negative"
          show={editModal}
          onHide={() => setEditModal(false)}
          scrollable
          dialogClassName="full"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("Edit Unit details")}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EditUnitDetails
              unitId={unitEditID}
              closeModal={setEditModal}
              propId={propId}
              refetch={reFetch}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          size="sm"
        >
          <Modal.Body className="text-danger">
            <h4> {t("Are you sure you want to delete this unit ?")} </h4>{" "}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setDeleteModal(false)}>
              {t("Cancel")}
            </Button>
            <Button variant="danger" onClick={deleteUnitFunc}>
              {t("Yes")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}

MyUnits.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(MyUnits));
