import React, { useState, useEffect } from "react";
import { Card, CardBody, Spinner, Badge, Button } from "reactstrap";
import { Modal } from "react-bootstrap";

import { MDBDataTable } from "mdbreact";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";

import useFecth from "../../../hooks/useFecth";
import usePost from "../../../hooks/usePost";

function ClosedTable() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending, data: postData } = usePost();
  const history = useHistory();
  const [reopenID, setReopenID] = useState();
  const [viewModal, setViewModal] = useState(false);
  const userId = userDet?.data?._id;
  const list = [];

  const { data: getMaintenanceTickets, reFetch: reFetchMaintenanceTickets } =
    useFecth(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceTickets/${userId}/serviceProvider`,
      token
    );

  getMaintenanceTickets?.forEach((res) => {
    if (res?.status === "closed") {
      list.push({
        ...res,
        propertyName: res?.propertyId?.propertyName,
        assidnedTo: `${res?.assigned_to?.firstName} ${res?.assigned_to?.lastName}`,
        status:
          res?.status === "closed" ? (
            <>
              <Badge className="rounded-pill bg-success p-2">Closed</Badge>
            </>
          ) : null,
        viewBtn: (
          <button
            className="btn btn-success"
            onClick={() => viewFunc(`${res?._id}`)}
          >
            View
          </button>
        ),
      });
    }
  });

  const column = [
    { label: "Property name", field: "propertyName", sort: "asc", width: 150 },

    { label: "Title Name", field: "subject", sort: "asc", width: 150 },
    // { label: "Ticket Reference", field: "TicketRef", sort: "asc", width: 150 },
    { label: "Status", field: "status", sort: "asc", width: 150 },
    {
      label: "Assign To",
      field: "assidnedTo",
      sort: "asc",
      width: 150,
    },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
  ];

  const dataDB = { columns: column, rows: list.reverse() };

  const viewFunc = (id) => {
    history.push(`/view-ticket/${id}`);
  };

  const reopenFunc = (id) => {
    setReopenID(id);
    setReopenModal(true);
  };

  useEffect(() => {
    if (postData?.status === "success") {
      reFetchMaintenanceTickets();
      setCloseModal(false);
    }
  }, [postData?.status]);

  return (
    <div>
      <Card className="bd-rds">
        <CardBody>
          <div className="d-flex justify-content-end">
            <button className="btn login-btn w-md waves-effect waves-light mb-4">
              <CSVLink data={"dataDb"} separator={";"} className="text-white">
                Download in Excel
              </CSVLink>
            </button>
          </div>
          <MDBDataTable
            entries={5}
            entriesOptions={[5, 10, 50]}
            responsive
            bordered
            striped
            hover
            data={dataDB}
          />
        </CardBody>
      </Card>
    </div>
  );
}

export default ClosedTable;
