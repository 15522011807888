import PropTypes from "prop-types";
import React from "react";
import { Row, Container } from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter, useParams } from "react-router-dom";

function Nav({
  visitor,
  setVisitor,
  visitorIn,
  setVisitorIn,
  visitorOut,
  setVisitorOut,
  visitorOverstay,
  setVisitorOverstay,
}) {
  const handleVisitors = () => {
    setVisitor(true);
    setVisitorIn(false);
    setVisitorOut(false);
    setVisitorOverstay(false);
  };

  const handleVisitorIn = () => {
    setVisitor(false);
    setVisitorIn(true);
    setVisitorOut(false);
    setVisitorOverstay(false);
  };

  const handleVisitorOut = () => {
    setVisitor(false);
    setVisitorIn(false);
    setVisitorOut(true);
    setVisitorOverstay(false);
  };
  const handleVisitorOverStayed = () => {
    setVisitor(false);
    setVisitorIn(false);
    setVisitorOut(false);
    setVisitorOverstay(true);
  };

  return (
    <React.Fragment>
      <Row>
        <Container fluid>
          <button
            onClick={handleVisitors}
            className={
              visitor
                ? "btn bg-white boder-3 border-success text-success me-3 p-3 rounded-3"
                : "btn btn-success me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-portrait me-1"></i> Visitors
          </button>
          <button
            onClick={handleVisitorIn}
            className={
              visitorIn
                ? "btn bg-white boder-3 border-info text-info me-3 p-3 rounded-3"
                : "btn btn-info me-3 p-3 rounded-3"
            }
          >
            <div>
              <i className="fas fa-clipboard me-1"></i>Visitor(s) Currectly In
            </div>
          </button>

          <button
            onClick={handleVisitorOut}
            className={
              visitorOut
                ? "btn bg-white text-dark border border-dark me-3 p-3 rounded-3"
                : "btn btn-dark me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-sign-out-alt"></i> Visitor(s) Currectly Out
          </button>

          <button
            onClick={handleVisitorOverStayed}
            className={
              visitorOverstay
                ? "btn bg-white text-danger border border-danger me-3 p-3 rounded-3"
                : "btn btn-danger me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-hourglass-end"></i> Overstayed Visitor(s)
          </button>
        </Container>
      </Row>
    </React.Fragment>
  );
}

Nav.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Nav));
