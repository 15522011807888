import { check } from "prettier"
import Img_1 from "../../../assets/images/Modules/faspro24_file_management.png"
import Img_2 from "../../../assets/images/Modules/Faspro24_illustrations_assets_management.png"
import Img_3 from "../../../assets/images/Modules/Faspro24_illustrations_booking_management.png"
import Img_7 from "../../../assets/images/Modules/Faspro24_illustrations_property_management.png"
import Img_8 from "../../../assets/images/Modules/Faspro24_illustrations_security_1.png"

const module__arr = [
    {
        id: 3,
        img: Img_2,
        price: "250",
        text: "Increase asset lifespan and manage standard operating procedures.",
        value: "250",
        label: "Asset Management",
        class_css: "activate-color",
        disable: false,
    },
    {
        id: 4,
        img: Img_8,
        price: "180",
        text: "Manage access to your property with a cloud based solution. Review check in/out reports",
        value: "180",
        label: "Security Management",
        class_css: "activate-color",
        disable: false,
    },
    {
        id: 5,
        img: Img_1,
        price: "150",
        text: "Share documents with tenants e.g AGM minutes",
        value: "150",
        label: "File Management",
        class_css: "activate-color",
        disable: false,
    },
    {
        id: 6,
        img: Img_3,
        price: "295",
        text: "Book and manage available property facilities i.e. halls for parties or private functions",
        value: "295",
        label: "Bookings Management",
        class_css: "activate-color",
        disable: false,
    },
    {
        id: 7,
        img: Img_7,
        price: "150",
        text: "Assign properties to property managers or invite your team to FASPRO ",
        value: "5",
        label: "User Management",
        class_css: "activate-color",
        disable: false,
    },

]

export const modules = () => {
    return module__arr
}