import React from 'react';
import { Card, CardBody } from "reactstrap"
import avatar from "../../../assets/images/users/avatar-1.jpg";
import avatar_2 from "../../../assets/images/users/avatar-2.jpg";

function messages() {
    return (
        <div className=" mb-3">
            <Card>
                <CardBody>
                    <div className="d-flex mb-4">
                        <img className="me-3 rounded-circle avatar-sm" src={avatar} alt="faspro24" />
                        <div className="flex-1">
                            <h5 className="font-size-15 m-0"> Jewel </h5>
                            <small className="text-muted">jewel@domain.com</small>
                        </div>
                    </div>
                    <p>TNG</p>
                    <p>
                        Sed elementum turpis eu lorem interdum, sed porttitor eros
                        commodo. Nam eu venenatis tortor, id lacinia diam. Sed
                        aliquam in dui et porta. Sed bibendum orci non tincidunt
                        ultrices. Vivamus fringilla, mi lacinia dapibus
                        condimentum, ipsum urna lacinia lacus, vel tincidunt mi
                        nibh sit amet lorem.
                    </p>
                    <hr />
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <div className="d-flex mb-4">
                        <img className="me-3 rounded-circle avatar-sm" src={avatar_2} alt="faspro24" />
                        <div className="flex-1">
                            <h5 className="font-size-15 m-0"> Alvin </h5>
                            <small className="text-muted">alvin@domain.com</small>
                        </div>
                    </div>

                    <p>Gallagher</p>
                    <p>
                        Sed elementum turpis eu lorem interdum, sed porttitor eros
                        commodo. Nam eu venenatis tortor, id lacinia diam. Sed
                        aliquam in dui et porta. Sed bibendum orci non tincidunt
                        ultrices. Vivamus fringilla, mi lacinia dapibus
                        condimentum, ipsum urna lacinia lacus, vel tincidunt mi
                        nibh sit amet lorem.
                    </p>
                    <hr />
                </CardBody>
            </Card>
        </div>
    )
}

export default messages