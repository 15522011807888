import React, { useState, useEffect } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap';
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
import { MDBDataTable } from "mdbreact"
import { Link, useParams } from 'react-router-dom';
import Loading from 'components/Loading';

function ServiceProviderDetails() {
    const { propId, serviceProId } = useParams()
    const [data, setData] = useState()
    const [servicePro, setServicePro] = useState()
    const Image = data?.Picture === "undefined" ? " " : data?.Picture?.split("/")

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`https://prop.faspro24.com/api/service_provider/GetServiceProviderByServiceProviderID/${serviceProId}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result)
            })
            .catch(error => console.log('error', error));
    }, [])

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`https://prop.faspro24.com/api/ticket/GetTicketsByPropertyID/${propId}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setServicePro(result)
            })
            .catch(error => console.log('error', error));
    }, [])

    if (!Image) {
        return <Loading />
    }

    const column = [
        // { label: "Ticket ID", field: "TicketID", sort: "asc", width: 150 },
        { label: "Unit number", field: "UnitNumber", sort: "asc", width: 150 },
        { label: "Ticket Type", field: "TicketType", sort: "asc", width: 150 },
        { label: "Person who open a tickets", field: "Name", sort: "asc", width: 150 },
        { label: "Ticket description", field: "Description", sort: "asc", width: 150 },
        { label: "Subject", field: "Subject", sort: "asc", width: 150 },
        { label: "Created", field: "created_at", sort: "asc", width: 150 },
    ];

    const dataServicePro = {
        columns: column,
        rows: servicePro,
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTagComp meta_tags="FASPRO 24 | Service provider details" />
                <Breadcrumb default="Property" title="Service provider details" />

                <CardBody>
                    <Link to={`/service-provider/${propId}`} className="btn mb-2 login-btn"> Back </Link>
                    <Card className="bd-rds">
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <div className='img-container'>
                                        <img src={`https://prop.faspro24.com/storage/${Image[1]}`} alt="" className='rounded-1' />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <h5 className='my-3'>Company Details : </h5>
                                    <h6>Company name : {data?.CompanyName} </h6>
                                    <h6>Contact Person : {data?.FirstName} {data?.LastName} </h6>
                                    <h6>Email : {data?.Email}</h6>
                                    <h6>Phone Number : {data?.PhoneNumber} </h6>
                                    <h6>Category : {data?.CategoryName} </h6>

                                    <h5 className='my-3'>Account details : </h5>
                                    <h6>Bank Account Name : {data?.AccountName} </h6>
                                    <h6>Account Number :  {data?.AccountNumber} </h6>
                                    <h6>Account Type :  {data?.AccountType} </h6>
                                    <h6>Branch Name :  {data?.BranchName} </h6>
                                    <h6>Swift code :  {data?.SWIFTCode_IBAN} </h6>

                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <h4> Service provider tickets</h4>

                    <Card className="bd-rds mt-5">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <button className="btn login-btn w-md waves-effect waves-light mb-4 d-flex justify-content-end report-warning">
                                    <CSVLink data={""} separator={";"} className="text-white"> Download in Excel </CSVLink> </button>
                            </div>
                            {
                                !dataServicePro ? null : (
                                    <MDBDataTable entries={5} entriesOptions={[5, 10, 50]} responsive bordered striped hover data={dataServicePro} fullPagination />
                                )
                            }
                        </CardBody>
                    </Card>


                </CardBody>

            </div>
        </React.Fragment>
    )
}

export default ServiceProviderDetails