import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Card, CardBody, Modal } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import Nav from "./Components/Nav";
import { useStore1Selector } from "index";
import { loginUser } from "Redux/Slices/userSlice";

import VisitorPerPropertyAsManager from "./Components/VisitorPerPropertyAsManager";
import VisitorMangerCheckIn from "./Components/VisitorMangerCheckIn";
import VisitorManagerCheckOut from "./Components/VisitorManagerCheckOut";

function VisitorAccess() {
  const userDet = useStore1Selector(loginUser);
  const [visitor, setVisitor] = useState(true);
  const [visitorIn, setVisitorIn] = useState(false);
  const [visitorOut, setVisitorOut] = useState(false);
  const [visitorOverstay, setVisitorOverstay] = useState(false);
  const [viewModel, setViewModel] = useState(false);

  const token = userDet?.token;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | Visitor Access" />
        <Breadcrumb default="Security Manangement" title="Visitor Access" />

        <Nav
          visitor={visitor}
          setVisitor={setVisitor}
          visitorIn={visitorIn}
          setVisitorIn={setVisitorIn}
          visitorOut={visitorOut}
          setVisitorOut={setVisitorOut}
          visitorOverstay={visitorOverstay}
          setVisitorOverstay={setVisitorOverstay}
        />

        <Row className="d-flex justify-content-around align-items-center">
          <CardBody data-aos="fade-bottom">
            <Card className="bd-rds">
              {visitor ? (
                <CardBody>
                  <VisitorPerPropertyAsManager />
                </CardBody>
              ) : null}
              {visitorIn ? <VisitorMangerCheckIn /> : null}
              {visitorOut ? <VisitorManagerCheckOut /> : null}
              {visitorOverstay ? <VisitorPerPropertyAsManager /> : null}
            </Card>
          </CardBody>
        </Row>

        <Modal
          isOpen={viewModel}
          toggle={() => setViewModal(false)}
          scrollable={true}
        >
          <div className="modal-header">
            <h4 className="modal-title"> View details </h4>
            <button
              type="button"
              onClick={() => setViewModal(false)}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <CardBody>
            <p> Description </p>
          </CardBody>

          <div className="modal-body">
            <button
              onClick={() => setViewModal(false)}
              className="btn btn-danger w-md waves-effect waves-light"
              type="submit"
            >
              Close
            </button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
}

VisitorAccess.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(VisitorAccess));
