import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  CardTitle,
  CardBody,
  Form,
  Spinner,
  Button,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useStore1Selector } from "../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import usePost from "../../../../hooks/usePost";

function SendForm({ ticketId, reFetchGetRequestTickets, setTicketModal }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const userId = userDet?.data?.id;
  const {
    execute: executeTicket,
    pending: pendingTicket,
    data: postDataTicket,
  } = usePost();
  const [attachedInvoice, setAttachedInvoice] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);

  const handleValidSubmit = (e, v) => {
    e.preventDefault();
    setBtnLoad(true);
    const isJSON = true;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    isJSON ? null : myHeaders.append("Content-Type", "application/json");

    var formdata = new FormData();
    // formdata.append("type", "invoice");
    formdata.append("type", "quotation");
    formdata.append("description", v.description);
    formdata.append("serviceProvider", userId);
    formdata.append("attachedInvAndQuote", attachedInvoice);
    formdata.append("amount", v.amount);
    formdata.append("status", "sent");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/serviceProviderInvoices/${ticketId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setBtnLoad(false);
        if (res?.status === "success") {
          handleTicketUpdate();
        }
      })
      .catch((error) => {
        setBtnLoad(false);
      });
  };

  const handleTicketUpdate = () => {
    const Method = "PATCH",
      endPoint = `maintenanceTickets/${ticketId}`,
      isJSON = null;
    const raw = JSON.stringify({
      status: "sent",
    });
    executeTicket(
      endPoint,
      raw,
      Method,
      "Quotation successfully sent",
      token,
      isJSON
    );
  };

  useEffect(() => {
    if (postDataTicket?.status === "success") {
      setBtnLoad(false);
      reFetchGetRequestTickets();
      setTicketModal(false);
    }
  }, [postDataTicket?.status]);

  const handleAttachment = (event) => {
    const file = event.target.files[0];
    setAttachedInvoice(file);
  };

  return (
    <>
      <AvForm
        className="form-horizontal mt-2"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        {/* <h4>Upload proof</h4> */}

        <Row>
          <Col md={12}>
            <AvField
              name="amount"
              label="Total quotation amount"
              className="form-control mb-2"
              type="number"
            />
          </Col>
          <Col md={12}>
            <AvField
              name="Attachment"
              label="Attachment file "
              className="form-control mb-2"
              type="file"
              onChange={handleAttachment}
            />
          </Col>
          <Col md={12}>
            <AvField
              name="description"
              label="Addition notes"
              className="form-control mb-2"
              type="textarea"
              rows={6}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end mt-3">
          <button
            className="mx-3 btn login-btn w-md waves-effect waves-light"
            type="submit"
          >
            {!btnLoad ? (
              "Submit"
            ) : (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="me-2"
                />
                Loading...
              </>
            )}
          </button>

          <Button
            className="bg-danger text-white"
            onClick={() => setTicketModal(false)}
          >
            Close
          </Button>
        </div>
      </AvForm>
    </>
  );
}

export default SendForm;
