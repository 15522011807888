import { BsFillHouseFill } from 'react-icons/bs';

export const Finance = [
    {
        id: 1,
        name: "Total Expenses",
        expense: 2000,
        color: "report-success",
        icon: BsFillHouseFill,
    },
    {
        id: 2,
        name: "Maintenance",
        expense: 1000,
        color: "report-warning",
        icon: BsFillHouseFill,
    },
    {
        id: 3,
        name: "Assets",
        expense: 800,
        color: "report-primary",
        icon: BsFillHouseFill,
    },
    {
        id: 4,
        name: "...",
        expense: 200,
        color: "report-success",
        icon: BsFillHouseFill,
    },

]