import React, { useState, useEffect } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { useStore1Selector } from "./../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import TicketGraph from "./TicketGraph";
import { Years } from "../../../../components/Years";
import { months } from "../../../../components/Month";
import useFecth from "../../../../hooks/useFecth";

function PageTaskTiming() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const [totalOpen, setTotalOpen] = useState(null);
  const [totalPending, setTotalPending] = useState(null);
  const [totalClose, setTotalClose] = useState(null);
  const [totalOverdue, setTotalOverdue] = useState(null);
  const [taskYear, setTaskYear] = useState(2023);
  const [taskMonth, setTaskMonth] = useState(null);

  const { data: getMonthlyTicket, reFetch: reFetchGetMonthlyTicket } = useFecth(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceReport?month=${taskMonth}&&year=${taskYear}`,
    token
  );

  useEffect(() => {
    setTotalOpen(getMonthlyTicket[0]?.totalTicketsCreated);
    setTotalPending(getMonthlyTicket[0]?.totalTicketsPending);
    setTotalClose(getMonthlyTicket[0]?.totalTicketsClosed);
    setTotalOverdue(getMonthlyTicket[0]?.totalOverdueTickets);
  }, [getMonthlyTicket]);

  const handleSelectTicketYear = (e) => {
    setTaskYear(e.target.value);
  };

  const handleSelectTicketMonth = (e) => {
    setTaskMonth(e.target.value);
  };

  return (
    <>
      <Card>
        <CardBody className="property_card">
          <div className="h5 d-flex justify-content-between mb-3">
            <div>Monthly Ticket Status Analysis</div>
            <div className="d-flex">
              <div>
                <select
                  id="selectOption"
                  className="btn btn-dark"
                  value={taskYear}
                  onChange={handleSelectTicketYear}
                >
                  {Years.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="ms-3">
                <select
                  id="selectOption"
                  className="btn btn-primary"
                  value={taskMonth}
                  onChange={handleSelectTicketMonth}
                >
                  {months.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between ">
            <Button className="shadow-none btn btn-outline-info  bg-white">
              <div className="text-info">Open Ticktes</div>
              <span className="m-2 text-info align-items-center fw-bold h4">
                {totalOpen}
              </span>
            </Button>
            <Button className="shadow-none btn btn-outline-warning  bg-white">
              <div className="text-warning">Pending Ticktes</div>
              <span className="m-2 text-warning align-items-center fw-bold h4">
                {totalPending}
              </span>
            </Button>
            <Button className="shadow-none btn btn-outline-success  bg-white">
              <div className="text-success">Closed Ticktes</div>
              <span className="m-2 text-success align-items-center fw-bold h4">
                {totalClose}
              </span>
            </Button>
            <Button className="shadow-none btn btn-outline-danger  bg-white">
              <div className="text-danger">Overdue Ticktes</div>
              <span className="m-2 text-danger align-items-center fw-bold h4">
                {totalOverdue}
              </span>
            </Button>
          </div>
          <TicketGraph
            totalOpen={totalOpen}
            totalPending={totalPending}
            totalClose={totalClose}
            totalOverdue={totalOverdue}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default PageTaskTiming;
