import React from "react"
import { Col, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { countryList } from "../../../components/Countries";

function Stand_Alone_PropertyForm() {

  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    console.log(values)

    var formdata = new FormData();
    formdata.append("Name", "Timbuktu");
    formdata.append("Address", "4th street");
    formdata.append("OwnerID", "1");
    formdata.append("Units", "2");
    formdata.append("PropertyCode", "JKD5065");
    formdata.append("PropertyType", "Estate");
    formdata.append("Picture", "");
    formdata.append("ManagingAgentID", "");
    formdata.append("EstateID", "");

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("http://prop.faspro24.com/api/property/NewProperty?Name=Village Valencia&Address=93 on Third&OwnerID=1&Units=5&PropertyCode=JKD5065&PropertyType=Complex&Picture&ManagingAgentID&EstateID", requestOptions)
      .then(response => response.json())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>
          <Row>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField name="name" label="First Name" className="form-control" type="text" required />
                  </div>
                </Col>

                <Col md={6}>
                  <div className="mb-3">
                    <AvField name="lastName" label="Last Name" className="form-control" type="text" required />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField name="phoneNumber" label="Phone Number" className="form-control" type="number" required />
                  </div>
                </Col>

                <Col md={6}>
                  <div className="mb-3">
                    <AvField name="email" label="Email Address" className="form-control" type="text" required />
                  </div>
                </Col>
              </Row>

              <div className="mb-3">
                <AvField name="PropertyCode" label="Property Code" className="form-control" type="text" required />
              </div>

              <div className="mb-3">
                <AvField name="pic" label="Upload Property Image(Font 600 x 400)" className="form-control" type="file" required />
              </div>
            </Col>

            <Col md={6}>
              <div className="mb-3">
                <AvField name="buildingName" label="Building Name / House Name" className="form-control" type="text" />
              </div>

              <div className="mb-3">
                <AvField name="address" label="Address" className="form-control" type="text" required />
              </div>

              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField name="city" label="City" className="form-control" type="text" required />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField name="Country" label="Country" className="form-control" type="select" required>
                      {countryList.map(country => (<option> {country} </option>))}
                    </AvField>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField name="state" label="State/Province" className="form-control" type="text" required />
                  </div>
                </Col>

                <Col md={6}>
                  <div className="mb-3">
                    <AvField name="Suburb" label="Suburb" className="form-control" type="text" required />
                  </div>
                </Col>
              </Row>

              <div className="mb-3">
                <AvField name="postalCode" label="Postal Code" className="form-control" type="text" required />
              </div>

            </Col>
          </Row>

          <div className="col-12">
            <button className="btn login-btn w-md waves-effect waves-light" type="submit">  Submit  </button>
          </div>

        </AvForm>
      </div>
    </React.Fragment>
  )
}

export default Stand_Alone_PropertyForm