import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row, CardBody, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter, Link, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "react-phone-input-2/lib/bootstrap.css";

import { useStore1Selector } from "./../../../../../index";
import { loginUser } from "../../../../../Redux/Slices/userSlice";
import usePost from "../../../../../hooks/usePost";
import useFetch from "../../../../../hooks/useFecth";
import { UtilitiesTypes } from "../../../../../components/UtilitiesTypes";

function UtilitiesEditForm({ propID, unitID, utilityID, closeModal }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { data } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/utilities/${utilityID}/${unitID}`,
    token
  );

  const { execute, pending } = usePost();
  const [Rate, setRate] = useState();
  const [consumption, setConsumption] = useState();

  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    // setIloadBtn(true);

    // const formdata = new FormData();
    // formdata.append("UnitID", unitID);
    // formdata.append("UtilityName", values.utilityName);
    // formdata.append("Rate", values.rate);
    // formdata.append("Consumtion", values.consumption);
    // formdata.append("UtilityCost", values.utilityCost);

    // const requestOptions = {
    //   method: "POST",
    //   body: formdata,
    //   redirect: "follow",
    // };

    // fetch("https://prop.faspro24.com/api/utility/NewUtility", requestOptions)
    //   .then((response) => response.json())
    //   .then((result) => {
    //     if (result.status === "success") {
    //       successMessage("Successfully add the utility ");
    //       setIloadBtn(false);
    //       closeModal(false);
    //       window.setTimeout(() => {
    //         location.reload();
    //       }, 3000);
    //     }
    //     if (result.status === "fail") {
    //       warningMessage("Something went wrong try again");
    //       setIloadBtn(false);
    //       closeModal(false);
    //     }
    //   })
    //   .catch((error) => {
    //     warningMessage("Something went wrong try again");
    //     setIloadBtn(false);
    //     closeModal(false);
    //   });
  };

  function handleChange(e) {
    setRate(e.target.value);
  }
  function handleChange2(e) {
    setConsumption(e.target.value);
  }
  // console.log("data....", data);
  return (
    <React.Fragment>
      <CardBody>
        <Row className="align-items-center">
          <Col md={12} lg={12} xl={12}>
            <AvForm
              className="form-horizontal mt-4"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <div className="mb-3">
                <AvField
                  type="select"
                  id="name"
                  name="utilityName"
                  value={data?.type}
                  className="form-control"
                  label="Utility Name"
                >
                  <option> Select... </option>
                  <option> Generator </option>
                  <option> Cleaning </option>
                  <option> Electricity </option>
                  <option> Garbage </option>
                  <option> Water </option>
                  <option> Other </option>
                </AvField>
              </div>

              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="rate"
                      value={data?.rate}
                      label="Rate"
                      className="form-control"
                      type="number"
                      onChange={handleChange}
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="consumption"
                      value={data?.consumption}
                      label="consumption"
                      className="form-control"
                      type="number"
                      onChange={handleChange2}
                    />
                  </div>
                </Col>
              </Row>

              <div className="mb-3">
                <AvField
                  value={Rate * consumption}
                  name="utilityCost"
                  label="Utility cost"
                  className="form-control"
                  type="number"
                  disabled
                />
              </div>

              <div className="text-start mt-5">
                <button
                  className="btn login-btn  waves-effect waves-light"
                  type="submit"
                >
                  {!pending ? <span className="me-2">Submit</span> : null}
                  {!pending ? null : (
                    <span>
                      <Spinner as="span" animation="border" size="sm" />
                      Loading...
                    </span>
                  )}
                </button>
              </div>
            </AvForm>
          </Col>
        </Row>
      </CardBody>
    </React.Fragment>
  );
}

UtilitiesEditForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UtilitiesEditForm));
