import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Card, CardBody, Button, Badge, Spinner, Modal } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";
import Nav from "./components/Nav";
import { useStore1Selector } from "index";
import { loginUser } from "Redux/Slices/userSlice";

import CreatedTable from "./components/CreatedTable";
import TenantTable from "./components/TenantTable";
import PendingTable from "./components/PendingTable";
import ClosedTable from "./components/ClosedTable";
import OverdueTable from "./components/OverdueTable";

function Tickets() {
  const [createTicket, setCreateTicket] = useState(true);
  const [tenantTicket, setTenantTicket] = useState(false);
  const [pendingTicket, setPendingTicket] = useState(false);
  const [clodedTicket, setClodedTicket] = useState(false);
  const [overdueTicket, setOverdueTicket] = useState(false);

  const userDet = useStore1Selector(loginUser);

  const [viewModel, setViewModal] = useState(false);

  const token = userDet?.token;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | My tickets" />
        <Breadcrumb default="Maintenance Manangement" title="My tickets" />

        <Nav
          createTicket={createTicket}
          setCreateTicket={setCreateTicket}
          tenantTicket={tenantTicket}
          setTenantTicket={setTenantTicket}
          pendingTicket={pendingTicket}
          setPendingTicket={setPendingTicket}
          clodedTicket={clodedTicket}
          setClodedTicket={setClodedTicket}
          overdueTicket={overdueTicket}
          setOverdueTicket={setOverdueTicket}
        />
        <Row className="d-flex justify-content-around align-items-center">
          <CardBody data-aos="fade-bottom">
            <Card className="bd-rds">
              <CardBody>
                {createTicket ? <CreatedTable /> : null}
                {tenantTicket ? <TenantTable /> : null}
                {pendingTicket ? <PendingTable /> : null}
                {clodedTicket ? <ClosedTable /> : null}
                {overdueTicket ? <OverdueTable /> : null}
              </CardBody>
            </Card>
          </CardBody>
        </Row>

        <Modal
          isOpen={viewModel}
          toggle={() => setViewModal(false)}
          scrollable={true}
        >
          <div className="modal-header">
            <h4 className="modal-title"> View details </h4>
            <button
              type="button"
              onClick={() => setViewModal(false)}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <CardBody>
            <p> Description </p>
          </CardBody>

          <div className="modal-body">
            <button
              onClick={() => setViewModal(false)}
              className="btn btn-danger w-md waves-effect waves-light"
              type="submit"
            >
              Close
            </button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
}

Tickets.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Tickets));
