import React, { useState, useEffect } from "react";
import { Row, Card, CardBody } from "reactstrap";

import { CSVLink } from "react-csv";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import useCustomQuery from "../../../hooks/useCustomQuery";

import PropertyVisitorTable from "./PropertyVisitorTable";

function VisitorPerPropertyAsManager() {
  const userDet = useStore1Selector(loginUser);
  const userId = userDet?.data?._id;
  const token = userDet?.token;

  const [propertyID, setPropertyID] = useState(null);

  const {
    data: getMyProperties,
    error: errorGetMyProperties,
    isLoading: isLoadingGetMyProperties,
  } = useCustomQuery(
    ["myPropertiesAsManager", userId],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/property_owner?fieldName=teamId`,
    token
  );

  console.log("getMyProperties...", getMyProperties);

  const [selectedProperty, setSelectedProperty] = useState(null);

  const handlePropertyNameChange = (e) => {
    if (e.target.value) {
      setPropertyID(e.target.value);
    }
  };

  return (
    <div>
      <Row className="d-flex justify-content-around align-items-center">
        <CardBody data-aos="fade-bottom">
          <div className="d-flex justify-content-between">
            <div className="mb-3  d-flex">
              <div className="d-flex align-items-center">
                <b>Property Name : </b>
              </div>
              <select
                id="selectOption"
                className="btn btn-primary mx-2"
                value={selectedProperty}
                onChange={handlePropertyNameChange}
              >
                <option value="">Select...</option>
                {getMyProperties?.map((item, index) => {
                  return (
                    <option value={item?._id} key={index}>
                      {item?.propertyName}
                    </option>
                  );
                })}
              </select>
            </div>
            <button className="btn login-btn w-md waves-effect waves-light mb-2">
              <CSVLink data={"dataDb"} separator={";"} className="text-white">
                Download in Excel
              </CSVLink>
            </button>
          </div>

          <Card className="bd-rds">
            <CardBody>
              {propertyID && (
                <PropertyVisitorTable propertyID={propertyID} token={token} />
              )}
            </CardBody>
          </Card>
        </CardBody>
      </Row>
    </div>
  );
}

export default VisitorPerPropertyAsManager;
