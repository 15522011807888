import PropTypes from "prop-types"
import React, { useState } from 'react'
import { Container, Row, Card, CardBody } from "reactstrap";
import { MDBDataTable } from "mdbreact"
import { withRouter, Link, useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { CSVLink } from "react-csv";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { properties_Obj } from '../../APIs/Properties'

function LeaseTable() {

  const [dataDb, setData] = useState(properties_Obj());

  var requestOptions = {
    method: 'POST',
    redirect: 'follow'
  };

  fetch("http://127.0.0.1:8000/api/leases/GetLeaseList", requestOptions)
    .then(response => response.json())
    .then(result => setData(result.data))
    .catch(error => console.log('error', error));

  const column = [
    { label: "Property Name", field: "property_name", sort: "asc", width: 150 },
    { label: "Property Code", field: "property_code", sort: "asc", width: 150 },
    { label: "Unit Name", field: "unit_name", sort: "asc", width: 150 },
    { label: "Total Rooms", field: "total_rooms", sort: "asc", width: 150 },
    { label: "Payment Method", field: "pament_method", sort: "asc", width: 150 },
    { label: "Late Fee", field: "late_fee", sort: "asc", width: 150 },
    { label: "Late Fee Cost", field: "late_fee_cost", sort: "asc", width: 150 },
    { label: "Frequency", field: "Frequency", sort: "asc", width: 150 },
  ];

  const data = {
    columns: column,
    rows: dataDb,
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Property" />
        <Breadcrumb default="Property" title="Property" />
        <Container fluid>
          <Row className="d-flex justify-content-around align-items-center" data-aos="fade-bottom">
            <CardBody>
              <Card className="bd-rds">
                <CardBody>

                  <div className="d-flex justify-content-between">
                    {/* <Link to="/add-lease" className="btn report-primary w-md waves-effect waves-light mb-4 text-white" type="submit"> + Add leases </Link> */}
                    <button className="btn report-warning w-md waves-effect waves-light mb-4 d-flex ">
                      <CSVLink data={dataDb} separator={";"} className="text-white"> Download in Excel </CSVLink>
                    </button>
                  </div>

                  <MDBDataTable entries={5} entriesOptions={[5, 10, 50]} responsive bordered striped hover data={data} fullPagination />
                </CardBody>
              </Card>
            </CardBody>
          </Row>


        </Container>

      </div>
    </React.Fragment>
  )
}

LeaseTable.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(LeaseTable))