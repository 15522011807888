import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
  Badge,
  Button,
} from "reactstrap";

import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { Link, useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import useFecth from "../../../../hooks/useFecth";
import usePost from "../../../../hooks/usePost";

function AllHistoryTable() {
  const history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const userId = userDet?.data?._id;
  const { execute, pending, data: postData } = usePost();
  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const list = [];

  const { data: getMyTicketHistory, reFetch: reFetchGetMyTicketHistory } =
    useFecth(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/ticketHistories/${userId}/serviceProvider`,
      token
    );

  getMyTicketHistory?.forEach((res) => {
    list.push({
      ...res,
      propertyName: `${res?.ticketId?.propertyId?.propertyName}`,
      ticketNumber: `${res?.ticketId?.ticketNumber}`,
      assignedTo: res?.ticketId?.assigned_to?.firstName
        ? `${res?.ticketId?.assigned_to?.firstName}`
        : "---",
      status:
        res?.action === "open" ? (
          <>
            <Badge className="rounded-pill bg-info p-2">Created</Badge>
          </>
        ) : res?.action === "accepted" ? (
          <>
            <Badge className="rounded-pill bg-primary p-2">Open</Badge>
          </>
        ) : res?.action === "closed" ? (
          <>
            <Badge className="rounded-pill bg-success p-2">Closed</Badge>
          </>
        ) : res?.action === "reopen" ? (
          <>
            <Badge className="rounded-pill bg-info p-2">Reopen</Badge>
          </>
        ) : res?.action === "cancelled" ? (
          <>
            <Badge className="rounded-pill bg-danger p-2">Cancel</Badge>
          </>
        ) : res?.action === "declined" ? (
          <>
            <Badge className="rounded-pill bg-warning p-2">Declined</Badge>
          </>
        ) : res?.action === "pending" ? (
          <>
            <Badge className="rounded-pill bg-dark p-2">Pending</Badge>
          </>
        ) : null,
      OpenedDate: res?.action_start_date
        ? `${res?.action_start_date.substring(0, 10)}`
        : "---",
      ClosedDate: res?.action_end_date
        ? `${res?.action_end_date.substring(0, 10)}`
        : "---",
      viewBtn: (
        <button
          className="btn btn-dark"
          onClick={() => viewFunc(`${res?.ticketId?._id}`)}
        >
          View
        </button>
      ),
      cancelBtn: (
        <button
          className="btn btn-danger"
          onClick={() => dangerFunc(`${res?._id}`)}
        >
          Delete
        </button>
      ),
    });
  });

  const viewFunc = (id) => {
    history.push(`/view-ticket/${id}`);
  };

  const dangerFunc = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  const handleDeleteTicketHistory = () => {
    const Method = "DELETE",
      endPoint = `ticketHistories/${deleteId}`,
      isJSON = null;
    const raw = null;

    execute(
      endPoint,
      raw,
      Method,
      "Ticket history successfully deleted",
      token,
      isJSON
    );
  };

  const column = [
    { label: "Property name", field: "propertyName", sort: "asc", width: 150 },
    { label: "Ticket Number", field: "ticketNumber", sort: "asc", width: 150 },
    { label: "Status", field: "status", sort: "asc", width: 150 },
    { label: "Assigned To", field: "assignedTo", sort: "asc", width: 150 },
    { label: "Open Date", field: "OpenedDate", sort: "asc", width: 150 },
    { label: "Close Date", field: "ClosedDate", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "cancelBtn", sort: "asc", width: 150 },
  ];

  const dataDB = { columns: column, rows: list.reverse() };

  useEffect(() => {
    if (postData?.status === "success") {
      reFetchGetMyTicketHistory();
      setDeleteModal(false);
    }
  }, [postData?.status]);

  // console.log("getMyTicketHistory...", getMyTicketHistory);

  return (
    <div>
      <Card className="bd-rds">
        <CardBody>
          <div className="d-flex justify-content-end">
            <button className="btn login-btn w-md waves-effect waves-light mb-4">
              <CSVLink data={"dataDb"} separator={";"} className="text-white">
                Download in Excel
              </CSVLink>
            </button>
          </div>
          <MDBDataTable
            entries={5}
            entriesOptions={[5, 10, 50]}
            responsive
            bordered
            striped
            hover
            data={dataDB}
            fullpagination="true"
          />
        </CardBody>
      </Card>
      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Delete History </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h4 className="text-danger">
            Are you sure you want to delete history ?
          </h4>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-success text-white"
            onClick={handleDeleteTicketHistory}
          >
            {!pending ? (
              "Sure"
            ) : (
              <span>
                <Spinner as="span" animation="border" size="sm" /> Loading...
              </span>
            )}
          </Button>
          <Button
            className="bg-danger text-white"
            onClick={() => setDeleteModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AllHistoryTable;
