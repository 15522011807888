import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col, Container, Row, Card, CardBody, Modal } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { BsEye } from "react-icons/bs";
import { CSVLink } from "react-csv";
import { BsFillHouseFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { AiTwotoneNotification } from "react-icons/ai";
import { TbFileReport } from "react-icons/tb";

function PropertyReport({ propId, propType, t }) {
  return (
    <React.Fragment>
      <Row>
        <Col xl={3} md={6}>
          <Link to={`/my-units/${propId}/${propType}`}>
            <Card className="mini-stat report-success text-white bd-rds">
              <CardBody>
                <div className="mb-1">
                  <div className="float-start mini-stat-img me-2 font-size-22">
                    <BsFillHouseFill size={28} />
                  </div>
                  <h6 className="font-size-14 text-uppercase mt-0 text-white-50">
                    {t("My units")}
                  </h6>
                  <BsEye size={25} /> {t("View")}
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col xl={3} md={6}>
          <Link to={`/my-tenants/${propId}`}>
            <Card className="mini-stat report-primary text-white bd-rds">
              <CardBody>
                <div className="mb-1">
                  <div className="float-start mini-stat-img me-2 font-size-22">
                    <FaUsers size={32} />
                  </div>
                  <h6 className="font-size-14 text-uppercase mt-0 text-white-50">
                    {t("My Tenants")}
                  </h6>
                  <BsEye size={25} /> {t("View")}
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col xl={3} md={6}>
          <Link to={`/my-property-notice/${propId}`}>
            <Card className="mini-stat report-warning text-white bd-rds bg-info">
              <CardBody>
                <div className="mb-1">
                  <div className="float-start mini-stat-img me-2 font-size-22">
                    <AiTwotoneNotification size={32} />
                  </div>
                  <h6 className="font-size-14 text-uppercase mt-0 text-white-50">
                    {t("My Notices")}
                  </h6>
                  <BsEye size={25} /> {t("View")}
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col xl={3} md={6}>
          <Link to={`/property-report/${propId}`}>
            <Card className="mini-stat report-warning text-white bd-rds bg-dark">
              <CardBody>
                <div className="mb-1">
                  <div className="float-start mini-stat-img me-2 font-size-22">
                    <TbFileReport size={32} />
                  </div>
                  <h6 className="font-size-14 text-uppercase mt-0 text-white-50">
                    {t("My Reports")}
                  </h6>
                  <BsEye size={25} /> {t("View")}
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>
      </Row>
    </React.Fragment>
  );
}
PropertyReport.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(PropertyReport));
