import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, CardBody, Col, Spinner } from "reactstrap";

import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { useStore1Selector } from "index";
import { loginUser } from "Redux/Slices/userSlice";
import useCustomMutation from "../../hooks/useCustomMutation";
import useFetch from "../../hooks/useFecth";

function AddBooking() {
  const userDet = useStore1Selector(loginUser);
  const ownerId = userDet?.data?._id;
  const token = userDet?.token;
  const [propertyId, setPropertyId] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [unitsPictures, setUnitsPictures] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  const [unitsName, setUnitName] = useState("");
  const [surfaceArea, setSurfaceArea] = useState("");
  const [unitType, setUnitType] = useState("");
  const [rentAmount, setRentAmount] = useState("");
  const [occupancy, setOccupancy] = useState("");
  const [parking, setParking] = useState(false);
  const [pets, setPets] = useState(false);
  const [bookableField, setBookableField] = useState(false);
  const [description, setDescription] = useState("");
  const [info, setInfo] = useState("");

  const key = ["getAllBookableUnits", ownerId];
  const mutation = useCustomMutation(key);
  const history = useHistory();

  const handleValidSubmit = (e) => {
    e.preventDefault();
    const Method = "POST",
      url = `bookableUnits/${ownerId}/${propertyId}/create`,
      isJSON = true;
    const formdata = new FormData();
    formdata.append("propId", propertyId);
    formdata.append("propertyName", propertyName);
    formdata.append("unitName", unitsName);
    formdata.append("unitType", unitType);
    formdata.append("unitSpecification", description);
    if (unitsPictures) {
      unitsPictures?.map((item) => {
        formdata.append("unitImages", item);
      });
    }
    formdata.append("surfaceArea", surfaceArea);
    formdata.append("parkingGarage", parking);
    formdata.append("bookingFee", rentAmount);
    formdata.append("maximumOccupancy", occupancy);
    formdata.append("deposit_and_CancellationPolicy", info);
    formdata.append("petPolicy", pets);
    formdata.append("bookableField", bookableField);

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Bookable Unit Successfully Created",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const {
    data: getPropertyAllProperties,
    reFetch: reFetchGetPropertyAllProperties,
  } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/property_owner?fieldName=userId`,
    token
  );
  const handlePropertyChange = (event) => {
    const [propertyId, propertyName] = event.target.value.split(",");
    setPropertyId(propertyId);
    setPropertyName(propertyName);
    // setPropertyId(event.target.value);
    // setPropertyName(event.target.value[1]);
  };

  const handleUnitsPictures = (event) => {
    const selectedFiles = event.target.files;
    const fileArray = Array.from(selectedFiles);
    setUnitsPictures(fileArray);

    const previews = fileArray.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  const handleParking = (event) => {
    if (event.target.checked) {
      setParking(true);
    } else {
      setParking(false);
    }
  };

  const handlePets = (event) => {
    if (event.target.checked) {
      setPets(true);
    } else {
      setPets(false);
    }
  };

  const handleBookableField = (event) => {
    if (event.target.checked) {
      setBookableField(true);
    } else {
      setBookableField(false);
    }
  };

  if (mutation.isSuccess) {
    history.push("/my-bookings");
  }

  // console.log("images...", unitsPictures);
  console.log("propertyId..", propertyId);
  console.log("propertyName..", propertyName);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Add Bookings" />
        <Breadcrumb default="Bookings Management" title="Add Bookable Units" />

        <CardBody>
          <Container fluid>
            <Row className="d-flex justify-content-around align-items-center">
              <CardBody data-aos="fade-bottom">
                <Card className="bd-rds">
                  <CardBody>
                    <form
                      className="form-horizontal mt-4"
                      onSubmit={handleValidSubmit}
                    >
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <label>Property Name</label>
                            <select
                              className="form-control"
                              required
                              onChange={handlePropertyChange}
                            >
                              <option> Select... </option>
                              {getPropertyAllProperties?.map((item, i) => (
                                <option
                                  key={i}
                                  value={[item?._id, item?.propertyName]}
                                >
                                  {item?.propertyName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <label>Unit Name</label>
                            <input
                              className="form-control"
                              onChange={(e) => setUnitName(e.target.value)}
                            />
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-3">
                            <label>Unit Type</label>
                            <input
                              className="form-control"
                              onChange={(e) => setUnitType(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <label>Price/hour</label>
                            <input
                              className="form-control"
                              onChange={(e) => setRentAmount(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <label>Surface Area</label>
                            <input
                              className="form-control"
                              onChange={(e) => setSurfaceArea(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <label>Maximum Occupancy</label>
                            <input
                              className="form-control"
                              onChange={(e) => setOccupancy(e.target.value)}
                            />
                          </div>
                        </Col>

                        <div className="mb-3">
                          <label>Unit Description</label>
                          <textarea
                            className="form-control"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={6}
                          />
                        </div>
                      </Row>
                      <Row>
                        <Col md={4} className="Input__form">
                          <input type="checkbox" onChange={handleParking} />
                          <label className="mx-1 text-success">Parking</label>
                        </Col>
                        <Col md={4} className="Input__form">
                          <input type="checkbox" onChange={handlePets} />
                          <label className="mx-1 text-success">
                            Allow Pets
                          </label>
                        </Col>
                        <Col md={4} className="Input__form">
                          <input
                            type="checkbox"
                            onChange={handleBookableField}
                          />
                          <label className="mx-1 text-success">
                            Make unit available
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            className="dropzone-previews d-flex align-items-center"
                            id="file-previews"
                          >
                            {imagePreviews.map((previewURL, index) => (
                              <img
                                key={index}
                                data-dz-thumbnail=""
                                height="150"
                                className="avatar-sm rounded bg-light m-2 border border-dark"
                                alt={`Preview ${index}`}
                                src={previewURL}
                              />
                            ))}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          <div className="mb-3 drop-zone">
                            <label
                              htmlFor="attachment"
                              className="drop-zone-label"
                            >
                              <i className="fas fa-cloud-upload-alt"></i>
                              <div className="drop-zone-text">
                                Upload pictures of units
                              </div>
                            </label>
                            <input
                              type="file"
                              id="attachment"
                              className="form-control drop-zone-input"
                              multiple
                              accept="image/jpeg, image/png, image/gif"
                              onChange={handleUnitsPictures}
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <label>Additional Info</label>
                            <textarea
                              className="form-control"
                              value={info}
                              onChange={(e) => setInfo(e.target.value)}
                              rows={6}
                            />
                          </div>
                        </Col>
                      </Row>

                      <div className="d-flex justify-content-center">
                        <button
                          className="btn login-btn w-md waves-effect waves-light"
                          type="submit"
                          handleUnitsPictures
                        >
                          {!mutation.isLoading ? (
                            "Create"
                          ) : (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                className="me-2"
                              />
                              Loading...
                            </>
                          )}
                        </button>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </CardBody>
            </Row>
          </Container>
        </CardBody>
      </div>
    </React.Fragment>
  );
}

export default AddBooking;
