import React, { useEffect, useState } from "react";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { useHistory, useParams, Link } from "react-router-dom";
import { Row, Col, Badge } from "reactstrap";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../../components/Common/MetaTags";
import { useStore1Selector } from "../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import useFetch from "../../../../hooks/useFecth";
import LoadComponents from "../../../../components/LoadComponents";

const ViewTicketPage = () => {
  const history = useHistory();
  const { viewID } = useParams();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const ownerId = userDet?.data?._id;
  const [photoIndex, setphotoIndex] = useState(0);
  const [photoSolvedIndex, setphotoSolvedIndex] = useState(0);
  const [isIsueGallery, setIsIsueGallery] = useState(false);
  const [isSolvedGallery, setIsSolvedGallery] = useState(false);

  const { data: getTicketsDetails, reFetch: reFetchGetTicketsDetails } =
    useFetch(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceTickets/${viewID}`,
      token
    );

  const PropertyImage = getTicketsDetails?.propertyId?.propertyImage;
  const PropertyName = getTicketsDetails?.propertyId?.propertyName;
  const UnitName = getTicketsDetails?.unitId?.unitName;
  const location = `${getTicketsDetails?.propertyId?.address}, ${getTicketsDetails?.propertyId?.city}`;
  const status = getTicketsDetails?.status;
  const completionDate = getTicketsDetails?.status_change_date;

  if (isIsueGallery) {
    return (
      <Lightbox
        mainSrc={getTicketsDetails?.issueAttachment[photoIndex]}
        nextSrc={
          getTicketsDetails?.issueAttachment[
            (photoIndex + 1) % getTicketsDetails?.issueAttachment?.length
          ]
        }
        prevSrc={
          getTicketsDetails?.issueAttachment[
            (photoIndex + getTicketsDetails?.issueAttachment?.length - 1) %
              getTicketsDetails?.issueAttachment.length
          ]
        }
        enableZoom={true}
        onCloseRequest={() => {
          setIsIsueGallery(false);
        }}
        onMovePrevRequest={() => {
          const newIndex =
            (photoIndex - 1 + getTicketsDetails?.issueAttachment?.length) %
            getTicketsDetails?.issueAttachment?.length;
          setphotoIndex(newIndex);
        }}
        onMoveNextRequest={() => {
          const newIndex =
            (photoIndex + 1) % getTicketsDetails?.issueAttachment?.length;
          setphotoIndex(newIndex);
        }}
      />
    );
  }

  if (isSolvedGallery) {
    return (
      <Lightbox
        mainSrc={getTicketsDetails?.resolvedAttachment[photoSolvedIndex]}
        nextSrc={
          getTicketsDetails?.resolvedAttachment[
            (photoSolvedIndex + 1) %
              getTicketsDetails?.resolvedAttachment?.length
          ]
        }
        prevSrc={
          getTicketsDetails?.resolvedAttachment[
            (photoSolvedIndex +
              getTicketsDetails?.resolvedAttachment?.length -
              1) %
              getTicketsDetails?.resolvedAttachment.length
          ]
        }
        enableZoom={true}
        onCloseRequest={() => {
          setIsSolvedGallery(false);
        }}
        onMovePrevRequest={() => {
          const newIndex =
            (photoSolvedIndex -
              1 +
              getTicketsDetails?.resolvedAttachment?.length) %
            getTicketsDetails?.resolvedAttachment?.length;
          setphotoSolvedIndex(newIndex);
        }}
        onMoveNextRequest={() => {
          const newIndex =
            (photoSolvedIndex + 1) %
            getTicketsDetails?.resolvedAttachment?.length;
          setphotoSolvedIndex(newIndex);
        }}
      />
    );
  }

  const goBack = () => {
    history.goBack();
  };

  if (!getTicketsDetails?.id) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTagComp meta_tags="FASPRO24 | View ticket" />
          <Breadcrumb default="Facility Manangement" title="View ticket" />
          <button className="btn login-btn mb-3" onClick={goBack}>
            Back
          </button>
          <div className="bg-white p-4 rounded-3">
            <LoadComponents />
          </div>
        </div>
      </React.Fragment>
    );
  }

  // console.log("getTicketsDetails...", getTicketsDetails);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | View ticket" />
        <Breadcrumb default="Facility Manangement" title="View ticket" />

        <button className="btn login-btn mb-3" onClick={goBack}>
          Back
        </button>
        <div className="bg-white p-4 rounded-3">
          <Row>
            <Col md={6}>
              <img src={PropertyImage} className="rounded-3 tickets_pictures" />
            </Col>
            <Col md={6}>
              <div className="d-flex align-item-center">
                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mt-2 mb-2 h4">
                      <b className="text-dark">
                        Ticket No: {getTicketsDetails?.ticketNumber}
                      </b>
                    </div>
                    {getTicketsDetails?.emergency ? (
                      <div className="mt-2 text-light bg-danger btn btn-white border border-danger h5">
                        <i className="fa fa-bell me-1 "></i>
                        Emergency
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-2">
                    <b className="text-success">Property Name:</b>
                    <i className="fas fa-home me-1 ms-2"></i>
                    {PropertyName}
                  </div>
                  <div className="mt-2">
                    <b className="text-success">Unit Name: </b>
                    {UnitName}
                  </div>
                  <div className="mt-2">
                    <b className="text-success">Location: </b>
                    <i className="fas fa-map-marker-alt me-1"></i>
                    {location}
                  </div>
                  {getTicketsDetails?.ticketAssign ? (
                    <div className="mt-2">
                      <b className="text-success">Ticket Assigned to :</b>
                      <i className="fas fa-user-alt me-1 mx-2"></i>
                      {`${getTicketsDetails?.assigned_to?.firstName} 
                  ${getTicketsDetails?.assigned_to?.lastName}`}
                    </div>
                  ) : null}
                  <div className="mt-2">
                    <b className="text-success">Issue :</b>
                    {getTicketsDetails?.subject}
                  </div>
                  <div className="mt-2">
                    <span>
                      <b className="text-success">Status : </b>
                    </span>
                    {status === "open" ? (
                      <Badge className="rounded-pill bg-primary p-2">
                        Created
                      </Badge>
                    ) : status === "reopen" ? (
                      <Badge className="rounded-pill bg-danger p-2">
                        Reopened
                      </Badge>
                    ) : status === "pending" ? (
                      <Badge className="rounded-pill bg-warning p-2">
                        Pending
                      </Badge>
                    ) : status === "closed" ? (
                      <Badge className="rounded-pill bg-success p-2">
                        Closed
                      </Badge>
                    ) : status === "cancelled" ? (
                      <Badge className="rounded-pill bg-danger p-2">
                        Cancelled
                      </Badge>
                    ) : status === "accepted" ? (
                      <Badge className="rounded-pill bg-success p-2">
                        Approved
                      </Badge>
                    ) : status === "declined" ? (
                      <Badge className="rounded-pill bg-danger p-2">
                        Declined
                      </Badge>
                    ) : status === "overdue" ? (
                      <Badge className="rounded-pill bg-danger p-2">
                        Overdue
                      </Badge>
                    ) : status === "sent" ? (
                      <Badge className="rounded-pill bg-warning p-2">
                        Wainting for approval
                      </Badge>
                    ) : null}
                  </div>
                  <div className="mt-2">
                    <b className="text-success">Compeltion Date : </b>
                    {completionDate?.substring(0, 10)}
                  </div>
                  <div className="mt-2">
                    <b className="text-success">Issue details</b>

                    <div className="bg-light rounded-3 p-3 mt-1">
                      {getTicketsDetails?.description}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* {getTicketsDetails} */}
          <Row>
            <Col></Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mt-5 h4">Issue Attached Pictures</div>
              <div className="popup-gallery d-flex flex-wrap">
                {getTicketsDetails?.issueAttachment?.map((item, i) => {
                  return (
                    <div className="img-fluid float-left" key={i}>
                      <img
                        src={item}
                        onClick={() => {
                          setIsIsueGallery(true);
                          setphotoIndex(i);
                        }}
                        alt=""
                        width="120"
                        height="120"
                        className="rounded-3 ms-2"
                      />
                    </div>
                  );
                })}
              </div>
            </Col>

            <Col md={6}>
              <div className="mt-5 h4">Resolved Attached</div>
              <div className="popup-gallery d-flex flex-wrap">
                {getTicketsDetails?.resolvedAttachment?.map((item, i) => {
                  return (
                    <div className="img-fluid float-left" key={i}>
                      <img
                        src={item}
                        onClick={() => {
                          setIsSolvedGallery(true);
                          setphotoSolvedIndex(i);
                        }}
                        alt=""
                        width="120"
                        height="120"
                        className="rounded-3 ms-2 border border-dark"
                      />
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          <Row></Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewTicketPage;
