import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, CardBody, Col, Button, Spinner } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "index";
import { loginUser } from "Redux/Slices/userSlice";
import useCustomQuery from "../../../hooks/useCustomQuery";
import useCustomMutation from "../../../hooks/useCustomMutation";
import LoadingTable from "../../../components/LoadingTable";
// import ViewDetails from "./components/ViewDetails";
// import EditDetails from "./components/EditDetails";
import { successMessage } from "components/Notifications";
import { AvField, AvForm } from "availity-reactstrap-validation";
import SuspendForm from "./SuspendForm";
import UnSuspendForm from "./UnSuspendForm";
import ViewBookableUnits from "./ViewBookableUnits";
import EditBookableUnits from "./EditBookableUnits";
import { reset } from "redux-form";

//import Navigation

function AvailableUnits() {
  const userDet = useStore1Selector(loginUser);
  const [ViewModalModal, setViewModal] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [DeleteModal, setDeleteModal] = useState(false);
  const [SuspendModal, setSuspendModal] = useState(false);
  const [unsuspendModal, setUnsuspendModal] = useState(true);

  // to keep
  // const userDet = useStore1Selector(loginUser);
  const ownerId = userDet?.data?._id;
  const token = userDet?.token;
  const [bookingId, setBookingId] = useState([]);
  const key = ["getAllBookableUnits", ownerId];
  const mutationDelete = useCustomMutation(key);
  const mutationUnSuspend = useCustomMutation(key);

  const {
    data: getMyBookableUnits,
    error: errorGetMyBookableUnits,
    isLoading: isLoadingGetMyBookableUnits,
  } = useCustomQuery(
    ["getAllBookableUnits", ownerId],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/bookableUnits/${ownerId}/bookableUnits`,
    token
  );

  // console.log("ownerId...", ownerId);
  // console.log("getMyBookableUnits...", getMyBookableUnits);

  const list = [];
  getMyBookableUnits?.forEach((res) => {
    if (res?.bookableField) {
      list.push({
        ...res,
        propertyName: res?.propId?.propertyName,
        unitName: res?.unitName,
        status: res?.bookableField ? (
          <>
            <span className="text-success">Available</span>
          </>
        ) : (
          <>
            <span className="text-danger">Suspended</span>
          </>
        ),
        amount: res?.bookingFee
          ? `${process.env.REACT_APP_CURRENCY} ${res?.bookingFee}`
          : "---",
        viewBtn: (
          <button
            className="btn btn-success"
            onClick={() => viewFunc(`${res?._id}`)}
          >
            View
          </button>
        ),
        editBtn: (
          <button
            className="btn btn-dark"
            onClick={() => editFunc(`${res?._id}`)}
          >
            Edit
          </button>
        ),
        statusBtn: res?.bookableField ? (
          <button
            className="btn btn-warning btn-width"
            onClick={() => suspendFunc(`${res?._id}`)}
          >
            Suspend
          </button>
        ) : (
          <button
            className="btn btn-primary btn-width"
            onClick={() => unsuspendFunc(`${res?._id}`)}
          >
            Unsuspend
          </button>
        ),
        deleteBtn: (
          <button
            className="btn btn-danger"
            onClick={() => deleteFunc(`${res?._id}`)}
          >
            Delete
          </button>
        ),
      });
    }
  });

  function viewFunc(id) {
    setViewModal(true);
    setBookingId(id);
  }
  function editFunc(id) {
    setEditModal(true);
    setBookingId(id);
  }
  function deleteFunc(id) {
    setDeleteModal(true);
    setBookingId(id);
  }
  function suspendFunc(id) {
    setSuspendModal(true);
    setBookingId(id);
  }

  function unsuspendFunc(id) {
    setUnsuspendModal(true);
    setBookingId(id);
  }

  const deleteProperty = (unitId) => {
    const Method = "DELETE",
      url = `bookableUnits/${unitId}`,
      isJSON = null;
    const raw = null;

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Bookable Unit Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  const unsuspendProperty = (unitId) => {
    const Method = "PATCH",
      url = `bookableUnits/${unitId}/updateStatus`,
      isJSON = true;
    var formdata = new FormData();
    // formdata.append("suspensionReason", v.reason);
    // formdata.append("unitSuspensionStartDate", startDateTime);
    // formdata.append("unitSuspensionStartDate", endDateTime);
    formdata.append("bookableField", true);

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Bookable Unit Successfully UnSuspended",
      token: token,
      isJSON: isJSON,
    };
    mutationUnSuspend.mutate(data);
  };

  const column = [
    { label: "Property Name", field: "propertyName", sort: "asc", width: 150 },
    { label: "Unit Name", field: "unitName", sort: "asc", width: 150 },
    { label: "Amount", field: "amount", sort: "asc", width: 150 },
    { label: "Status", field: "status", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Suspend", field: "statusBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const data = {
    columns: column,
    rows: list?.reverse(),
  };

  useEffect(() => {
    setDeleteModal(false);
    setUnsuspendModal(false);
  }, [mutationDelete.isSuccess, mutationUnSuspend.isSuccess]);

  return (
    <div>
      <CardBody>
        <div className="d-flex justify-content-end">
          <button className="btn report-warning w-md waves-effect waves-light mb-4 d-flex justify-content-end">
            <CSVLink data={list} separator={";"} className="text-white">
              Download in Excel
            </CSVLink>
          </button>
        </div>
        {isLoadingGetMyBookableUnits ? (
          <LoadingTable />
        ) : (
          <MDBDataTable
            entries={5}
            entriesOptions={[5, 10, 50]}
            responsive
            bordered
            striped
            hover
            data={data}
          />
        )}
      </CardBody>

      <Modal
        className="modal-right scroll-out-negative"
        show={ViewModalModal}
        onHide={() => setViewModal(false)}
        scrollable
        dialogClassName="full"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Bookable Units</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ViewBookableUnits
            ownerId={ownerId}
            bookUnitId={bookingId}
            setViewModal={setViewModal}
            token={token}
          />
        </Modal.Body>
      </Modal>

      <Modal
        className="modal-right scroll-out-negative"
        show={EditModal}
        onHide={() => setEditModal(false)}
        scrollable
        dialogClassName="full"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Bookable Units</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditBookableUnits
            ownerId={ownerId}
            bookingId={bookingId}
            setEditModal={setEditModal}
            token={token}
          />
        </Modal.Body>
      </Modal>

      <Modal show={DeleteModal} onHide={() => setDeleteModal(false)} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Delete Bookable Unit</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-danger">
          <h4> Are you sure you want to delete this unit? </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-success"
            onClick={() => setDeleteModal(false)}
          >
            No
          </Button>
          {!mutationDelete.isLoading ? (
            <Button
              className="btn btn-danger"
              onClick={() => deleteProperty(bookingId)}
            >
              Yes
            </Button>
          ) : (
            <>
              <Button className="btn btn-danger">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="me-2"
                />
                Loading...
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        show={SuspendModal}
        onHide={() => setSuspendModal(false)}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Suspend The Unit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SuspendForm
            unitID={bookingId}
            setSuspendModal={setSuspendModal}
            ownerId={ownerId}
            token={token}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={unsuspendModal}
        onHide={() => setUnsuspendModal(false)}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Unsuspend Unit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-danger">
            Are you sure you want to Unsuspend this unit?{" "}
          </h4>
          {/* <UnSuspendForm
            unitID={bookingId}
            setSuspendModal={setSuspendModal}
            ownerId={ownerId}
            token={token}
          /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-success"
            onClick={() => setUnsuspendModal(false)}
          >
            No
          </Button>
          {!mutationUnSuspend.isLoading ? (
            <Button
              className="btn btn-danger"
              onClick={() => unsuspendProperty(bookingId)}
            >
              Yes
            </Button>
          ) : (
            <>
              <Button className="btn btn-danger">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="me-2"
                />
                Loading...
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

AvailableUnits.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(AvailableUnits));
