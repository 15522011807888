import React, { useState } from "react";
import { Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";

import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFecth";

function EditBlockForm({ blockIdUpdate, setBlock, refetchUpdate }) {
  const { execute, pending } = usePost();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { data } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/blocks/${blockIdUpdate}`,
    token
  );

  const handleValidSubmit = (e, v) => {
    e.preventDefault();
    const Method = "PATCH",
      endPoint = `blocks/${blockIdUpdate}`;
    const raw = JSON.stringify({
      blockName: v.blockName,
      blockNumber: v.blockNumber,
      blockAddress: v.blockAddress,
      numberOfUnits: v.numberOfUnits,
      blockSize: v.blockSize,
      Amenities: "",
      maintenanceInformation: "",
      TenantInformation: "",
    });
    execute(endPoint, raw, Method, "Block successfully updated", token);
    setBlock(false);
    setTimeout(() => {
      refetchUpdate();
    }, 3000);
  };

  // if (postData?.status === "success") {
  //   console.log("sucees....");
  //   // closeModal(false);
  //   setTimeout(() => {
  //     refetchUpdate();
  //   }, 3000);
  // }

  // console.log("get blocks...", data);
  return (
    <React.Fragment>
      <div className="page-content">
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >
          <div className="mb-3">
            <AvField
              name="blockName"
              value={data?.blockName}
              label="Block name"
              className="form-control"
              type="text"
            />
          </div>
          <div className="mb-3">
            <AvField
              name="blockNumber"
              value={data?.blockNumber}
              label="Block number"
              className="form-control"
              type="text"
            />
          </div>
          <div className="mb-3">
            <AvField
              name="blockAddress"
              value={data?.blockAddress}
              label="Block Address"
              className="form-control"
              type="text"
              required
            />
          </div>
          <div className="mb-3">
            <AvField
              name="numberOfUnits"
              value={data?.numberOfUnits}
              label="Number of Units"
              className="form-control"
              type="text"
              required
            />
          </div>
          <div className="mb-3">
            <AvField
              name="blockSize"
              value={data?.blockSize}
              label="Block Size"
              className="form-control"
              type="text"
            />
          </div>
          <button
            className="btn login-btn w-md waves-effect waves-light"
            type="submit"
          >
            {!pending ? <span className="me-2"> Submit </span> : null}
            {!pending ? null : (
              <span>
                {" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="me-2"
                />
                Loading...
              </span>
            )}
          </button>
        </AvForm>
      </div>
    </React.Fragment>
  );
}
export default EditBlockForm;
