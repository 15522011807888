import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  Card,
  FormGroup,
  Spinner
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { withRouter, Link, useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumb from "../../components/Common/Breadcrumb"
import MetaTagComp from "../../components/Common/MetaTags"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useStore1Selector } from "./../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
import { warningMessage, successMessage } from "../../components/Notifications";

function AddTenant() {

  const history = useHistory();
  const userDet = useStore1Selector(loginUser)
  const userId = userDet?.original?.userDetails?.OwnerID
  const [check, setCheck] = useState("");
  const [value, setValue] = useState("");
  const [available, setAvailable] = useState("");
  const [phone, setPhone] = useState("");
  const [loadBtn, setIloadBtn] = useState(false);
  const [myProperty, setMyProperty] = useState();
  const [myUnit, setMyUnit] = useState();
  const [Unit, setUnit] = useState();
  const [propertyId, setPropertyId] = useState();

  const getValue = (e, v) => {
    setCheck(v)
  }
  const toggleAvailable = (e, v) => {
    setAvailable(v)
  }
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleValidSubmit = ((e, v) => {
    e.preventDefault();
    setIloadBtn(true);

    const formdata = new FormData();
    formdata.append("Email", v.email);
    formdata.append("Name", v.firstName);
    formdata.append("Surname", v.flastName);
    formdata.append("password", v.password);
    formdata.append("Phone_Number", phone);
    formdata.append("OwnerID", userId);
    formdata.append("Gender", v.gender);
    formdata.append("ID/PassportNumber", v.idNumber);

    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://prop.faspro24.com/api/tenant/NewTenant", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log("result : ", result)
        if (result.status === "success") {
          successMessage('You have successfully assigned this unit to a tenant');
          setIloadBtn(false);
          window.setTimeout(() => {
            history.push('/tenant-table')
          }, 3000);
        }
        if (result.status === "fail") {
          warningMessage("Couldn't  add a tenant");
          setIloadBtn(false);
        }
        // if (result[0] === "The email has already been taken.") {
        //   warningMessage("Couldn't  add a tenant");
        //   setIloadBtn(false);
        // }
      })
      .catch(error => {
        warningMessage(error);
        setIloadBtn(false);
      });
  })

  const getProperty = async () => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://prop.faspro24.com/api/property/GetPropertyList/${userId}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setMyProperty(result?.data)
      })
      .catch(error => console.log('error', error));
  }

  const getUnit = () => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://prop.faspro24.com/api/unit/GetUnitsByPropertyID/${propertyId}`, requestOptions)
      .then(response => response.json())
      .then(res => {
        setMyUnit(res.data)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    getProperty();
    getUnit();
  }, []);

  const propertyIdHandle = (e) => {
    setPropertyId(e.target.value);
    getUnit();
    getProperty();
  }

  const unitHandle = (e) => {
    setUnit(e.target.value);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | add Tenant" />
        <Breadcrumb default="Property" title="Tenants" />

        <CardBody>
          <Container fluid>
            <Link to="/my-tenants" className="btn login-btn w-md waves-effect waves-light mb-3" type="submit"> Back </Link>

            <div className="page-title-box">
              <Card className="bd-rds" data-aos="fade-bottom">
                <div className="TenantHeader bd-rds-header">
                  <h5>Add New Tenants</h5>
                </div>
                <CardBody>

                  <AvForm>
                    <p> Tenant type </p>
                    <Col md={2}>
                      <div className="mb-3">
                        <AvField name="gender" type="select" required value={value} onChange={handleChange}>
                          <option> Residential </option>
                          <option> Commercial </option>
                        </AvField>
                      </div>
                    </Col>
                  </AvForm>

                  <Row className="align-items-center">
                    <Col md={12} lg={12} xl={12}>
                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                      >
                        <Row>
                          <Col md={6} lg={6} xl={6}>

                            {
                              value === "Commercial" ? (
                                <Row>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <AvField name="company" label="Company Name" type="text" required />
                                    </div>
                                  </Col>

                                  <Col md={6}>
                                    <div className="mb-3">
                                      <AvField name="comp_reg" label="Company registration number" className="form-control" type="text" required />
                                    </div>
                                  </Col>
                                </Row>
                              ) : null
                            }

                            <Row className="mt-5">
                              <Col md={6}>
                                {/* <div className="mb-3">
                                  <AvField name="assign" label="Assign to Unit" className="form-control" type="select" required>
                                    <option> Select... </option>
                                    <option> Preston </option>
                                    <option> Court Jabula </option>
                                  </AvField>
                                </div> */}

                                <div className="mb-3">
                                  <AvField name="propertyId" value={propertyId} onChange={propertyIdHandle} label="Assign Property" className="form-control" type="select" required>
                                    <option> Select... </option>
                                    {
                                      myProperty?.map((prop, id) => {
                                        return (
                                          <option key={id} value={prop?.PropertyID}> {prop?.Name} </option>
                                        )
                                      })
                                    }
                                  </AvField>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField name="unit" value={Unit} onChange={unitHandle} label="Assign Unit" className="form-control" type="select" required>
                                    <option> Select... </option>
                                    {
                                      myUnit?.map((unit, id) => {
                                        console.log(unit)
                                        return (
                                          <option key={id} value={unit.id}> {"unit.BlockName"} </option>
                                        )
                                      })
                                    }
                                  </AvField>
                                </div>
                              </Col>
                            </Row>



                            <Row className="mt-3">
                              {/* <b className="mb-3">Contact person</b> */}
                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField name="firstName" label="First Name" className="form-control" type="text" required />
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField name="flastName" label="Last Name" className="form-control" type="text" required />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField name="email" label="Email" className="form-control" type="email" required />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField name="password" label="Password" type="password" required />
                                </div>
                              </Col>
                            </Row>

                          </Col>

                          <Col md={6} lg={6} xl={6} className="mt-5">

                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField name="gender" label="Gender" type="select" required value={value} onChange={handleChange}>
                                    <option value="one"> Select... </option>
                                    <option> Male </option>
                                    <option> Female </option>
                                    <option> Other </option>
                                  </AvField>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField name="idNumber" label="ID/ Passport Number" type="text" required />
                                </div>
                              </Col>
                            </Row>
                            <Col md={12}>
                              <PhoneInput
                                inputProps={{
                                  name: 'phone',
                                  required: true,
                                  autoFocus: true
                                }}
                                buttonStyle={{
                                  borderBottomLeftRadius: 5,
                                  borderTopLeftRadius: 5,
                                  height: 40,
                                }}
                                inputStyle={{
                                  margin: 0,
                                  width: "100%",
                                  color: 'purple',
                                  height: 1,
                                  border: 'none'
                                }}
                                containerStyle={{
                                  width: "100%",
                                }}
                                className="blue"
                                country={"za"}
                                enableSearch={true}
                                value={phone}
                                onChange={(phone) => setPhone(phone)}

                              />
                            </Col>

                          </Col>
                        </Row>

                        <div className="col-12 text-center mt-5">
                          <button className="btn login-btn  waves-effect waves-light" type="submit">
                            {
                              !loadBtn ? <span className="me-2">Submit</span> : null
                            }

                            {
                              !loadBtn ? null : <span>  <Spinner as="span" animation="border" size="sm" /> Loading...</span>
                            }
                          </button>
                        </div>

                      </AvForm>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Container>
        </CardBody>
      </div>
    </React.Fragment >
  )
}

AddTenant.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(AddTenant))
