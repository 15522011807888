import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, Modal, Button } from "react-bootstrap";
import { useStore1Selector } from "./../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { ThreeCircles } from "react-loader-spinner";
import { CSVLink } from "react-csv";
import { ImCross, ImEye } from "react-icons/im";
import ViewUnitDetails from "./components/ViewUnitDetails";
import TenantForm from "./components/TenantForm";
import UnitNotice from "./components/UnitNotice";

import ExtraChargesForm from "./components//UnitsComponents/ExtraCharges/ExtraChargesForm";

import UtilitiesForm from "./components/UtilitiesForm";
// import InvoiceForm from "./components/InvoiceForm";
import InvoiceForm from "./components/UnitsComponents/Invoices/InvoiceForm";

import InvoiceTableForm from "./components/UnitsComponents/Invoices/InvoiceTableForm";
import UnitNoticesTableForm from "./components/UnitsComponents/Notice/UnitNoticesTableForm";
import ExtraChargesTableForm from "./components/UnitsComponents/ExtraCharges/ExtraChargesTableForm";
import UnitUtilitiesTableForm from "./components/UnitsComponents/Utilities/UnitUtilitiesTableForm";

import { RiCheckDoubleLine } from "react-icons/ri";
import { Badge } from "reactstrap";
import useFetch from "../../hooks/useFecth";
import usePost from "../../hooks/usePost";

function UnitDetailsFunc() {
  const history = useHistory();
  const { propID, unitID, propType } = useParams();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending } = usePost();

  const { data: unitLease, reFetch: reFetchLease } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/leases/unit/${unitID}`,
    token
  );

  const { data: unitEInvoiceTable, reFetch: reFetchInvoice } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/invoices/unit/${unitID}`,
    token
  );
  const { data: unitExtraChargeTable, reFetch: reFetchExtraCharge } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/extraCharges/unit/${unitID}`,
    token
  );
  const { data: unitUtilityTable, reFetch: reFetchUtility } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/utilities/unit/${unitID}`,
    token
  );

  const { data: unitNoticeTable, reFetch: reFetchNotice } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/notices/unit/${unitID}`,
    token
  );

  const [tenantModal, setTenantModal] = useState(false);
  const [deleteLeaseModal, setDeleteLeaseModal] = useState(false);
  const [utilityCharge, setUtilityCharge] = useState(false);
  const [sendInvoice, setSendInvoice] = useState(false);
  const [extraChargeModal, setExtraChargeModal] = useState(false);
  const [sendNoticeModal, setSendNoticeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  function addTenantFunc() {
    setTenantModal(true);
  }

  function deleteLeaseFunc() {
    setDeleteLeaseModal(true);
  }

  function extraChargesFunc() {
    setExtraChargeModal(true);
  }

  function utilityCharges() {
    setUtilityCharge(true);
  }
  function SendInvoiceModal() {
    setSendInvoice(true);
  }

  function viewReport() {
    history.push(`/unit-report/${propID}/${unitID}/${propType}`);
  }
  const deleteLease = () => {
    const Method = "DELETE",
      endPoint = `leases/${unitLease[0]?._id}`,
      isJSON = true;
    var formdata = new FormData();
    execute(
      endPoint,
      formdata,
      Method,
      "Lease Successfully Deleted",
      token,
      isJSON
    );
    setDeleteLeaseModal(false);
  };

  if (pending) {
    setTimeout(() => {
      reFetchLease();
      reFetchInvoice();
      reFetchExtraCharge();
      reFetchUtility();
      reFetchNotice();
    }, 2000);
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Unit details" />
        <Breadcrumb default="Property" title="Unit details" />
        <Card.Body>
          <Link
            className="btn login-btn mx-3 mb-3"
            to={`/my-units/${propID}/${propType}`}
          >
            Back
          </Link>
          <Container fluid>
            <button
              onClick={() => addTenantFunc()}
              className="btn btn-info me-3 p-3"
            >
              <i className="ti-plus me-1"></i> Add a tenant / Co-tenant
            </button>

            {unitLease.length === 0 ? null : (
              <button
                onClick={() => deleteLeaseFunc()}
                className="btn btn-danger me-3 p-3"
              >
                <i className="ti-trash me-1"></i> Delete lease
              </button>
            )}

            <button
              onClick={() => extraChargesFunc()}
              className="btn btn-warning me-3 p-3"
            >
              <i className="ti-plus me-1"></i> Extra charges
            </button>
            <button
              onClick={() => utilityCharges()}
              className="btn btn-primary me-3 p-3"
            >
              <i className="ti-plus me-1"></i> Utility charges
            </button>
            <button
              onClick={() => {
                setSendNoticeModal(true);
                // setViewDet(false);
              }}
              className="btn btn-success me-2 p-3 "
            >
              <i className="ti-comment-alt me-1"></i> Send a notice
            </button>

            <button
              onClick={() => viewReport(unitID)}
              className="btn btn-dark me-3 p-3"
            >
              <i className="ti-eye me-1"></i> Unit report
            </button>

            <Card className="mt-4 bd-rds">
              <Card.Body>
                {isLoading ? (
                  <div className="d-flex justify-content-center">
                    <ThreeCircles
                      height="250"
                      width="250"
                      color="#23B25A"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor=""
                      innerCircleColor=""
                      middleCircleColor=""
                    />
                  </div>
                ) : (
                  <ViewUnitDetails
                    propID={propID}
                    unitID={unitID}
                    unitLease={unitLease}
                    unitExtraChargeTable={unitExtraChargeTable}
                    unitUtilityTable={unitUtilityTable}
                    reFetchInvoice={reFetchInvoice}
                    reFetchLease={reFetchLease}
                  />
                )}
              </Card.Body>
            </Card>

            <h5 className="my-4"> Unit Notices</h5>

            <UnitNoticesTableForm
              unitNoticeTable={unitNoticeTable}
              reFetchNotice={reFetchNotice}
            />

            <h5 className="my-4"> Unit Invoices </h5>
            <InvoiceTableForm
              unitID={unitID}
              unitEInvoiceTable={unitEInvoiceTable}
              reFetchInvoice={reFetchInvoice}
            />

            <h5 className="my-4"> Extra charges </h5>
            <ExtraChargesTableForm
              propID={propID}
              propType={propType}
              unitID={unitID}
              unitExtraChargeTable={unitExtraChargeTable}
              reFetchExtraCharge={reFetchExtraCharge}
            />

            <h5 className="my-4"> Utility charges </h5>
            <UnitUtilitiesTableForm
              propID={propID}
              propType={propType}
              unitID={unitID}
              unitUtilityTable={unitUtilityTable}
              reFetchUtility={reFetchUtility}
            />
          </Container>
        </Card.Body>
        <Modal
          className="modal-right scroll-out-negative"
          show={tenantModal}
          onHide={() => setTenantModal(false)}
          scrollable
          dialogClassName="full"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add a tenant / cotenant</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <TenantForm
              propID={propID}
              unitID={unitID}
              closeModal={setTenantModal}
            />
          </Modal.Body>
        </Modal>
        <Modal
          className="modal-close-out"
          show={sendNoticeModal}
          onHide={() => setSendNoticeModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title> Send a notice to a tenant </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <UnitNotice
              unitID={unitID}
              closeModal={setSendNoticeModal}
              reFetchNotice={reFetchNotice}
            />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={() => setSendNoticeModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          className="modal-right scroll-out-negative"
          show={extraChargeModal}
          onHide={() => setExtraChargeModal(false)}
          scrollable
          dialogClassName="full"
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Create extra charges </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <ExtraChargesForm
              propID={propID}
              propType={propType}
              unitID={unitID}
              closeModal={setExtraChargeModal}
              reFetchExtraCharge={reFetchExtraCharge}
            />
          </Modal.Body>
        </Modal>
        <Modal
          className="modal-right scroll-out-negative"
          show={utilityCharge}
          onHide={() => setUtilityCharge(false)}
          scrollable
          dialogClassName="full"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Create utilities charges</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <UtilitiesForm
              unitID={unitID}
              propID={propID}
              propType={propType}
              closeModal={setUtilityCharge}
              reFetchUtility={reFetchUtility}
            />
          </Modal.Body>
        </Modal>
        <Modal
          className="modal-right scroll-out-negative"
          show={sendInvoice}
          onHide={() => setSendInvoice(false)}
          scrollable
          dialogClassName="full"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Send an invoice</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <InvoiceForm
            // unitID={unitId}
            // propID={id}
            // closeModal={() => setSendInvoice(false)}
            // utility={utilityData}
            // extraCharge={ExtraChargesData}
            // lease={setLeaseDet}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={deleteLeaseModal}
          onHide={() => setDeleteLeaseModal(false)}
          size="sm"
        >
          <Modal.Body className="text-danger">
            <h6> Are you sure you want to delete this lease ? </h6>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => setDeleteLeaseModal(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={deleteLease}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}

UnitDetailsFunc.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UnitDetailsFunc));
