export const ServiceType = [
  "Plumber",
  "Electrician",
  "Tiling",
  "Municipality",
  "Security",
  "TV services",
  "Garden",
  "House cleaning",
  "Other",
];
export default ServiceType;
