import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Row, Progress, Col } from "reactstrap";

const ReportCard = ({ t }) => {
  return (
    <React.Fragment>
      <Row>
        <Col md={3}>
          <Card>
            <CardBody>
              <Row className="align-items-center">
                <div className="col-8">
                  <p className="mb-2 text-primary">
                    {" "}
                    {t("Rental amount paid")}{" "}
                  </p>
                  <h4 className="mb-0">GH₵</h4>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <CardBody>
              <Row className="align-items-center">
                <div className="col-8">
                  <p className="mb-2 text-warning"> {t("Bookings Income")} </p>
                  <h4 className="mb-0">GH₵</h4>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <CardBody>
              <Row className="align-items-center">
                <div className="col-8">
                  <p className="mb-2 text-success">{t("Total assets")}</p>
                  <h4 className="mb-0">GH₵</h4>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <CardBody>
              <Row className="align-items-center">
                <div className="col-8">
                  <p className="mb-2 text-info">{t("Maintenance Cost")} </p>
                  <h4 className="mb-0">GH₵</h4>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

ReportCard.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(ReportCard));
