import React, { useState } from "react";
import { Button, Row, Col, Card } from "reactstrap";
import { BsCheckLg } from "react-icons/bs";
import { CgLoadbar } from "react-icons/cg";
import { Link, useHistory } from "react-router-dom";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
// import { useStore1Dispatch } from "../../../index";
import usePost from "../../../hooks/usePost";
import { SubscriptionMsg } from "../../../components/NotifyMessage";
// import { MyPropertyRoute } from "../../../components/RouteName";

function MonthlyForm() {
  const history = useHistory();
  const user = useStore1Selector(loginUser);
  const { execute, pending, data } = usePost();
  const monthlyFunc = () => {
    // e.preventDefault();
    // setIloadBtn(true);
    const Method = "POST",
      endPoint = `packages/${user?.data?._id}`,
      token = user?.token;
    const raw = JSON.stringify({
      name: "Starter",
      price: 200,
      duration: "monthly",
      numberOfUnits: 20,
    });

    execute(endPoint, raw, Method, SubscriptionMsg, token);
  };
  console.log("data...");
  if (data?.status === "success") {
    window.setTimeout(() => {
      history.push("/my-properties");
    }, 2000);
  }
  return (
    <div className="package_subscription_features">
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>

        <h5 className="ms-2 p-1 mb-2">Manage up to 20 units</h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>

        <h5 className="ms-2 p-1 mb-2">Create lease</h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>

        <h5 className="ms-2 p-1 mb-2">Assign units to tenants </h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>

        <h5 className="ms-2 p-1 mb-2">Invoice tenants </h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <BsCheckLg size={20} color="green" />
        </div>

        <h5 className="ms-2 p-1 mb-2">Pay rent online</h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <CgLoadbar size={20} color="red" />
        </div>
        <h5 className="ms-2 p-1 mb-2">Limited analytics</h5>
      </div>
      <div className="d-flex">
        <div className="d-flex mt-2">
          <CgLoadbar size={20} color="red" />
        </div>
        <h5 className="ms-2 p-1 mb-2">Limited property performance reports</h5>
      </div>
      <div className="mt-5 d-flex justify-content-center">
        <Button className="bg-info text-light" onClick={monthlyFunc}>
          Get Started
        </Button>
      </div>
    </div>
  );
}

export default MonthlyForm;
