import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropertyForm from "./Property-form";
import UnitForm from "./UnitFormWithNoBlock";
import BlockForm from "./Block-Form";

const FormWizard = (props) => {
  const [activeTab, setactiveTab] = useState(1);
  const [btn_Valid, setBtnValid] = useState();

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
      }
    }
  }

  const [check, setCheck] = useState("");
  const [available, setAvailable] = useState("");

  const getValue = (e, v) => {
    setCheck(v);
  };

  const toggleAvailable = (e, v) => {
    console.log(v);
    setAvailable(v);
  };

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="bd-rds" data-aos="fade-bottom">
              <CardBody>
                <h4 className="card-title mb-4"> Add New Property </h4>
                <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                  <PropertyForm />
                  <div></div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default FormWizard;
