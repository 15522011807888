import PropTypes from "prop-types";
import React from "react";
import "./Style.scss";
import SignUp from "./../Signup/Components/Choose-signup-method";

const Index = () => {
  return (
    <React.Fragment>
      <SignUp />
    </React.Fragment>
  );
};

export default Index;
