import React from "react";
import ReactEcharts from "echarts-for-react";

const ReceivablePie = ({ unitsStats }) => {
  if (!unitsStats) {
    return (
      <React.Fragment>
        <p> No record for this month </p>
      </React.Fragment>
    );
  }
  const pieName = [
    {
      name: "Rental Paid",
    },
    { name: "Rental Pending" },
    { name: "Rental Overdue" },
  ];
  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: pieName,
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#02a499", "#f8b425", "#ec4561"],
      series: [
        {
          name: "montly",
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: [
            {
              name: "Rental Paid",
              value: unitsStats?.total_Paid_OfAll,
            },
            { name: "Rental Pending", value: unitsStats?.total_Pending_OfAll },
            { name: "Rental Overdue", value: unitsStats?.total_Overdue_OfAll },
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={getOption()} />
    </React.Fragment>
  );
};
export default ReceivablePie;
