import React, { useState, useRef, useEffect } from "react";
import { useHistory, withRouter, useParams } from "react-router-dom";
import { Col, Row, Button, Spinner, Label } from "reactstrap";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { countryList } from "../../../components/Countries";
import Img_600 from "./../../../assets/images/img-size/600x400.gif";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFecth";
import { ThreeCircles } from "react-loader-spinner";
import "./form.scss";
import propertyType from "../../../components/PropertyType";
import propertyCategory from "../../../components/PropertyCategory";
import Multiselect from "multiselect-react-dropdown";

function PropertyEditForm() {
  let history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const { propId } = useParams();
  const token = userDet?.token;
  const [profileServer, setProfileServer] = useState();
  const { execute, pending, data: postData } = usePost();
  const [profile, setProfile] = useState();

  // const [propCat, setPropCat] = useState();

  const { data } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/${propId}`,
    token
  );
  const [value, setValue] = useState();
  const [checkBlock, setCheckBlock] = useState();
  const [checkNote, setCheckNote] = useState(false);
  const [selectedFeatures, setselectedFeatures] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (data && data?.propertyType) {
      setValue(data?.propertyType);
    }
    if (data && data?.propertyBlock) {
      setCheckBlock(data?.propertyBlock);
    }
    if (data && data?.Note) {
      setCheckNote(true);
    }
  }, [data]);

  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    const Method = "PATCH",
      endPoint = `properties/${propId}`,
      isJSON = true;

    var formdata = new FormData();
    if (profileServer) {
      formdata.append("propertyImage", profileServer);
    }
    if (values.PropertyName) {
      formdata.append("propertyName", values.PropertyName);
    }
    if (values.categories) {
      formdata.append("propertyCategory", values.categories);
    }
    if (values.Type) {
      formdata.append("propertyType", values.Type);
    }
    if (values.buildingName) {
      formdata.append("buildingName", values.buildingName);
    }
    if (values.address) {
      formdata.append("address", values.address);
    }
    if (values.City) {
      formdata.append("city", values.City);
    }
    if (values.Country) {
      formdata.append("country", values.Country);
    }
    if (values.postalCode) {
      formdata.append("postalCode", values.postalCode);
    }
    if (checkBlock) {
      formdata.append("propertyBlock", checkBlock);
    }

    if (checkNote) {
      formdata.append("Note", values.Note);
    } else {
      formdata.append("Note", "");
    }
    selectedFeatures.map((feature) =>
      formdata.append("propertyFeatures", feature)
    );
    execute(
      endPoint,
      formdata,
      Method,
      "Property successfully updated",
      token,
      isJSON
    );
  };

  const selectHandle = (e) => {
    setValue(e.target.value);
  };

  function handleChange(event) {
    setPropCat(event.target.value);
  }

  const refFileUpload = useRef(null);
  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };

  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileServer(event.target.files[0]);

      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const propFeatures = [
    "Gym",
    "Generator",
    "Solar panel",
    "CCTV",
    "Storage",
    "Pool",
    "Parking facilities",
    "Elevator",
    "Emergency exit",
    "Center cooling",
    "Fire alarm",
    "Balcony",
    "Laundry",
    "Washing line",
    "Fire place",
    "Sauna",
    "Sprinkler systems",
  ];
  const onSelect = (event) => {
    setselectedFeatures(event);
  };
  const onRemove = (e) => {
    setselectedFeatures(e);
  };
  const multiselectStyles = {
    multiselectContainer: {
      backgroundColor: "#F7F7F7",
      border: "1px solid #F7F7F7",
      borderRadius: "4px",
    },
    optionContainer: {
      backgroundColor: "#fff",
      border: "1px solid #C8C8C8",
      color: "#333",
    },
    option: {
      backgroundColor: "#F7F7F7",
      border: "1px solid #C8C8C8",
      color: "#26a958",
    },
    chips: {
      backgroundColor: "#26a958",
      borderRadius: "4px",
      color: "#fff",
    },
  };
  const handleBlocks = (event) => {
    if (event.target.checked) {
      setCheckBlock(true);
    } else {
      setCheckBlock(false);
    }
  };
  const handleNote = (event) => {
    if (event.target.checked) {
      setCheckNote(true);
    } else {
      setCheckNote(false);
    }
  };
  if (postData?.status === "success") {
    setTimeout(() => {
      history.push(`/my-properties`);
    }, 3000);
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >
          <h5 className="text-center mb-5">Upload property front image</h5>
          <Row>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <ThreeCircles
                  height="50"
                  width="50"
                  color="#23B25A"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                  outerCircleColor=""
                  innerCircleColor=""
                  middleCircleColor=""
                />
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={
                    profile === undefined ? `${data?.propertyImage}` : profile
                  }
                  alt="user"
                  width={150}
                  height={150}
                  className="rounded"
                />
                <Button
                  size="sm"
                  variant="separator-light"
                  className="btn-icon btn-icon-only position-absolute rounded s-0 b-0 mt-5"
                  onClick={onThumbChangeClick}
                >
                  <i className="ion ion-md-image"></i>
                </Button>
                <input
                  type="file"
                  ref={refFileUpload}
                  className="file-upload d-none"
                  accept="image/*"
                  onChange={changeThumb}
                />
              </div>
            )}
          </Row>

          <Row>
            <Col md={2}>
              <div className="mb-3">
                <AvField
                  name="Type"
                  value={value}
                  onChange={selectHandle}
                  label="Property type"
                  className="Input__form"
                  type="select"
                >
                  {propertyType.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </AvField>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              {value === "Commercial" ? (
                <div className="mb-3">
                  <AvField
                    name="categories"
                    onChange={handleChange}
                    label="Property Categories"
                    className="Input__form"
                    type="select"
                  >
                    <option value={data?.propertyCategory}>
                      {data?.propertyCategory}
                    </option>
                    {propertyCategory[0].data.map((item, index) => {
                      if (data?.propertyCategory != item) {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      }
                    })}
                  </AvField>
                </div>
              ) : null}

              {value === "Residential" ? (
                <div className="mb-3">
                  <AvField
                    name="categories"
                    onChange={handleChange}
                    label="Property Categories"
                    className="Input__form"
                    type="select"
                  >
                    <option value={data?.propertyCategory}>
                      {data?.propertyCategory}
                    </option>
                    {propertyCategory[1].data.map((item, index) => {
                      if (data?.propertyCategory != item) {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      }
                    })}
                  </AvField>
                </div>
              ) : null}

              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="PropertyName"
                      label="Property Name"
                      className="form-control"
                      type="text"
                      value={data?.propertyName}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="buildingName"
                      label="Building Name"
                      className="form-control"
                      type="text"
                      value={data?.buildingName}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <AvField
                      name="address"
                      label="Address"
                      className="form-control"
                      type="text"
                      value={data?.address}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="Country"
                      label="Country"
                      className="form-control"
                      type="select"
                    >
                      <option value={data?.country}> {data?.country} </option>
                      {countryList.map((country, index) => {
                        if (data?.country != country) {
                          return (
                            <option value={country} key={index}>
                              {country}
                            </option>
                          );
                        }
                      })}
                    </AvField>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="City"
                      label="City"
                      className="form-control"
                      type="text"
                      value={data?.city}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <AvField
                      name="postalCode"
                      label="Postal Code(optional)"
                      className="form-control"
                      type="text"
                      value={data?.postalCode}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <label className="control-label">Property Features</label>
                    <Multiselect
                      isObject={false}
                      //
                      options={propFeatures}
                      selectedValues={data?.propertyFeatures}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      style={multiselectStyles}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="Input__form">
                  <input
                    type="checkbox"
                    checked={checkBlock}
                    onChange={handleBlocks}
                  />
                  <label className="mx-1 text-success">
                    My properrty has blocks
                  </label>
                </Col>
                <Col md={6} className="Input__form">
                  <input
                    type="checkbox"
                    checked={checkNote}
                    onChange={handleNote}
                  />
                  <label className="mx-1 text-success">Add Property Note</label>
                </Col>
                {checkNote ? (
                  <div className="mb-5 d-flex">
                    <div className="property_features">
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="Note"
                            value={data?.Note}
                            className="form-control"
                            type="textarea"
                            rows={7}
                          />
                        </div>
                      </Col>
                    </div>
                  </div>
                ) : null}
              </Row>
            </Col>
          </Row>

          <div className="mb-3 row ">
            <div className="col-12 d-flex justify-content-center">
              <button
                className="btn login-btn w-md waves-effect waves-light"
                type="submit"
              >
                {!pending ? <span className="me-2"> Submit </span> : null}

                {!pending ? null : (
                  <span>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      className="me-2"
                    />
                    Loading...
                  </span>
                )}
              </button>
            </div>
          </div>
        </AvForm>
      </div>
    </React.Fragment>
  );
}

export default PropertyEditForm;
