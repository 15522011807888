import PropTypes from "prop-types"
import React, { useState } from 'react'
import { Col, Container, Row, Card, CardBody, Modal } from "reactstrap";
import { MDBDataTable } from "mdbreact"
import { withRouter, Link, useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { BsEye, BsFillHouseFill } from 'react-icons/bs';
import { CSVLink } from "react-csv";

function Report() {
    return (
        <React.Fragment>
            <Row>
                <Col xl={4} md={6}>
                    <Link to="/assets-past-year">
                        <Card className="mini-stat  bg-black text-white bd-rds">
                            <CardBody>
                                <div className="mb-1">
                                    <div className="float-start mini-stat-img me-2 font-size-22">
                                        <BsFillHouseFill />
                                    </div>
                                    <h6 className="font-size-14 text-uppercase mt-0 text-white-50">
                                        PAST YEAR ASSETS
                                    </h6>
                                    <BsEye size={25} /> View
                                </div>
                            </CardBody>
                        </Card>
                    </Link>
                </Col>
                <Col xl={4} md={6}>
                    <Link to="/assets-current-month">
                        <Card className="mini-stat report-primary text-white bd-rds">
                            <CardBody>
                                <div className="mb-1">
                                    <div className="float-start mini-stat-img me-2 font-size-22">
                                        <BsFillHouseFill />
                                    </div>
                                    <h6 className="font-size-14 text-uppercase mt-0 text-white-50">
                                        CURRENT MONTH ASSETS
                                    </h6>
                                    <BsEye size={25} /> View
                                </div>
                            </CardBody>
                        </Card>
                    </Link>
                </Col>
            </Row>
        </React.Fragment>
    )
}
Report.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(Report))