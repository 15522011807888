import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, CardBody, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "react-phone-input-2/lib/bootstrap.css";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import usePost from "../../../hooks/usePost";
import { UtilitiesTypes } from "../../../components/UtilitiesTypes";

function UtilityChargesForm({
  propID,
  propType,
  unitID,
  closeModal,
  reFetchUtility,
}) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending } = usePost();
  const [Rate, setRate] = useState();
  const [consumption, setConsumption] = useState();
  const [other, setOther] = useState();

  const handleValidSubmit = (e, v) => {
    e.preventDefault();

    const Method = "POST",
      endPoint = `utilities/${propID}/${unitID}`,
      isJSON = null;
    const raw = JSON.stringify({
      utilityName: v.utilityName,
      type: v.type !== "Other" ? v.type : v.Specify,
      provider: v.provider,
      rate: v.rate,
      startDate: v.startDate,
      endDate: v.endDate,
      consumption: v.consumption,
      cost: v.utilityCost,
      billingPeriod: v.billingPeriod,
    });
    execute(
      endPoint,
      raw,
      Method,
      "Utility charge successfully added",
      token,
      isJSON
    );
    closeModal(false);
  };

  function handleChange(e) {
    setRate(e.target.value);
  }
  function handleChange2(e) {
    setConsumption(e.target.value);
  }
  if (pending) {
    setTimeout(() => {
      reFetchUtility();
    }, 2000);
  }
  function handleChangeType(event) {
    if (event.target.value === "Other") {
      setOther(event.target.value);
    }
  }
  return (
    <React.Fragment>
      <CardBody>
        <Row className="align-items-center">
          <Col md={12} lg={12} xl={12}>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      type="text"
                      id="name"
                      name="utilityName"
                      className="form-control"
                      label="Utility Name"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    {propType === "Commercial" ? (
                      <AvField
                        type="select"
                        id="name"
                        name="type"
                        onChange={handleChangeType}
                        className="form-control"
                        label="Utility Type"
                      >
                        <option> Select... </option>
                        {UtilitiesTypes?.Commercial?.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          );
                        })}
                      </AvField>
                    ) : propType === "Residential" ? (
                      <AvField
                        type="select"
                        id="name"
                        name="type"
                        onChange={handleChangeType}
                        className="form-control"
                        label="Utility Type"
                      >
                        <option> Select... </option>
                        {UtilitiesTypes?.Residential?.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          );
                        })}
                      </AvField>
                    ) : null}
                  </div>
                </Col>
              </Row>
              {other === "Other" ? (
                <Row>
                  <Col md={12}>
                    <AvField
                      name="Specify"
                      label="Specify utility type"
                      type="text"
                      required
                      className="form-control mb-3"
                    />
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <AvField
                      type="text"
                      id="name"
                      name="provider"
                      className="form-control"
                      label="Provider"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <AvField
                      name="rate"
                      label="Rate"
                      className="form-control"
                      type="number"
                      onChange={handleChange}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <AvField
                      name="consumption"
                      label="consumption"
                      className="form-control"
                      type="number"
                      onChange={handleChange2}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <AvField
                      value={Rate * consumption}
                      name="utilityCost"
                      label="Utility cost"
                      className="form-control"
                      type="number"
                      disabled
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <AvField
                      name="billingPeriod"
                      label="Billing Period"
                      className="form-control"
                      type="select"
                      required
                    >
                      <option> Select... </option>
                      <option> once off </option>
                      <option> monthly </option>
                    </AvField>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <AvField
                      name="startDate"
                      label="Start Date"
                      className="form-control"
                      type="date"
                      required
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <AvField
                      name="endDate"
                      label="End Date"
                      className="form-control"
                      type="date"
                      required
                    />
                  </div>
                </Col>
              </Row>

              <div className="text-start mt-5">
                <button
                  className="btn login-btn  waves-effect waves-light"
                  type="submit"
                >
                  {!pending ? <span className="me-2">Submit</span> : null}
                  {!pending ? null : (
                    <span>
                      {" "}
                      <Spinner as="span" animation="border" size="sm" />{" "}
                      Loading...
                    </span>
                  )}
                </button>
              </div>
            </AvForm>
          </Col>
        </Row>
      </CardBody>
    </React.Fragment>
  );
}

UtilityChargesForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UtilityChargesForm));
