import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import Nav from "./components/Nav";

import AssignTable from "./components/AssignTable";
import WorkingTable from "./components/WorkingTable";
import ClosedTable from "./components/ClosedTable";
import ReopenTable from "./components/ReopenTable";
import OverdueTable from "./components/OverdueTable";

function MyTickets() {
  const [assignTicket, setAssignTicket] = useState(true);
  const [workingTicket, setWorkingTicket] = useState(false);
  const [clodedTicket, setClodedTicket] = useState(false);
  const [reopenTicket, setReopenTicket] = useState(false);
  const [overdueTicket, setOverdueTicket] = useState(false);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | My tickets" />
        <Breadcrumb default="Maintenance Manangement" title="My tickets" />

        <Nav
          assignTicket={assignTicket}
          setAssignTicket={setAssignTicket}
          workingTicket={workingTicket}
          setWorkingTicket={setWorkingTicket}
          clodedTicket={clodedTicket}
          setClodedTicket={setClodedTicket}
          overdueTicket={overdueTicket}
          setOverdueTicket={setOverdueTicket}
          reopenTicket={reopenTicket}
          setReopenTicket={setReopenTicket}
        />
        <Row className="d-flex justify-content-around align-items-center">
          <CardBody data-aos="fade-bottom">
            <Card className="bd-rds">
              <CardBody>
                {assignTicket ? <AssignTable /> : null}
                {workingTicket ? <WorkingTable /> : null}
                {clodedTicket ? <ClosedTable /> : null}
                {reopenTicket ? <ReopenTable /> : null}
                {overdueTicket ? <OverdueTable /> : null}
              </CardBody>
            </Card>
          </CardBody>
        </Row>
      </div>
    </React.Fragment>
  );
}

MyTickets.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(MyTickets));
