import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"
import ResidentialForm from './Residential-form'
import ResidentialUnitForm from './Residential-unit-form'
import UnitForm from './UnitFormWithNoBlock'



const FormWizard = props => {
  const [activeTab, setactiveTab] = useState(1)
  const [activeTabProgress, setactiveTabProgress] = useState(1)
  const [progressValue, setprogressValue] = useState(25)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [btn_Valid, setBtnValid] = useState()

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
      }
    }
  }

  function toggleTabVertical(tab) {

    if (activeTabVartical !== tab) {
      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
      }
    }
  }

  function toggleTabProgress(tab) {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTabProgress(tab)

        if (tab === 1) {
          setprogressValue(25)
        }
        if (tab === 2) {
          setprogressValue(50)
        }
        if (tab === 3) {
          setprogressValue(75)
        }
        if (tab === 4) {
          setprogressValue(100)
        }
      }
    }
  }

  return (
    <React.Fragment>
      <Container fluid={true}  >

        <Row>
          <Col sm="12" >
            <Card className="bd-rds" data-aos="fade-bottom">
              <CardBody>

                <h4 className="card-title mb-4"> Add New Properties </h4>

                <div className="form-horizontal form-wizard-wrapper wizard clearfix">

                  <div className="steps clearfix">
                    <ul>
                      <NavItem className={classnames, "nav_tab", ({ current: activeTab === 1 })}>
                        <NavLink className={classnames({ current: activeTab === 1 })} onClick={() => { setactiveTab(1) }}>
                          <span className="number">1.</span>  Add Properties
                        </NavLink>
                      </NavItem>

                      <NavItem className={classnames({ current: activeTab === 2 })}>
                        <NavLink className={classnames({ active: activeTab === 2 })} onClick={() => { setactiveTab(2) }}>
                          <span className="number">2.</span> Add a block
                        </NavLink>
                      </NavItem>
                      <NavItem className={classnames({ current: activeTab === 3 })}>
                        <NavLink className={classnames({ active: activeTab === 3 })} onClick={() => { setactiveTab(3) }}>
                          <span className="number">2.</span> Add Unit Details
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>

                  <div>

                    <TabContent activeTab={activeTab} className="body">
                      <TabPane tabId={1}>
                        <Row>
                          <Col md={12}>
                            <ResidentialForm />
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId={2}>
                        <Row>
                          <Col md={12}>
                            <ResidentialUnitForm />
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>

                    {/* <div className="actions clearfix">
                      <ul>

                        <li className={activeTab === 1 ? "previous disabled" : "previous"}>
                          <Link to="#" className="btn login-btn w-md waves-effect waves-light" onClick={() => { toggleTab(activeTab - 1) }}> Previous</Link>
                        </li>

                        {
                          activeTab < 2 ? <li className={activeTab === 2 ? "next disabled" : null}>
                            <button className="btn login-btn w-md waves-effect waves-light" onClick={() => { toggleTab(activeTab + 1) }} type="submit"> {activeTab === 2 ? "Submit" : "Next"}  </button>
                          </li> : null
                        }

                      </ul>
                    </div> */}

                  </div>

                </div>
              </CardBody>

            </Card>

          </Col>
        </Row>

      </Container>
    </React.Fragment>
  )
}

export default FormWizard