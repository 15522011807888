import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
  Badge,
  Button,
} from "reactstrap";

import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { Link, useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import SendForm from "./SendForm";
import useFecth from "../../../../hooks/useFecth";
import logo from "../../../../assets/images/faspro24_favicon-original.png";
import showNotification from "react-push-notification";

//* Socket-io
import io from "socket.io-client";
// const socket = io.connect(`${process.env.REACT_APP_LOCAL}`); //when you are in production  =>  https://squid-app-hsedq.ondigitalocean.app
const socket = io.connect("https://squid-app-hsedq.ondigitalocean.app");

function RequestTable({
  setRequestService,
  setNoApproveService,
  setNoDeclineService,
}) {
  const history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const [ticketId, setTicketId] = useState(null);
  const [ticketModal, setTicketModal] = useState(false);
  const userId = userDet?.data?._id;

  useEffect(() => {
    socket.emit("register_service_provider", userId);
  }, [userId]);

  // console.log(" id : ", userId);

  const RequestList = [];
  const acceptedList = [];
  const declinedList = [];

  const { data: getRequestTickets, reFetch: reFetchGetRequestTickets } =
    useFecth(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceTickets/${userId}/serviceProvider`,
      token
    );

  getRequestTickets?.forEach((res) => {
    if (res?.status === "open") {
      RequestList.push({
        ...res,
        propertyName: res?.propertyId?.propertyName,
        assidnedTo: `${res?.assigned_to?.firstName} ${res?.assigned_to?.lastName}`,
        status:
          res?.status === "open" ? (
            <>
              <Badge className="rounded-pill bg-primary p-2">Opened</Badge>
            </>
          ) : null,
        viewBtn: (
          <button
            className="btn btn-dark"
            onClick={() => viewFunc(`${res?._id}`)}
          >
            View
          </button>
        ),
        sendInvoice: (
          <button
            className="btn btn-success"
            onClick={() => sendInvoiceFunc(`${res?._id}`)}
          >
            Send Quotation
          </button>
        ),
      });
    }
  });

  const viewFunc = (id) => {
    history.push(`/view-ticket/${id}`);
  };

  const sendInvoiceFunc = (id) => {
    setTicketId(id);
    setTicketModal(true);
  };

  const column = [
    { label: "Property name", field: "propertyName", sort: "asc", width: 150 },
    { label: "Title Name", field: "subject", sort: "asc", width: 150 },
    {
      label: "Ticket Reference",
      field: "ticketNumber",
      sort: "asc",
      width: 150,
    },
    { label: "Status", field: "status", sort: "asc", width: 150 },

    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Action", field: "sendInvoice", sort: "asc", width: 150 },
  ];

  const dataDB = { columns: column, rows: RequestList.reverse() };

  // *********************************************NOTIFICATION*********************************************
  const { data: getNotifications } = useFecth(
    // `https://squid-app-hsedq.ondigitalocean.app/api/v1/notifications/${userId}`,
    // token
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/notifications/${userId}`,
    token
  );

  const [liveNotifications, setLiveNotifications] = useState([]);

  //* This will notify the user whenever the was a notification send to him and he was not connected to the server
  useEffect(() => {
    if (getNotifications && getNotifications.length > 0) {
      // alert("There are new tickets that were created while you were away!");
      showNotification({
        title: "Faspro 24",
        message: "New Requested Service !!!",
        duration: 5000,
        icon: logo,
        native: true,
        onClick: () => {
          history.push("/request-service");
        },
      });
    }
  }, [getNotifications]);

  //* This will notify the user whenever he is live on the website
  useEffect(() => {
    // setRequestService(RequestList?.length);
    // setNoApproveService(acceptedList?.length);
    // setNoDeclineService(declinedList?.length);

    const handleNewTicket = (new_ticket) => {
      showNotification({
        title: "Faspro 24",
        message: "New Requested Service !!!",
        duration: 5000,
        icon: logo,
        native: true,
        onClick: () => {
          alert("/request-service");
        },
      });

      setLiveNotifications((prevNotifications) => [
        ...prevNotifications,
        new_ticket,
      ]);
    };

    socket.on("receive_new_ticket", handleNewTicket);

    return () => {
      socket.off("receive_new_ticket", handleNewTicket);
    };
  }, [socket]);

  const allNotifications = [...getNotifications, ...liveNotifications];

  // ******************************END******************************

  return (
    <div>
      {/* <div>
      // Render the notifications 
        {allNotifications.map((notification, index) => {
          console.log("notification...", notification);
          return (
            <div key={index}>
              {notification.message}
             // Add more properties or buttons as needed 
            </div>
          );
        })}
      </div> */}

      <Card className="bd-rds">
        <CardBody>
          <div className="d-flex justify-content-end">
            <button className="btn login-btn w-md waves-effect waves-light mb-4">
              <CSVLink data={"dataDb"} separator={";"} className="text-white">
                Download in Excel
              </CSVLink>
            </button>
          </div>
          <MDBDataTable
            entries={5}
            entriesOptions={[5, 10, 50]}
            responsive
            bordered
            striped
            hover
            data={dataDB}
          />
        </CardBody>
      </Card>
      <Modal show={ticketModal} onHide={() => setTicketModal(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Send Quotation </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SendForm
            ticketId={ticketId}
            setTicketModal={setTicketModal}
            reFetchGetRequestTickets={reFetchGetRequestTickets}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RequestTable;
