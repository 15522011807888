import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col, Container, Row, Card, Modal, Button } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
import Loading from "components/Loading";
import NoticeDetails from "./components/NoticeDetails";
import useFetch from "../../hooks/useFecth";
import usePost from "../../hooks/usePost";
import { useStore1Selector } from "./../../index";
import { loginUser } from "../../Redux/Slices/userSlice";

function UnitNotice() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { propID, unitID } = useParams();
  const { execute, pending } = usePost();

  const [viewID, setViewID] = useState();
  const [editID, setEditID] = useState();
  const [delteID, setDeleteID] = useState();

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  // const [List, setList] = useState();
  let list = [];

  const { data, reFetch } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/notices/unit/${unitID}`,
    token
  );

  function viewFunc(id) {
    setViewID(id);
    setViewModal(true);
  }

  function editFunc(id) {
    setEditID(id);
    setEditModal(true);
  }

  function deleteFunc(id) {
    setDeleteID(id);
    setDeleteModal(true);
  }

  const deleteNotice = () => {
    const Method = "DELETE",
      endPoint = `notices/${delteID}`,
      isJSON = true;
    var formdata = new FormData();
    execute(
      endPoint,
      formdata,
      Method,
      "Notice Successfully Deleted",
      token,
      isJSON
    );
    setDeleteModal(false);
  };

  data.forEach((res) => {
    list.push({
      ...res,
      unitName: res?.unitId?.unitName,
      viewBtn: (
        <button className="btn btn-success" onClick={() => viewFunc(res?._id)}>
          view
        </button>
      ),
      editBtn: (
        <button className="btn btn-primary" onClick={() => editFunc(res?._id)}>
          Edit
        </button>
      ),
      deleteBtn: (
        <button className="btn btn-danger" onClick={() => deleteFunc(res?._id)}>
          Delete
        </button>
      ),
    });
  });

  if (pending) {
    setTimeout(() => {
      reFetch();
    }, 2000);
  }
  const column = [
    { label: "Unit Name", field: "unitName", sort: "asc", width: 150 },
    { label: "Subject", field: "subject", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const newDdata = {
    columns: column,
    rows: list,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | Unit Notice" />
        <Breadcrumb default="Property" title="Unit Notices" />

        <Card.Body>
          <Link
            to={`/unit-details/${propID}/${unitID}`}
            className="btn login-btn mb-2"
          >
            Back
          </Link>
          <Container fluid>
            <Row className="d-flex justify-content-around align-items-center">
              <Card className="bd-rds">
                <Card.Body data-aos="fade-bottom">
                  <div className="d-flex justify-content-between">
                    <button className="btn report-warning w-md waves-effect waves-light mb-4 d-flex justify-content-end">
                      <CSVLink
                        data={list}
                        separator={";"}
                        className="text-white"
                      >
                        Download in Excel
                      </CSVLink>
                    </button>
                  </div>
                  <MDBDataTable
                    entries={5}
                    entriesOptions={[5, 10, 50]}
                    responsive
                    bordered
                    striped
                    hover
                    data={newDdata}
                    fullPagination
                  />
                </Card.Body>
              </Card>
            </Row>
          </Container>
        </Card.Body>

        <Modal
          className="modal-right scroll-out-negative"
          show={viewModal}
          onHide={() => setViewModal(false)}
          scrollable
          dialogClassName="full"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title> Notice Details </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* <NoticeDetails unitDetails={unitDetails} /> */}
          </Modal.Body>
        </Modal>

        {/* <Modal
                    className="modal-right scroll-out-negative"
                    show={editModal}
                    onHide={() => setEditModal(false)}
                    scrollable
                    dialogClassName="full">

                    <Modal.Header closeButton>
                        <Modal.Title> Edit Notice </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>      
                    </Modal.Body>
                </Modal> */}

        <Modal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          size="sm"
        >
          <Modal.Body className="text-danger">
            {" "}
            <h6> Are you sure you want to delete this ? </h6>{" "}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setDeleteModal(false)}>
              {" "}
              Cancel{" "}
            </Button>
            <Button variant="danger" onClick={deleteNotice}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}

UnitNotice.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UnitNotice));
