import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  CardTitle,
  CardBody,
  Form,
  Spinner,
  Button,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import usePost from "../../../hooks/usePost";

function CloseForm({ closeID, reFetchMaintenanceTickets, setCloseModal }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const userId = userDet?.data?.id;
  const { execute, pending, data: postData } = usePost();
  const [note, setNote] = useState("");
  const [resolvedAttachment, setResolvedAttachment] = useState(null);
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleValidSubmit = (e) => {
    e.preventDefault();

    const Method = "PATCH",
      endPoint = `maintenanceTickets/${closeID}/moveToClosed`,
      isJSON = true;

    var formdata = new FormData();
    if (resolvedAttachment) {
      resolvedAttachment.map((item) => {
        formdata.append("resolvedAttachment", item);
      });
    }

    formdata.append("closingComments", note);
    formdata.append("status", "closed");
    formdata.append("ticketClosedBy", userId);

    execute(
      endPoint,
      formdata,
      Method,
      "Ticket successfully closed",
      token,
      isJSON
    );
  };

  useEffect(() => {
    if (postData?.status === "success") {
      reFetchMaintenanceTickets();
      setCloseModal(false);
    }
  }, [postData?.status]);

  function handleAcceptedFiles(event) {
    const selectedFiles = event.target.files;
    const fileArray = Array.from(selectedFiles);
    setResolvedAttachment(fileArray);

    const previews = fileArray.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  }

  return (
    <>
      <form className="form-horizontal mt-3" onSubmit={handleValidSubmit}>
        <Row>
          <Col md={12}>
            <div
              className="dropzone-previews d-flex align-items-center"
              id="file-previews"
            >
              {imagePreviews.map((previewURL, index) => (
                <img
                  key={index}
                  data-dz-thumbnail=""
                  height="180"
                  className="avatar-sm rounded bg-light m-2 border border-dark"
                  alt={`Preview ${index}`}
                  src={previewURL}
                />
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="mb-3 drop-zone">
              <label htmlFor="attachment" className="drop-zone-label">
                <i className="fas fa-cloud-upload-alt"></i>
                <div className="drop-zone-text">
                  Attach pictures of the job done
                </div>
              </label>
              <input
                type="file"
                id="attachment"
                className="form-control drop-zone-input"
                multiple
                accept="image/jpeg, image/png, image/gif"
                onChange={handleAcceptedFiles}
              />
            </div>
          </Col>
        </Row>

        <div className="mb-3">
          <label>Additional Notes</label>
          <textarea
            className="form-control"
            onChange={(e) => setNote(e.target.value)}
            rows={6}
          />
        </div>

        <div className="d-flex justify-content-center">
          <button
            className="btn login-btn w-md waves-effect waves-light"
            type="submit"
          >
            {!pending ? (
              "Submit"
            ) : (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="me-2"
                />
                Loading...
              </>
            )}
          </button>
        </div>
      </form>
    </>
  );
}

export default CloseForm;
