import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import user1 from "../../../assets/images/users/user-4.png";
import { useStore1Selector, useStore1Dispatch } from "./../../../index";
import { loginUser, Login } from "../../../Redux/Slices/userSlice";

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);
  const userDet = useStore1Selector(loginUser);
  const dispatch = useStore1Dispatch();
  const profileImg = userDet?.data?.profilePic;

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={!profileImg ? user1 : `${profileImg}`}
            alt="Header Avatar"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {userDet?.RoleID === 6 || userDet?.RoleID === 3 ? null : (
            <Link to="/my-profile">
              <DropdownItem tag="a">
                <i className="bx bx-user font-size-16 align-middle me-1" />
                {props.t("Profile")}
              </DropdownItem>
            </Link>
          )}

          <div className="dropdown-divider" />

          <Link
            to="/login"
            className="dropdown-item"
            onClick={() => {
              dispatch(Login(""));
              localStorage.removeItem("persist:users");
            }}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
