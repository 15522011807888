import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";

const LineBar = ({ invoiceByUnit }) => {
  const resRent = invoiceByUnit?.map((item) => item?.rentalAmount);
  const resExtraCharges = invoiceByUnit?.map((item) => item?.extraCharges);
  const resUtilities = invoiceByUnit?.map((item) => item?.utilityCharges);
  const resMonth = invoiceByUnit?.map((item) => item?.month?.substring(0, 3));
  const getOption = () => {
    return {
      series: [
        {
          name: "Rent Amount",
          type: "bar",
          data: resRent,
        },
        {
          name: "Utilities",
          type: "bar",
          data: resUtilities,
        },
        {
          name: "Extra Charges",
          type: "bar",
          data: resExtraCharges,
        },
      ],
      grid: {
        zlevel: 0,
        x: 70,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
        backgroundColor: "rgba(0,0,0,0)",
        borderColor: "rgba(0,0,0,0)",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      toolbox: {
        feature: {
          dataView: { show: false, readOnly: false },
          magicType: { show: false, type: ["line", "bar"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      color: ["#26A958", "#1BC5C5", "#FB0404"],
      legend: {
        data: ["Rent Amount", "Utilities", "Extra Charges"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      xAxis: [
        {
          type: "category",
          data: resMonth,
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: `GH₵`,
          min: 0,
          max: 100000,
          interval: 10000,
          axisLabel: {
            formatter: `{value}`,
          },
          axisLine: {
            lineStyle: {
              color: "#74788d",
            },
          },
        },
        {
          //   type: "value",
          //   name: "Temperature",
          //   min: 0,
          //   max: 25,
          //   interval: 5,
          //   axisLabel: {
          //     formatter: "{value} °C",
          //   },
        },
      ],
    };
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={getOption()} />
    </React.Fragment>
  );
};
export default LineBar;
