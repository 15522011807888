import React, { useState, useEffect } from "react";
import { Card, CardBody, Spinner, Badge, Button } from "reactstrap";

import { Modal } from "react-bootstrap";

import { MDBDataTable } from "mdbreact";
import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone";

import { CSVLink } from "react-csv";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import CloseForm from "./CloseForm";
import useFecth from "../../../hooks/useFecth";
import usePost from "../../../hooks/usePost";

function WorkingTable() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const userId = userDet?.data?._id;
  const { execute, pending, data: postData } = usePost();
  const history = useHistory();
  const [closeID, setCloseID] = useState();
  const [closeModal, setCloseModal] = useState(false);

  const list = [];

  const { data: getMaintenanceTickets, reFetch: reFetchMaintenanceTickets } =
    useFecth(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceTickets/${userId}/serviceProvider`,
      token
    );

  getMaintenanceTickets?.forEach((res) => {
    if (res?.status === "pending") {
      list.push({
        ...res,
        propertyName: res?.propertyId?.propertyName,
        assidnedTo: `${res?.assigned_to?.firstName} ${res?.assigned_to?.lastName}`,
        status:
          res?.status === "pending" ? (
            <>
              <Badge className="rounded-pill bg-warning p-2">Pending</Badge>
            </>
          ) : null,
        viewBtn: (
          <button
            className="btn btn-success"
            onClick={() => viewFunc(`${res?._id}`)}
          >
            View
          </button>
        ),
        closeBtn: (
          <button
            className="btn btn-dark"
            onClick={() => closedFunc(`${res?._id}`)}
          >
            Close
          </button>
        ),
      });
    }
  });

  const column = [
    { label: "Property name", field: "propertyName", sort: "asc", width: 150 },

    { label: "Title Name", field: "subject", sort: "asc", width: 150 },
    // { label: "Ticket Reference", field: "TicketRef", sort: "asc", width: 150 },
    { label: "Status", field: "status", sort: "asc", width: 150 },
    {
      label: "Assign To",
      field: "assidnedTo",
      sort: "asc",
      width: 150,
    },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Close Ticket", field: "closeBtn", sort: "asc", width: 150 },
  ];

  const dataDB = { columns: column, rows: list };

  const viewFunc = (id) => {
    history.push(`/view-ticket/${id}`);
  };

  const closedFunc = (id) => {
    setCloseID(id);
    setCloseModal(true);
  };

  const handleCloseTicket = () => {
    const Method = "PATCH",
      endPoint = `maintenanceTickets/${closeID}/moveToPending`,
      isJSON = null;

    const raw = JSON.stringify({
      status: "closed",
    });

    execute(endPoint, raw, Method, "Ticket successfully closed", token, isJSON);
  };

  useEffect(() => {
    if (postData?.status === "success") {
      reFetchMaintenanceTickets();
      setCloseModal(false);
    }
  }, [postData?.status]);

  return (
    <div>
      <Card className="bd-rds">
        <CardBody>
          <div className="d-flex justify-content-end">
            <button className="btn login-btn w-md waves-effect waves-light mb-4">
              <CSVLink data={"dataDb"} separator={";"} className="text-white">
                Download in Excel
              </CSVLink>
            </button>
          </div>
          <MDBDataTable
            entries={5}
            entriesOptions={[5, 10, 50]}
            responsive
            bordered
            striped
            hover
            data={dataDB}
            fullpagination="true"
          />
        </CardBody>
      </Card>

      <Modal show={closeModal} onHide={() => setCloseModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Close Ticket </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* <div className="text-danger h4">
            You are about to close this ticket
          </div> */}

          <CloseForm
            closeID={closeID}
            setCloseModal={setCloseModal}
            reFetchMaintenanceTickets={reFetchMaintenanceTickets}
          />
        </Modal.Body>
        {/* <Modal.Footer className="d-flex justify-content-end p-2">
          <Button className="bg-success text-white" onClick={handleCloseTicket}>
            {!pending ? (
              "Proceed"
            ) : (
              <span>
                <Spinner as="span" animation="border" size="sm" /> Loading...
              </span>
            )}
          </Button>
          <Button
            className="bg-danger text-white"
            onClick={() => setCloseModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}

export default WorkingTable;
