import PropTypes from "prop-types"
import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody } from "reactstrap";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact"
import { withRouter, Link, useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";

function AllNotice() {

  const [getNotice, setGetnotice] = useState();
  const getAllNotice = () => {
    var formdata = new FormData();
    formdata.append("OwnerID", "1");

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("http://prop.faspro24.com/api/notice/GetNoticeByID", requestOptions)
      .then(response => response.json())
      .then(result => {
        setGetnotice(result.data)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    getAllNotice()
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Notice" />
        <Breadcrumb default="Property" title="Notice" />
        <CardBody>
          <Container fluid>
            <div className="page-title-box" >
              <CardBody data-aos="fade-bottom">

                {/* <Row className="align-items-center mb-5">
                  <div>
                    <Link to="/add-notice" className="btn report-success text-white w-md waves-effect waves-light" type="submit"> + Add Notice </Link>
                  </div>
                </Row> */}
                {
                  getNotice?.map(res => {
                    console.log(res)
                    return (
                      <Card className="bd-rds">
                        <div className="TenantHeader bd-rds-header">
                          <Row>
                            <Col md={6}>
                              <h5> issuer {res.Issuer}</h5>
                            </Col>
                            <Col md={6}>
                              <p> Assign date {res?.Date} </p>
                            </Col>
                          </Row>
                        </div>

                        <CardBody>
                          <Row className="align-items-center">
                            <p> <b> Title :  </b> {res.Title} </p>
                            <p> <b> Description : </b> {res.Description} </p>
                            <p> <b> Documents : </b> {res.Document} </p>
                          </Row>

                          <button className="btn login-btn w-md waves-effect waves-light" type="submit" > Get PDF </button>
                        </CardBody>
                      </Card>
                    )
                  })
                }
              </CardBody>
            </div>
          </Container>

        </CardBody>

      </div>
    </React.Fragment>
  )
}

AllNotice.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(AllNotice))