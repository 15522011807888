import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStore1Selector, useStore1Dispatch } from "../../../index";
import { loginUser, Login } from "../../../Redux/Slices/userSlice";
import { Col, Row, Card, CardBody, Spinner, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useHistory } from "react-router-dom";
import usePost from "../../../hooks/usePost";

function UserPassword({ getUserById, token }) {
  const userDet = useStore1Selector(loginUser);
  const dispatch = useStore1Dispatch();
  const history = useHistory();
  const { execute, pending, data: postDetails } = usePost();
  const {
    execute: executeLogout,
    pending: executePending,
    data: postLogout,
  } = usePost();

  const handleBtnUpdate = (e, values) => {
    e.preventDefault();
    const Method = "PATCH",
      endPoint = `auth/updateMyPassword`;
    const raw = JSON.stringify({
      passwordCurrent: values.passwordCurrent,
      password: values.password,
      passwordConfirm: values.passwordConfirm,
    });
    execute(endPoint, raw, Method, "Password successfully updated", token);
  };

  const Logout = () => {
    const Method = "POST",
      endPoint = "auth/logout";
    const raw = "";
    execute(
      endPoint,
      raw,
      Method,
      "You will be redirected to login page",
      token
    );
    dispatch(Login(""));
    localStorage.removeItem("persist:users");
    setTimeout(() => {
      history.push(`/login`);
    }, 3000);
  };

  if (postDetails?.status === "success") {
    Logout();
  }

  return (
    <>
      <AvForm
        className="form-horizontal mt-4 "
        onValidSubmit={(e, v) => {
          handleBtnUpdate(e, v);
        }}
      >
        <h5 className="text-center mb-4">Password</h5>

        <Row>
          <Col md={4}>
            <div className="mb-3">
              <AvField
                name="passwordCurrent"
                label="Current Password"
                type="password"
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-3">
              <AvField name="password" label="New Password" type="password" />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-3">
              <AvField
                name="passwordConfirm"
                label="Confirm Password"
                type="password"
              />
            </div>
          </Col>
        </Row>

        <div className="d-flex justify-content-center mt-5">
          <div className="mb-3 mt-3 w-25">
            <button
              className="btn btn-dark w-100 waves-effect waves-light text-center"
              type="submit"
            >
              {!pending ? <span className="me-2">Update Password</span> : null}
              {!pending ? null : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading...
                </span>
              )}
            </button>
          </div>
        </div>
      </AvForm>
    </>
  );
}

export default UserPassword;
