import PropTypes from "prop-types";
import React, { useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { loginUser } from "../../../Redux/Slices/userSlice";
import { useStore1Selector } from "./../../../index";
import useFetch from "../../../hooks/useFecth";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import usePost from "hooks/usePost";
import { UpdateNoticeMsg } from "components/NotifyMessage";
import CustomBtn from "components/CustomBtn";

function EditNotice({ noticeId, closeModal, reFetch, t }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const [state, setState] = useState();
  const { execute, pending, data: postData } = usePost();

  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    const Method = "PATCH",
      endPoint = `notices/${noticeId}`,
      isJSON = true;

    const formdata = new FormData();
    formdata.append("subject", values.title);
    formdata.append("description", values.notice);
    formdata.append("noticeDocument", state);

    execute(endPoint, formdata, Method, UpdateNoticeMsg, token, isJSON);
  };

  const { data } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/notices/${noticeId}`,
    token
  );

  const onFileChange = (e) => {
    setState(e.target.files[0]);
  };

  if (postData?.status === "success") {
    closeModal();
    setTimeout(() => {
      reFetch();
    }, 2000);
  }

  return (
    <div>
      <AvForm
        className="form-horizontal mt-4"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <div className="mb-3">
          <span> {t("Title")} </span>
          <AvField
            name="title"
            value={data?.subject}
            className="form-control"
            type="text"
          />
        </div>

        <div className="mb-3">
          <span> {t("Attach a document")} </span>
          <AvField
            name="name"
            type="file"
            className="form-control"
            onChange={onFileChange}
          />
        </div>

        <div className="mb-3">
          <span>{t("Description")}</span>
          <AvField
            className="form-control"
            type="textarea"
            rows="3"
            name="notice"
            value={data?.description}
            errorMessage="This value is required."
            validate={{ required: { value: true } }}
          />
        </div>

        <CustomBtn Pending={pending} btnName="Submit" />
      </AvForm>
    </div>
  );
}

EditNotice.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(EditNotice));
