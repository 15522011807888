const LoginMeta = "FASPRO 24 | Login";
const ForgotMeta = "FASPRO 24 | Forgot Password";
const ResetMeta = "FASPRO 24 | Reset Password";
const EmailMeta = "FASPRO 24 | Verification Email";
const SignupMeta = "FASPRO 24 | Sign Up";
const PropertyOwnerMeta = "FASPRO 24 | Property Owner";
const PropertyAgencyMeta = "FASPRO 24 | Property Agency";
const PropertyTeamsMeta = "FASPRO 24 | Property Teams";
const PropertyManagerMeta = "FASPRO 24 | Property Manager";

export {
  LoginMeta,
  ForgotMeta,
  ResetMeta,
  EmailMeta,
  SignupMeta,
  PropertyOwnerMeta,
  PropertyAgencyMeta,
  PropertyTeamsMeta,
  PropertyManagerMeta,
};
