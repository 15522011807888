import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";

const OverdueLinechart = ({ getAllstats }) => {
  const resRentOverdue = getAllstats?.map(
    (item) => item?.total_Overdue_RentAmount
  );
  const resOverdueUtility = getAllstats?.map(
    (item) => item?.total_Overdue_UtilityRentAmount
  );
  const resOverdueExtraCharge = getAllstats?.map(
    (item) => item?.total_Overdue_ExtraChargesRentAmount
  );

  const resMonth = getAllstats?.map((item) => item?.month?.substring(0, 3));

  const getOption = () => {
    return {
      series: [
        {
          name: "Rent Amount",
          type: "bar",
          data: resRentOverdue,
        },
        {
          name: "Utilities",
          type: "bar",
          data: resOverdueUtility,
        },
        {
          name: "Extra Charges",
          type: "bar",
          data: resOverdueExtraCharge,
        },
      ],
      grid: {
        zlevel: 0,
        x: 70,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
        backgroundColor: "rgba(0,0,0,0)",
        borderColor: "rgba(0,0,0,0)",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      toolbox: {
        feature: {
          dataView: { show: false, readOnly: false },
          magicType: { show: false, type: ["line", "bar"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      color: ["#FC0000", "#DB6060", "#FDA3A3"],
      legend: {
        data: ["Rent Amount", "Utilities", "Extra Charges"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      xAxis: [
        {
          type: "category",
          data: resMonth,
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: `GH₵`,
          min: 0,
          max: 100000,
          interval: 10000,
          axisLabel: {
            formatter: `{value} `,
          },
          axisLine: {
            lineStyle: {
              color: "#74788d",
            },
          },
        },
      ],
    };
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={getOption()} />
    </React.Fragment>
  );
};
export default OverdueLinechart;
