import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { AiFillPrinter } from "react-icons/ai";

import { withTranslation } from "react-i18next";
// import Navbar from "./components/Navbar";

import PropertyReport from "./components/PropertyReport";

function Report({ t }) {
  const handlePrint = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Bookings  Management Report" />
        <Breadcrumb default={t("Bookings  Management")} title={t("report")} />
        <Container fluid>
          <Row>
            <Col md={12}>
              <PropertyReport />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

Report.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(Report));
