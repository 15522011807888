import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Col, Row, CardBody, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter, Link, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import gender from "../../../components/Gender";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFecth";
import Avatar from "../../../assets/images/Avatar.png";
import Multiselect from "multiselect-react-dropdown";

function EditTeamForm({ viewID, setViewModal, reFetchMyTeam }) {
  const history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending, data: postData } = usePost();

  const { data: getTeamDetails, reFetch: reFetchTeamDetails } = useFetch(
    `users/${viewID}`,
    token
  );

  const handleValidSubmit = (e, v) => {
    e.preventDefault();

    const Method = "PATCH",
      endPoint = `users/${viewID}/updateUser`;
    const raw = JSON.stringify({
      roles: v.roles,
    });
    execute(endPoint, formdata, Method, "User successfully updated", token);
    setViewModal(false);
  };
  if (postData?.status === "success") {
    setTimeout(() => {
      reFetchMyTeam();
    }, 3000);
  }
  console.log("display details....", getTeamDetails);
  return (
    <React.Fragment>
      <CardBody>
        <Row className="align-items-center">
          <Col md={12} lg={12} xl={12}>
            <AvForm
              className="form-horizontal mt-3"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <Row>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={Avatar}
                    alt="user"
                    width={150}
                    height={150}
                    className="rounded"
                  />
                </div>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3 mt-4">
                    <AvField
                      name="roles"
                      label="Specify team member role"
                      type="select"
                      required
                    >
                      <option> Select... </option>
                      <option value="property_mgt_marketing">Marketing</option>
                      <option value="property_mgt_sales">Sales</option>
                      <option value="property_mgt_finance">
                        Portfolio management
                      </option>
                      <option value="property_mgt_maintenance">
                        Maintenance
                      </option>
                      <option value="property_mgt_leasing_officer">
                        Lease officer
                      </option>
                      <option value="portfolio_manager">
                        Portfolio manager
                      </option>
                      <option value="facility_manager">Facility manager</option>
                    </AvField>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="firstName"
                      label="First Name"
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="lastName"
                      label="Last Name"
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="Email"
                      className="form-control"
                      type="email"
                      required
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="gender"
                      label="Gender"
                      type="select"
                      required
                    >
                      <option> Select... </option>
                      {gender.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </AvField>
                  </div>
                </Col>
              </Row>

              {/* <Row>
                <Col md={12}>
                  <div className="mb-3 mt-3">
                    <label className="control-label">Assign property</label>
                    <Multiselect
                      options={assignprop}
                      displayValue="propertyname"
                      selectedValues={selectedProps}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      closeOnSelect={false}
                      style={multiselectStyles}
                      placeholder="Select properties"
                    />
                  </div>
                </Col>
              </Row> */}

              <div className="text-center mt-5">
                <button
                  className="btn login-btn  waves-effect waves-light"
                  type="submit"
                >
                  {!pending ? <span className="me-2">Submit</span> : null}

                  {!pending ? null : (
                    <span>
                      {" "}
                      <Spinner as="span" animation="border" size="sm" />{" "}
                      Loading...
                    </span>
                  )}
                </button>
              </div>
            </AvForm>
          </Col>
        </Row>
      </CardBody>
    </React.Fragment>
  );
}

EditTeamForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(EditTeamForm));
