import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStore1Selector, useStore1Dispatch } from "./../../../index";
import { loginUser, Login } from "../../../Redux/Slices/userSlice";
import { Col, Row, Card, CardBody, Spinner, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useHistory } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import Avatar from "../../../assets/images/Avatar.png";
import usePost from "../../../hooks/usePost";
import PhoneInput from "react-phone-input-2";
import gender from "../../../components/Gender";
import AccountType from "../../../components/AccountType";

function UserDetails({ picture, getUserById, token, reFetchGetUserById }) {
  const userDet = useStore1Selector(loginUser);
  const dispatch = useStore1Dispatch();
  const history = useHistory();
  const [profileServer, setProfileServer] = useState();
  const [phoneState, setPhoneState] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState();
  const { execute, pending, data: postDetails } = usePost();
  const {
    execute: executeLogout,
    pending: executePending,
    data: postLogout,
  } = usePost();
  // console.log("Get my details..", getUserById);
  const handleBtnMainUser = (e, values) => {
    e.preventDefault();
    const Method = "PATCH",
      endPoint = `users/updateMe`,
      isJSON = true;

    const formdata = new FormData();
    {
      !profileServer ? null : formdata.append("profilePic", profileServer);
    }
    {
      !values.firstName ? null : formdata.append("firstName", values.firstName);
    }
    {
      !values.lastName ? null : formdata.append("lastName", values.lastName);
    }
    {
      !values.email ? null : formdata.append("email", values.email);
    }
    {
      !values.ID ? null : formdata.append("passportNumber", values.ID);
    }

    {
      !phoneState ? null : formdata.append("phoneNumber", phoneState);
    }
    {
      !values.buildingName
        ? null
        : formdata.append("BuildingName", values.buildingName);
    }
    {
      !values.companyName
        ? null
        : formdata.append("companyName", values.companyName);
    }
    {
      !values.address ? null : formdata.append("street", values.address);
    }
    {
      !values.suburb ? null : formdata.append("suburb", values.suburb);
    }
    {
      !values.city ? null : formdata.append("city", values.city);
    }
    {
      !values.gender ? null : formdata.append("gender", values.gender);
    }
    {
      !values.state ? null : formdata.append("state", values.state);
    }
    {
      !values.bankName ? null : formdata.append("bankName", values.bankName);
    }
    {
      !values.branchName
        ? null
        : formdata.append("branchName", values.branchName);
    }
    {
      !values.swiftCode ? null : formdata.append("swiftCode", values.swiftCode);
    }
    {
      !values.accountNumber
        ? null
        : formdata.append("accountNumber", values.accountNumber);
    }

    {
      !values.accountType
        ? null
        : formdata.append("accountType", values.accountType);
    }
    formdata.append("country", "Ghana");
    execute(endPoint, formdata, Method, null, token, isJSON);
  };

  const refFileUpload = useRef(null);
  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileServer(event.target.files[0]);

      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const Logout = () => {
    const Method = "POST",
      endPoint = "auth/logout";
    const raw = "";
    execute(endPoint, raw, Method, "Profile successfully updated", token);
    dispatch(Login(""));
    localStorage.removeItem("persist:users");
    setTimeout(() => {
      history.push(`/login`);
    }, 3000);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  const myRole = getUserById?.roles ?? [];
  const Roles = [
    "property_owner_individual",
    "property_owner_agency",
    "property_owner_company",
    "property_mgt_marketing",
    "property_mgt_sales",
    "property_mgt_finance",
    "property_mgt_maintenance",
    "property_mgt_leasing_officer",
    "portfolio_manager",
    "property_agency",
    "facility_manager",
  ];

  useEffect(() => {
    if (postDetails?.status === "success") {
      Logout();
    }
  }, [postDetails?.status]);

  console.log("getUserById..", getUserById);

  return (
    <>
      <AvForm
        className="form-horizontal mt-4 "
        onValidSubmit={(e, v) => {
          handleBtnMainUser(e, v);
        }}
      >
        <h5 className="text-center mb-4">Profile details</h5>
        {isLoading ? (
          <div className="d-flex mt-3 mb-5 justify-content-center align-items-center">
            <ThreeCircles
              height="50"
              width="50"
              color="#23B25A"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center mb-3">
            <img
              src={
                profile
                  ? profile
                  : !profile && !picture
                  ? Avatar
                  : !profile && picture
                  ? `${picture}`
                  : profile && picture
                  ? profile
                  : null
              }
              alt="user"
              className="rounded-3 mb-3"
              width={150}
              height={150}
            />
            <Button
              size="sm"
              variant="separator-light"
              className="btn-icon btn-icon-only position-absolute rounded s-0 b-0 mt-2"
              onClick={onThumbChangeClick}
            >
              <i className="ion ion-md-image"></i>
            </Button>
            <input
              type="file"
              ref={refFileUpload}
              className="file-upload d-none"
              accept="image/*"
              onChange={changeThumb}
            />
          </div>
        )}

        <Row>
          <Col md={6} lg={6}>
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <AvField
                    value={
                      myRole[0] === Roles[0]
                        ? "Property Owner"
                        : myRole[0] === Roles[1]
                        ? "Property Owner"
                        : myRole[0] === Roles[2]
                        ? "Property Agency"
                        : myRole[0] === Roles[9]
                        ? "Property Agency"
                        : null
                    }
                    name="roles"
                    label="Role"
                    type="text"
                    disabled
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    value={getUserById?.firstName}
                    name="firstName"
                    label="First Name"
                    type="text"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    value={getUserById?.lastName}
                    name="lastName"
                    label="Last Name"
                    type="text"
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <AvField
                    value={getUserById?.email}
                    name="email"
                    label="Email"
                    className="form-control"
                    type="email"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    value={getUserById?.gender}
                    name="gender"
                    label="Gender"
                    type="select"
                  >
                    <option name={getUserById?.gender}>
                      {getUserById?.gender}
                    </option>
                    {gender.map((item, i) => {
                      if (getUserById?.gender !== item) {
                        return (
                          <option name={item} key={i}>
                            {item}
                          </option>
                        );
                      }
                    })}
                  </AvField>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    value={getUserById?.passportNumber}
                    name="ID"
                    label="ID / Passport number"
                    type="text"
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={6} lg={6}>
            {myRole[0] === "property_owner_individual" ? (
              <Col md={12}>
                <div className="mb-3">
                  <AvField
                    value={getUserById?.BuildingName}
                    name="buildingName"
                    label="Building Name"
                    type="text"
                  />
                </div>
              </Col>
            ) : (
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      value={getUserById?.BuildingName}
                      name="buildingName"
                      label="Building Name"
                      type="text"
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      value={getUserById?.CompanyName}
                      name="companyName"
                      label="Company Name"
                      type="text"
                    />
                  </div>
                </Col>
              </Row>
            )}

            <div className="mb-3">
              <AvField
                value={getUserById?.addressDetails?.street}
                name="address"
                label="Address"
                type="text"
              />
            </div>

            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    value={getUserById?.addressDetails?.suburb}
                    name="suburb"
                    label="Suburb"
                    type="text"
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    value={getUserById?.addressDetails?.city}
                    name="city"
                    label="City"
                    type="text"
                  />
                </div>
              </Col>
            </Row>

            <div className="mb-3">
              <AvField
                value={getUserById?.addressDetails?.state}
                name="state"
                label="State / Province "
                type="text"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Row>
              <Col md={12}></Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    value={getUserById?.bankDetails?.bankName}
                    name="bankName"
                    label="Bank Name"
                    type="text"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    value={getUserById?.bankDetails?.branchName}
                    name="branchName"
                    label="Branch Name"
                    type="text"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    value={getUserById?.bankDetails?.accountNumber}
                    name="accountNumber"
                    label="Account Number"
                    className="form-control"
                    type="text"
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    value={getUserById?.bankDetails?.accountType}
                    name="accountType"
                    label="Account Type"
                    type="select"
                  >
                    {AccountType.map((item, i) => {
                      return <option key={i}>{item}</option>;
                    })}
                  </AvField>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    value={getUserById?.bankDetails?.swiftCode}
                    name="swiftCode"
                    label="Swift Code"
                    type="text"
                  />
                </div>
              </Col>
              <Col md={6}>
                <p>Phone number</p>
                <PhoneInput
                  buttonStyle={{
                    borderBottomLeftRadius: 5,
                    borderTopLeftRadius: 5,
                    height: 40,
                  }}
                  inputStyle={{
                    margin: 0,
                    width: "100%",
                    color: "purple",
                    height: 1,
                    border: "none",
                  }}
                  containerStyle={{
                    width: "100%",
                  }}
                  className="blue"
                  country={"gh"}
                  enableSearch={true}
                  value={getUserById?.phoneNumber}
                  onChange={(phone) => setPhoneState(phone)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="d-flex justify-content-center mt-5">
          <div className="mb-3 mt-3 w-25">
            <button
              className="btn btn-primary w-100 waves-effect waves-light text-center"
              type="submit"
            >
              {!pending ? <span className="me-2">Update Profile</span> : null}
              {!pending ? null : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading...
                </span>
              )}
            </button>
          </div>
        </div>
      </AvForm>
    </>
  );
}

export default UserDetails;
