import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";
import Nav from "./components/Nav";

import RequestTable from "./components/RequestTable";
import ApproveTable from "./components/ApproveTable";
import DeclinedTable from "./components/DeclinedTable";

function Request() {
  const [requestTicket, setRequestTicket] = useState(true);
  const [approvedTicket, setApprovedTicket] = useState(false);
  const [declinedTicket, setDeclinedTicket] = useState(false);
  const [NoRequestService, setRequestService] = useState(null);
  const [NoApproveService, setNoApproveService] = useState(null);
  const [NoDeclineService, setNoDeclineService] = useState(null);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | Request Tickets" />
        <Breadcrumb default="Maintenance Manangement" title="Request Tickets" />

        <Nav
          requestTicket={requestTicket}
          setRequestTicket={setRequestTicket}
          approvedTicket={approvedTicket}
          setApprovedTicket={setApprovedTicket}
          declinedTicket={declinedTicket}
          setDeclinedTicket={setDeclinedTicket}
          NoRequestService={NoRequestService}
          NoApproveService={NoApproveService}
          NoDeclineService={NoDeclineService}
        />
        <Row className="d-flex justify-content-around align-items-center">
          <CardBody data-aos="fade-bottom">
            <Card className="bd-rds">
              <CardBody>
                {requestTicket ? (
                  <RequestTable
                    setRequestService={setRequestService}
                    setNoApproveService={setNoApproveService}
                    setNoDeclineService={setNoDeclineService}
                  />
                ) : approvedTicket ? (
                  <ApproveTable />
                ) : null}
                {declinedTicket ? <DeclinedTable /> : null}
              </CardBody>
            </Card>
          </CardBody>
        </Row>
      </div>
    </React.Fragment>
  );
}

Request.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Request));
