const LoginRoute = "/login";
const ForgottenRoute = "/forgot-password";
const ResetRoute = "/resetPassword/:token";
const VerifyEmailRoute = "/Verify/:id/:token";
const SignupRoute = "/signup";
const SignupPropertyOwnerRoute = "/property-owner/:userType/:leadId/:invoiceId";
// const SignupPropertyAgencyRoute = "/signup/property-agency";
const SignupPropertyAgencyRoute = "/property-agencies";
const SubscriptionRoute = "/subscriptions";
const MyPropertyRoute = "/my-properties";

export {
  LoginRoute,
  ForgottenRoute,
  ResetRoute,
  VerifyEmailRoute,
  SignupRoute,
  SignupPropertyOwnerRoute,
  SignupPropertyAgencyRoute,
  SubscriptionRoute,
  MyPropertyRoute,
};
