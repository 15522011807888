import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row, CardBody, Card, Progress } from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter, Link, useParams } from "react-router-dom";
import {
  MdOutlineAssignmentTurnedIn,
  MdPendingActions,
  MdOutlinePreview,
} from "react-icons/md";
import Click from "../../../../assets/Gif/Click here.gif";

function Nav({
  receivedInv,
  setReceivedInv,
  approvedInv,
  setApprovedInv,
  declineInv,
  setDeclineInv,
}) {
  const { propId } = useParams();

  const handleReceived = () => {
    setReceivedInv(true);
    setApprovedInv(false);
    setDeclineInv(false);
  };

  const handleApprove = () => {
    setReceivedInv(false);
    setApprovedInv(true);
    setDeclineInv(false);
  };
  const handleDecline = () => {
    setReceivedInv(false);
    setApprovedInv(false);
    setDeclineInv(true);
  };

  return (
    <React.Fragment>
      <Row>
        <Container fluid>
          <button
            onClick={handleReceived}
            className={
              receivedInv
                ? "btn bg-white boder-3 border-dark text-dark me-3 p-3 rounded-3"
                : "btn btn-dark me-3 p-3 rounded-3"
            }
          >
            <div>
              <i className="fa fa-bell me-1"></i> Received Quotations
            </div>
          </button>

          <button
            onClick={handleApprove}
            className={
              approvedInv
                ? "btn btn-success bg-white border border-success text-success me-3 p-3 rounded-3"
                : "btn btn-success me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-check-circle"></i> Approved Quotations
          </button>
          <button
            onClick={handleDecline}
            className={
              declineInv
                ? "btn bg-white border border-danger text-danger me-3 p-3 rounded-3"
                : "btn btn-danger me-3 p-3 rounded-3"
            }
          >
            <i className="	fas fa-clock"></i> Cancel Quotations
          </button>
        </Container>
      </Row>
    </React.Fragment>
  );
}

Nav.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Nav));
