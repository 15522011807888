import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Container, Row, Col, CardBody, Spinner } from "reactstrap";
import { withRouter, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { useStore1Selector } from "../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
import useCustomQuery from "../../hooks/useCustomQuery";
import useCustomMutation from "../../hooks/useCustomMutation";
import usePost from "../../hooks/usePost";
import useFetch from "../../hooks/useFecth";
import { documentType } from "../../components/DocumentsType";

function AddDocument() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const UserRole = userDet?.data?.roles[0];
  const uploadedBy = userDet?.data?._id;
  const teamsArray = process.env.REACT_APP_TEAM.split(",");
  const [myId, setMyId] = useState(
    teamsArray.includes(UserRole) ? "teamId" : "userId"
  );

  const key = ["myPropertiesDocuments"];
  const mutation = useCustomMutation(key);

  const history = useHistory();
  const [propertyId, setPropertyId] = useState(null);
  const [unitId, setUnitId] = useState("");
  const [unitDetails, setUnitDetails] = useState([]);
  const [fileType, setFileType] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [docDescription, setDocDescription] = useState("");
  const [issueAttachment, setIssueAttachment] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [showAllUnits, setShowAllUnits] = useState([]);

  const {
    data: getMyProperties,
    error: errorGetMyProperties,
    isLoading: isLoadingGetMyProperties,
  } = useCustomQuery(
    ["myProperties"],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/property_owner?fieldName=${myId}`,
    token
  );

  const { data: getAllUnits, reFetch: reFetchGetAllUnits } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/units/property_owner/${propertyId}`,
    token
  );

  const handleValidSubmit = (e) => {
    e.preventDefault();

    const Method = "POST",
      url = `filemanagements`,
      isJSON = true;
    const formdata = new FormData();
    formdata.append("propertyId", propertyId);

    if (unitId !== "Select..." && unitId) {
      formdata.append("unitId", unitId);
    }

    formdata.append("documentName", documentName);
    formdata.append("description", docDescription);
    formdata.append("documentType", fileType);
    // formdata.append("fileDocument", fileInput.files[0], "[PROXY]");
    // formdata.append("expirationDate", "2023-12-31");
    formdata.append("uploadedbyId", uploadedBy);
    if (issueAttachment) {
      issueAttachment.map((item) => {
        formdata.append("fileDocument", item);
      });
    }

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Document Successfully Created",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const handleAttachment = (event) => {
    const selectedFiles = event.target.files;
    const fileArray = Array.from(selectedFiles);
    setIssueAttachment(fileArray);

    const previews = fileArray.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  useEffect(() => {
    if (propertyId) {
      reFetchGetAllUnits();
    }
  }, [propertyId]);

  useEffect(() => {
    if (getAllUnits) {
      setUnitDetails(getAllUnits);
    }
  }, [getAllUnits]);

  const getFileIcon = (fileName) => {
    if (fileName.endsWith(".pdf")) {
      return "far fa-file-pdf";
    } else if (fileName.endsWith(".xls") || fileName.endsWith(".xlsx")) {
      return "far fa-file-excel";
    } else if (fileName.endsWith(".doc") || fileName.endsWith(".docx")) {
      return "far fa-file-word";
    }
    // Add more conditions for other file types if needed
    return "far fa-file"; // Default icon
  };

  if (mutation.isSuccess) {
    history.push("my-documents");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Create Documents" />
        <Breadcrumb default="File Manangement" title="Create Documents" />

        <Container fluid>
          <CardBody>
            <div className="page-title-box">
              <div className="container-content">
                <CardBody>
                  <>
                    <form
                      className="form-horizontal mt-4"
                      onSubmit={handleValidSubmit}
                    >
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <label>Property Name</label>
                            <select
                              className="form-control"
                              required
                              onChange={(e) => setPropertyId(e.target.value)}
                            >
                              <option> Select... </option>
                              {getMyProperties?.map((item, i) => (
                                <option key={i} value={item?._id}>
                                  {item?.propertyName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <label>Unit Name</label>
                            <select
                              className="form-control"
                              value={unitId}
                              required
                              onChange={(e) => setUnitId(e.target.value)}
                            >
                              <option> Select...</option>
                              {unitDetails?.map((item, i) => (
                                <option key={i} value={item?._id}>
                                  {item?.unitName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <label>Document Name</label>
                            <input
                              className="form-control"
                              onChange={(e) => setDocumentName(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <label>Document Type</label>
                            <select
                              className="form-control"
                              required
                              onChange={(e) => setFileType(e.target.value)}
                            >
                              <option> Select...</option>
                              {documentType?.map((item, i) => (
                                <option key={i} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                      </Row>

                      <div className="mb-3">
                        <label>Description</label>
                        <textarea
                          className="form-control"
                          value={docDescription}
                          onChange={(e) => setDocDescription(e.target.value)}
                          rows={6}
                        />
                      </div>

                      <Row>
                        <Col md={12}>
                          {/* <div
                            className="dropzone-previews d-flex align-items-center"
                            id="file-previews"
                          > */}
                          <div className="file-previews-doc">
                            {issueAttachment.map((file, index) => (
                              <div key={index} className="file-preview-doc">
                                <i
                                  className={`${getFileIcon(
                                    file.name
                                  )} file-icon-doc`}
                                ></i>
                                <div className="file-details-doc">
                                  <div className="file-name-doc">
                                    {file.name}
                                  </div>
                                  <div className="file-size-doc">
                                    {(file.size / 1024).toFixed(2)} KB
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          {/* <div className="dropzone-previews d-flex flex-wrap">
                              {issueAttachment.map((file, index) => {
                                return (
                                  <div key={index} className="file-preview">
                                    <i className={getFileIcon(file.name)}></i>
                                    <span>{file.name}</span>
                                  </div>
                                );
                              })}
                            </div> */}
                          {/* </div> */}
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          <div className="mb-3 drop-zone">
                            <label
                              htmlFor="attachment"
                              className="drop-zone-label"
                            >
                              <i className="fas fa-cloud-upload-alt"></i>
                              <div className="drop-zone-text">
                                Attach file(s)
                              </div>
                            </label>
                            <input
                              type="file"
                              id="attachment"
                              className="form-control drop-zone-input"
                              multiple
                              accept=".pdf, .xls, .xlsx, .doc, .docx"
                              onChange={handleAttachment}
                            />
                          </div>
                        </Col>
                      </Row>

                      <div className="d-flex justify-content-center">
                        <button
                          className="btn login-btn w-md waves-effect waves-light"
                          type="submit"
                        >
                          {!mutation?.isLoading ? (
                            "Upload"
                          ) : (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                className="me-2"
                              />
                              Loading...
                            </>
                          )}
                        </button>
                      </div>
                    </form>
                  </>
                </CardBody>
              </div>
            </div>
          </CardBody>
        </Container>
      </div>
    </React.Fragment>
  );
}

AddDocument.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(AddDocument));
