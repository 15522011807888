import React, { useState, useRef, useEffect } from "react";
import useCustomQuery from "../../../hooks/useCustomQuery";
import PropTypes from "prop-types";
import { Col, Row, CardBody, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import Avatar from "../../../assets/images/Avatar.png";
import LoadComponents from "../../../components/LoadComponents";

function ViewVisitorDetail({ viewID }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const UserRole = userDet?.data?.roles[0];

  const ownerId = userDet?.data?._id;
  const teamsArray = process.env.REACT_APP_TEAM.split(",");

  const [myId, setMyId] = useState(
    teamsArray.includes(UserRole) ? "teamId" : "userId"
  );
  const [propertyID, setPropertyID] = useState(null);
  const [unitID, setUnitID] = useState(null);
  const [phone, setPhone] = useState("");
  const [visitorType, setVisitorType] = useState(null);
  const [selectTime, setSelectTime] = useState("");
  const [timeValue, setTimeValue] = useState(null);

  const {
    data: getMyVisitorDetails,
    error: errorGetMyVisitorDetails,
    isLoading: isLoadingGetMyVisitorDetails,
  } = useCustomQuery(
    ["myVisitorDetails", viewID],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/visitors/${viewID}`,
    token
  );

  if (isLoadingGetMyVisitorDetails) {
    return <LoadComponents />;
  }

  // if (getMyVisitorDetails) {
  //   return <LoadComponents />;
  // }

  const createdAt = new Date(getMyVisitorDetails?.createdAt);
  const expirationTime = new Date(getMyVisitorDetails?.expirationTime);
  const timeDifference = expirationTime - createdAt;
  const seconds = timeDifference / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const weeks = days / 7;
  const months = days / 30;

  let duration = "";

  if (months >= 1) {
    duration = `${Math.round(months)} month(s)`;
  } else if (weeks >= 1) {
    duration = `${Math.round(weeks)} week(s)`;
  } else if (days >= 1) {
    duration = `${Math.round(days)} day(s)`;
  } else if (hours >= 1) {
    duration = `${Math.round(hours)} hour(s)`;
  } else {
    duration = `${Math.round(minutes)} minute(s)`;
  }

  let expiration_Date = "";
  const now = new Date();
  const expirationDate = new Date(getMyVisitorDetails?.expirationTime);
  const currentDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  );
  const targetDate = new Date(
    expirationDate.getFullYear(),
    expirationDate.getMonth(),
    expirationDate.getDate()
  );

  const diffInTime = currentDate - targetDate;

  const diffInDays = diffInTime / (1000 * 60 * 60 * 24);
  if (diffInDays === 0) {
    expiration_Date = "Today";
  } else if (diffInDays === 1) {
    expiration_Date = "Yesterday";
  } else if (diffInDays > 1 && diffInDays < 7) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    expiration_Date = `${daysOfWeek[expirationDate.getDay()]}`;
  } else if (diffInDays >= 7) {
    expiration_Date = expirationDate.toISOString().slice(0, 10);
  } else {
    expiration_Date = expirationDate.toISOString().slice(0, 10);
  }

  return (
    <React.Fragment>
      <CardBody>
        <Row className="align-items-center">
          <Col md={12} lg={12} xl={12}>
            <AvForm className="form-horizontal mt-3">
              <Row>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={Avatar}
                    alt="user"
                    width={150}
                    height={150}
                    className="rounded"
                  />
                </div>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3 mt-3">
                    <AvField
                      name="visitorType"
                      value={getMyVisitorDetails?.entryPIN}
                      label="Visitor Pin"
                      type="text"
                      disabled
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3 mt-3">
                    <AvField
                      name="visitorType"
                      value={getMyVisitorDetails?.type}
                      label="Type of visitor"
                      type="text"
                      disabled
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="duration"
                      label="Duration"
                      value={duration}
                      type="text"
                      disabled
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="time"
                      label={`Expiration Date`}
                      value={expiration_Date}
                      type="text"
                      disabled
                    />
                  </div>
                </Col>
                {getMyVisitorDetails?.type === "Delivery" ? (
                  <>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="fullName"
                          label="Visitor Full Name"
                          value={getMyVisitorDetails?.visitorDetails?.fullName}
                          className="form-control"
                          type="text"
                          disabled
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="propertyName"
                          label="Property Name"
                          value={getMyVisitorDetails?.property?.propertyName}
                          type="text"
                          disabled
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="unitName"
                          label="Unit Name"
                          value={
                            getMyVisitorDetails?.unit
                              ? getMyVisitorDetails?.unit?.unitName
                              : null
                          }
                          type="text"
                          disabled
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="companyName"
                          label="Company Name"
                          value={
                            getMyVisitorDetails?.visitorDetails?.delivery
                              ?.companyName
                          }
                          className="form-control"
                          type="text"
                          disabled
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="contactPerson"
                          label="Contact Person"
                          value={
                            getMyVisitorDetails?.visitorDetails?.delivery
                              ?.contactPerson
                          }
                          className="form-control"
                          type="text"
                          disabled
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="plateNumber"
                          label="Plate Number"
                          value={
                            getMyVisitorDetails?.visitorDetails?.delivery
                              ?.licenseInfo
                          }
                          className="form-control"
                          type="text"
                          disabled
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="ServiceProvided"
                          label="Service Provided"
                          value={
                            getMyVisitorDetails?.visitorDetails?.delivery
                              ?.serviceProvided
                          }
                          className="form-control"
                          type="text"
                          disabled
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="address"
                          label="Company Address"
                          value={
                            getMyVisitorDetails?.visitorDetails?.delivery
                              ?.companyAddress
                          }
                          className="form-control"
                          type="text"
                          disabled
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value={getMyVisitorDetails?.visitorDetails?.email}
                          className="form-control"
                          type="email"
                          disabled
                        />
                      </div>
                    </Col>

                    <div className="mb-3">
                      <PhoneInput
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{
                          borderBottomLeftRadius: 5,
                          borderTopLeftRadius: 5,
                          height: 40,
                        }}
                        inputStyle={{
                          margin: 0,
                          width: "100%",
                          color: "purple",
                          height: 1,
                          border: "none",
                        }}
                        containerStyle={{
                          width: "100%",
                        }}
                        className="blue"
                        country={"gh"}
                        enableSearch={true}
                        value={getMyVisitorDetails?.visitorDetails?.phoneNumber}
                      />
                    </div>

                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="additionalInfo"
                          label="Additional Info"
                          value={
                            getMyVisitorDetails?.visitorDetails?.delivery?.notes
                          }
                          type="textarea"
                          rows={5}
                          disabled
                        />
                      </div>
                    </Col>
                  </>
                ) : null}
                {getMyVisitorDetails?.type === "Family" ? (
                  <>
                    <>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="fullName"
                            label="Visitor Full Name"
                            value={
                              getMyVisitorDetails?.visitorDetails?.fullName
                            }
                            className="form-control"
                            type="text"
                            disabled
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <AvField
                            name="propertyName"
                            label="Property Name"
                            value={getMyVisitorDetails?.property?.propertyName}
                            type="text"
                            disabled
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <AvField
                            name="unitName"
                            label="Unit Name"
                            value={
                              getMyVisitorDetails?.unit
                                ? getMyVisitorDetails?.unit?.unitName
                                : null
                            }
                            type="text"
                            disabled
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            value={getMyVisitorDetails?.visitorDetails?.email}
                            className="form-control"
                            type="text"
                            disabled
                          />
                        </div>
                      </Col>
                      <div className="mb-3">
                        <PhoneInput
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                          buttonStyle={{
                            borderBottomLeftRadius: 5,
                            borderTopLeftRadius: 5,
                            height: 40,
                          }}
                          inputStyle={{
                            margin: 0,
                            width: "100%",
                            color: "purple",
                            height: 1,
                            border: "none",
                          }}
                          containerStyle={{
                            width: "100%",
                          }}
                          className="blue"
                          country={"gh"}
                          enableSearch={true}
                          value={
                            getMyVisitorDetails?.visitorDetails?.phoneNumber
                          }
                        />
                      </div>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="visitAddress"
                            label="Visitor Address"
                            value={
                              getMyVisitorDetails?.visitorDetails?.family
                                ?.address
                            }
                            className="form-control"
                            type="text"
                            disabled
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="address"
                            label="Relation"
                            value={
                              getMyVisitorDetails?.visitorDetails?.family
                                ?.relation
                            }
                            className="form-control"
                            type="text"
                            disabled
                          />
                        </div>
                      </Col>
                    </>
                  </>
                ) : null}
                {getMyVisitorDetails?.type === "Other" ? (
                  <>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="fullName"
                          label="Visitor Full Name"
                          value={getMyVisitorDetails?.visitorDetails?.fullName}
                          className="form-control"
                          type="text"
                          disabled
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="propertyName"
                          label="Property Name"
                          value={getMyVisitorDetails?.property?.propertyName}
                          type="tex"
                          disabled
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          name="unitName"
                          label="Unit Name"
                          value={
                            getMyVisitorDetails?.unit
                              ? getMyVisitorDetails?.unit?.unitName
                              : null
                          }
                          type="text"
                          disabled
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value={getMyVisitorDetails?.visitorDetails?.email}
                          className="form-control"
                          type="text"
                          disabled
                        />
                      </div>
                    </Col>
                    <div className="mb-3">
                      <PhoneInput
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{
                          borderBottomLeftRadius: 5,
                          borderTopLeftRadius: 5,
                          height: 40,
                        }}
                        inputStyle={{
                          margin: 0,
                          width: "100%",
                          color: "purple",
                          height: 1,
                          border: "none",
                        }}
                        containerStyle={{
                          width: "100%",
                        }}
                        className="blue"
                        country={"gh"}
                        enableSearch={true}
                        value={getMyVisitorDetails?.visitorDetails?.phoneNumber}
                      />
                    </div>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="visitReason"
                          label="Reason For Visit"
                          value={
                            getMyVisitorDetails?.visitorDetails?.other
                              ?.reasonForVisit
                          }
                          className="form-control"
                          type="text"
                          disabled
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="additionalInfo"
                          label="Additional Info"
                          value={
                            getMyVisitorDetails?.visitorDetails?.other
                              ?.additionalInfo
                          }
                          type="textarea"
                          rows={5}
                        />
                      </div>
                    </Col>
                  </>
                ) : null}
              </Row>
            </AvForm>
          </Col>
        </Row>
      </CardBody>
    </React.Fragment>
  );
}

ViewVisitorDetail.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(ViewVisitorDetail));
