import React from "react";
import ReactEcharts from "echarts-for-react";

const TaskGraph = ({ totalOpen, totalPending, totalClose, totalOverdue }) => {
  const pieName = [
    { name: "Open Tickets" },
    { name: "Pending Tickets" },
    {
      name: "Completed Tickets",
    },
    {
      name: "Overdue Tickets",
    },
  ];
  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        x: "left",
        // data: pieName,
        data: [],
        textStyle: {
          color: ["#74788d"],
        },
      },
      // "#02a499"
      color: ["#38a4f8", "#26AA58", "#f8b425", "#E40C1B"],
      series: [
        {
          name: "montly",
          type: "pie",
          radius: ["37%", "70%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "12",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: [
            { name: "Open Tasks", value: totalOpen },
            {
              name: "Completed Tasks",
              value: totalClose,
            },
            { name: "Pending Tasks", value: totalPending },
            { name: "Overdue Tasks", value: totalOverdue },
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "314px" }} option={getOption()} />
    </React.Fragment>
  );
};
export default TaskGraph;
