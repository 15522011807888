import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import useCustomMutation from "../../../hooks/useCustomMutation";
import useCustomQuery from "../../../hooks/useCustomQuery";
import useFetch from "../../../hooks/useFecth";

function EditBookableUnits({ bookUnitId, setEditModal, ownerId, token }) {
  const [propertyId, setPropertyId] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [unitsPictures, setUnitsPictures] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  const [unitsName, setUnitName] = useState("");
  const [surfaceArea, setSurfaceArea] = useState("");
  const [unitType, setUnitType] = useState("");
  const [rentAmount, setRentAmount] = useState("");
  const [occupancy, setOccupancy] = useState("");
  const [parking, setParking] = useState(false);
  const [pets, setPets] = useState(false);
  const [bookableField, setBookableField] = useState(false);
  const [description, setDescription] = useState("");
  const [info, setInfo] = useState("");

  const key = ["getAllBookableUnits", ownerId];
  const mutation = useCustomMutation(key);

  const {
    data: getMyBookableUnits,
    error: errorGetMyBookableUnits,
    isLoading: isLoadingGetMyBookableUnits,
  } = useCustomQuery(
    ["getBookableUnitsDetails", ownerId],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/bookableUnits//${bookUnitId}`,
    token
  );

  useEffect(() => {
    if (getMyBookableUnits?.bookableField) {
      setBookableField(getMyBookableUnits?.bookableField);
      setPets(getMyBookableUnits?.petPolicy);
      setParking(getMyBookableUnits?.parkingGarage);
      //   setImagePreviews(getMyBookableUnits?.getMyBookableUnits);
    }
  }, [getMyBookableUnits]);

  //   console.log("getMyBookableUnits...", getMyBookableUnits);

  const handleValidSubmit = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `bookableUnits/${ownerId}/${propertyId}/create`,
      isJSON = true;
    const formdata = new FormData();
    if (propertyId) {
      formdata.append("propId", propertyId);
    }
    if (propertyName) {
      formdata.append("propertyName", propertyName);
    }
    if (unitsName) {
      formdata.append("unitName", unitsName);
    }
    if (unitType) {
      formdata.append("unitType", unitType);
    }
    if (description) {
      formdata.append("unitSpecification", description);
    }

    if (unitsPictures) {
      unitsPictures?.map((item) => {
        formdata.append("unitImages", item);
      });
    }
    if (surfaceArea) {
      formdata.append("surfaceArea", surfaceArea);
    }
    if (parking) {
      formdata.append("parkingGarage", parking);
    }
    if (rentAmount) {
      formdata.append("bookingFee", rentAmount);
    }
    if (occupancy) {
      formdata.append("maximumOccupancy", occupancy);
    }
    if (info) {
      formdata.append("deposit_and_CancellationPolicy", info);
    }
    if (pets) {
      formdata.append("petPolicy", pets);
    }
    if (bookableField === true) {
      formdata.append("bookableField", bookableField);
    } else if (bookableField === false) {
      formdata.append("bookableField", bookableField);
    }

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Bookable Unit Successfully Created",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const {
    data: getPropertyAllProperties,
    reFetch: reFetchGetPropertyAllProperties,
  } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/property_owner?fieldName=userId`,
    token
  );

  const handlePropertyChange = (event) => {
    const [propertyId, propertyName] = event.target.value.split(",");
    setPropertyId(propertyId);
    setPropertyName(propertyName);
    // setPropertyId(event.target.value);
    // setPropertyName(event.target.value[1]);
  };

  const handleUnitsPictures = (event) => {
    const selectedFiles = event.target.files;
    const fileArray = Array.from(selectedFiles);
    setUnitsPictures(fileArray);

    const previews = fileArray.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  const handleParking = (event) => {
    if (event.target.checked) {
      setParking(true);
    } else {
      setParking(false);
    }
  };

  const handlePets = (event) => {
    if (event.target.checked) {
      setPets(true);
    } else {
      setPets(false);
    }
  };

  const handleBookableField = (event) => {
    if (event.target.checked) {
      setBookableField(true);
    } else {
      setBookableField(false);
    }
  };

  if (mutation.isSuccess) {
    setEditModal(false);
  }

  return (
    <form className="form-horizontal mt-4" onSubmit={handleValidSubmit}>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <label>Property Name</label>
            <input
              className="form-control"
              value={getMyBookableUnits?.propertyName}
              required
              onChange={handlePropertyChange}
              disabled
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <label>Unit Name</label>
            <input
              className="form-control"
              value={getMyBookableUnits?.unitName}
              onChange={(e) => setUnitName(e.target.value)}
            />
          </div>
        </Col>

        <Col md={3}>
          <div className="mb-3">
            <label>Unit Type</label>
            <input
              className="form-control"
              value={getMyBookableUnits?.unitType}
              onChange={(e) => setUnitType(e.target.value)}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="mb-3">
            <label>Price/hour</label>
            <input
              className="form-control"
              value={getMyBookableUnits?.bookingFee}
              onChange={(e) => setRentAmount(e.target.value)}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="mb-3">
            <label>Surface Area</label>
            <input
              className="form-control"
              value={getMyBookableUnits?.surfaceArea}
              onChange={(e) => setSurfaceArea(e.target.value)}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="mb-3">
            <label>Maximum Occupancy</label>
            <input
              className="form-control"
              value={getMyBookableUnits?.maximumOccupancy}
              onChange={(e) => setOccupancy(e.target.value)}
            />
          </div>
        </Col>

        <div className="mb-3">
          <label>Unit Description</label>
          <textarea
            className="form-control"
            value={getMyBookableUnits?.unitSpecification}
            onChange={(e) => setDescription(e.target.value)}
            rows={6}
          />
        </div>
      </Row>
      <Row>
        <Col md={4} className="Input__form">
          <input type="checkbox" value={parking} onChange={handleParking} />
          <label className="mx-1 text-success">Parking</label>
        </Col>
        <Col md={4} className="Input__form">
          <input type="checkbox" value={pets} onChange={handlePets} />
          <label className="mx-1 text-success">Allow Pets</label>
        </Col>
        <Col md={4} className="Input__form">
          <input
            type="checkbox"
            value={bookableField}
            onChange={handleBookableField}
          />
          <label className="mx-1 text-success">Make unit available</label>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div
            className="dropzone-previews d-flex align-items-center"
            id="file-previews"
          >
            {imagePreviews.map((previewURL, index) => (
              <img
                key={index}
                data-dz-thumbnail=""
                height="150"
                className="avatar-sm rounded bg-light m-2 border border-dark"
                alt={`Preview ${index}`}
                src={previewURL}
              />
            ))}
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div className="mb-3 drop-zone">
            <label htmlFor="attachment" className="drop-zone-label">
              <i className="fas fa-cloud-upload-alt"></i>
              <div className="drop-zone-text">Upload pictures of units</div>
            </label>
            <input
              type="file"
              id="attachment"
              className="form-control drop-zone-input"
              multiple
              accept="image/jpeg, image/png, image/gif"
              onChange={handleUnitsPictures}
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="mb-3">
            <label>Additional Info</label>
            <textarea
              className="form-control"
              value={info}
              onChange={(e) => setInfo(e.target.value)}
              rows={6}
            />
          </div>
        </Col>
      </Row>

      <div className="d-flex justify-content-center">
        <button
          className="btn login-btn w-md waves-effect waves-light"
          type="submit"
          handleUnitsPictures
        >
          {!mutation.isLoading ? (
            "Create"
          ) : (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="me-2"
              />
              Loading...
            </>
          )}
        </button>
      </div>
    </form>
  );
}

export default EditBookableUnits;
