import React, { useEffect, useState } from "react";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { Row, Col, Spinner } from "reactstrap";
import { useStore1Selector } from "../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import useFetch from "../../../../hooks/useFecth";
import LoadComponents from "../../../../components/LoadComponents";

function ViewInvoiceForm({ viewID }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;

  const { data: getInvoiceDetails, reFetch: reFetchGetInvoiceDetails } =
    useFetch(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/serviceProviderInvoices/${viewID}/tickets`,
      token
    );

  if (!getInvoiceDetails[0]?.serviceProvider) {
    return <LoadComponents />;
  }

  return (
    <div>
      <AvForm className="form-horizontal mt-4">
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="serviceProvider"
                value={`${getInvoiceDetails[0]?.serviceProvider?.firstName} ${getInvoiceDetails[0]?.serviceProvider?.lastName}`}
                label="Sent By"
                className="form-control"
                type="text"
                disabled
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="price"
                label="Total Invoice Amount"
                value={`GH₵ ${getInvoiceDetails[0]?.amount}`}
                className="form-control"
                type="text"
                disabled
              />
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="TicketRef"
                value={getInvoiceDetails[0]?.ticketId?.ticketNumber}
                label="Ticket Reference Number"
                className="form-control"
                type="text"
                disabled
              />
            </div>
          </Col>
          {getInvoiceDetails[0]?.declineReason ? (
            <Col>
              <div className="mb-3 text-danger">
                <AvField
                  name="declineReason"
                  value={getInvoiceDetails[0]?.declineReason}
                  label="Decline Reason"
                  className="form-control"
                  type="textarea"
                  rows={6}
                  disabled
                />
              </div>
            </Col>
          ) : null}

          {getInvoiceDetails[0]?.description ? (
            <Col md={12}>
              <div className="mb-3">
                <AvField
                  name="notes"
                  value={getInvoiceDetails[0]?.description}
                  label="Additional Information"
                  className="form-control"
                  type="textarea"
                  rows={6}
                  disabled
                />
              </div>
            </Col>
          ) : null}

          <Col md={12}>
            <div className="mb-3">
              <label>Attached Invoice</label>

              <iframe
                src={getInvoiceDetails[0]?.attachedInvAndQuote}
                width="100%"
                height="500px"
              ></iframe>
            </div>
          </Col>
        </Row>
      </AvForm>
    </div>
  );
}

export default ViewInvoiceForm;
