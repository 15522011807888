import React, { useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import {
  AvForm,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { modules } from "./Obejct-available";

function Available_modules() {
  const [check, setCheck] = useState("");

  const handleValidSubmit = (values) => {
    console.log(values);
  };

  return (
    <AvForm
      className="mt-2"
      onValidSubmit={(e, v) => {
        handleValidSubmit(e, v);
      }}
    >
      <AvCheckboxGroup name="checkboxExample">
        <Row className="main-container">
          {modules().map((module, idx) => (
            <Col md={6} key={idx}>
              <Card>
                <CardBody className="d-flex">
                  <div className="img__container">
                    <img src={module.img} alt="" />
                  </div>
                  <div className="checkbox">
                    <div>
                      <h3>
                        ${module.price}
                        <small>/month</small>
                      </h3>
                      <small className="text-center"> {module.text} </small>
                    </div>
                    <p>
                      <AvCheckbox
                        label={module.label}
                        value={module.value}
                        disabled={module.disable}
                        checked={module.checked}
                        onChange={(e) => {
                          setCheck(e.target.checked);
                        }}
                      />
                    </p>
                  </div>
                </CardBody>
                {/* <div className="border__bottom"></div> */}
              </Card>
            </Col>
          ))}

          <div div className="text-center mb-2">
            <Link to="#">
              <button className="btn report-success w-md text-white mt-3">
                Proceed
              </button>{" "}
            </Link>
            {/* <Link to="/property-table"> <button className="btn report-success w-md text-white mt-3">Proceed</button> </Link> */}
          </div>
        </Row>
      </AvCheckboxGroup>
    </AvForm>
  );
}

export default Available_modules;
