import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Button, Spinner, Modal } from "reactstrap";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { countryList } from "./../../../components/Countries";
import Img_600 from "./../../../assets/images/img-size/600x400.gif";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import { warningMessage, successMessage } from "../../../components/Toast";
import Multiselect from "multiselect-react-dropdown";
import useCustomQuery from "../../../hooks/useCustomQuery";
import useCustomMutation from "../../../hooks/useCustomMutation";
import propFeatures from "../../../components/PropFeatures";

function PropertyForm() {
  let history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const key = ["myProperties"];
  const mutation = useCustomMutation(key);
  const [profileServer, setProfileServer] = useState();
  const [profile, setProfile] = useState();
  const [propType, setPropType] = useState(null);
  const [propCategory, setPropCategory] = useState(null);
  const [checkBlock, setCheckBlock] = useState(false);
  const [checkNote, setCheckNote] = useState(false);
  const [selectedFeatures, setselectedFeatures] = useState([]);

  const onSelect = (event) => {
    setselectedFeatures(event);
  };
  const onRemove = (e) => {
    setselectedFeatures(e);
  };
  const multiselectStyles = {
    multiselectContainer: {
      backgroundColor: "#F7F7F7",
      border: "1px solid #F7F7F7",
      borderRadius: "4px",
    },
    optionContainer: {
      backgroundColor: "#fff",
      border: "1px solid #C8C8C8",
      color: "#333",
    },
    option: {
      backgroundColor: "#F7F7F7",
      border: "1px solid #C8C8C8",
      color: "#26a958",
    },
    chips: {
      backgroundColor: "#26a958",
      borderRadius: "4px",
      color: "#fff",
    },
  };

  const {
    data: settingId,
    error,
    isLoading,
  } = useCustomQuery(
    ["mySettingsId"],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/packages/property_owner?fieldName=userId`,
    token
  );

  console.log("settingId...", settingId);

  const handleValidSubmit = (e, v) => {
    e.preventDefault();

    const Method = "POST",
      endPoint = `properties/${userDet?.data?._id}/${settingId[0]?._id}`,
      isJSON = true;
    const formdata = new FormData();

    if (profileServer) {
      formdata.append("propertyImage", profileServer);
      formdata.append("propertyName", v.PropertyName);
      formdata.append("propertyCategory", propCategory);
      formdata.append("propertyType", v.Type);
      formdata.append("buildingName", v.buildingName);
      formdata.append("address", v.address);
      formdata.append("city", v.City);
      formdata.append("country", v.Country);
      formdata.append("postalCode", v.postalCode);
      formdata.append("propertyBlock", checkBlock);
      selectedFeatures.map((feature) =>
        formdata.append("propertyFeatures", feature)
      );
      if (checkNote) {
        formdata.append("Note", v.Note);
      } else {
        formdata.append("Note", "");
      }
    } else {
      warningMessage("Please upload your property image");
    }
    const data = {
      endPoint: endPoint,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Property successfully added",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const selectHandle = (e) => {
    setPropType(e.target.value);
  };

  function handleChange(event) {
    setPropCategory(event.target.value);
  }

  const refFileUpload = useRef(null);
  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };

  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileServer(event.target.files[0]);

      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const handleBlocks = (event) => {
    if (event.target.checked) {
      setCheckBlock(true);
    } else {
      setCheckBlock(false);
    }
  };
  const handleNote = (event) => {
    if (event.target.checked) {
      setCheckNote(true);
    } else {
      setCheckNote(false);
    }
  };

  if (mutation.isSuccess) {
    setTimeout(() => {
      history.push(`/my-properties`);
    }, 3000);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >
          <h5 className="text-center mb-5">Upload property front image</h5>
          <Row>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={profile === undefined ? Img_600 : profile}
                alt="user"
                width={150}
                height={150}
                className="rounded"
              />
              <Button
                size="sm"
                variant="separator-light"
                className="btn-icon btn-icon-only position-absolute rounded s-0 b-0 mt-5"
                onClick={onThumbChangeClick}
              >
                <i className="ion ion-md-image"></i>
              </Button>
              <input
                type="file"
                ref={refFileUpload}
                className="file-upload d-none"
                accept="image/*"
                onChange={changeThumb}
              />
            </div>
          </Row>

          <Row>
            <Col md={2}>
              <div className="mb-3">
                <AvField
                  name="Type"
                  value={propType}
                  onChange={selectHandle}
                  label="Property type"
                  className="Input__form"
                  type="select"
                  required
                >
                  <option> Select... </option>
                  <option value="Residential"> Residential </option>
                  <option value="Commercial"> Commercial </option>
                </AvField>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              {propType === "Commercial" ? (
                <div className="mb-3">
                  <AvField
                    name="categoriesCom"
                    onChange={handleChange}
                    label="Property Categories"
                    className="Input__form"
                    type="select"
                    required
                  >
                    <option> Select... </option>
                    <option value="Offices"> Offices </option>
                    <option value="Warehouse"> Warehouse </option>
                    <option value="Hotels & Restaurant">
                      Hotels & Restaurant
                    </option>
                    <option value="Shopping Malls"> Shopping Malls </option>
                    <option value="Industrial Buildings">
                      Industrial Buildings
                    </option>
                    <option value="Retail House"> Retail House </option>
                  </AvField>
                </div>
              ) : null}

              {propType === "Residential" ? (
                <div className="mb-3">
                  <AvField
                    name="categoriesRes"
                    onChange={handleChange}
                    label="Property Categories"
                    className="Input__form"
                    type="select"
                    required
                  >
                    <option> Select... </option>
                    <option value="Apartments"> Apartments </option>
                    <option value="Duplex"> Duplex </option>
                    <option value="Single family homes">
                      Single family homes
                    </option>
                    <option value="Vacation homes"> Vacation homes </option>
                    <option value="condominiums"> condominiums </option>
                    <option value="Townhouses"> Townhouses </option>
                  </AvField>
                </div>
              ) : null}

              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="PropertyName"
                      label="Property Name"
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="buildingName"
                      label="Building Name"
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <AvField
                      name="address"
                      label="Address"
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="Country"
                      label="Country"
                      className="form-control"
                      type="select"
                      required
                    >
                      <option> Select... </option>
                      {countryList.map((country, index) => (
                        <option value={country} key={index}>
                          {country}
                        </option>
                      ))}
                    </AvField>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="City"
                      label="City"
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <AvField
                      name="postalCode"
                      label="Postal Code(optional)"
                      className="form-control"
                      type="text"
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <label className="control-label">Property Features</label>
                    <Multiselect
                      isObject={false}
                      options={propFeatures} // Options to display in the dropdown
                      // selectedValues={"this.state.selectedValue"} // Preselected value to persist in dropdown
                      onSelect={onSelect} // Function will trigger on select event
                      onRemove={onRemove} // Function will trigger on remove event
                      // displayValue="name" // Property name to display in the dropdown options
                      style={multiselectStyles}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6} className="Input__form">
                  <input type="checkbox" onChange={handleBlocks} />
                  <label className="mx-1 text-success">
                    My properrty has blocks
                  </label>
                </Col>
                <Col md={6} className="Input__form">
                  <input type="checkbox" onChange={handleNote} />
                  <label className="mx-1 text-success">Add Property Note</label>
                </Col>
                {checkNote ? (
                  <div className="mb-5 d-flex">
                    <div className="property_features">
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="Note"
                            className="form-control"
                            type="textarea"
                            rows={7}
                          />
                        </div>
                      </Col>
                    </div>
                  </div>
                ) : null}
              </Row>
            </Col>
          </Row>
          {/* <div className="mt-5 d-flex justify-content-center">
            <h5>Property Features</h5>
          </div> */}
          {/* <div className="mb-5 d-flex justify-content-center">
            <div className="property_features">
              <Row>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="kitchen">
                    <div className="Input__form_2">
                      <AvCheckbox label="Kitchen" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="pool">
                    <div className="Input__form_2">
                      <AvCheckbox label="Pool" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="gym">
                    <div className="Input__form_2">
                      <AvCheckbox label="Gym" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="fire">
                    <div className="Input__form_2">
                      <AvCheckbox label="Fire" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
              </Row>

              <Row>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="alarm">
                    <div className="Input__form_2">
                      <AvCheckbox label="Alarm" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="balcony">
                    <div className="Input__form_2">
                      <AvCheckbox label="Balcony" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="pet">
                    <div className="Input__form_2">
                      <AvCheckbox label="Pet" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="storage">
                    <div className="Input__form_2">
                      <AvCheckbox label="Storage" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
              </Row>

              <Row>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="centerCooling">
                    <div className="Input__form_2">
                      <AvCheckbox label="Center cooling" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="heating">
                    <div className="Input__form_2">
                      <AvCheckbox label="Heating" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="laundry">
                    <div className="Input__form_2">
                      <AvCheckbox label="laundry" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="dishWasher">
                    <div className="Input__form_2">
                      <AvCheckbox label="dishwasher" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
              </Row>

              <Row>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="barBeque">
                    <div className="Input__form_2">
                      <AvCheckbox label="barbeque" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="dryer">
                    <div className="Input__form_2">
                      <AvCheckbox label="Dryer" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="sauna">
                    <div className="Input__form_2">
                      <AvCheckbox label="Sauna" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="elevator">
                    <div className="Input__form_2">
                      <AvCheckbox label="Elevator" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
              </Row>
              <Row>
                <Col md={3} className="Input__form">
                  <AvCheckboxGroup name="emergency">
                    <div className="Input__form_2">
                      <AvCheckbox label="Emergency" value={true} />
                    </div>
                  </AvCheckboxGroup>
                </Col>
              </Row>
            </div>
          </div> */}

          <div className="mb-3 row ">
            <div className="col-12 d-flex justify-content-center">
              <button
                className="btn login-btn w-md waves-effect waves-light"
                type="submit"
              >
                {!mutation.isLoading ? (
                  <span className="me-2"> Submit </span>
                ) : null}

                {!mutation.isLoading ? null : (
                  <span>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      className="me-2"
                    />
                    Loading...
                  </span>
                )}
              </button>
            </div>
          </div>
        </AvForm>
      </div>
    </React.Fragment>
  );
}

export default PropertyForm;
