import React, { useState, useRef } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useStore1Selector } from "./../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import usePost from "../../../../hooks/usePost";
import unitType from "../../../../components/UnitType";

function appartments({ closeModal, unitId, unitDetails, refetch }) {
  const { execute, pending } = usePost();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const handleValidSubmit = (e, v) => {
    e.preventDefault();

    const Method = "PATCH",
      endPoint = `units/${unitId}`;
    const raw = JSON.stringify({
      unitType: v.unitType,
      surfaceArea: v.surfaceArea,
      unitName: v.unitName,
      unitNumber: v.unitNumber,
      RentAmount: v.RentAmount,
      unitOwnerName: v.unitOwnerName,
      numberOfBedrooms: v.numberOfBedrooms,
      numberOfBathRoom: v.numberOfBathRoom,
      kitchen: v.kitchen,
      storage: v.storage,
      parkingGarage: v.parkingGarage,
      leaseTerm: "test",
    });
    execute(endPoint, raw, Method, "Unit successfully updated", token);
    closeModal(false);
    setTimeout(() => {
      refetch();
    }, 3000);
  };
  // console.log("unitType..", unitType);
  return (
    <div>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="unitType"
                value={unitDetails?.unitType}
                label="Unit type"
                className="form-control"
                type="select"
              >
                <option value={unitDetails?.unitType}>
                  {unitDetails?.unitType}{" "}
                </option>

                {unitType.map((item, index) => {
                  if (unitDetails?.unitType != item) {
                    return <option key={index}> {item} </option>;
                  }
                })}
              </AvField>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="surfaceArea"
                value={unitDetails?.surfaceArea}
                label="Unit size (square meter)"
                className="form-control"
                type="text"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="unitName"
                value={unitDetails?.unitName}
                label="Unit name"
                className="form-control"
                type="text"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="unitNumber"
                value={unitDetails?.unitNumber}
                label="Unit number"
                className="form-control"
                type="text"
              />
            </div>
          </Col>
        </Row>
        <div className="mb-3">
          <AvField
            name="RentAmount"
            value={unitDetails?.RentAmount}
            label="Rent amount"
            className="form-control"
            type="text"
          />
        </div>

        <div className="mb-3">
          <AvField
            name="unitOwnerName"
            value={unitDetails?.unitOwnerName}
            label="Unit owner(optional)"
            className="form-control"
            type="text"
          />
        </div>

        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="numberOfBedrooms"
                value={unitDetails?.numberOfBedrooms}
                label="Number of bedroom(s)"
                className="form-control"
                type="number"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="numberOfBathRoom"
                value={unitDetails?.numberOfBathRoom}
                label="Number of bathroom(s)"
                className="form-control"
                type="number"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="kitchen"
                value={unitDetails?.kitchen}
                label="Number of kitchen(s)"
                className="form-control"
                type="number"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="storage"
                value={unitDetails?.storage}
                label="Number of storeroom(s)"
                className="form-control"
                type="number"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="parkingGarage"
                value={unitDetails?.parkingGarage}
                label="Parking and garage"
                className="form-control"
                type="text"
              />
            </div>
          </Col>
        </Row>
        <button
          className="btn login-btn w-md waves-effect waves-light"
          type="submit"
        >
          {!pending ? <span className="me-2"> Submit </span> : null}
          {!pending ? null : (
            <span>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="me-2"
              />
              Loading...
            </span>
          )}
        </button>
      </AvForm>
    </div>
  );
}

export default appartments;
