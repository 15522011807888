import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Card, CardBody, Button, Badge, Spinner } from "reactstrap";
import { Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";
import Nav from "./components/Nav";
import ReceivedIvoiceTable from "./components/ReceivedIvoiceTable";
import ApprovedInvoiceTable from "./components/ApprovedInvoiceTable";
import DeclinedInvoiceTable from "./components/DeclinedInvoiceTable";

import { AvForm, AvField } from "availity-reactstrap-validation";
// import Report from "./components/MaintenanceReport";
import { CSVLink } from "react-csv";

import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";

function Invoices() {
  const [receivedInv, setReceivedInv] = useState(true);
  const [approvedInv, setApprovedInv] = useState(false);
  const [declineInv, setDeclineInv] = useState(false);

  const userDet = useStore1Selector(loginUser);
  const userId = userDet?.original?.userDetails?.OwnerID;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Invoices" />
        <Breadcrumb default="Maintenance Management" title="Quotations" />
        <Nav
          receivedInv={receivedInv}
          setReceivedInv={setReceivedInv}
          approvedInv={approvedInv}
          setApprovedInv={setApprovedInv}
          declineInv={declineInv}
          setDeclineInv={setDeclineInv}
        />
        <CardBody>
          <Row className="d-flex justify-content-around align-items-center">
            <CardBody data-aos="fade-bottom">
              <Card className="bd-rds">
                <CardBody>
                  {receivedInv ? <ReceivedIvoiceTable /> : null}
                  {approvedInv ? <ApprovedInvoiceTable /> : null}

                  {declineInv ? <DeclinedInvoiceTable /> : null}
                </CardBody>
              </Card>
            </CardBody>
          </Row>
        </CardBody>
      </div>
    </React.Fragment>
  );
}

export default Invoices;
