import React from 'react'
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { Finance } from "./Object";
import { AvForm, AvField } from "availity-reactstrap-validation"

function PropChart() {
    return (
        <Row>
            {
                Finance.map((chart, idx) => (
                    <Col xl={3} md={6} key={idx}>
                        <Card className={`mini-stat  text-white ${chart.color}`}>
                            <CardBody>
                                <div className="mb-4">
                                    <div className="float-start mini-stat-img me-4">
                                        <chart.icon size={24} />
                                    </div>
                                    <h5 className="font-size-14 text-uppercase mt-0 text-white-100">
                                        {chart.name}
                                    </h5>
                                    <h4 className="fw-medium font-size-16"> R {chart.expense}
                                    </h4>
                                </div>
                                <div>
                                    <div className="float-end">
                                    </div>
                                    <p className="text-white-50 mb-0 mt-1"> {chart.duration} </p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                ))
            }
        </Row>
    )
}

export default PropChart