import PropTypes from "prop-types"
import React , {useState, useEffect }from 'react'
import { Col, Container, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody } from "reactstrap";
import { MDBDataTable, MDBDataTableV5  } from "mdbreact"
import { withRouter, Link, useHistory, useParams } from "react-router-dom"
import { withTranslation } from "react-i18next"

import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
// import Utility from '../../assets/images/components-img/utilities.jpg'

function UtilityDetails() {

  const { id } = useParams();
  let history = useHistory();
  const [dataDb, setData] = useState();

  var requestOptions = {
    method: 'POST',
    redirect: 'follow'
  };

  const handleClick = (e) =>{};
  
    useEffect(() => {
      const fecthData = async () =>{
        let data_arr = [];
        try {
          const dbData = await fetch("http://127.0.0.1:8000/api/utility/GetUtility", requestOptions)
          const dataJSN = await dbData.json();
        dataJSN.data.forEach(data => {
          data_arr.push({id: data.UnitID, ...data, clickEvent: () => handleClick(data.UnitID)})
        });
          const res = data_arr.find((unitID) =>  unitID.id === parseInt(id))
          setData(res);
        } catch (error) {
          console.log(error);
        }
      }
      fecthData();
  },[id])

  return (
    <React.Fragment>
      <div className="page-content">
      <MetaTagComp meta_tags="FASPRO 24 | Utility detail" />
      <Breadcrumb default="Property" title="Utility detail" />
      <CardBody> 
      <CardBody> 
      <Card className="bd-rds">
      <CardBody> 
        <Container fluid>                
          <Row className="d-flex justify-content-around align-items-center">
          <Col sm={12} md={6} lg={6}> 
            <h1 className="mb-5"> Name {dataDb?.Name} </h1>
            <p> <b> Area </b>: {dataDb?.Area} </p>
            <p> <b> Type of Rooms </b> : {dataDb?.Type} </p>

            <p> <b> Number of Bath Rooms </b> : {dataDb?.BathRooms} </p>
            <p> <b> Number of Bed Rooms </b> : {dataDb?.BedRooms} </p>
            <p> <b> Total Number of Rooms </b> : {dataDb?.TotalRooms} </p>

          </Col> 
          <Col sm={12} md={6} lg={6}> 
          <div className="bg-img"> 
          <div className="img-opacity">

              </div>
            </div>
          </Col>  
          </Row>
        </Container>
        </CardBody> 
       </Card> 
      </CardBody>
      </CardBody>
      </div>
    </React.Fragment>
  )
}

UtilityDetails.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(UtilityDetails))