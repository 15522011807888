import React, { useState, useRef } from "react";
import { AvField } from "availity-reactstrap-validation";
import { Row, Col } from "reactstrap";

function Input4() {
  return (
    <div className="mt-4 d-flex justify-content-center container-form">
      <div className="wrapper-form">
        <div className="d-flex justify-content-center">
          <h2>Bank Details</h2>
        </div>
        <Row className="">
          <Col md={6} lg={6}>
            <div className="mb-3">
              <AvField
                name="accountName"
                label="Account Name"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={6} lg={6}>
            <div className="mb-3">
              <AvField
                name="branchName"
                label="Branch Name"
                type="text"
                required
              />
            </div>
          </Col>
        </Row>
        <Row className="">
          <Col md={6} lg={6}>
            <div className="mb-3">
              <AvField
                name="branchCode"
                label="SWIFT code / IBAN"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={6} lg={6}>
            <div className="mb-3">
              <AvField
                name="accountNumber"
                label="Account Number"
                type="number"
                required
              />
            </div>
          </Col>
        </Row>
        <Row>
          <div className="mb-3">
            <AvField
              name="accountType"
              label="Account Type"
              type="select"
              required
            >
              <option> Select... </option>
              <option> Savings </option>
              <option> Current / Cheque</option>
            </AvField>
          </div>
        </Row>
        <Row>
          <div className="d-flex">
            <AvField
              name="privacy"
              type="checkbox"
              placeholder="Agree to Terms & Conditions"
              required
            />
            <span className="mx-2 mt-4">I accept the terms of the FASPRO</span>
            <span className="px-1 mt-4">
              <a href="https://faspro24.com/privacy-policy">privacy policy</a>
            </span>
          </div>
          {/* <div className="d-flex">
            <AvField
              type="checkbox"
              name="privacy"
              label="I accept the terms of the FASPRO"
            />
            <a href="https://faspro24.com/privacy-policy" className="px-1 mt-3">
              privacy policy
            </a>
          </div> */}
        </Row>
      </div>
    </div>
  );
}

export default Input4;
