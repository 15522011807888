import React, { useState, useRef } from "react";
import "../Style.scss";
// import Img from "../../../../assets/images/faspro24_logo_original.png";
import { AvField } from "availity-reactstrap-validation";
import Img_600 from "./../../../../assets/images/img-size/600x400.gif";
import { Row, Col, Button } from "reactstrap";
import PhoneInput from "react-phone-input-2";

function Input1(props) {
  const [profile, setProfile] = useState();
  const refFileUpload = useRef(null);
  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };

  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      props.setProfileServer(event.target.files[0]);

      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <div className="mt-4 d-flex justify-content-center container-form">
      <div className="wrapper-form">
        <div className="d-flex justify-content-center">
          <h2>Personal Details</h2>
        </div>
        <div className="d-flex justify-content-center align-items-center mb-2">
          <img
            src={profile === undefined ? Img_600 : profile}
            alt="user"
            width={200}
            height={150}
            className="rounded"
          />
          <Button
            size="sm"
            variant="separator-light"
            className="btn-icon btn-icon-only position-absolute rounded s-0 b-0 mt-5"
            onClick={onThumbChangeClick}
          >
            <i className="ion ion-md-image"></i>
          </Button>
          <input
            type="file"
            ref={refFileUpload}
            className="file-upload d-none"
            accept="image/*"
            onChange={changeThumb}
          />
        </div>
        <Row>
          <Col md={12} lg={12}>
            <div className="mb-3">
              <AvField
                name="email"
                label="Email"
                value={props?.email}
                className="form-control"
                type="email"
                required
                disabled
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={6}>
            <div className="mb-3">
              <AvField
                name="password"
                label="Password"
                type="password"
                required
              />
            </div>
          </Col>
          <Col md={6} lg={6}>
            <div className="mb-3">
              <AvField
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                required
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="firstName"
                value={props?.firstName}
                label="First Name"
                type="text"
                required
                disabled
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="lastName"
                value={props?.lastName}
                label="Last Name"
                type="text"
                required
                disabled
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <AvField name="gender" label="Gender" type="select" required>
                <option> Select... </option>
                <option> Male </option>
                <option> Female </option>
              </AvField>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="ID"
                label="ID / Passport number"
                type="text"
                required
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <label>Phone number</label>
            <div className="mb-3">
              <PhoneInput
                buttonStyle={{
                  borderBottomLeftRadius: 5,
                  borderTopLeftRadius: 5,
                  height: 40,
                }}
                inputStyle={{
                  margin: 0,
                  width: "100%",
                  color: "purple",
                  height: 1,
                  border: "none",
                }}
                containerStyle={{
                  width: "100%",
                }}
                className="blue"
                country={"za"}
                enableSearch={true}
                onChange={(phone) => props.setPhoneState(phone)}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Input1;
