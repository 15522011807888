import PropTypes from "prop-types"
import React, {useState} from "react"
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  Card,
  Form,
  Input,Button,CardTitle, Label,
} from "reactstrap"

import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

import Breadcrumb from "../../components/Common/Breadcrumb"
import MetaTagComp from "../../components/Common/MetaTags"
import { AvForm, AvField } from "availity-reactstrap-validation"

function AddInvoices() {

  const [rows1, setrows1] = useState([])
  const [rows2, setrows2] = useState([])

  function handleAddRowNested() {
    const item1 = { name1: "" }
    setrows1([...rows1, item1])
  }

  function handleAddRowNested1() {
    const item2 = { name1: "" }
    setrows2([...rows2, item2])
  }

  console.log(rows1);
  console.log(rows2);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Add Invoice" />
        <Breadcrumb default="Dashboard" title="Add Invoice" />

        <Container fluid>
          <div className="page-title-box">
            <CardBody data-aos="fade-bottom">
              <div className="page-title-box mb-3">
              <Link to="/all-invoice" className="btn login-btn w-md waves-effect waves-light" type="submit"> Back  </Link>
            </div>
              <Card className="bd-rds">
                <div className="TenantHeader bd-rds-header">
                  <h5>Add Invoice</h5>
                  
                </div>


                <Row>
          <Col xs={12}>

            <CardBody>
              <CardTitle className="h4 m-3 mb-5 text-center">Invoices Items</CardTitle>

              <Form className="repeater">
                <div data-repeater-list="group-a">

                <div data-repeater-item className="row">
                  <div className="mb-3 col-lg-6">
                    <label className="form-label" htmlFor="name">Assign Tenant (Choose) </label>
                    <Input type="select" id="name" name="quantity" className="form-control" >
                      <option></option>
                      <option>Jewel</option>
                    </Input>
                  </div>

                  <div className="mb-3 col-lg-6">
                    <label className="form-label" htmlFor="name"> Payment due date </label>
                    <input type="date" id="name" name="due_date" className="form-control" />
                  </div>
                </div>

                  <div data-repeater-item className="row">

                    <div className="mb-3 col-lg-4">
                      <label className="form-label" htmlFor="name">Quantity</label>
                      <input type="text" id="name" name="quantity" className="form-control" />
                    </div>

                    <div className="mb-3 col-lg-4">
                      <label className="form-label" htmlFor="email">Price Unit</label>
                      <input type="number"  className="form-control" />
                    </div>

                    <div className="mb-3 col-lg-4">
                      <label className="form-label" htmlFor="subject">Amount</label>
                      <input type="number"  className="form-control" />
                    </div>

                    {
                      /* 
                    }
                    <Col lg={2} className="align-self-center">
                      <div className="d-grid">
                        <input data-repeater-delete type="button" className="btn btn-danger" value="Delete" />
                      </div>
                    </Col>
                  */}
                  </div>

                </div>

                {rows2.map((item2, idx) => (
                  <React.Fragment key={idx}>
                    <div data-repeater-list="group-a" id={"addr" + idx} >
                    <div data-repeater-item className="row">

                    <div className="mb-3 col-lg-4">
                      <label className="form-label" htmlFor="name">Quantity</label>
                      <input type="text" id="name" name="quantity" className="form-control" />
                    </div>

                    <div className="mb-3 col-lg-4">
                      <label className="form-label" htmlFor="email">Price Unit</label>
                      <input type="number"  className="form-control" />
                    </div>

                    <div className="mb-3 col-lg-4">
                      <label className="form-label" htmlFor="subject">Amount</label>
                      <input type="number"  className="form-control" />
                    </div>

                    {
                      /* 
                    }
                    <Col lg={2} className="align-self-center">
                      <div className="d-grid">
                        <input data-repeater-delete type="button" className="btn btn-danger" value="Delete" />
                      </div>
                    </Col>
                  */}

                  </div>
                    </div>
                  </React.Fragment>
                ))}
                <Button
                  onClick={() => {
                    handleAddRowNested1()
                  }}
                  color="success"
                  className="btn login-btn w-md waves-effect waves-light"
                >Add</Button>
              </Form>
            </CardBody>
        </Col>
      </Row>



              </Card>
            </CardBody>
          </div>
        </Container>


        
                

      </div>
    </React.Fragment>
  )
}

AddInvoices.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(AddInvoices))
