import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "index";
import { loginUser } from "Redux/Slices/userSlice";
// import ViewDetails from "./components/ViewDetails";
// import EditDetails from "./components/EditDetails";
import { successMessage } from "components/Notifications";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { warningMessage } from "../../components/Notifications";

//import Navigation

import NavRequest from "./components/NavRequest";
import BookingRequest from "./components/BookingRequest";
import BookingApproved from "./components/BookingApproved";
import BookingDeclined from "./components/BookingDeclined";

function RequestBooking() {
  const userDet = useStore1Selector(loginUser);
  const ownerId = userDet?.original?.userDetails?.OwnerID;
  const [ViewModal, setViewModal] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [DeleteModal, setDeleteModal] = useState(false);
  const [SuspendModal, setSuspendModal] = useState(false);
  const [dataDb, setData] = useState([]);
  const [bookingId, setBookingId] = useState([]);
  const [dataProperty, setDataProperty] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState();
  const [loadBtn, setIloadBtn] = useState(false);

  // to keep
  // const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const [newRequest, setNewRequest] = useState(true);
  const [approvedRequest, setApprovedRequest] = useState(false);
  const [cancelledRequest, setCancelledRequest] = useState(false);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Bookings Management" />
        <Breadcrumb default="Bookings Management" title="Bookable Request" />
        <NavRequest
          allBookableUnits={newRequest}
          setAllBookableUnits={setNewRequest}
          availableUnits={approvedRequest}
          setAvailableUnits={setApprovedRequest}
          suspendedBUnits={cancelledRequest}
          setSuspendedUnits={setCancelledRequest}
        />
        <CardBody>
          <Container fluid>
            <Row className="d-flex justify-content-around align-items-center">
              <CardBody data-aos="fade-bottom">
                <Card className="bd-rds">
                  {newRequest ? <BookingRequest /> : null}
                  {approvedRequest ? <BookingApproved /> : null}
                  {cancelledRequest ? <BookingDeclined /> : null}
                </Card>
              </CardBody>
            </Row>
          </Container>
        </CardBody>
      </div>
    </React.Fragment>
  );
}

RequestBooking.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(RequestBooking));
