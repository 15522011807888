import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import logoSm from "../../../../assets/images/favicon.png";
import logo from "../../../../assets/images/faspro24_favicon-original.png";
import { useStore1Dispatch } from "../../../../index";
import { Login } from "../../../../Redux/Slices/userSlice";
import usePost from "../../../../hooks/usePostRegistration";
import { LoginMsg } from "../../../../components/NotifyMessage";

//* Socket-io
// import io from "socket.io-client";
// const socket = io.connect(`${process.env.REACT_APP_LOCAL}`);

import io from "socket.io-client";
const socket = io.connect("https://squid-app-hsedq.ondigitalocean.app");

function LoginForm() {
  const history = useHistory();

  const dispatch = useStore1Dispatch();
  const { execute, pending, data: postData } = usePost();

  const handleValidSubmit = (e, values) => {
    e.preventDefault();

    const Method = "POST",
      endPoint = "auth/login";
    const raw = JSON.stringify({
      email: values.email,
      password: values.password,
    });
    execute(endPoint, raw, Method, LoginMsg);
  };

  useEffect(() => {
    if (postData?.status === "success") {
      showNotification("Faspro 24", {
        body: "Logged in successfully",
      });

      if (
        postData?.data?.roles[0] === "service_provider" ||
        postData?.data?.roles[0] === "service_provider_manager"
      ) {
        dispatch(Login(postData));

        socket.emit("register_service_provider", postData?.data?.id);
        // console.log("postData?.data?.roles[0]...", postData?.data?.roles[0]);
        window.setTimeout(() => {
          history.push("/request-service");
        }, 2000);
      }
      if (postData?.data?.roles[0] === "security_manger") {
        dispatch(Login(postData));
        window.setTimeout(() => {
          history.push("/visitor-access");
        }, 2000);
      } else {
        dispatch(Login(postData));
        window.setTimeout(() => {
          history.push("/my-properties");
        }, 2000);
      }
    }
  }, [postData?.status]);

  // console.log("postData...", postData);

  function showNotification(title, options) {
    if (!("Notification" in window)) {
    } else if (Notification.permission === "granted") {
      new Notification(title, options);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          new Notification(title, options);
        }
      });
    }
  }

  // localhost:4800
  // gh.prop.faspro24.com

  //package route
  // if (result?.length === 0) {
  //       //   history.push("/subscriptions");
  //       // }
  //     })

  return (
    <div className="login-content-container">
      <div>
        <Card className="overflow-hidden">
          <div className="login-header">
            <div className="text-primary text-center p-4">
              <p className="text-white-50">Sign in to continue</p>
              <Link to="/" className="logo logo-admin">
                <img src={logoSm} alt="logo" className="favicon-logo" />
              </Link>
            </div>
          </div>

          <CardBody className="p-4">
            <div className="p-3">
              <AvForm
                className="form-horizontal mt-4"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email"
                    className="form-control"
                    type="email"
                    required
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    name="password"
                    label="Password"
                    type="password"
                    required
                  />
                </div>

                <Row className="mb-3">
                  <Col sm={12} className="text-center">
                    <button
                      className="btn login-btn w-md waves-effect waves-light"
                      type="submit"
                    >
                      <span className="me-2">Log In </span>
                      {!pending ? null : (
                        <Spinner as="span" animation="border" size="sm" />
                      )}
                    </button>
                  </Col>
                </Row>

                <Row className="mt-2 mb-0 row">
                  <div className="col-12 mt-4">
                    <Link to="/forgot-password" className="remember">
                      <i className="mdi mdi-lock"></i> Forgot your password?
                    </Link>
                  </div>
                </Row>
              </AvForm>
            </div>
          </CardBody>
        </Card>
        <div className="mt-5 text-center">
          {/* <p>
            Don&#39;t have an account?
            <Link to="/signup" className="fw-medium remember">
              {`  Signup now`}
            </Link>
          </p> */}
          <p> © {new Date().getFullYear()} FASPRO24 </p>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
