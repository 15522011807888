const LoginMsg = "Successfully logged In";
const ForgotPasswordMsg = "Successfully sent ";
const ResetPasswordMsg = "Your password has been successfully reset";
const LogoutMsg = "Successfully logout";
const RegisterMsg = "Successfully registered";
const verifyMsg = "Successfully verify";
const UpdatePasswordMsg = "Password successfully updated";
const SubscriptionMsg = "Successfully subscribe";
const updateProfileMsg = "Successfully updated the profile";
const AddBookMarkMsg = "Successfully add to book mark";
const RemoveBookMarkMsg = "Successfully removed ";
const CreateCourseMsg = "Successfully create a course ";
const DeleteCourseMsg = "Successfully deleted ";
const CreateSessionMsg = "Successfully create a session  ";
const SuspendedMsg = "Successfully suspended  ";
const UnSuspendedMsg = "Successfully unsuspended  ";
const ApprovedMsg = "Successfully Approved  ";
const DeclineMsg = "Successfully Decline  ";
const ApplyLongMsg = "Successfully applied  ";
const ServerError = "Something went wrong  ";
const ApprovedLoanMsg = "Successfully approved  ";
const DeclinedLoanMsg = "Successfully declined  ";
const VerificationLoanMsg = "Successfully moved to verification  ";
const SuspendUserMsg = "Successfully suspend the user  ";
const PaidPercentageMsg = "Successfully paid  ";
const UpdateNoticeMsg = "Successfully update notice  ";
const LeadNoticeMsg = "Successfully send  ";

export {
  LoginMsg,
  ForgotPasswordMsg,
  ResetPasswordMsg,
  LogoutMsg,
  RegisterMsg,
  verifyMsg,
  UpdatePasswordMsg,
  updateProfileMsg,
  SubscriptionMsg,
  AddBookMarkMsg,
  RemoveBookMarkMsg,
  CreateCourseMsg,
  DeleteCourseMsg,
  CreateSessionMsg,
  SuspendedMsg,
  UnSuspendedMsg,
  ApprovedMsg,
  DeclineMsg,
  ApplyLongMsg,
  ServerError,
  ApprovedLoanMsg,
  DeclinedLoanMsg,
  VerificationLoanMsg,
  SuspendUserMsg,
  PaidPercentageMsg,
  UpdateNoticeMsg,
  LeadNoticeMsg,
};
