import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Spinner,
  Badge,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { Link, useParams, useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import CreateTableForm from "./CreateTicketForm";
import useFecth from "../../../../hooks/useFecth";
import usePost from "../../../../hooks/usePost";
import CancelForm from "./CancelForm";
import AssignTicketForm from "./AssignTicketForm";

function TenantTable() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;

  const { execute, pending, data: postData } = usePost();
  const history = useHistory();
  const [assignID, setAssignID] = useState(false);
  const [cancelID, setCancelID] = useState();
  const [deleteID, setDeleteID] = useState();
  const [ticketModal, setTicketModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const list = [];

  const { data: getMaintenanceTickets, reFetch: reFetchMaintenanceTickets } =
    useFecth(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/maintenanceTickets`,
      token
    );

  getMaintenanceTickets?.forEach((res) => {
    if (res?.status === "open" || res?.status === "reopen") {
      if (!res?.assigned_to) {
        list.push({
          ...res,
          propertyName: res?.propertyId?.propertyName,
          status:
            res?.status === "open" ? (
              <>
                <Badge className="rounded-pill bg-primary p-2">Created</Badge>
              </>
            ) : res?.status === "reopen" ? (
              <>
                <Badge className="rounded-pill bg-info p-2">Reopened</Badge>
              </>
            ) : null,
          assidnedToBtn: (
            <button
              className="btn btn-dark"
              onClick={() => assignFunc(`${res?._id}`)}
            >
              Assign
            </button>
          ),
          viewBtn: (
            <button
              className="btn btn-success"
              onClick={() => viewFunc(`${res?._id}`)}
            >
              View
            </button>
          ),
          cancelBtn: (
            <button
              className="btn btn-warning"
              onClick={() => cancelFunc(`${res?._id}`)}
            >
              Cancel
            </button>
          ),
          deleteBtn: (
            <button
              className="btn btn-danger"
              onClick={() => deleteFunc(`${res?._id}`)}
            >
              Delete
            </button>
          ),
        });
      }
    }
  });

  const column = [
    { label: "Property name", field: "propertyName", sort: "asc", width: 150 },

    { label: "Title Name", field: "subject", sort: "asc", width: 150 },
    {
      label: "Ticket Reference",
      field: "ticketNumber",
      sort: "asc",
      width: 150,
    },
    { label: "Status", field: "status", sort: "asc", width: 150 },
    {
      label: "Assign To",
      field: "assidnedToBtn",
      sort: "asc",
      width: 150,
    },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Cancel", field: "cancelBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const dataDB = { columns: column, rows: list };

  const viewFunc = (id) => {
    history.push(`/view-ticket/${id}`);
  };

  const assignFunc = (id) => {
    setAssignID(id);
    setAssignModal(true);
  };

  const cancelFunc = (id) => {
    setCancelID(id);
    setCancelModal(true);
  };

  const deleteFunc = (id) => {
    setDeleteID(id);
    setDeleteModal(true);
  };

  const handleDeleteTicket = () => {
    const Method = "DELETE",
      endPoint = `maintenanceTickets/${deleteID}`,
      isJSON = null;

    const raw = null;

    execute(endPoint, raw, Method, "Ticket successfully delete", token, isJSON);
  };

  useEffect(() => {
    if (postData?.status === "success") {
      reFetchMaintenanceTickets();
      setDeleteModal(false);
      setCancelModal(false);
    }
  }, [postData?.status]);

  return (
    <div>
      <Card className="bd-rds">
        <CardBody>
          <div className="d-flex justify-content-end">
            <button className="btn login-btn w-md waves-effect waves-light mb-4">
              <CSVLink data={"dataDb"} separator={";"} className="text-white">
                Download in Excel
              </CSVLink>
            </button>
          </div>

          <MDBDataTable
            entries={5}
            entriesOptions={[5, 10, 50]}
            responsive
            bordered
            striped
            hover
            data={dataDB}
          />
        </CardBody>
      </Card>
      <Modal show={ticketModal} onHide={() => setTicketModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Create a ticket </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CreateTableForm
            setTicketModal={setTicketModal}
            reFetchMaintenanceTickets={reFetchMaintenanceTickets}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-danger text-white"
            onClick={() => setTicketModal(false)}
          >
            close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={assignModal} onHide={() => setAssignModal(false)} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Assign Service Provider </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <AssignTicketForm
            assignID={assignID}
            setAssignModal={setAssignModal}
            reFetchMaintenanceTickets={reFetchMaintenanceTickets}
          />
        </Modal.Body>
        {/* <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-danger text-white"
            onClick={() => setAssignModal(false)}
          >
            close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={cancelModal} onHide={() => setCancelModal(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Cancel Ticket </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-dark h5">Give a reaseon</div>
          <CancelForm
            cancelID={cancelID}
            reFetchMaintenanceTickets={reFetchMaintenanceTickets}
            setCancelModal={setCancelModal}
          />
        </Modal.Body>
      </Modal>
      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Delete Ticket </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-danger h4">
            Are you sure you want to delete this ticket ?
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-success text-white"
            onClick={handleDeleteTicket}
          >
            {!pending ? (
              "Sure"
            ) : (
              <span>
                <Spinner as="span" animation="border" size="sm" /> Loading...
              </span>
            )}
          </Button>
          <Button
            className="bg-danger text-white"
            onClick={() => setDeleteModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TenantTable;
