import React from "react";
import { Redirect } from "react-router-dom";
import {
  LoginRoute,
  ForgottenRoute,
  ResetRoute,
  VerifyEmailRoute,
  SignupRoute,
  SignupPropertyOwnerRoute,
  SignupPropertyAgencyRoute,
  SubscriptionRoute,
  MyPropertyRoute,
} from "components/RouteName";

// Profile
import UserProfile from "../pages/Authentication/Profile/Index";
import TalkToUsPage from "../pages/TalkToUs/index";
// Authentication related pages
import LoginPage from "../pages/Authentication/Login/Index";
import ForgetPasswordPage from "../pages/Authentication/Forget-password/Index";
import ResetPasswordPage from "../pages/Authentication/Reset Password/index";
import SignupPage from "../pages/Authentication/Signup/Index";
import PropertyManagementPage from "../pages/Authentication/Index";
import EstateManagementPage from "../pages/Authentication/Estate/Index";
import SubscriptionsPage from "../pages/Subscription/Index";

import Modules from "../pages/Authentication/Modules/Index";
import verifyEmailPage from "../pages/Authentication/verifyEmail";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import AddProperty from "../pages/Properties/Add-property";
import EditProperty from "../pages/Properties/Edit-property";
import AddStandAlonePro from "../pages/Properties/Stand-alone";
import PropertyTable from "../pages/Properties/Property-table";
import PropertyDetail from "../pages/Properties/Property-details";
import AddTenant from "../pages/Tenants/Add-tenant";
import TenantTable from "../pages/Tenants/Tenant-table";
import ViewMyTeam from "../pages/MyTeam/Team-table";
import AddMyTeam from "../pages/MyTeam/Add-team";
import GetTenantByProperty from "../pages/Tenants/Tenant-table";
import AddLease from "../pages/Leases/Add-lease";
import TableLease from "../pages/Leases/Lease-table";
import AddUtility from "../pages/Utilities/Add-utility";
import UtilityTable from "../pages/Utilities/Utility-table";
import UtilityDetails from "../pages/Utilities/Utility-details";

import AllInvoice from "../pages/Invoices/Invoice-table";
import AddInvoice from "../pages/Invoices/add-invoice";
import PaymentTable from "../pages/Payment/Payment-table";

import PropertyNotice from "../pages/Vocate Note/PropertyNotice";
import UnitNotice from "../pages/Vocate Note/UnitNotice";
import UnitDetails from "../pages/Properties/Unit-details";
import PropertyReport from "../pages/Properties/PropertyReport";
import UnitReport from "../pages/Properties/UnitReport";
import AddVacateNote from "../pages/Vocate Note/Add-vocate-note";
import AddNotice from "../pages/Notice/Add-Notice";
import AllNotice from "../pages/Notice/All-notice";

import Booking from "../pages/Bookings/Index";
import AddBooking from "../pages/Bookings/AddBooking";
import RequestBooking from "../pages/Bookings/RequestBooking";
import BookingReport from "../pages/Bookings/Report";
// import AvailableBookings from "../pages/Bookings/Available-booking";
// import BookedProperties from "../pages/Bookings/Booked-properties";

import MyTasks from "../pages/Maintenance/Tasks/Tasks";
import FacilityMaintenanceReport from "../pages/Maintenance/Report/Report";
import MaintenanceRequest from "../pages/Service Provider/Maintenance-request";

// security modules
import SecurityManagerTable from "../pages/Security/SecurityManagerTable";
import SecurityTeamTable from "../pages/Security/SecurityTeamTable";

import AddAssets from "../pages/Assets/Add-assets";
import AssetsTable from "../pages/Assets/Asset-table";
import UserManagement from "../pages/User-management/User-table";
import AddUser from "../pages/User-management/Add-user";
import Settings from "../pages/Settings/Subscription";
import Profile from "../pages/Settings/Profile";
import FinanceDetails from "../pages/Finance/Finance-details";
import Tickets from "../pages/Maintenance/Tickets/Tickets";
import ViewTicket from "../pages/Maintenance/Tickets/components/ViewTicketPage";
import RequestService from "../pages/Maintenance/Request-Tickets/Request";
import MyTickets from "../pages/Service Provider/My-tickets";
import MaintenanceInvoices from "../pages/Maintenance/Invoices/Invoices";
import historyTicket from "../pages/Maintenance/TicketsHistory/History";
import myHistoryTicket from "../pages/Maintenance/MyHistory/MyHistory";
import Notifications from "../pages/Notifications/Index";
import MyUnits from "../pages/Properties/My-Unit";
import MyServiceProviders from "../pages/Service Provider/MyServiceProviders";
import ServiceProviderDetails from "../pages/Properties/ServiceProviderDetails";

import Finance from "../pages/Finance/Index";

import RequestMyVisitors from "../pages/Security/RequestMyVisitors";
import VisitorAccess from "pages/Security/VisitorAccess";
import CheckedIn from "pages/Security/Check-in";
import CheckedOut from "pages/Security/Check-out";
import ManagerAccessVisitors from "pages/Security/ManagerAccessVisitors";

// import SuspendBookableUnits from "pages/Bookings/SuspendBookableUnits";
import AssetsPastYear from "pages/Assets/AssetsPastYear";
import CurrentMonthAssets from "pages/Assets/CurrentMonthAssets";
// import MyTasks from "pages/Maintenance/Tasks/Tasks";
// import VisitorReport from "pages/Security/Visitor-report";
import SecurityReport from "pages/Security/Report";
import ViewDocuments from "pages/File Management/ViewDocuments";
import CreateDocuments from "pages/File Management/CreateDocuments";
import PropertyDocReport from "pages/File Management/Report";
const userRoutes = [
  { path: "/stand-alone", component: AddStandAlonePro },
  { path: "/my-properties", component: PropertyTable },
  { path: "/add-property", component: AddProperty },
  { path: "/edit-property/:propId", component: EditProperty },
  { path: "/my-property-notice/:propID", component: PropertyNotice },
  { path: "/property-report/:propID", component: PropertyReport },
  { path: "/property-details/:propId", component: PropertyDetail },

  { path: "/view-team", component: ViewMyTeam },
  { path: "/add-team", component: AddMyTeam },

  { path: "/add-lease", component: AddLease },
  { path: "/my-leases", component: TableLease },
  { path: "/add-utility", component: AddUtility },
  { path: "/my-utilities", component: UtilityTable },
  { path: "/utility-details/:id", component: UtilityDetails },

  // { path: "/all-invoice", component: AllInvoice },
  // { path: "/add-invoice", component: AddInvoice },
  // { path: "/payment-table", component: PaymentTable },
  // { path: "/add-vacate-note", component: AddVacateNote },
  // { path: "/add-notice", component: AddNotice },
  // { path: "/my-notices", component: AllNotice },

  { path: "/my-units/:propId/:propType", component: MyUnits },
  { path: "/unit-report/:propID/:unitID/:propType", component: UnitReport },
  { path: "/my-unit-notice/:unitID", component: UnitNotice },
  { path: "/unit-details/:propID/:unitID/:propType", component: UnitDetails },
  { path: "/my-tenants", component: TenantTable },
  { path: "/add-tenant", component: AddTenant },
  { path: "/my-tenants/:id", component: GetTenantByProperty },

  { path: "/my-bookings", component: Booking },
  { path: "/add-bookings", component: AddBooking },
  { path: "/request-booking", component: RequestBooking },
  { path: "/bookings-report", component: BookingReport },

  // service provider

  { path: "/view-ticket/:viewID", component: ViewTicket },
  { path: "/tickets", component: Tickets },
  { path: "/maintance-invoices", component: MaintenanceInvoices },
  { path: "/tickets-history", component: historyTicket },
  {
    path: "/facitity-maintenance-report",
    component: FacilityMaintenanceReport,
  },

  { path: "/request-service", component: RequestService },
  { path: "/my-tickets", component: MyTickets },
  { path: "/my-tasks", component: MyTasks },

  { path: "/my-history", component: myHistoryTicket },

  { path: "/service-provider", component: MyServiceProviders },
  { path: "/maintenance-request", component: MaintenanceRequest },

  { path: "/add-asset", component: AddAssets },
  { path: "/my-assets", component: AssetsTable },

  // { path: "/my-teams", component: UserManagement },
  // { path: "/add-user", component: AddUser },
  // { path: "/my-subscription", component: Settings },
  // { path: "/finance-details", component: FinanceDetails },

  // settings
  { path: "/my-profile", component: Profile },

  { path: "/assets-past-year", component: AssetsPastYear },
  { path: "/assets-current-month", component: CurrentMonthAssets },
  // { path: "/suspended-bookable-units", component: SuspendBookableUnits },

  {
    path: "/service-provider-details/:propId/:serviceProId",
    component: ServiceProviderDetails,
  },

  { path: "/my-finances", component: Finance },
  { path: "/notifications", component: Notifications },
  { path: "/my-access-list", component: SecurityReport },

  // { path: "/my-access-list", component: AccessList },

  { path: "/request-visitor", component: RequestMyVisitors },
  { path: "/security-manager", component: SecurityManagerTable },
  { path: "/security-team", component: SecurityTeamTable },
  { path: "/visitor-access", component: ManagerAccessVisitors },
  { path: "/visit-list", component: VisitorAccess },
  { path: "/check-in", component: CheckedIn },
  { path: "/check-out", component: CheckedOut },
  { path: "/security-report", component: SecurityReport },
  { path: "/my-documents", component: ViewDocuments },
  { path: "/Create-documents", component: CreateDocuments },
  { path: "/document-report", component: PropertyDocReport },

  { path: "/", exact: true, component: () => <Redirect to={LoginRoute} /> },
];

const authRoutes = [
  { path: "/Talk-To-Us", component: TalkToUsPage },
  { path: LoginRoute, component: LoginPage },
  { path: ForgottenRoute, component: ForgetPasswordPage },
  { path: ResetRoute, component: ResetPasswordPage },
  { path: SignupRoute, component: SignupPage },
  { path: SignupPropertyOwnerRoute, component: PropertyManagementPage },
  { path: SignupPropertyAgencyRoute, component: EstateManagementPage },
  { path: SubscriptionRoute, component: SubscriptionsPage },

  { path: "/modules", component: Modules },
  { path: VerifyEmailRoute, component: verifyEmailPage },
];

export { userRoutes, authRoutes };
