import { useEffect, useState } from "react";

const useFetch = (url, token) => {
  const [data, setData] = useState([]);
  const [stripeData, setStripeData] = useState();
  const [length, setLength] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    setLoading(true);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === "success") {
          setStripeData(result?.data);
          setData(result?.data);
          setStatus(result?.status);
          setLoading(false);
        }
        if (result?.status === "fail") {
          setStatus("fail");
          setLoading(false);
        }
        if (result?.status === "error") {
          setStatus("fail");
          setLoading(false);
        }
      })
      .catch((error) => {
        setStatus("fail");
        setError(error.message);
        setLoading(false);
      });
  }, [url]);

  const reFetch = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    setLoading(true);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === "success") {
          setData(result?.data);
          setLength(result?.length);
          setLoading(false);
        }
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  return { stripeData, data, length, error, reFetch, loading, status };
};

export default useFetch;
