import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col, Container, Row, Card, Modal, Button } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "./../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
import useFetch from "../../hooks/useFecth";
import useCustomQuery from "../../hooks/useCustomQuery";
import usePost from "../../hooks/usePost";
import Loading from "components/Loading";
import NoticeDetails from "./components/NoticeDetails";
import EditNotice from "./components/EditNotice";
import LoadingTable from "components/LoadingTable";

function VocateNote({ t }) {
  const { propID } = useParams();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending, status } = usePost();
  const [noticeID, setNoticeID] = useState();

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  let list = [];
  const key = ["myPropertyNotice", propID];
  const { data, reFetch } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/notices/property/${propID}`,
    token
  );

  const {
    data: getMyPropertyNotice,
    error: errorMyPropertyNotice,
    isLoading: loadingMyPropertyNotice,
  } = useCustomQuery(
    key,
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/notices/property/${propID}`,
    token
  );

  function viewFunc(id) {
    setNoticeID(id);
    setViewModal(true);
  }

  function editFunc(id) {
    setNoticeID(id);
    setEditModal(true);
  }

  function deleteFunc(id) {
    setNoticeID(id);
    setDeleteModal(true);
  }

  getMyPropertyNotice?.forEach((res) => {
    list.push({
      ...res,
      propertyName: res?.propertyId?.propertyName,
      descriptionNotice: `${res?.description.substring(0, 40)}`,
      viewBtn: (
        <button className="btn btn-success" onClick={() => viewFunc(res?._id)}>
          {t("view")}
        </button>
      ),
      editBtn: (
        <button className="btn btn-primary" onClick={() => editFunc(res?._id)}>
          Edit
        </button>
      ),
      deleteBtn: (
        <button className="btn btn-danger" onClick={() => deleteFunc(res?._id)}>
          Delete
        </button>
      ),
    });
  });

  const deleteNotice = () => {
    const Method = "DELETE",
      endPoint = `notices/${noticeID}`,
      isJSON = true;
    var formdata = new FormData();
    execute(
      endPoint,
      formdata,
      Method,
      "Notice Successfully Deleted",
      token,
      isJSON
    );
    setDeleteModal(false);
  };

  if (pending) {
    setTimeout(() => {
      reFetch();
    }, 2000);
  }

  const column = [
    {
      label: "Property Name",
      field: "propertyName",
      sort: "asc",
      width: 150,
    },
    { label: "Notice Title", field: "subject", sort: "asc", width: 150 },
    {
      label: "Description",
      field: "descriptionNotice",
      sort: "asc",
      width: 150,
    },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const newData = {
    columns: column,
    rows: list,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | Property Notice" />
        <Breadcrumb default={t("Property")} title={t("Property Notices")} />
        <Card.Body>
          <Link
            to={`/property-details/${propID}`}
            className="btn login-btn mb-3"
          >
            {" "}
            {t("Back")}{" "}
          </Link>
          <Container fluid>
            <Row className="d-flex justify-content-around align-items-center">
              <Card className="bd-rds">
                <Card.Body data-aos="fade-bottom">
                  <div className="d-flex justify-content-between">
                    <button className="btn report-warning w-md waves-effect waves-light mb-4 d-flex justify-content-end">
                      <CSVLink
                        data={list?.reverse()}
                        separator={";"}
                        className="text-white"
                      >
                        {" "}
                        {t("Download in Excel")}{" "}
                      </CSVLink>
                    </button>
                  </div>
                  {loadingMyPropertyNotice ? (
                    <LoadingTable />
                  ) : (
                    <MDBDataTable
                      entries={5}
                      entriesOptions={[5, 10, 50]}
                      responsive
                      bordered
                      striped
                      hover
                      data={newData}
                      fullPagination
                    />
                  )}
                </Card.Body>
              </Card>
            </Row>
          </Container>
        </Card.Body>
        <Modal
          className="modal-right scroll-out-negative"
          show={viewModal}
          onHide={() => setViewModal(false)}
          scrollable
          size="lg"
          dialogClassName="full"
        >
          <Modal.Header closeButton>
            <Modal.Title> {t("Notice Details")} </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <NoticeDetails noticeId={noticeID} />
          </Modal.Body>
        </Modal>
        <Modal
          className="modal-right scroll-out-negative"
          show={editModal}
          onHide={() => setEditModal(false)}
          scrollable
          dialogClassName="full"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("Edit Noticee")}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EditNotice
              noticeId={noticeID}
              closeModal={setEditModal}
              reFetch={reFetch}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          size="sm"
        >
          <Modal.Body className="text-danger">
            <h4>{t("Are you sure you want to delete this notice ? ")}</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setDeleteModal(false)}>
              {t("Cancel")}
            </Button>
            <Button variant="danger" onClick={deleteNotice}>
              {t("Yes")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}

VocateNote.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(VocateNote));
