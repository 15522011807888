import PropTypes from "prop-types";
import React from "react";
import { Col, Row, CardBody, Card, Container } from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter, Link, useParams } from "react-router-dom";
import {
  MdOutlineAssignmentTurnedIn,
  MdPendingActions,
  MdOutlinePreview,
} from "react-icons/md";
import Click from "../../../assets/Gif/Click here.gif";

function Nav({
  propertyDocument,
  setPropertyDocuments,
  tenantTicket,
  pendingTicket,
  setTenantTicket,
  setPendingTicket,
  clodedTicket,
  setClodedTicket,
  overdueTicket,
  setOverdueTicket,
}) {
  const { propId } = useParams();

  const handlePropertyDocuments = () => {
    setPropertyDocuments(true);
    setTenantTicket(false);
    setPendingTicket(false);
    setClodedTicket(false);
    setOverdueTicket(false);
  };

  const handleUnitDocuments = () => {
    setPropertyDocuments(false);
    setTenantTicket(false);
    setPendingTicket(true);
    setClodedTicket(false);
    setOverdueTicket(false);
  };

  return (
    <React.Fragment>
      <Row>
        <Container fluid>
          <button
            onClick={handlePropertyDocuments}
            className={
              propertyDocument
                ? "btn bg-white boder-3 border-success text-success me-3 p-3 rounded-3"
                : "btn btn-success me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-house-user me-1"></i> Property Documents
          </button>

          {/* <button
            onClick={handleUnitDocuments}
            className={
              pendingTicket
                ? "btn bg-white text-warning border border-warning me-3 p-3 rounded-3"
                : "btn btn-warning me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-door-open"></i> Units Documents 
          </button>*/}

          {/* <button
            onClick={handleClosed}
            className={
              clodedTicket
                ? "btn btn-success bg-white border border-success text-success me-3 p-3 rounded-3"
                : "btn btn-success me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-check-circle"></i> closed Ticket (04)
          </button> */}
          {/* <button
            onClick={handleOverdue}
            className={
              overdueTicket
                ? "btn bg-white border border-danger text-danger me-3 p-3 rounded-3"
                : "btn btn-danger me-3 p-3 rounded-3"
            }
          >
            <i className="	fas fa-clock"></i> Overdue Ticket (01)
          </button> */}
        </Container>
      </Row>
    </React.Fragment>
  );
}

Nav.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Nav));
