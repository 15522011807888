import React, { useEffect, useState } from "react";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { Row, Col, Spinner } from "reactstrap";

import { useStore1Selector } from "../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";

import usePost from "../../../../hooks/usePost";
import useFetch from "../../../../hooks/useFecth";
import { ServiceType } from "../../../../components/ServiceType";
import { priority } from "../../../../components/Priority";

function AssignTicketForm({
  assignID,
  setAssignModal,
  reFetchMaintenanceTickets,
}) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending, data: postData } = usePost();
  const [propertyId, setPropertyId] = useState(null);
  const [unitId, setUnitId] = useState("");
  const [unitDetails, setUnitDetails] = useState([]);
  const [category, setCategory] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [issueAttachment, setIssueAttachment] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  const [emergency, setEmergency] = useState(false);
  const ownerId = userDet?.data?._id;

  const {
    data: getPropertyAllProperties,
    reFetch: reFetchGetPropertyAllProperties,
  } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/property_owner?fieldName=userId`,
    token
  );

  const { data: getAllUnits, reFetch: reFetchGetAllUnits } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/units/property_owner/${propertyId}`,
    token
  );

  const { data: getAllService, reFetch: reFetchGetAllService } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/users/propertyOwner/${ownerId}/serviceProvider`,
    token
  );

  const handleValidSubmit = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      endPoint = `maintenanceTickets/${assignID}`,
      isJSON = true;
    var formdata = new FormData();
    // formdata.append("subject", subject);
    // formdata.append("description", description);
    // if (issueAttachment) {
    //   issueAttachment.map((item) => {
    //     formdata.append("issueAttachment", item);
    //   });
    // }
    // formdata.append("categories", category);
    formdata.append("assigned_to", serviceProvider);
    // formdata.append("propertyId", propertyId);
    // formdata.append("unitId", unitId);
    // formdata.append("emergency", emergency);

    execute(
      endPoint,
      formdata,
      Method,
      "Ticket successfully assigned",
      token,
      isJSON
    );
  };

  // const handleAttachment = (event) => {
  //   const selectedFiles = event.target.files;
  //   const fileArray = Array.from(selectedFiles);
  //   setIssueAttachment(fileArray);

  //   const previews = fileArray.map((file) => URL.createObjectURL(file));
  //   setImagePreviews(previews);
  // };

  useEffect(() => {
    if (propertyId) {
      reFetchGetAllUnits();
    }
  }, [propertyId]);

  // useEffect(() => {
  //   if (getAllUnits) {
  //     setUnitDetails(getAllUnits);
  //   }
  // }, [getAllUnits]);

  // const handlePropertyChange = (event) => {
  //   setPropertyId(event.target.value);
  // };

  // const handleEmergency = (event) => {
  //   if (event.target.checked) {
  //     setEmergency(true);
  //   } else {
  //     setEmergency(false);
  //   }
  // };

  if (postData?.status === "success") {
    setAssignModal();
    reFetchMaintenanceTickets();
  }

  return (
    <form className="form-horizontal mt-4" onSubmit={handleValidSubmit}>
      <Row>
        {/* <Col md={6}>
          <div className="mb-3">
            <label>Property Name</label>
            <select
              className="form-control"
              required
              onChange={handlePropertyChange}
            >
              <option> Select... </option>
              {getPropertyAllProperties?.map((item, i) => (
                <option key={i} value={item?._id}>
                  {item?.propertyName}
                </option>
              ))}
            </select>
          </div>
        </Col> */}
        {/* <Col md={6}>
          <div className="mb-3">
            <label>Unit Name</label>
            <select
              className="form-control"
              value={unitId}
              required
              onChange={(e) => setUnitId(e.target.value)}
            >
              <option> Select...</option>
              {unitDetails?.map((item, i) => (
                <option key={i} value={item?._id}>
                  {item?.unitName}
                </option>
              ))}
            </select>
          </div>
        </Col> */}
        {/* <Col md={6}>
          <div className="mb-3">
            <label>Categories</label>
            <select
              className="form-control"
              required
              onChange={(e) => setCategory(e.target.value)}
            >
              <option> Select...</option>
              {ServiceType?.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </Col> */}
        <Col md={12}>
          <div className="mb-3">
            <label>Service Provider</label>
            <select
              className="form-control"
              required
              onChange={(e) => setServiceProvider(e.target.value)}
            >
              <option> Select...</option>
              {getAllService?.map((item, i) => (
                <option key={i} value={item?._id}>
                  {item?.firstName} {item?.lastName}
                </option>
              ))}
            </select>
          </div>
        </Col>
        {/* <Col md={12}>
          <div className="mb-3">
            <label>Subject</label>
            <input
              className="form-control"
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
        </Col> */}
      </Row>

      {/* <div className="mb-3">
        <label>Description</label>
        <textarea
          className="form-control"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={6}
        />
      </div> */}
      {/* <Row>
        <Col md={6} className="Input__form">
          <input type="checkbox" onChange={handleEmergency} />
          <label className="mx-1 text-success">Tick if emergency</label>
        </Col>
      </Row> */}
      {/* <Row>
        <Col md={12}>
          <div
            className="dropzone-previews d-flex align-items-center"
            id="file-previews"
          >
            {imagePreviews.map((previewURL, index) => (
              <img
                key={index}
                data-dz-thumbnail=""
                height="150"
                className="avatar-sm rounded bg-light m-2 border border-dark"
                alt={`Preview ${index}`}
                src={previewURL}
              />
            ))}
          </div>
        </Col>
      </Row> */}

      {/* <Row>
        <Col md={12}>
          <div className="mb-3 drop-zone">
            <label htmlFor="attachment" className="drop-zone-label">
              <i className="fas fa-cloud-upload-alt"></i>
              <div className="drop-zone-text">Upload pictures of the issue</div>
            </label>
            <input
              type="file"
              id="attachment"
              className="form-control drop-zone-input"
              multiple
              accept="image/jpeg, image/png, image/gif"
              onChange={handleAttachment}
            />
          </div>
        </Col>
      </Row> */}

      <div className="d-flex justify-content-center">
        <button
          className="btn login-btn w-md waves-effect waves-light"
          type="submit"
        >
          {!pending ? (
            "Assign"
          ) : (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="me-2"
              />
              Loading...
            </>
          )}
        </button>
      </div>
    </form>
  );
}

export default AssignTicketForm;
