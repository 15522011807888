import PropTypes from "prop-types"
import React from 'react'
import { Container, Row, CardBody, Col, Card } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next"
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import UserForm from "./Components/User-form";
import { Link } from "react-router-dom";

function EditUser() {

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTagComp meta_tags="FASPRO 24 | edit user" />
                <Breadcrumb default="Property" title="Add New User" />

                <CardBody>
                    <Link to="/my-teams" className="btn login-btn w-md waves-effect waves-light mb-3" type="submit"> Back </Link>
                    <Container fluid>

                        <Row className="d-flex justify-content-around align-items-center">
                            <Card>
                                <CardBody>
                                    <UserForm />
                                </CardBody>
                            </Card>

                        </Row>
                    </Container>
                </CardBody>
            </div>
        </React.Fragment>
    )
}

EditUser.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(EditUser))