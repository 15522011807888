

const Properties_arr = [
    {
      property_name :"Gallagher",
      property_code :"234",
      unit_name :"floor 5",
      total_rooms :10,
      pament_method :"Cash",
      late_fee :"Penalty",
      late_fee_cost : 400,
      Frequency :"Monthly",

      viewBtn : <button className="btn login-btn" onClick={()=> tog_viewDet()}>View</button>,

      editBtn : <button className="btn btn-primary" 
      onClick={() => history.push(`/vacate-detail/${data.vacateNoticeID}`)}>Edit</button>,

      deleteBtn : <button className="btn btn-danger" 
      onClick={() => history.push(`/delete-detail/${data.vacateNoticeID}`)}>Delete</button>,

    },
    {
      property_name :"Gallagher",
      property_code :"234",
      unit_name :"floor 5",
      total_rooms :10,
      pament_method :"Cash",
      late_fee :"Penalty",
      late_fee_cost : 400,
      Frequency :"Monthly",
      viewBtn : <button className="btn login-btn" onClick={() => {  tog_scroll(data.vacateNoticeID) }}>View</button>,

      editBtn : <button className="btn btn-primary" 
      onClick={() => history.push(`/vacate-detail/${data.vacateNoticeID}`)}>Edit</button>,

      deleteBtn : <button className="btn btn-danger" 
      onClick={() => history.push(`/delete-detail/${data.vacateNoticeID}`)}>Delete</button>,
    }
]

export function properties_Obj() {
    return Properties_arr;
}
