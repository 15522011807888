import PropTypes from "prop-types";
import React from "react";
import { Col, Row, CardBody, Card, Container } from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter, Link, useParams } from "react-router-dom";
import {
  MdOutlineAssignmentTurnedIn,
  MdPendingActions,
  MdOutlinePreview,
} from "react-icons/md";

function NavRequest({
  allBookableUnits,
  setAllBookableUnits,
  availableUnits,
  setAvailableUnits,
  suspendedBUnits,
  setSuspendedUnits,
}) {
  const { propId } = useParams();

  const handleTAllBookableUnits = () => {
    setAllBookableUnits(true);
    setAvailableUnits(false);
    setSuspendedUnits(false);
  };

  const handleAvailableBookings = () => {
    setAllBookableUnits(false);
    setAvailableUnits(true);
    setSuspendedUnits(false);
  };

  const handleSuspendedBookings = () => {
    setAllBookableUnits(false);
    setAvailableUnits(false);
    setSuspendedUnits(true);
  };

  return (
    <React.Fragment>
      <Row>
        <Container fluid>
          <button
            onClick={handleTAllBookableUnits}
            className={
              allBookableUnits
                ? "btn bg-white boder-3 border-primary text-primary me-3 p-3 rounded-3"
                : "btn btn-primary me-3 p-3 rounded-3"
            }
          >
            <div>
              <i className="fas fa-home me-1"></i>New Bookings Request
            </div>
          </button>
          <button
            onClick={handleAvailableBookings}
            className={
              availableUnits
                ? "btn bg-white boder-3 border-success text-success me-3 p-3 rounded-3"
                : "btn btn-success me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-clipboard-check me-1"></i> Accepted Request
          </button>

          <button
            onClick={handleSuspendedBookings}
            className={
              suspendedBUnits
                ? "btn bg-white text-danger border border-danger me-3 p-3 rounded-3"
                : "btn btn-danger me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-ban"></i> Declined Request
          </button>
        </Container>
      </Row>
    </React.Fragment>
  );
}

NavRequest.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(NavRequest));
