import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStore1Selector, useStore1Dispatch } from "../../../index";
import { loginUser, Login } from "../../../Redux/Slices/userSlice";
import { Col, Row, Card, CardBody, Spinner, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useHistory } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import Avatar from "../../../assets/images/Avatar.png";
import usePost from "../../../hooks/usePost";
import PhoneInput from "react-phone-input-2";
import gender from "../../../components/Gender";

function OtherUserDetails({ picture, getUserById, token, reFetchGetUserById }) {
  const userDet = useStore1Selector(loginUser);
  const dispatch = useStore1Dispatch();
  const history = useHistory();
  const [profileServer, setProfileServer] = useState();
  const [phoneState, setPhoneState] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState();
  const { execute, pending, data: postDetails } = usePost();
  const {
    execute: executeLogout,
    pending: executePending,
    data: postLogout,
  } = usePost();

  const handleBtnOtherUser = (e, values) => {
    e.preventDefault();
    console.log(values);
    const Method = "PATCH",
      endPoint = `users/updateMe`,
      isJSON = true;

    const formdata = new FormData();
    {
      !profileServer ? null : formdata.append("profilePic", profileServer);
    }
    {
      !values.firstName ? null : formdata.append("firstName", values.firstName);
    }
    {
      !values.lastName ? null : formdata.append("lastName", values.lastName);
    }
    {
      !values.email ? null : formdata.append("email", values.email);
    }

    {
      !phoneState ? null : formdata.append("phoneNumber", phoneState);
    }

    {
      !values.gender ? null : formdata.append("gender", values.gender);
    }

    execute(
      endPoint,
      formdata,
      Method,
      "Profile successfully updated",
      token,
      isJSON
    );
  };

  const refFileUpload = useRef(null);
  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileServer(event.target.files[0]);

      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const Logout = () => {
    const Method = "POST",
      endPoint = "auth/logout";
    const raw = "";
    execute(
      endPoint,
      raw,
      Method,
      "You will be redirected to login page",
      token
    );
    dispatch(Login(""));
    localStorage.removeItem("persist:users");
    setTimeout(() => {
      history.push(`/login`);
    }, 3000);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  const myRole = getUserById?.roles ?? [];
  const Roles = [
    "property_owner_individual",
    "property_owner_agency",
    "property_owner_company",
    "property_mgt_marketing",
    "property_mgt_sales",
    "property_mgt_finance",
    "property_mgt_maintenance",
    "property_mgt_leasing_officer",
    "portfolio_manager",
    "property_agency",
    "facility_manager",
    "service_provider",
    "service_provider_manager",
  ];
  if (postDetails?.status === "success") {
    Logout();
  }

  return (
    <>
      <AvForm
        className="form-horizontal mt-4 "
        onValidSubmit={(e, v) => {
          handleBtnOtherUser(e, v);
        }}
      >
        <h5 className="text-center mb-4">Profile details</h5>
        {isLoading ? (
          <div className="d-flex mt-3 mb-5 justify-content-center align-items-center">
            <ThreeCircles
              height="50"
              width="50"
              color="#23B25A"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center mb-3">
            <img
              src={
                profile
                  ? profile
                  : !profile && !picture
                  ? Avatar
                  : !profile && picture
                  ? `${picture}`
                  : profile && picture
                  ? profile
                  : null
              }
              alt="user"
              className="rounded-3 mb-3"
              width={150}
              height={150}
            />
            <Button
              size="sm"
              variant="separator-light"
              className="btn-icon btn-icon-only position-absolute rounded s-0 b-0 mt-2"
              onClick={onThumbChangeClick}
            >
              <i className="ion ion-md-image"></i>
            </Button>
            <input
              type="file"
              ref={refFileUpload}
              className="file-upload d-none"
              accept="image/*"
              onChange={changeThumb}
            />
          </div>
        )}

        <Row>
          <Col md={12}>
            <div className="mb-3">
              <AvField
                value={
                  myRole[0] === Roles[3]
                    ? "Marketing"
                    : myRole[0] === Roles[4]
                    ? "Sales"
                    : myRole[0] === Roles[5]
                    ? "Finance"
                    : myRole[0] === Roles[6]
                    ? "Maintenance"
                    : myRole[0] === Roles[7]
                    ? "Lease Officer"
                    : myRole[0] === Roles[8]
                    ? "Portfolio Manager"
                    : myRole[0] === Roles[9]
                    ? "Property Agency"
                    : myRole[0] === Roles[10]
                    ? "Facility Manager"
                    : myRole[0] === Roles[11]
                    ? "Service Provider"
                    : myRole[0] === Roles[12]
                    ? "Service Provider"
                    : null
                }
                name="roles"
                label="Role"
                type="text"
                disabled
              />
            </div>
          </Col>

          <Col md={6}>
            <div className="mb-3">
              <AvField
                value={getUserById?.firstName}
                name="firstName"
                label="First Name"
                type="text"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                value={getUserById?.lastName}
                name="lastName"
                label="Last Name"
                type="text"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                value={getUserById?.email}
                name="email"
                label="Email"
                className="form-control"
                type="email"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                value={getUserById?.gender}
                name="gender"
                label="Gender"
                type="select"
              >
                <option name={getUserById?.gender}>
                  {getUserById?.gender}
                </option>
                {gender.map((item, i) => {
                  if (getUserById?.gender !== item) {
                    return (
                      <option name={item} key={i}>
                        {item}
                      </option>
                    );
                  }
                })}
              </AvField>
            </div>
          </Col>

          <Col md={6}>
            <p>Phone number</p>
            <PhoneInput
              buttonStyle={{
                borderBottomLeftRadius: 5,
                borderTopLeftRadius: 5,
                height: 40,
              }}
              inputStyle={{
                margin: 0,
                width: "100%",
                color: "purple",
                height: 1,
                border: "none",
              }}
              containerStyle={{
                width: "100%",
              }}
              className="blue"
              country={"gh"}
              enableSearch={true}
              value={getUserById?.phoneNumber}
              onChange={(phone) => setPhoneState(phone)}
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-center mt-5">
          <div className="mb-3 mt-3 w-25">
            <button
              className="btn btn-primary w-100 waves-effect waves-light text-center"
              type="submit"
            >
              {!pending ? <span className="me-2">Update Profile</span> : null}
              {!pending ? null : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading...
                </span>
              )}
            </button>
          </div>
        </div>
      </AvForm>
    </>
  );
}

export default OtherUserDetails;
