import PropTypes from "prop-types";
import React from "react";
import { Col, Row, CardBody, Card, Container } from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter, Link, useParams } from "react-router-dom";
import {
  MdOutlineAssignmentTurnedIn,
  MdPendingActions,
  MdOutlinePreview,
} from "react-icons/md";
import Click from "../../../assets/Gif/Click here.gif";

function Nav({
  assignTicket,
  setAssignTicket,
  workingTicket,
  setWorkingTicket,
  clodedTicket,
  setClodedTicket,
  overdueTicket,
  setOverdueTicket,
  reopenTicket,
  setReopenTicket,
}) {
  const { propId } = useParams();

  const handleAssign = () => {
    setWorkingTicket(false);
    setAssignTicket(true);
    setWorkingTicket(false);
    setClodedTicket(false);
    setOverdueTicket(false);
    setReopenTicket(false);
  };

  const handleWorking = () => {
    setAssignTicket(false);
    setWorkingTicket(false);
    setWorkingTicket(true);
    setClodedTicket(false);
    setOverdueTicket(false);
    setReopenTicket(false);
  };

  const handleReopened = () => {
    setAssignTicket(false);
    setWorkingTicket(false);
    setWorkingTicket(false);
    setClodedTicket(false);
    setOverdueTicket(false);
    setReopenTicket(true);
  };

  const handleClosed = () => {
    setAssignTicket(false);
    setWorkingTicket(false);
    setClodedTicket(true);
    setOverdueTicket(false);
    setReopenTicket(false);
  };
  const handleOverdue = () => {
    setAssignTicket(false);
    setWorkingTicket(false);
    setClodedTicket(false);
    setOverdueTicket(true);
    setReopenTicket(false);
  };

  return (
    <React.Fragment>
      <Row>
        <Container fluid>
          {/* <button
            onClick={handleCreated}
            className={
              createTicket
                ? "btn bg-white boder-3 border-dark text-dark me-3 p-3 rounded-3"
                : "btn btn-dark me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-clipboard me-1"></i> Assign Ticket(0)
          </button> */}
          <button
            onClick={handleAssign}
            className={
              assignTicket
                ? "btn bg-white boder-3 border-info text-info me-3 p-3 rounded-3"
                : "btn btn-info me-3 p-3 rounded-3"
            }
          >
            <div>
              <i className="fas fa-clipboard me-1"></i>Assign Ticket
            </div>
          </button>

          <button
            onClick={handleWorking}
            className={
              workingTicket
                ? "btn bg-white text-warning border border-warning me-3 p-3 rounded-3"
                : "btn btn-warning me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-spinner fa-spin"></i> Ticket in progess
          </button>
          <button
            onClick={handleClosed}
            className={
              clodedTicket
                ? "btn btn-success bg-white border border-success text-success me-3 p-3 rounded-3"
                : "btn btn-success me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-check-circle"></i> closed Ticket
          </button>
          <button
            onClick={handleReopened}
            className={
              reopenTicket
                ? "btn bg-white border border-dark text-dark me-3 p-3 rounded-3"
                : "btn btn-dark me-3 p-3 rounded-3"
            }
          >
            <i className="	fas fa-clock"></i> Reopen Ticket
          </button>
          <button
            onClick={handleOverdue}
            className={
              overdueTicket
                ? "btn bg-white border border-danger text-danger me-3 p-3 rounded-3"
                : "btn btn-danger me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-hourglass-end"></i> Overdue Ticket
          </button>
        </Container>
      </Row>
      {/* <Row>
        <Col xl={3} md={6}>
          <Card
            className={
              createTicket
                ? "mini-stat  text-white bd-rds border border-primary"
                : "mini-stat  text-white bd-rds bg-primary"
            }
            onClick={handleOpen}
          >
            <CardBody>
              <div className="d-flex align-items-center">
                <div
                  className={
                    createTicket
                      ? "float-start mini-stat-img me-2 font-size-22 bg-primary"
                      : "float-start mini-stat-img me-2 font-size-22 "
                  }
                >
                  10
                </div>
                <div>
                  <h6
                    className={
                      createTicket
                        ? "font-size-14  text-uppercase text-primary"
                        : "font-size-14  text-uppercase text-white"
                    }
                  >
                    Created Tickets
                  </h6>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} md={6}>
          <Card
            className={
              pendingTicket
                ? "mini-stat  text-white bd-rds border border-warning"
                : "mini-stat  text-white bd-rds bg-warning"
            }
            onClick={handlePending}
          >
            <CardBody>
              <div className="d-flex align-items-center">
                <div
                  className={
                    pendingTicket
                      ? "float-start mini-stat-img me-2 font-size-22 bg-warning"
                      : "float-start mini-stat-img me-2 font-size-22"
                  }
                >
                  02
                </div>
                <div>
                  <h6
                    className={
                      pendingTicket
                        ? "font-size-14  text-uppercase text-warning"
                        : "font-size-14  text-uppercase text-white"
                    }
                  >
                    Pending Tickets
                  </h6>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} md={6}>
          <Card
            className={
              clodedTicket
                ? "mini-stat  text-white bd-rds border border-success"
                : "mini-stat  text-white bd-rds bg-success"
            }
            onClick={handleClosed}
          >
            <CardBody>
              <div className="d-flex align-items-center">
                <div
                  className={
                    clodedTicket
                      ? "float-start mini-stat-img me-2 font-size-22 bg-success"
                      : "float-start mini-stat-img me-2 font-size-22 "
                  }
                >
                  30
                </div>
                <div>
                  <h6
                    className={
                      clodedTicket
                        ? "font-size-14  text-uppercase text-success"
                        : "font-size-14  text-uppercase"
                    }
                  >
                    Closed Tickets
                  </h6>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} md={6}>
          <Card
            className={
              overdueTicket
                ? "mini-stat  text-white bd-rds border border-danger"
                : "mini-stat  text-white bd-rds bg-danger"
            }
            onClick={handleOverdue}
          >
            <CardBody>
              <div className="d-flex align-items-center">
                <div
                  className={
                    overdueTicket
                      ? "float-start mini-stat-img me-2 font-size-22 bg-danger"
                      : "float-start mini-stat-img me-2 font-size-22"
                  }
                >
                  04
                </div>
                <div>
                  <h6
                    className={
                      overdueTicket
                        ? "font-size-14  text-uppercase text-danger"
                        : "font-size-14  text-uppercase"
                    }
                  >
                    Overdue Tickets
                  </h6>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row> */}
    </React.Fragment>
  );
}

Nav.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Nav));
