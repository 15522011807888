import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import useCustomMutation from "../../../hooks/useCustomMutation";
import useCustomQuery from "../../../hooks/useCustomQuery";
import useFetch from "../../../hooks/useFecth";

function ViewBookableUnits({ bookUnitId, setEditModal, ownerId, token }) {
  const [propertyId, setPropertyId] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [unitsPictures, setUnitsPictures] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  const [unitsName, setUnitName] = useState("");
  const [surfaceArea, setSurfaceArea] = useState("");
  const [unitType, setUnitType] = useState("");
  const [rentAmount, setRentAmount] = useState("");
  const [occupancy, setOccupancy] = useState("");
  const [parking, setParking] = useState(false);
  const [pets, setPets] = useState(false);
  const [bookableField, setBookableField] = useState(false);
  const [description, setDescription] = useState("");
  const [info, setInfo] = useState("");

  const key = ["getAllBookableUnits", ownerId];
  const mutation = useCustomMutation(key);

  const {
    data: getMyBookableUnits,
    error: errorGetMyBookableUnits,
    isLoading: isLoadingGetMyBookableUnits,
  } = useCustomQuery(
    ["getBookableUnitsDetails", ownerId],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/bookableUnits//${bookUnitId}`,
    token
  );

  useEffect(() => {
    if (getMyBookableUnits?.bookableField) {
      setBookableField(getMyBookableUnits?.bookableField);
      setPets(getMyBookableUnits?.petPolicy);
      setParking(getMyBookableUnits?.parkingGarage);
      //   setImagePreviews(getMyBookableUnits?.getMyBookableUnits);
    }
  }, [getMyBookableUnits]);

  //   console.log("getMyBookableUnits...", getMyBookableUnits);

  const {
    data: getPropertyAllProperties,
    reFetch: reFetchGetPropertyAllProperties,
  } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/property_owner?fieldName=userId`,
    token
  );

  //   console.log(
  //     "getMyBookableUnits?.unitImages..",
  //     getMyBookableUnits?.unitImages
  //   );

  return (
    <form className="form-horizontal mt-4">
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <label>Property Name</label>
            <input
              className="form-control"
              value={getMyBookableUnits?.propertyName}
              required
              disabled
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <label>Unit Name</label>
            <input
              className="form-control"
              value={getMyBookableUnits?.unitName}
              disabled
            />
          </div>
        </Col>

        <Col md={3}>
          <div className="mb-3">
            <label>Unit Type</label>
            <input
              className="form-control"
              value={getMyBookableUnits?.unitType}
              disabled
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="mb-3">
            <label>Price/hour</label>
            <input
              className="form-control"
              value={getMyBookableUnits?.bookingFee}
              disabled
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="mb-3">
            <label>Surface Area</label>
            <input
              className="form-control"
              value={getMyBookableUnits?.surfaceArea}
              disabled
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="mb-3">
            <label>Maximum Occupancy</label>
            <input
              className="form-control"
              value={getMyBookableUnits?.maximumOccupancy}
              onChange={(e) => setOccupancy(e.target.value)}
              disabled
            />
          </div>
        </Col>

        <div className="mb-3">
          <label>Unit Description</label>
          <textarea
            className="form-control"
            value={getMyBookableUnits?.unitSpecification}
            rows={10}
            disabled
          />
        </div>
      </Row>
      <Row>
        <Col md={4} className="Input__form">
          <input type="checkbox" value={getMyBookableUnits?.parkingGarage} />
          <label className="mx-1 text-success">Parking</label>
        </Col>
        <Col md={4} className="Input__form">
          <input type="checkbox" value={getMyBookableUnits?.petPolicy} />
          <label className="mx-1 text-success">Allow Pets</label>
        </Col>
        <Col md={4} className="Input__form">
          <input
            type="checkbox"
            value={getMyBookableUnits?.bookableField}
            disabled
          />
          <label className="mx-1 text-success">Make unit available</label>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="mb-3">
            <label>Additional Info</label>
            <textarea className="form-control" value={info} rows={6} disabled />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div
            className="dropzone-previews d-flex align-items-center"
            id="file-previews"
          >
            {getMyBookableUnits?.unitImages?.map((item, index) => {
              return (
                <img
                  key={index}
                  data-dz-thumbnail=""
                  className="avatar-sm rounded bg-light m-2 border border-dark bookableImages-sizes"
                  alt={`Preview ${index}`}
                  src={item}
                />
              );
            })}
          </div>
        </Col>
      </Row>
    </form>
  );
}

export default ViewBookableUnits;
