import { check } from "prettier"
import Img_5 from "../../../assets/images/Modules/Faspro24_illustrations_lease_management_2.png"
import Img_6 from "../../../assets/images/Modules/Faspro24_illustrations_property_maintenance.png"


const module__arr = [
    {
        id: 1,
        img: Img_5,
        price: "450",
        text: "Add all your property details, assign to tenants, add your utilities per property and assign leases.",
        value: "450",
        label: "Facility Management",
        class_css: "activate-color",
        disable: true,
        checked: true
    },

    {
        id: 2,
        img: Img_6,
        price: "380",
        text: "Track maintenance tickets and allocate to service providers seamlessly.",
        value: "380",
        label: "Maintenance Management",
        class_css: "activate-color",
        disable: true,
        checked: true
    },
]

export const modules = () => {
    return module__arr
}