import React, { useState } from "react";
import "./../modules.scss";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import {
  AvForm,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { modules } from "./Object";

function Modules() {
  const [check, setCheck] = useState("");

  const handleValidSubmit = (values) => {
    console.log(values);
  };
  console.log(check);

  return (
    <div className="account-pages module">
      <h2 className="text-center m-5 text-success">Select Modules</h2>

      <AvForm
        className="mt-4"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <AvCheckboxGroup name="checkboxExample">
          <Row className="main-container">
            {modules().map((module, idx) => (
              <Col md={6} key={idx}>
                {/* <Card className={`card-module ${check ? module.class_css : ""}`}> */}
                <Card>
                  <CardBody className="d-flex">
                    <div className="img__container">
                      <img src={module.img} alt="" />
                    </div>
                    <div className="checkbox">
                      <div>
                        {/* <h3>
                          ${module.price}
                          <small>/month</small>
                        </h3> */}
                        <small className="text-center"> {module.text} </small>
                      </div>
                      <p>
                        <AvCheckbox
                          label={module.label}
                          value={module.value}
                          disabled={module.disable}
                          checked={module.checked}
                          onChange={(e) => {
                            setCheck(e.target.checked);
                          }}
                        />
                      </p>
                    </div>
                  </CardBody>
                  <div className="border__bottom"></div>
                </Card>
              </Col>
            ))}

            <div className="text-center mb-5">
              <Link to="/login">
                <button className="btn report-success w-md text-white mt-3">
                  Send
                </button>{" "}
              </Link>
            </div>
          </Row>
        </AvCheckboxGroup>
      </AvForm>
    </div>
  );
}

export default Modules;
