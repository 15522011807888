import PropTypes from "prop-types";
import React from "react";
import "./../Style.scss";
import MetaTagComp from "../../../../components/Common/MetaTags";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { signUp } from "./Object";
import Slide from "../../../../components/Slide";
import Img from "./../../../../assets/images/faspro24_logo_original.png";
import { SignupMeta } from "../../../../components/SCO_Name";

const ChooseModule = (props) => {
  return (
    <React.Fragment>
      <MetaTagComp meta_tags={SignupMeta} />
      <Row>
        <Col md={6}>
          <CardBody>
            <CardBody>
              <div className="logo w-50">
                <img src={Img} alt="" />
              </div>
              <p className="text-center mt-5 text-success">
                Start managing your property like a PRO today!
              </p>
              <Row className="justify-content-center module-content-container mt-5">
                {signUp().map((signUp, idx) => (
                  <Col md={6} lg={6} xl={6} key={idx}>
                    <Card className="bd-rds" data-aos="fade-bottom">
                      <CardBody>
                        <h4 className="text-center text-success">
                          {signUp.name}
                        </h4>
                      </CardBody>
                      <div className="text-center">
                        <signUp.icon size={30} />
                      </div>
                      <div className="text-center">
                        <div className="d-flex justify-content-center">
                          <Link to={`${signUp.link}`}>
                            <div className="mt-3 mb-3 button___style text-white">
                              <p className="pt-1">Proceed</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </CardBody>
          </CardBody>
        </Col>

        <Col md={6} className="login-side-container">
          <div className="login-img-container">
            <Slide />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default ChooseModule;
