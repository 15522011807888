import PropTypes from "prop-types";
import React from "react";
import { Col, Row, CardBody, Card, Container } from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter, Link, useParams } from "react-router-dom";
import {
  MdOutlineAssignmentTurnedIn,
  MdPendingActions,
  MdOutlinePreview,
} from "react-icons/md";
import Click from "../../../../assets/Gif/Click here.gif";

function Nav({
  requestTicket,
  setRequestTicket,
  approvedTicket,
  setApprovedTicket,
  declinedTicket,
  setDeclinedTicket,
  NoRequestService,
  NoApproveService,
  NoDeclineService,
}) {
  const { propId } = useParams();

  const handleAssign = () => {
    setRequestTicket(true);
    setApprovedTicket(false);
    setDeclinedTicket(false);
  };

  const handleApproved = () => {
    setRequestTicket(false);
    setApprovedTicket(true);
    setDeclinedTicket(false);
  };

  const handleDeclined = () => {
    setRequestTicket(false);
    setApprovedTicket(false);
    setDeclinedTicket(true);
  };

  return (
    <React.Fragment>
      <Row>
        <Container fluid>
          <button
            onClick={handleAssign}
            className={
              requestTicket
                ? "btn bg-white boder-3 border-primary text-primary me-3 p-3 rounded-3"
                : "btn btn-primary me-3 p-3 rounded-3"
            }
          >
            <div>
              <i className="fa fa-bell me-1"></i>Request Service
              {/* (
              {NoRequestService}) */}
            </div>
          </button>
          <button
            onClick={handleApproved}
            className={
              approvedTicket
                ? "btn btn-success bg-white border border-success text-success me-3 p-3 rounded-3"
                : "btn btn-success me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-check-circle"></i> Approved Service
            {/* (
            {NoApproveService}) */}
          </button>
          <button
            onClick={handleDeclined}
            className={
              declinedTicket
                ? "btn btn-danger bg-white border border-danger text-danger me-3 p-3 rounded-3"
                : "btn btn-danger me-3 p-3 rounded-3"
            }
          >
            <i className="fas fa-times-circle"></i> Declined Service
            {/* (
            {NoDeclineService}) */}
          </button>
        </Container>
      </Row>
    </React.Fragment>
  );
}

Nav.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Nav));
