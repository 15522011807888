import PropTypes from "prop-types";
import React, { useState } from "react";
import { Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import useCustomMutation from "../../../hooks/useCustomMutation";

import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

function PropertyNotice({ propId, closeModal, t }) {
  const userDet = useStore1Selector(loginUser);

  const key = ["myPropertyNotice", propId];
  const mutation = useCustomMutation(key);

  const [state, setState] = useState();
  const token = userDet?.token;
  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    const Method = "POST",
      endPoint = `notices/property/${propId}`,
      isJSON = true;
    const formdata = new FormData();
    formdata.append("subject", values.title);
    formdata.append("description", values.notice);
    if (state) {
      formdata.append("noticeDocument", state);
    }

    const data = {
      endPoint: endPoint,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Notice Successfully Sent",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const onFileChange = (e) => {
    setState(e.target.files[0]);
  };

  if (mutation.isSuccess) {
    setTimeout(() => {
      closeModal(false);
    }, 1000);
  }

  return (
    <div>
      <AvForm
        className="form-horizontal mt-4"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <div className="mb-3">
          <span> {t("Title")} </span>
          <AvField name="title" className="form-control" type="text" required />
        </div>

        <div className="mb-3">
          <span> {t("Attach a document")} </span>
          <AvField
            name="name"
            type="file"
            className="form-control"
            onChange={onFileChange}
          />
        </div>

        <div className="mb-3">
          <span>{t("Description")}</span>
          <AvField
            className="form-control"
            type="textarea"
            rows="3"
            name="notice"
            errorMessage="This value is required."
            validate={{ required: { value: true } }}
          />
        </div>

        <button
          className="btn login-btn w-md waves-effect waves-light"
          type="submit"
        >
          {!mutation.isLoading ? (
            <span className="me-2"> {t("Submit")} </span>
          ) : null}
          {!mutation.isLoading ? null : (
            <span>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="me-2"
              />
              {t("Loading...")}
            </span>
          )}
        </button>
      </AvForm>
    </div>
  );
}

PropertyNotice.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(PropertyNotice));
