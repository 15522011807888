import React, { useState } from "react";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import { Container, Row, Card, Modal, Button } from "react-bootstrap";
import { useStore1Selector } from "../../../../../index";
import { loginUser } from "../../../../../Redux/Slices/userSlice";
import usePost from "../../../../../hooks/usePost";
import GetInvoiceForm from "./GetInvoiceForm";
import EditInvoiceForm from "./EditInvoiceForm";

function InvoiceTableForm({ unitID, unitEInvoiceTable, reFetchInvoice }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending } = usePost();
  const [viewInvoiceModal, setViewInvoiceModal] = useState(false);
  const [editInvoiceModal, setEditInvoiceModal] = useState(false);
  const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
  const [viewInvoiceID, setViewInvoiceID] = useState();
  const [editInvoiceID, setEditInvoiceID] = useState();
  const [deleteInvoiceID, setDeleteInvoiceID] = useState();


  const listExtraCharge = [];
  unitEInvoiceTable.forEach((res) => {
    listExtraCharge.push({
      ...res,
      unit_rentalAmount: `GH₵ ${res?.rentalAmount}`,
      unit_totalAmount: `GH₵ ${res?.totalAmount}`,
      created_On: res?.createdOn.substring(0, 10),
      status_Invoice: (
        <p
          className={
            res?.status === "paid" ? "text-success" : res?.status === "pending" ? "text-warning" : "text-danger"}
        >
          {res?.status}
        </p>
      ),
      viewBtn: (
        <button
          className="btn btn-success"
          onClick={() => viewInvoiceFunc(res?._id)}
        >
          view
        </button>
      ),
      editBtn: (
        <button
          className="btn btn-primary"
          onClick={() => editInvoiceFunc(res?._id)}
        >
          Edit
        </button>
      ),
      deleteBtn: (
        <button
          className="btn btn-danger"
          onClick={() => deleteInvoiceFunc(res?._id)}
        >
          Delete
        </button>
      ),
    });
  });

  const columnInvoices = [
    {
      label: "Rental amount",
      field: "unit_rentalAmount",
      sort: "asc",
      width: 150,
    },
    {
      label: "Total amount",
      field: "unit_totalAmount",
      sort: "asc",
      width: 150,
    },
    { label: "Status", field: "status_Invoice", sort: "asc", width: 150 },
    { label: "Created on", field: "created_On", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const DataInvoices = {
    columns: columnInvoices,
    rows: listExtraCharge,
  };

  console.log(" listExtraCharge : ", listExtraCharge)

  function viewInvoiceFunc(id) {
    setViewInvoiceID(id);
    setViewInvoiceModal(true);
  }
  function editInvoiceFunc(id) {
    setEditInvoiceID(id);
    setEditInvoiceModal(true);
  }
  function deleteInvoiceFunc(id) {
    setDeleteInvoiceID(id);
    setDeleteInvoiceModal(true);
  }
  const deleteUnitNotice = () => {
    const Method = "DELETE",
      endPoint = `invoices/${deleteInvoiceID}`,
      isJSON = true;
    var formdata = new FormData();
    execute(
      endPoint,
      formdata,
      Method,
      "Notice Successfully Deleted",
      token,
      isJSON
    );
    setDeleteInvoiceModal(false);
    setTimeout(() => {
      reFetchInvoice();
    }, 2000);
  };

  return (
    <Card className="bd-rds">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <button className="btn login-btn w-md waves-effect waves-light mb-4 d-flex justify-content-end report-warning">
            <CSVLink
              data={"Invoices"}
              headers={listExtraCharge.reverse()}
              separator={";"}
              className="text-white"
            >
              Download in Excel
            </CSVLink>
          </button>
        </div>
        <MDBDataTable
          entries={5}
          entriesOptions={[5, 10, 50]}
          responsive
          bordered
          striped
          hover
          data={DataInvoices}
        />
      </Card.Body>
      <Modal
        className="modal-right scroll-out-negative"
        show={viewInvoiceModal}
        onHide={() => setViewInvoiceModal(false)}
        scrollable
        dialogClassName="full"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Invoice </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <GetInvoiceForm
            unitID={unitID}
            invoiceId={viewInvoiceID}
            reFetchInvoice={reFetchInvoice}
            closeModal={setViewInvoiceModal}
          />
        </Modal.Body>
      </Modal>
      <Modal
        className="modal-right scroll-out-negative"
        show={editInvoiceModal}
        onHide={() => setEditInvoiceModal(false)}
        scrollable
        dialogClassName="full"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Invoice </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditInvoiceForm
            unitID={unitID}
            invoiceId={editInvoiceID}
            reFetchInvoice={reFetchInvoice}
            closeModal={setEditInvoiceModal}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={deleteInvoiceModal}
        onHide={() => setDeleteInvoiceModal(false)}
        size="sm"
      >
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to delete this Invoice ? </h6>{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setDeleteInvoiceModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteUnitNotice}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
}

export default InvoiceTableForm;
