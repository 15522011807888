import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, Modal, Button, Spinner } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
import { ImEye } from "react-icons/im";
import { useStore1Selector } from "../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
import AddVisitorForm from "./Components/AddVisitorForm";
import EditServiceProviderForm from "./Components/EditSecurityForm";
import ViewVisitorDetail from "./Components/ViewVisitorDetail";
import useCustomQuery from "../../hooks/useCustomQuery";
import useCustomMutation from "../../hooks/useCustomMutation";
import LoadingTable from "../../components/LoadingTable";

function RequestMyVisitors() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const ownerId = userDet?.data?._id;

  const {
    data: getMyVisitors,
    error,
    isLoading,
  } = useCustomQuery(
    ["myVisitors"],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/visitors/${ownerId}/property-owner-get-visitors`,
    token
  );

  console.log("getMyVisitors...", getMyVisitors);

  const key = ["myVisitors"];
  const mutation = useCustomMutation(key);
  const [editDet, setEdit] = useState(false);
  const [viewID, setViewID] = useState(false);
  const [deleteID, setDeleteID] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [AddVisitor, setAddVisitor] = useState(false);

  const list = [];

  getMyVisitors?.forEach((res) => {
    let duration = "";
    const createdAt = new Date(res?.createdAt);
    const expirationTime = new Date(res?.expirationTime);
    const timeDifference = expirationTime - createdAt;

    const minutesDuration = Math.floor(timeDifference / (1000 * 60));
    const hoursDuration = Math.floor(timeDifference / (1000 * 60 * 60));
    const daysDuration = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const weeksDuration = Math.floor(
      timeDifference / (1000 * 60 * 60 * 24 * 7)
    );
    const monthsDuration = Math.floor(
      timeDifference / (1000 * 60 * 60 * 24 * 30)
    );

    if (minutesDuration < 60) {
      duration = `${minutesDuration + 1} min`;
    } else if (hoursDuration < 24) {
      duration = `${hoursDuration + 1} hr(s)`;
    } else if (daysDuration < 7) {
      duration = `${daysDuration + 1} day(s)`;
    } else if (weeksDuration < 4) {
      duration = `${weeksDuration + 1} week(s)`;
    } else {
      duration = `${monthsDuration + 1} month(s)`;
    }

    let expiration_Date = "";
    const now = new Date();
    const expirationDate = new Date(res?.expirationTime);
    const currentDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    const targetDate = new Date(
      expirationDate.getFullYear(),
      expirationDate.getMonth(),
      expirationDate.getDate()
    );

    const diffInTime = currentDate - targetDate;

    const diffInDays = diffInTime / (1000 * 60 * 60 * 24);
    if (diffInDays === 0) {
      expiration_Date = "Today";
    } else if (diffInDays === 1) {
      expiration_Date = "Yesterday";
    } else if (diffInDays > 1 && diffInDays < 7) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      expiration_Date = `${daysOfWeek[expirationDate.getDay()]}`;
    } else if (diffInDays >= 7) {
      expiration_Date = expirationDate.toISOString().slice(0, 10);
    } else {
      expiration_Date = expirationDate.toISOString().slice(0, 10);
    }

    list.push({
      ...res,
      FullName: `${res?.visitorDetails?.fullName}`,
      PropertyName: res?.property?.propertyName,
      UnitName: res?.unit ? `${res?.unit?.unitName}` : `---`,
      requestDate: `${res?.updatedAt?.substring(0, 10)}`,
      durationDate: duration,
      expirationDate: expiration_Date,
      phoneNumber: `+${res?.visitorDetails?.phoneNumber}`,
      status:
        res?.status === "checkIn" ? (
          <span className="text-success">Checked In</span>
        ) : res?.status === "checkOut" ? (
          <span className="text-danger">Checked Out</span>
        ) : (
          "---"
        ),

      viewBtn: (
        <button
          className="btn btn-success"
          onClick={() => viewFunc(`${res?._id}`)}
        >
          View
        </button>
      ),
      editBtn: (
        <button
          className="btn btn-dark"
          onClick={() => editFunc(`${res?._id}`)}
        >
          Edit
        </button>
      ),
      deleteBtn: (
        <button
          className="btn btn-danger"
          onClick={() => deleteFunc(`${res?._id}`)}
        >
          Delete
        </button>
      ),
    });
  });

  const viewFunc = (id) => {
    setViewID(id);
    setViewModal(true);
  };

  const editFunc = (id) => {};

  const deleteFunc = (id) => {
    setDeleteID(id);
    setDeleteModal(true);
  };

  const deleteUnitFunc = () => {
    const Method = "DELETE",
      endPoint = `visitors/${deleteID}/`,
      isJSON = null;

    const raw = null;
    const data = {
      endPoint: endPoint,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Visitor Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const column = [
    { label: "Full Name", field: "FullName", sort: "asc", width: 150 },
    { label: "Phone Number", field: "phoneNumber", sort: "asc", width: 150 },
    { label: "Visitor Type", field: "type", sort: "asc", width: 150 },
    { label: "Pin", field: "entryPIN", sort: "asc", width: 150 },
    { label: "Duration", field: "durationDate", sort: "asc", width: 150 },
    {
      label: "Expiration Date",
      field: "expirationDate",
      sort: "asc",
      width: 150,
    },
    { label: "Property Name", field: "PropertyName", sort: "asc", width: 150 },
    { label: "Unit Name", field: "UnitName", sort: "asc", width: 150 },
    { label: "Visitor Status", field: "status", sort: "asc", width: 200 },

    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const data = {
    columns: column,
    rows: list,
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      setDeleteModal(false);
    }
  }, [mutation.isSuccess]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Request Visitor" />
        <Breadcrumb
          default="Security Management"
          title="Request Visitor Access"
        />

        <Card.Body>
          <Container fluid>
            <Row
              className="d-flex justify-content-around align-items-center"
              data-aos="fade-bottom"
            >
              <Card className="bd-rds">
                <Card.Body>
                  <button
                    onClick={() => setAddVisitor(true)}
                    className="btn login-btn w-md waves-effect waves-light mb-4 report-primary mx-2"
                    type="submit"
                  >
                    + Request Visitor Access
                  </button>
                  {isLoading ? (
                    <LoadingTable />
                  ) : (
                    <MDBDataTable
                      entries={5}
                      entriesOptions={[5, 10, 50]}
                      responsive
                      bordered
                      striped
                      hover
                      data={data}
                    />
                  )}
                </Card.Body>
              </Card>
            </Row>
          </Container>
        </Card.Body>

        <Modal
          className="modal-center scroll-out-negative"
          show={AddVisitor}
          onHide={() => setAddVisitor(false)}
          scrollable
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Request Visitor Access</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <AddVisitorForm setAddVisitor={setAddVisitor} />
          </Modal.Body>
        </Modal>
        <Modal
          className="modal-center scroll-out-negative"
          show={viewModal}
          onHide={() => setViewModal(false)}
          scrollable
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Visitor details</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <ViewVisitorDetail viewID={viewID} setViewModal={setViewModal} />
          </Modal.Body>
        </Modal>

        <Modal
          className="modal-right scroll-out-negative"
          show={editDet}
          onHide={() => setEdit(false)}
          scrollable
          dialogClassName="full"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Visitor Details</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EditServiceProviderForm setEdit={setEdit} />
          </Modal.Body>
        </Modal>

        <Modal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          size="md"
        >
          <Modal.Body className="text-danger">
            <h4>Are you sure you want to delete this visitor ?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={deleteUnitFunc}>
              {!mutation.isLoading ? (
                <>
                  <span>Sure</span>
                </>
              ) : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading...
                </span>
              )}
            </Button>
            <Button variant="danger" onClick={() => setDeleteModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}

RequestMyVisitors.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(RequestMyVisitors));
