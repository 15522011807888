import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Spinner, Button } from "reactstrap";
import useCustomQuery from "../../../hooks/useCustomQuery";
import useCustomMutation from "../../../hooks/useCustomMutation";
import LoadingTable from "../../../components/LoadingTable";
import { Modal } from "react-bootstrap";
import ViewVisitorDetail from "./ViewVisitorDetail";

function PropertyVisitorTable({ propertyID, token }) {
  const [viewID, setViewID] = useState();
  const [deleteID, setDeleteID] = useState();
  const [viewModal, setViewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const key = ["myPropertyVisit", propertyID];
  const mutation = useCustomMutation(key);

  const {
    data: getMyPropVisit,
    error: errorGetMyPropVisit,
    isLoading: isLoadingGetMyPropVisit,
  } = useCustomQuery(
    ["myPropertyAllVisitor", propertyID],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/visitor-history/${propertyID}/property`,
    token
  );

  console.log("getMyPropVisit...", getMyPropVisit);

  const list = [];
  getMyPropVisit?.forEach((res) => {
    const dateIn = new Date(res?.checkInTime);
    const dateOut = new Date(res?.checkOutTime);

    const hoursIn = dateIn.getUTCHours();
    const minutesIn = dateIn.getUTCMinutes();
    const secondsIn = dateIn.getUTCSeconds();

    const timeIn = `${hoursIn.toString().padStart(2, "0")}:${minutesIn
      .toString()
      .padStart(2, "0")}:${secondsIn.toString().padStart(2, "0")}`;

    const hoursOut = dateOut.getUTCHours();
    const minutesOut = dateOut.getUTCMinutes();
    const secondsOut = dateOut.getUTCSeconds();

    const timeOut = `${hoursOut.toString().padStart(2, "0")}:${minutesOut
      .toString()
      .padStart(2, "0")}:${secondsOut.toString().padStart(2, "0")}`;

    list.push({
      ...res,
      fullName: `${res?.visitorId?.visitorDetails?.fullName}`,
      propertyName: res?.visitorId?.property?.propertyName,
      unitName: res?.unit ? `${res?.visitorId?.unit?.unitName}` : "---",
      check_in_By: `${res?.check_in_By?.firstName} ${res?.check_in_By?.lastName}`,
      check_out_By: res?.check_out_By?.firstName
        ? `${res?.check_out_By?.firstName} ${res?.check_out_By?.lastName}`
        : "---",
      timeIn: timeIn,
      timeOut: timeOut === "NaN:NaN:NaN" ? "---" : timeOut,
      checkInDate: res?.checkInTime.substring(0, 10),
      checkOutDate: res?.checkOutTime
        ? res?.checkOutTime.substring(0, 10)
        : "---",
      visitorType: res?.visitorId?.type,

      viewBtn: (
        <button
          className="btn btn-success"
          onClick={() => viewFunc(`${res?._id}`)}
        >
          View
        </button>
      ),
      cancelBtn: (
        <button
          className="btn btn-warning"
          onClick={() => cancelFunc(`${res?._id}`)}
        >
          Cancel
        </button>
      ),
      deleteBtn: (
        <button
          className="btn btn-danger"
          onClick={() => deleteFunc(`${res?._id}`)}
        >
          Delete
        </button>
      ),
    });
  });

  const column = [
    {
      label: "Full Name",
      field: "fullName",
      sort: "asc",
      width: 150,
    },
    {
      label: "Property name",
      field: "propertyName",
      sort: "asc",
      width: 150,
    },
    { label: "Unit Name", field: "unitName", sort: "asc", width: 150 },
    {
      label: "Visitor Type",
      field: "visitorType",
      sort: "asc",
      width: 150,
    },

    { label: "Time In", field: "timeIn", sort: "asc", width: 150 },
    { label: "Time Out", field: "timeOut", sort: "asc", width: 150 },
    { label: "Check-in Date", field: "checkInDate", sort: "asc", width: 150 },
    { label: "Check-out Date", field: "checkOutDate", sort: "asc", width: 150 },
    { label: "Check-in By", field: "check_in_By", sort: "asc", width: 150 },
    { label: "Check-out By", field: "check_out_By", sort: "asc", width: 150 },
  ];

  const dataDB = { columns: column, rows: list };

  const viewFunc = (id) => {
    setViewID(id);
    setViewModal(true);
  };

  const handleDeleteTicket = () => {
    const Method = "DELETE",
      url = `visitors/${deleteID}`,
      isJSON = null;
    const raw = null;

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Visitor Successfully Delete",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const deleteFunc = (id) => {
    setDeleteID(id);
    setDeleteModal(true);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      setDeleteModal(false);
    }
  }, [mutation.isSuccess]);

  if (isLoadingGetMyPropVisit) {
    return <LoadingTable />;
  }

  return (
    <>
      <MDBDataTable
        entries={5}
        entriesOptions={[5, 10, 50]}
        responsive
        bordered
        striped
        hover
        data={dataDB}
      />
      <Modal show={viewModal} onHide={() => setViewModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> View Visitor Details </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ViewVisitorDetail viewID={viewID} setViewModal={setViewModal} />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-danger text-white"
            onClick={() => setViewModal(false)}
          >
            close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Delete Ticket </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-danger h4">
            Are you sure you want to delete this ticket ?
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-success text-white"
            onClick={handleDeleteTicket}
          >
            {!mutation.isLoading ? (
              "Sure"
            ) : (
              <span>
                <Spinner as="span" animation="border" size="sm" /> Loading...
              </span>
            )}
          </Button>
          <Button
            className="bg-danger text-white"
            onClick={() => setDeleteModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default PropertyVisitorTable;
