import PropTypes from "prop-types"
import React from "react";
import "./Style.scss"
import { Container, Row, CardBody, Card, Col } from "reactstrap";
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import My_modules from "./Components/My-modules";
import Available_modules from "./Components/Available-module";

const Settings = () => {

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTagComp meta_tags="FASPRO24 | settings" />
                <Breadcrumb default="Property" title="Settings" />

                <Container fluid>
                    <Row className="d-flex justify-content-around align-items-center">
                        <CardBody data-aos="fade-bottom">
                            <Row>

                                <Col md={12}>
                                    <h5>Current Subscription</h5>
                                    <My_modules />

                                    <h5>Available Features</h5>
                                    <Available_modules />

                                </Col>


                            </Row>
                        </CardBody>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

Settings.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(Settings))