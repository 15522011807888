import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Col, Row, CardBody, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import gender from "../../../components/Gender";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import useCustomMutation from "../../../hooks/useCustomMutation";
import useCustomQuery from "../../../hooks/useCustomQuery";
import useFetch from "../../../hooks/useFecth";
import Avatar from "../../../assets/images/Avatar.png";

function AddSecurityTeamForm({ setAddSecurity }) {
  const userDet = useStore1Selector(loginUser);
  const securityManagerID = userDet?.data?._id;
  const token = userDet?.token;
  const key = ["mySecurityTeam", securityManagerID];
  const mutation = useCustomMutation(key);

  const [phone, setPhone] = useState("");

  const { data: getPropertyDetails, reFetch: reFetchPropertyDetails } =
    useFetch(
      `https://squid-app-hsedq.ondigitalocean.app/api/v1/properties/property_owner?fieldName=teamId`,
      token
    );

  const handleValidSubmit = (e, v) => {
    e.preventDefault();

    console.log("property_id...", v.property_id);

    const Method = "POST",
      url = `auth/security_team/register/${securityManagerID}`,
      isJSON = true;

    const formdata = new FormData();
    formdata.append("firstName", v.firstName);
    formdata.append("lastName", v.lastName);
    formdata.append("phoneNumber", phone);
    formdata.append("gender", v.gender);
    formdata.append("email", v.email);
    formdata.append("roles", "security_worker");
    if (v.property_id && v.property_id !== "Select...") {
      formdata.append("property_id", v.property_id);
    }

    {
      v.bankName ? formdata.append("bankName", v.bankName) : null;
    }
    {
      v.branchName ? formdata.append("branchName", v.branchName) : null;
    }
    {
      v.swiftCode ? formdata.append("swiftCode", v.swiftCode) : null;
    }
    {
      v.accountNumber
        ? formdata.append("accountNumber", v.accountNumber)
        : null;
    }
    {
      v.accountType ? formdata.append("accountType", v.accountType) : null;
    }

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Security Team Successfully Added",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  if (mutation.isSuccess) {
    setAddSecurity(false);
  }

  return (
    <React.Fragment>
      <CardBody>
        <Row className="align-items-center">
          <Col md={12} lg={12} xl={12}>
            <AvForm
              className="form-horizontal mt-3"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <Row>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={Avatar}
                    alt="user"
                    width={150}
                    height={150}
                    className="rounded"
                  />
                </div>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="mb-3 mt-3">
                    <AvField
                      name="firstName"
                      label="First Name"
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3 mt-3">
                    <AvField
                      name="lastName"
                      label="Last Name"
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="Email"
                      className="form-control"
                      type="email"
                      required
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="gender"
                      label="Gender"
                      type="select"
                      required
                    >
                      <option> Select... </option>
                      {gender.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </AvField>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <AvField
                      name="property_id"
                      label="Assign property"
                      type="select"
                      required
                    >
                      <option> Select... </option>
                      {getPropertyDetails?.map((item, index) => {
                        return (
                          <option value={item?._id} key={index}>
                            {item?.propertyName}
                          </option>
                        );
                      })}
                    </AvField>
                  </div>
                </Col>
              </Row>

              <PhoneInput
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                buttonStyle={{
                  borderBottomLeftRadius: 5,
                  borderTopLeftRadius: 5,
                  height: 40,
                }}
                inputStyle={{
                  margin: 0,
                  width: "100%",
                  color: "purple",
                  height: 1,
                  border: "none",
                }}
                containerStyle={{
                  width: "100%",
                }}
                className="blue"
                country={"gh"}
                enableSearch={true}
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />

              <div className="text-center mt-3">
                <button
                  className="btn login-btn  waves-effect waves-light"
                  type="submit"
                >
                  {!mutation.isLoading ? (
                    <span className="me-2">Submit</span>
                  ) : null}

                  {!mutation.isLoading ? null : (
                    <span>
                      <Spinner as="span" animation="border" size="sm" />{" "}
                      Loading...
                    </span>
                  )}
                </button>
              </div>
            </AvForm>
          </Col>
        </Row>
      </CardBody>
    </React.Fragment>
  );
}

AddSecurityTeamForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(AddSecurityTeamForm));
