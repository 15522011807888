import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Card, CardBody, Spinner, Badge, Button } from "reactstrap";
import useCustomQuery from "../../../hooks/useCustomQuery";
import useCustomMutation from "../../../hooks/useCustomMutation";
import LoadingTable from "../../../components/LoadingTable";
import { Modal } from "react-bootstrap";
import DeleteForm from "./DeleteForm";

function PropertyFile({ propertyID, token }) {
  const [viewID, setViewID] = useState();
  const [deleteID, setDeleteID] = useState();
  const [viewModal, setViewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const key = ["myPropertiesDocuments", propertyID];
  const mutationDelete = useCustomMutation(key);

  const {
    data: getMyPropertiesDoc,
    error: errorGetMyPropertiesDoc,
    isLoading: isLoadingGetMyPropertiesDoc,
  } = useCustomQuery(
    ["myPropertiesDocuments", propertyID],
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/filemanagements/${propertyID}/file-per-property`,
    token
  );

  // Here you can return a component or null, handle errors, loading states, etc.
  //   if (errorGetMyPropertiesDoc) {
  //     return <p>Error: {errorGetMyPropertiesDoc.message}</p>;
  //   }

  console.log("myPropertiesDocuments..", getMyPropertiesDoc);

  if (isLoadingGetMyPropertiesDoc) {
    return <LoadingTable />;
  }
  const list = [];
  getMyPropertiesDoc?.forEach((res) => {
    list.push({
      ...res,
      propertyName: res?.propertyId?.propertyName,
      createdBy: `${res?.uploadedbyId?.firstName} ${res?.uploadedbyId?.lastName}`,
      viewBtn: (
        <button
          className="btn btn-success"
          onClick={() => viewFunc(`${res?._id}`)}
        >
          View
        </button>
      ),
      cancelBtn: (
        <button
          className="btn btn-warning"
          onClick={() => cancelFunc(`${res?._id}`)}
        >
          Cancel
        </button>
      ),
      deleteBtn: (
        <button
          className="btn btn-danger"
          onClick={() => deleteFunc(`${res?._id}`)}
        >
          Delete
        </button>
      ),
    });
  });

  const column = [
    {
      label: "Property name",
      field: "propertyName",
      sort: "asc",
      width: 150,
    },

    { label: "File Name", field: "documentName", sort: "asc", width: 150 },
    {
      label: "File Type",
      field: "documentType",
      sort: "asc",
      width: 150,
    },
    { label: "Created By", field: "createdBy", sort: "asc", width: 150 },

    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const dataDB = { columns: column, rows: list };

  //   const viewFunc = (id) => {
  //     history.push(`/view-ticket/${id}`);
  //   };

  const deleteFunc = (id) => {
    setDeleteID(id);
    setDeleteModal(true);
  };

  return (
    <>
      <MDBDataTable
        entries={5}
        entriesOptions={[5, 10, 50]}
        responsive
        bordered
        striped
        hover
        data={dataDB}
      />
      {/* <Modal show={viewModal} onHide={() => setViewModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> View a ticket </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ViewTicketForm
            viewID={viewID}
            setViewModal={setViewModal}
            reFetchMaintenanceTickets={reFetchMaintenanceTickets}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-danger text-white"
            onClick={() => setViewModal(false)}
          >
            close
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="p-2"> Delete File </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-danger h4">
            Are you sure you want to delete this File ?
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          {/* <DeleteForm
            propertyID={propertyID}
            token={token}
            setDeleteModal={setDeleteModal}
            deleteID={deleteID}
          /> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default PropertyFile;
