import PropTypes from "prop-types"
import React, { useState, useEffect } from 'react'
import { Container, Row, Card, CardBody } from "reactstrap";
import { MDBDataTable } from "mdbreact"
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "index";
import { loginUser } from "Redux/Slices/userSlice";
import { AvForm, AvField } from "availity-reactstrap-validation"

function CheckIn() {

    const userDet = useStore1Selector(loginUser)
    const propId = userDet?.original?.userDetails?.OwnerID
    const portfolioPropId = userDet?.OwnerID
    const [dataDb, setData] = useState([])
    const [dataProperty, setDataProperty] = useState()
    const [selectedProperty, setSelectedProperty] = useState()
    const [smExample, setSmExample] = useState(false);
    const [delteID, setDeleteID] = useState()
    const [viewModal, setViewModal] = useState()
    const API_1 = `https://prop.faspro24.com/api/property/GetPropertyList/${!propId ? userDet?.PropertyOwnerID : propId}`
    const API_2 = `https://prop.faspro24.com/api/property/GetPropertyList/${portfolioPropId}`

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(propId ? API_1 : API_2, requestOptions)
            .then(response => response.json())
            .then(result => {
                setDataProperty(result.data.sort().reverse())
            })
            .catch(error => console.log('error', error));
    }, []);

    useEffect(() => {
        getFile();
    }, [selectedProperty]);

    const getFile = () => {
        let list = [];
        const formdata = new FormData();
        formdata.append("PropertyID", selectedProperty);

        const requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`https://prop.faspro24.com/api/visitors/ListbyPropertyID`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                result?.visitors?.forEach((res) => {
                    list.push({
                        ...res?.visitor_details,
                        UnitName: res?.unit_details?.Name,
                        PropertyName: res?.property_details?.Name,
                        checkInBy: res?.signed_in_by?.FirstName
                    })
                })
                setData(list.sort().reverse())
            })
            .catch(error => console.log('error', error));
    }

    const deleteProperty = () => {
        const requestOptions = {
            method: 'DELETE',
            redirect: 'follow'
        };

        fetch(`https://prop.faspro24.com/api/files/DeleteFile/${delteID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    successMessage("Successful deleted")
                    setSmExample(false)
                    getFile();
                }
            })
            .catch(error => {
                console.log(`try again something went wrong${error.message}`)
                setSmExample(false)
            });
    }

    function handleChange(event) {
        setSelectedProperty(event.target.value)
    }

    function deleteFunc(id) {
        setDeleteID(id)
        setSmExample(true)
    }

    function viewFunc(id) {
        setDeleteID(id)
        setViewModal(true)
    }

    const column = [
        { label: "Property name", field: "PropertyName", sort: "asc", width: 150 },
        { label: "Unit name", field: "UnitName", sort: "asc", width: 150 },
        { label: "First Name", field: "Name", sort: "asc", width: 150 },
        { label: "Last Name", field: "Surname", sort: "asc", width: 150 },
        { label: "check In By", field: "checkInBy", sort: "asc", width: 150 },
        { label: "Checked in date", field: "checked_in_at", sort: "asc", width: 150 },
        { label: "Duration", field: "Duration", sort: "asc", width: 150 },
        { label: "Number of plate", field: "NumberPlate", sort: "asc", width: 150 },
        { label: "Passport / Id number", field: "PassportOrIDNumber", sort: "asc", width: 150 },
        { label: "Phone number", field: "PhoneNumber", sort: "asc", width: 150 },
    ];


    const InVisitors = dataDb?.filter(visitor => {
        return visitor.visit_status === 1
    })
    console.log(" Check In : ", InVisitors)

    const data = {
        columns: column,
        rows: InVisitors,
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTagComp meta_tags="FASPRO 24 | check in" />
                <Breadcrumb default="property" title="check in" />

                <CardBody>
                    <Container fluid>
                        <Link to="/my-access-list" className="btn login-btn w-md waves-effect waves-light mb-3" type="submit"> Back </Link>

                        <Row className="d-flex justify-content-around align-items-center">
                            <CardBody data-aos="fade-bottom">
                                <Card className="bd-rds">
                                    <CardBody>
                                        <AvForm>
                                            <h2>Select property</h2>
                                            <AvField name="propertyName" className="form-control" type="select" required onChange={handleChange}>
                                                <option> Select... </option>
                                                {
                                                    dataProperty?.map(property => (
                                                        <option value={property?.PropertyID}> {property?.Name} </option>
                                                    ))
                                                }
                                            </AvField>
                                        </AvForm>
                                    </CardBody>
                                    <CardBody>
                                        <MDBDataTable entries={5} entriesOptions={[5, 10, 50]} responsive bordered striped hover data={data} fullPagination />
                                    </CardBody>
                                </Card>
                            </CardBody>
                        </Row>


                    </Container>
                </CardBody>
            </div>
        </React.Fragment>
    )
}

CheckIn.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(CheckIn))