import React from "react";
import { AvField } from "availity-reactstrap-validation";
import { countryList } from "../../../../components/Countries";
import { Row, Col, CardBody, Button } from "reactstrap";

function Input2() {
  return (
    <div
      className="mt-4 d-flex justify-content-center container-form
  "
    >
      <div className="wrapper-form">
        <div className="d-flex justify-content-center">
          <h2>Address</h2>
        </div>
        <div className="mb-3">
          <AvField
            name="buildingName"
            label="Building Name"
            type="text"
            required
          />
        </div>

        <div className="mb-3">
          <AvField name="address" label="Address" type="text" required />
        </div>

        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField name="suburb" label="Suburb" type="text" required />
            </div>
          </Col>

          <Col md={6}>
            <div className="mb-3">
              <AvField name="city" label="City" type="text" required />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <AvField name="zip" label="Zip" type="text" required />
          </Col>
          <Col md={6}>
            <AvField
              name="state"
              label="State / Province "
              type="text"
              required
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="mb-3">
              <AvField name="country" label="Country" type="select" required>
                {countryList.map((country, index) => (
                  <option key={index}> {country} </option>
                ))}
              </AvField>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Input2;
