import PropTypes from "prop-types";
import React, { useState } from "react";
import { Container, Row, Card, CardBody, Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { CSVLink } from "react-csv";

import { useStore1Selector } from "../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
import usePost from "../../hooks/usePost";
import useFetch from "../../hooks/useFecth";
import TenantDetails from "./components/TenantDetailsForm";
import EditTeamForm from "./components/EditTeamForm";

function TeamTable({ t }) {
  const { id } = useParams();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const ownerId = userDet?.data?._id;

  const { execute, pending, data } = usePost();
  const { data: getMyTeam, reFetch: reFetchMyTeam } = useFetch(
    `https://squid-app-hsedq.ondigitalocean.app/api/v1/users/team?fieldName=propertyOwnerId`,
    token
  );
  const myTeamArrayRaw = getMyTeam ?? [];
  const [deleteModal, setDeleteModal] = useState(false);
  const [delteID, setDeleteID] = useState();
  const [viewModal, setViewModal] = useState(false);
  const [viewID, setViewID] = useState();
  const roles = [
    "property_mgt_marketing",
    "property_mgt_sales",
    "property_mgt_finance",
    "property_mgt_maintenance",
    "property_mgt_leasing_officer",
    "portfolio_manager",
    "property_agency",
    "facility_manager",
  ];
  const filteredTeamArray = myTeamArrayRaw?.filter((item) => {
    return roles?.includes(item?.roles[0]);
  });

  const myTeam = [];
  filteredTeamArray?.forEach((res) => {
    myTeam?.push({
      ...res,
      Phone_Number: `+${res?.phoneNumber}`,
      Role:
        res?.roles[0] === roles[0]
          ? "Marketing"
          : res?.roles[0] === roles[1]
          ? "Sales"
          : res?.roles[0] === roles[2]
          ? "Finance"
          : res?.roles[0] === roles[3]
          ? "Maintenance"
          : res?.roles[0] === roles[4]
          ? "Lease officer"
          : res?.roles[0] === roles[5]
          ? "Portfolio manager"
          : res?.roles[0] === roles[6]
          ? "Agency"
          : res?.roles[0] === roles[7]
          ? "Facility Manager"
          : null,
      edit: (
        <button className="btn btn-primary" onClick={() => viewFunc(res?._id)}>
          {t("View")}
        </button>
      ),
      suspendBtn:
        res?.status === true ? (
          <button
            className="btn btn-dark"
            onClick={() => suspendFunc(`${res?._id}`)}
          >
            {t("Suspend")}
          </button>
        ) : (
          <button
            className="btn btn-info"
            onClick={() => unsuspendFunc(res?._id)}
          >
            Unsuspend
          </button>
        ),
      deleteBtn: (
        <button
          className="btn btn-danger"
          onClick={() => deleteFunc(`${res?._id}`)}
        >
          {t("Delete")}
        </button>
      ),
    });
  });

  function suspendFunc(id) {
    const Method = "PATCH",
      endPoint = `users/${id}/suspended`;
    const raw = "";
    execute(endPoint, raw, Method, "User successfully suspended", token);
  }

  function unsuspendFunc(id) {
    const Method = "PATCH",
      endPoint = `users/${id}/un-suspended`;
    const raw = "";
    execute(endPoint, raw, Method, "User successfully unsuspended", token);
  }
  function viewFunc(id) {
    setViewID(id);
    setViewModal(true);
  }

  function deleteFunc(id) {
    setDeleteID(id);
    setDeleteModal(true);
  }

  const deleteTeamMember = () => {
    const Method = "DELETE",
      endPoint = `users/${delteID}`,
      isJSON = true;
    var formdata = new FormData();
    execute(
      endPoint,
      formdata,
      Method,
      "Team member successfully deleted",
      token,
      isJSON
    );

    setDeleteModal(false);
  };

  const column = [
    { label: "First Name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last Name", field: "lastName", sort: "asc", width: 150 },
    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "Phone Number", field: "Phone_Number", sort: "asc", width: 150 },
    { label: "Gender", field: "gender", sort: "asc", width: 150 },
    { label: "Role", field: "Role", sort: "asc", width: 150 },
    { label: "View Details", field: "edit", sort: "asc", width: 150 },
    { label: "Action", field: "suspendBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const newData = {
    columns: column,
    rows: myTeam,
  };
  if (data?.status === "success") {
    setTimeout(() => {
      reFetchMyTeam();
    }, 3000);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Team" />
        <Breadcrumb default="My Team" title="View my team" />
        <CardBody>
          <Container fluid>
            <Row className="d-flex justify-content-around align-items-center">
              <CardBody data-aos="fade-bottom">
                <Card className="bd-rds">
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <button className="btn login-btn w-md waves-effect waves-light mb-4 d-flex justify-content-end report-warning">
                        <CSVLink
                          data={"myTenant"}
                          separator={";"}
                          className="text-white"
                        >
                          {"Download in Excel"}
                        </CSVLink>
                      </button>
                    </div>
                    <MDBDataTable
                      entries={5}
                      entriesOptions={[5, 10, 50]}
                      responsive
                      bordered
                      striped
                      hover
                      data={newData}
                    />
                  </CardBody>
                </Card>
              </CardBody>
            </Row>
          </Container>
        </CardBody>
      </div>
      <Modal show={viewModal} onHide={() => setViewModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Upade Team Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditTeamForm
            viewID={viewID}
            setViewModal={setViewModal}
            // reFetchMyTeam={reFetchMyTeam}
          />
        </Modal.Body>
      </Modal>

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Delete Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-danger">
          <h4>Are you sure you want to delete this user? </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setDeleteModal(false)}
          >
            No
          </Button>
          <Button className="bg-success text-white" onClick={deleteTeamMember}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

TeamTable.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(TeamTable));
