import PropTypes from "prop-types"
import React, { useState } from 'react'
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { MDBDataTable } from "mdbreact"
import { withRouter, Link, useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next"

import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { CSVLink, CSVDownload } from "react-csv";

function Payment() {
  const [dataDb, setData] = useState([
    {
      tenant: "John Doe",
      vat_reg: "HY78",
      vat_num: "HK234",
      amount: '500',
      due_date: "2022-07-14",

      editBtn: <button className="btn btn-primary"
        onClick={() => history.push(`/add-property`)}>Edit</button>,

      deleteBtn: <button className="btn btn-danger"
        onClick={() => console.log("delete")}>Delete</button>,

    }
  ]);
  let history = useHistory();

  function handleClick(prm) {
    history.push(`/property-details/${prm}`);
  }

  const column = [
    { label: "Tenant Name", field: "tenant", sort: "asc", width: 150 },
    { label: "Amount", field: "amount", sort: "asc", width: 150 },
    { label: "Payment Due Date", field: "due_date", sort: "asc", width: 150 },
    { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const data = {
    columns: column,
    rows: dataDb,
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Invoice" />
        <Breadcrumb default="Dashboard" title="Invoice" />
        <CardBody>
          <Container fluid>
            <Row className="d-flex justify-content-around align-items-center">
              <CardBody data-aos="fade-bottom">
                <Card className="bd-rds">
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <Link to="/add-invoice" className="btn report-primary text-white waves-effect waves-light mb-4" type="submit"> Add new invoice  </Link>

                      <button className="btn report-warning waves-effect waves-light mb-4">
                        <CSVLink data={dataDb} separator={";"} className="text-white"> Download in Excel </CSVLink> </button>
                    </div>
                    <MDBDataTable entries={5} entriesOptions={[5, 10, 50]} responsive bordered striped hover data={data} fullPagination />
                  </CardBody>
                </Card>
              </CardBody>
            </Row>
          </Container>
        </CardBody>

      </div>
    </React.Fragment>
  )
}

Payment.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Payment))