import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, CardBody, Col, Button, Spinner } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "index";
import { loginUser } from "Redux/Slices/userSlice";
// import ViewDetails from "./components/ViewDetails";
// import EditDetails from "./components/EditDetails";
import { successMessage } from "components/Notifications";
import { AvField, AvForm } from "availity-reactstrap-validation";

//import Navigation

function BookingDeclined() {
  const userDet = useStore1Selector(loginUser);
  const ownerId = userDet?.original?.userDetails?.OwnerID;
  const [ViewModal, setViewModal] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [DeleteModal, setDeleteModal] = useState(false);
  const [SuspendModal, setSuspendModal] = useState(false);
  const [dataDb, setData] = useState([]);
  const [bookingId, setBookingId] = useState([]);
  const [dataProperty, setDataProperty] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState();
  const [loadBtn, setIloadBtn] = useState(false);

  // to keep
  // const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const [allBookableUnits, setAllBookableUnits] = useState(true);
  const [availableUnits, setAvailableUnits] = useState(false);
  const [suspendedBUnits, setSuspendedUnits] = useState(false);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://prop.faspro24.com/api/property/GetPropertyList/${ownerId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setDataProperty(result.data.sort().reverse());
      })
      .catch((error) => console.log("error", error));
  }, []);

  function ViewFunction(id) {
    setViewModal(true);
    setBookingId(id);
  }
  function EditFunction(id) {
    setEditModal(true);
    setBookingId(id);
  }
  function deleteFunc(id) {
    setDeleteModal(true);
    setBookingId(id);
  }
  function suspendFunc(id) {
    setSuspendModal(true);
    setBookingId(id);
  }
  function handleChange(event) {
    setSelectedProperty(event.target.value);
  }

  const GetBookableUnit = () => {
    let list = [];
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://prop.faspro24.com/api/booking/GetBookingListByPropertyID/${selectedProperty}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        result.forEach((res) => {
          list.push({
            ...res,
            // PropertyName: res.property.Name,
            // UnitName: res.unit.Name,
            viewBtn: (
              <button
                className="btn btn-success"
                onClick={() => ViewFunction(`${res.BookingID}`)}
              >
                {" "}
                View{" "}
              </button>
            ),
            editBtn: (
              <button
                className="btn btn-primary"
                onClick={() => EditFunction(`${res.BookingID}`)}
              >
                {" "}
                Edit{" "}
              </button>
            ),
            suspendBtn: (
              <button
                className="btn btn-info"
                onClick={() => suspendFunc(`${res.BookingID}`)}
              >
                {" "}
                Suspend{" "}
              </button>
            ),
            deleteBtn: (
              <button
                className="btn btn-danger"
                onClick={() => deleteFunc(`${res.BookingID}`)}
              >
                {" "}
                Delete{" "}
              </button>
            ),
          });
        });
        setData(list.sort().reverse());
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    GetBookableUnit();
  }, [selectedProperty]);

  const deleteProperty = () => {};

  const handleValidSubmit = (e, v) => {};

  const column = [
    { label: "Property Name", field: "PropertyName", sort: "asc", width: 150 },
    { label: "Unit Name", field: "UnitName", sort: "asc", width: 150 },
    { label: "Amount", field: "Amount", sort: "asc", width: 150 },
    { label: "Requested By", field: "requestedBy", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Approve", field: "ApproveBtn", sort: "asc", width: 150 },
    { label: "Decline", field: "DeclineBtn", sort: "asc", width: 150 },
  ];

  const data = {
    columns: column,
    rows: dataDb,
  };

  return (
    <div>
      <CardBody>
        <div className="d-flex justify-content-end">
          <button className="btn report-warning w-md waves-effect waves-light mb-4 d-flex justify-content-end">
            <CSVLink data={dataDb} separator={";"} className="text-white">
              Download in Excel
            </CSVLink>
          </button>
        </div>
        <MDBDataTable
          entries={5}
          entriesOptions={[5, 10, 50]}
          responsive
          bordered
          striped
          hover
          data={data}
          fullPagination
        />
      </CardBody>
      <Modal
        className="modal-right scroll-out-negative"
        show={ViewModal}
        onHide={() => setViewModal(false)}
        scrollable
        dialogClassName="full"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> View Details </Modal.Title>{" "}
        </Modal.Header>

        <Modal.Body>{/* <ViewDetails bookUnitId={bookingId} /> */}</Modal.Body>
      </Modal>

      <Modal
        className="modal-right scroll-out-negative"
        show={EditModal}
        onHide={() => setEditModal(false)}
        scrollable
        dialogClassName="full"
        size="lg"
      >
        <Modal.Header closeButton>
          {" "}
          <Modal.Title>Edit details</Modal.Title>{" "}
        </Modal.Header>

        <Modal.Body>{/* <EditDetails bookUnitId={bookingId} /> */}</Modal.Body>
      </Modal>

      <Modal show={DeleteModal} onHide={() => setDeleteModal(false)} size="sm">
        <Modal.Body className="text-danger">
          {" "}
          <h4> Are you sure you want to delete this ? </h4>{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-success"
            onClick={() => setDeleteModal(false)}
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button className="btn btn-danger" onClick={deleteProperty}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={SuspendModal}
        onHide={() => setSuspendModal(false)}
        size="md"
      >
        <Modal.Body>
          <h4 className="text-danger"> Suspend the unit </h4>

          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v);
            }}
          >
            <Row>
              <Col md={6}>
                <AvField
                  name="startTime"
                  label="Start time"
                  className="form-control mb-2"
                  type="time"
                />
              </Col>
              <Col md={6}>
                <AvField
                  name="endTime"
                  label="End time"
                  className="form-control mb-2"
                  type="time"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <AvField
                  name="startDate"
                  label="Start date"
                  className="form-control mb-2"
                  type="date"
                />
              </Col>
              <Col md={6}>
                <AvField
                  name="endDate"
                  label="End date"
                  className="form-control mb-2"
                  type="date"
                />
              </Col>
            </Row>

            <button
              className="btn login-btn w-md waves-effect waves-light"
              type="submit"
            >
              {!loadBtn ? <span className="me-2"> Submit </span> : null}
              {!loadBtn ? null : (
                <span>
                  {" "}
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    className="me-2"
                  />
                  Loading...
                </span>
              )}
            </button>
          </AvForm>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-danger"
            onClick={() => setSuspendModal(false)}
          >
            {" "}
            Cancel{" "}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

BookingDeclined.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(BookingDeclined));
